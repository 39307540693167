// Generated by protobufjs
// @ts-nocheck
import * as $protobuf from "protobufjs/minimal";

/** Namespace affiliate. */
export namespace affiliate {

    /** Properties of an AffiliateLandingParam. */
    export interface IAffiliateLandingParam {

        /** AffiliateLandingParam key */
        key?: (string);

        /** AffiliateLandingParam value */
        value?: (string);
    }

    /** Represents an AffiliateLandingParam. */
    class AffiliateLandingParam implements IAffiliateLandingParam {

        /**
         * Constructs a new AffiliateLandingParam.
         * @param [properties] Properties to set
         */
        constructor(properties?: affiliate.IAffiliateLandingParam);

        /** AffiliateLandingParam key. */
        public key?: (string);

        /** AffiliateLandingParam value. */
        public value?: (string);

        /**
         * Gets the default type url for AffiliateLandingParam
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AffiliatePostLandingEventRequest. */
    export interface IAffiliatePostLandingEventRequest {

        /** AffiliatePostLandingEventRequest sessionId */
        sessionId?: (string);

        /** AffiliatePostLandingEventRequest paramList */
        paramList?: (affiliate.IAffiliateLandingParam[]);
    }

    /** Represents an AffiliatePostLandingEventRequest. */
    class AffiliatePostLandingEventRequest implements IAffiliatePostLandingEventRequest {

        /**
         * Constructs a new AffiliatePostLandingEventRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: affiliate.IAffiliatePostLandingEventRequest);

        /** AffiliatePostLandingEventRequest sessionId. */
        public sessionId?: (string);

        /** AffiliatePostLandingEventRequest paramList. */
        public paramList: affiliate.IAffiliateLandingParam[];

        /**
         * Gets the default type url for AffiliatePostLandingEventRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AffiliatePostRegistrationEventRequest. */
    export interface IAffiliatePostRegistrationEventRequest {

        /** AffiliatePostRegistrationEventRequest sessionId */
        sessionId?: (string);
    }

    /** Represents an AffiliatePostRegistrationEventRequest. */
    class AffiliatePostRegistrationEventRequest implements IAffiliatePostRegistrationEventRequest {

        /**
         * Constructs a new AffiliatePostRegistrationEventRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: affiliate.IAffiliatePostRegistrationEventRequest);

        /** AffiliatePostRegistrationEventRequest sessionId. */
        public sessionId?: (string);

        /**
         * Gets the default type url for AffiliatePostRegistrationEventRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace types. */
export namespace types {

    /** SharedWithStatus enum. */
    export enum SharedWithStatus {
        SHARED_WITH_STATUS_UNKNOWN = "SHARED_WITH_STATUS_UNKNOWN",
        SHARED_WITH_NOBODY = "SHARED_WITH_NOBODY",
        SHARED_WITH_DIRECT_LINK = "SHARED_WITH_DIRECT_LINK",
        SHARED_WITH_PUBLIC = "SHARED_WITH_PUBLIC",
        SHARED_WITH_FOLLOWER = "SHARED_WITH_FOLLOWER",
        SHARED_WITH_MUTUAL_FOLLOWER = "SHARED_WITH_MUTUAL_FOLLOWER"
    }

    /** OS enum. */
    export enum OS {
        OS_UNSPECIFIED = "OS_UNSPECIFIED",
        OS_IOS = "OS_IOS",
        OS_ANDROID = "OS_ANDROID"
    }

    /** Properties of an Empty. */
    export interface IEmpty {
    }

    /** Represents an Empty. */
    class Empty implements IEmpty {

        /**
         * Constructs a new Empty.
         * @param [properties] Properties to set
         */
        constructor(properties?: types.IEmpty);

        /**
         * Gets the default type url for Empty
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** CommentRestrictionType enum. */
    export enum CommentRestrictionType {
        COMMENT_RESTRICTION_TYPE_UNSPECIFIED = "COMMENT_RESTRICTION_TYPE_UNSPECIFIED",
        COMMENT_RESTRICTION_TYPE_UNRESTRICTED = "COMMENT_RESTRICTION_TYPE_UNRESTRICTED",
        COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS_AND_FOLLOWINGS = "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS_AND_FOLLOWINGS",
        COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWINGS = "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWINGS",
        COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS = "COMMENT_RESTRICTION_TYPE_ONLY_FOLLOWERS"
    }

    /** Properties of a UInt32Range. */
    export interface IUInt32Range {

        /** UInt32Range greaterThanEqual */
        greaterThanEqual?: (number);

        /** UInt32Range lessThan */
        lessThan?: (number);
    }

    /** Represents a UInt32Range. */
    class UInt32Range implements IUInt32Range {

        /**
         * Constructs a new UInt32Range.
         * @param [properties] Properties to set
         */
        constructor(properties?: types.IUInt32Range);

        /** UInt32Range greaterThanEqual. */
        public greaterThanEqual?: (number);

        /** UInt32Range lessThan. */
        public lessThan?: (number);

        /**
         * Gets the default type url for UInt32Range
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TimestampRange. */
    export interface ITimestampRange {

        /** TimestampRange greaterThanEqual */
        greaterThanEqual?: (string);

        /** TimestampRange lessThan */
        lessThan?: (string);
    }

    /** Represents a TimestampRange. */
    class TimestampRange implements ITimestampRange {

        /**
         * Constructs a new TimestampRange.
         * @param [properties] Properties to set
         */
        constructor(properties?: types.ITimestampRange);

        /** TimestampRange greaterThanEqual. */
        public greaterThanEqual?: (string);

        /** TimestampRange lessThan. */
        public lessThan?: (string);

        /**
         * Gets the default type url for TimestampRange
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** ViolationType enum. */
    export enum ViolationType {
        VIOLATION_TYPE_UNKNOWN = "VIOLATION_TYPE_UNKNOWN",
        VIOLATION_TYPE_VIOLENCE = "VIOLATION_TYPE_VIOLENCE",
        VIOLATION_TYPE_HARASSMENT = "VIOLATION_TYPE_HARASSMENT",
        VIOLATION_TYPE_PRIVACY = "VIOLATION_TYPE_PRIVACY",
        VIOLATION_TYPE_RIGHTS = "VIOLATION_TYPE_RIGHTS",
        VIOLATION_TYPE_ADULT = "VIOLATION_TYPE_ADULT",
        VIOLATION_TYPE_SPAM = "VIOLATION_TYPE_SPAM",
        VIOLATION_TYPE_OTHER = "VIOLATION_TYPE_OTHER"
    }

    /** Platform enum. */
    export enum Platform {
        PLATFORM_UNSPECIFIED = "PLATFORM_UNSPECIFIED",
        PLATFORM_IOS_APP = "PLATFORM_IOS_APP",
        PLATFORM_ANDROID_APP = "PLATFORM_ANDROID_APP",
        PLATFORM_WEB_APP = "PLATFORM_WEB_APP"
    }

    /** App enum. */
    export enum App {
        APP_UNSPECIFIED = "APP_UNSPECIFIED",
        APP_TELLER_NOVEL = "APP_TELLER_NOVEL",
        APP_TELLER_DRAMA = "APP_TELLER_DRAMA"
    }
}

/** Namespace bonus. */
export namespace bonus {

    /** Properties of a PawBonusStatus. */
    export interface IPawBonusStatus {

        /** PawBonusStatus availableCount */
        availableCount?: (number);

        /** PawBonusStatus amount */
        amount?: (number);

        /** PawBonusStatus nextGainableAt */
        nextGainableAt?: (string);
    }

    /** Represents a PawBonusStatus. */
    class PawBonusStatus implements IPawBonusStatus {

        /**
         * Constructs a new PawBonusStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: bonus.IPawBonusStatus);

        /** PawBonusStatus availableCount. */
        public availableCount?: (number);

        /** PawBonusStatus amount. */
        public amount?: (number);

        /** PawBonusStatus nextGainableAt. */
        public nextGainableAt?: (string);

        /**
         * Gets the default type url for PawBonusStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a LoginBonusStatus. */
    export interface ILoginBonusStatus {

        /** LoginBonusStatus availableCount */
        availableCount?: (number);

        /** LoginBonusStatus nextGainableAt */
        nextGainableAt?: (string);

        /** LoginBonusStatus currentIndex */
        currentIndex?: (number);

        /** LoginBonusStatus steps */
        steps?: (bonus.ILoginBonusStep[]);
    }

    /** Represents a LoginBonusStatus. */
    class LoginBonusStatus implements ILoginBonusStatus {

        /**
         * Constructs a new LoginBonusStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: bonus.ILoginBonusStatus);

        /** LoginBonusStatus availableCount. */
        public availableCount?: (number);

        /** LoginBonusStatus nextGainableAt. */
        public nextGainableAt?: (string);

        /** LoginBonusStatus currentIndex. */
        public currentIndex?: (number);

        /** LoginBonusStatus steps. */
        public steps: bonus.ILoginBonusStep[];

        /**
         * Gets the default type url for LoginBonusStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a LoginBonusStep. */
    export interface ILoginBonusStep {

        /** LoginBonusStep amount */
        amount?: (number);

        /** LoginBonusStep imageUrl */
        imageUrl?: (string);
    }

    /** Represents a LoginBonusStep. */
    class LoginBonusStep implements ILoginBonusStep {

        /**
         * Constructs a new LoginBonusStep.
         * @param [properties] Properties to set
         */
        constructor(properties?: bonus.ILoginBonusStep);

        /** LoginBonusStep amount. */
        public amount?: (number);

        /** LoginBonusStep imageUrl. */
        public imageUrl?: (string);

        /**
         * Gets the default type url for LoginBonusStep
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TimeLimitedBonusStatus. */
    export interface ITimeLimitedBonusStatus {

        /** TimeLimitedBonusStatus availableCount */
        availableCount?: (number);

        /** TimeLimitedBonusStatus expiresAt */
        expiresAt?: (string);
    }

    /** Represents a TimeLimitedBonusStatus. */
    class TimeLimitedBonusStatus implements ITimeLimitedBonusStatus {

        /**
         * Constructs a new TimeLimitedBonusStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: bonus.ITimeLimitedBonusStatus);

        /** TimeLimitedBonusStatus availableCount. */
        public availableCount?: (number);

        /** TimeLimitedBonusStatus expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for TimeLimitedBonusStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BonusGetStatusResponse. */
    export interface IBonusGetStatusResponse {

        /** BonusGetStatusResponse sharing */
        sharing?: (bonus.IPawBonusStatus);

        /** BonusGetStatusResponse advertisement */
        advertisement?: (bonus.IPawBonusStatus);

        /** BonusGetStatusResponse beginner */
        beginner?: (bonus.IPawBonusStatus);

        /** BonusGetStatusResponse login */
        login?: (bonus.ILoginBonusStatus);

        /** BonusGetStatusResponse review */
        review?: (bonus.IPawBonusStatus);

        /** BonusGetStatusResponse timeLimited */
        timeLimited?: (bonus.ITimeLimitedBonusStatus);
    }

    /** Represents a BonusGetStatusResponse. */
    class BonusGetStatusResponse implements IBonusGetStatusResponse {

        /**
         * Constructs a new BonusGetStatusResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: bonus.IBonusGetStatusResponse);

        /** BonusGetStatusResponse sharing. */
        public sharing?: (bonus.IPawBonusStatus);

        /** BonusGetStatusResponse advertisement. */
        public advertisement?: (bonus.IPawBonusStatus);

        /** BonusGetStatusResponse beginner. */
        public beginner?: (bonus.IPawBonusStatus);

        /** BonusGetStatusResponse login. */
        public login?: (bonus.ILoginBonusStatus);

        /** BonusGetStatusResponse review. */
        public review?: (bonus.IPawBonusStatus);

        /** BonusGetStatusResponse timeLimited. */
        public timeLimited?: (bonus.ITimeLimitedBonusStatus);

        /**
         * Gets the default type url for BonusGetStatusResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BonusGainPawResponse. */
    export interface IBonusGainPawResponse {

        /** BonusGainPawResponse current */
        current?: (paw.IPaw);

        /** BonusGainPawResponse gainedAmount */
        gainedAmount?: (number);
    }

    /** Represents a BonusGainPawResponse. */
    class BonusGainPawResponse implements IBonusGainPawResponse {

        /**
         * Constructs a new BonusGainPawResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: bonus.IBonusGainPawResponse);

        /** BonusGainPawResponse current. */
        public current?: (paw.IPaw);

        /** BonusGainPawResponse gainedAmount. */
        public gainedAmount?: (number);

        /**
         * Gets the default type url for BonusGainPawResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace paw. */
export namespace paw {

    /** Properties of a Paw. */
    export interface IPaw {

        /** Paw userId */
        userId?: (string);

        /** Paw amount */
        amount?: (number);

        /** Paw updatedAt */
        updatedAt?: (string);

        /** Paw nextGainAt */
        nextGainAt?: (string);

        /** Paw upperLimit */
        upperLimit?: (number);

        /** Paw emptyCount */
        emptyCount?: (number);
    }

    /** Represents a Paw. */
    class Paw implements IPaw {

        /**
         * Constructs a new Paw.
         * @param [properties] Properties to set
         */
        constructor(properties?: paw.IPaw);

        /** Paw userId. */
        public userId?: (string);

        /** Paw amount. */
        public amount?: (number);

        /** Paw updatedAt. */
        public updatedAt?: (string);

        /** Paw nextGainAt. */
        public nextGainAt?: (string);

        /** Paw upperLimit. */
        public upperLimit?: (number);

        /** Paw emptyCount. */
        public emptyCount?: (number);

        /**
         * Gets the default type url for Paw
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace bookmark. */
export namespace bookmark {

    /** BookmarkItemType enum. */
    export enum BookmarkItemType {
        BOOKMARK_ITEM_TYPE_UNSPECIFIED = "BOOKMARK_ITEM_TYPE_UNSPECIFIED",
        BOOKMARK_ITEM_TYPE_STORY = "BOOKMARK_ITEM_TYPE_STORY",
        BOOKMARK_ITEM_TYPE_SERIES = "BOOKMARK_ITEM_TYPE_SERIES"
    }

    /** Properties of a BookmarkItem. */
    export interface IBookmarkItem {

        /** BookmarkItem type */
        type?: (bookmark.BookmarkItemType);

        /** BookmarkItem story */
        story?: (story.IStoryHeader);

        /** BookmarkItem series */
        series?: (series.ISeries);

        /** BookmarkItem labelIdList */
        labelIdList?: (string[]);

        /** BookmarkItem createdAt */
        createdAt?: (string);

        /** BookmarkItem updatedAt */
        updatedAt?: (string);
    }

    /** Represents a BookmarkItem. */
    class BookmarkItem implements IBookmarkItem {

        /**
         * Constructs a new BookmarkItem.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkItem);

        /** BookmarkItem type. */
        public type: bookmark.BookmarkItemType;

        /** BookmarkItem story. */
        public story?: (story.IStoryHeader);

        /** BookmarkItem series. */
        public series?: (series.ISeries);

        /** BookmarkItem labelIdList. */
        public labelIdList: string[];

        /** BookmarkItem createdAt. */
        public createdAt?: (string);

        /** BookmarkItem updatedAt. */
        public updatedAt?: (string);

        /** BookmarkItem itemOneof. */
        public itemOneof?: ("story"|"series");

        /**
         * Gets the default type url for BookmarkItem
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkItemV2. */
    export interface IBookmarkItemV2 {

        /** BookmarkItemV2 type */
        type?: (bookmark.BookmarkItemType);

        /** BookmarkItemV2 storyId */
        storyId?: (string);

        /** BookmarkItemV2 seriesId */
        seriesId?: (string);

        /** BookmarkItemV2 labelIdList */
        labelIdList?: (string[]);

        /** BookmarkItemV2 isItemDeleted */
        isItemDeleted?: (boolean);

        /** BookmarkItemV2 createdAt */
        createdAt?: (string);

        /** BookmarkItemV2 updatedAt */
        updatedAt?: (string);
    }

    /** Represents a BookmarkItemV2. */
    class BookmarkItemV2 implements IBookmarkItemV2 {

        /**
         * Constructs a new BookmarkItemV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkItemV2);

        /** BookmarkItemV2 type. */
        public type: bookmark.BookmarkItemType;

        /** BookmarkItemV2 storyId. */
        public storyId?: (string);

        /** BookmarkItemV2 seriesId. */
        public seriesId?: (string);

        /** BookmarkItemV2 labelIdList. */
        public labelIdList: string[];

        /** BookmarkItemV2 isItemDeleted. */
        public isItemDeleted?: (boolean);

        /** BookmarkItemV2 createdAt. */
        public createdAt?: (string);

        /** BookmarkItemV2 updatedAt. */
        public updatedAt?: (string);

        /** BookmarkItemV2 idOneof. */
        public idOneof?: ("storyId"|"seriesId");

        /**
         * Gets the default type url for BookmarkItemV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkLabel. */
    export interface IBookmarkLabel {

        /** BookmarkLabel id */
        id?: (string);

        /** BookmarkLabel name */
        name?: (string);

        /** BookmarkLabel userId */
        userId?: (string);

        /** BookmarkLabel description */
        description?: (string);

        /** BookmarkLabel count */
        count?: (number);

        /** BookmarkLabel sharedWithStatus */
        sharedWithStatus?: (types.SharedWithStatus);

        /** BookmarkLabel createdAt */
        createdAt?: (string);

        /** BookmarkLabel updatedAt */
        updatedAt?: (string);
    }

    /** Represents a BookmarkLabel. */
    class BookmarkLabel implements IBookmarkLabel {

        /**
         * Constructs a new BookmarkLabel.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkLabel);

        /** BookmarkLabel id. */
        public id?: (string);

        /** BookmarkLabel name. */
        public name?: (string);

        /** BookmarkLabel userId. */
        public userId?: (string);

        /** BookmarkLabel description. */
        public description?: (string);

        /** BookmarkLabel count. */
        public count?: (number);

        /** BookmarkLabel sharedWithStatus. */
        public sharedWithStatus: types.SharedWithStatus;

        /** BookmarkLabel createdAt. */
        public createdAt?: (string);

        /** BookmarkLabel updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for BookmarkLabel
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkItemId. */
    export interface IBookmarkItemId {

        /** BookmarkItemId type */
        type?: (bookmark.BookmarkItemType);

        /** BookmarkItemId storyId */
        storyId?: (string);

        /** BookmarkItemId seriesId */
        seriesId?: (string);
    }

    /** Represents a BookmarkItemId. */
    class BookmarkItemId implements IBookmarkItemId {

        /**
         * Constructs a new BookmarkItemId.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkItemId);

        /** BookmarkItemId type. */
        public type: bookmark.BookmarkItemType;

        /** BookmarkItemId storyId. */
        public storyId?: (string);

        /** BookmarkItemId seriesId. */
        public seriesId?: (string);

        /** BookmarkItemId idOneof. */
        public idOneof?: ("storyId"|"seriesId");

        /**
         * Gets the default type url for BookmarkItemId
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkIsAddedMultiRequest. */
    export interface IBookmarkIsAddedMultiRequest {

        /** BookmarkIsAddedMultiRequest idList */
        idList?: (bookmark.IBookmarkItemId[]);
    }

    /** Represents a BookmarkIsAddedMultiRequest. */
    class BookmarkIsAddedMultiRequest implements IBookmarkIsAddedMultiRequest {

        /**
         * Constructs a new BookmarkIsAddedMultiRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkIsAddedMultiRequest);

        /** BookmarkIsAddedMultiRequest idList. */
        public idList: bookmark.IBookmarkItemId[];

        /**
         * Gets the default type url for BookmarkIsAddedMultiRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkAdded. */
    export interface IBookmarkAdded {

        /** BookmarkAdded id */
        id?: (bookmark.IBookmarkItemId);

        /** BookmarkAdded added */
        added?: (boolean);
    }

    /** Represents a BookmarkAdded. */
    class BookmarkAdded implements IBookmarkAdded {

        /**
         * Constructs a new BookmarkAdded.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkAdded);

        /** BookmarkAdded id. */
        public id?: (bookmark.IBookmarkItemId);

        /** BookmarkAdded added. */
        public added?: (boolean);

        /**
         * Gets the default type url for BookmarkAdded
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkIsAddedMultiResponse. */
    export interface IBookmarkIsAddedMultiResponse {

        /** BookmarkIsAddedMultiResponse addedList */
        addedList?: (bookmark.IBookmarkAdded[]);
    }

    /** Represents a BookmarkIsAddedMultiResponse. */
    class BookmarkIsAddedMultiResponse implements IBookmarkIsAddedMultiResponse {

        /**
         * Constructs a new BookmarkIsAddedMultiResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkIsAddedMultiResponse);

        /** BookmarkIsAddedMultiResponse addedList. */
        public addedList: bookmark.IBookmarkAdded[];

        /**
         * Gets the default type url for BookmarkIsAddedMultiResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkUserCount. */
    export interface IBookmarkUserCount {

        /** BookmarkUserCount id */
        id?: (bookmark.IBookmarkItemId);

        /** BookmarkUserCount count */
        count?: (number);
    }

    /** Represents a BookmarkUserCount. */
    class BookmarkUserCount implements IBookmarkUserCount {

        /**
         * Constructs a new BookmarkUserCount.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkUserCount);

        /** BookmarkUserCount id. */
        public id?: (bookmark.IBookmarkItemId);

        /** BookmarkUserCount count. */
        public count?: (number);

        /**
         * Gets the default type url for BookmarkUserCount
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkCountUserMultiRequest. */
    export interface IBookmarkCountUserMultiRequest {

        /** BookmarkCountUserMultiRequest idList */
        idList?: (bookmark.IBookmarkItemId[]);
    }

    /** Represents a BookmarkCountUserMultiRequest. */
    class BookmarkCountUserMultiRequest implements IBookmarkCountUserMultiRequest {

        /**
         * Constructs a new BookmarkCountUserMultiRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkCountUserMultiRequest);

        /** BookmarkCountUserMultiRequest idList. */
        public idList: bookmark.IBookmarkItemId[];

        /**
         * Gets the default type url for BookmarkCountUserMultiRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkCountUserMultiResponse. */
    export interface IBookmarkCountUserMultiResponse {

        /** BookmarkCountUserMultiResponse count */
        count?: (bookmark.IBookmarkUserCount[]);
    }

    /** Represents a BookmarkCountUserMultiResponse. */
    class BookmarkCountUserMultiResponse implements IBookmarkCountUserMultiResponse {

        /**
         * Constructs a new BookmarkCountUserMultiResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkCountUserMultiResponse);

        /** BookmarkCountUserMultiResponse count. */
        public count: bookmark.IBookmarkUserCount[];

        /**
         * Gets the default type url for BookmarkCountUserMultiResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkAddLabelRequest. */
    export interface IBookmarkAddLabelRequest {

        /** BookmarkAddLabelRequest idList */
        idList?: (bookmark.IBookmarkItemId[]);

        /** BookmarkAddLabelRequest labelId */
        labelId?: (string);
    }

    /** Represents a BookmarkAddLabelRequest. */
    class BookmarkAddLabelRequest implements IBookmarkAddLabelRequest {

        /**
         * Constructs a new BookmarkAddLabelRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkAddLabelRequest);

        /** BookmarkAddLabelRequest idList. */
        public idList: bookmark.IBookmarkItemId[];

        /** BookmarkAddLabelRequest labelId. */
        public labelId?: (string);

        /**
         * Gets the default type url for BookmarkAddLabelRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkRemoveLabelRequest. */
    export interface IBookmarkRemoveLabelRequest {

        /** BookmarkRemoveLabelRequest idList */
        idList?: (bookmark.IBookmarkItemId[]);

        /** BookmarkRemoveLabelRequest labelId */
        labelId?: (string);
    }

    /** Represents a BookmarkRemoveLabelRequest. */
    class BookmarkRemoveLabelRequest implements IBookmarkRemoveLabelRequest {

        /**
         * Constructs a new BookmarkRemoveLabelRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkRemoveLabelRequest);

        /** BookmarkRemoveLabelRequest idList. */
        public idList: bookmark.IBookmarkItemId[];

        /** BookmarkRemoveLabelRequest labelId. */
        public labelId?: (string);

        /**
         * Gets the default type url for BookmarkRemoveLabelRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkGetStatusResponse. */
    export interface IBookmarkGetStatusResponse {

        /** BookmarkGetStatusResponse canAddItem */
        canAddItem?: (boolean);

        /** BookmarkGetStatusResponse vipRequired */
        vipRequired?: (boolean);

        /** BookmarkGetStatusResponse totalCount */
        totalCount?: (number);

        /** BookmarkGetStatusResponse limitCount */
        limitCount?: (number);
    }

    /** Represents a BookmarkGetStatusResponse. */
    class BookmarkGetStatusResponse implements IBookmarkGetStatusResponse {

        /**
         * Constructs a new BookmarkGetStatusResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkGetStatusResponse);

        /** BookmarkGetStatusResponse canAddItem. */
        public canAddItem?: (boolean);

        /** BookmarkGetStatusResponse vipRequired. */
        public vipRequired?: (boolean);

        /** BookmarkGetStatusResponse totalCount. */
        public totalCount?: (number);

        /** BookmarkGetStatusResponse limitCount. */
        public limitCount?: (number);

        /**
         * Gets the default type url for BookmarkGetStatusResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkIsAddedResponse. */
    export interface IBookmarkIsAddedResponse {

        /** BookmarkIsAddedResponse added */
        added?: (boolean);
    }

    /** Represents a BookmarkIsAddedResponse. */
    class BookmarkIsAddedResponse implements IBookmarkIsAddedResponse {

        /**
         * Constructs a new BookmarkIsAddedResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkIsAddedResponse);

        /** BookmarkIsAddedResponse added. */
        public added?: (boolean);

        /**
         * Gets the default type url for BookmarkIsAddedResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkLabelIdRequest. */
    export interface IBookmarkLabelIdRequest {

        /** BookmarkLabelIdRequest labelId */
        labelId?: (string);
    }

    /** Represents a BookmarkLabelIdRequest. */
    class BookmarkLabelIdRequest implements IBookmarkLabelIdRequest {

        /**
         * Constructs a new BookmarkLabelIdRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkLabelIdRequest);

        /** BookmarkLabelIdRequest labelId. */
        public labelId?: (string);

        /**
         * Gets the default type url for BookmarkLabelIdRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkListItemRequest. */
    export interface IBookmarkListItemRequest {

        /** BookmarkListItemRequest labelId */
        labelId?: (string);

        /** BookmarkListItemRequest limit */
        limit?: (number);

        /** BookmarkListItemRequest cursor */
        cursor?: (string);
    }

    /** Represents a BookmarkListItemRequest. */
    class BookmarkListItemRequest implements IBookmarkListItemRequest {

        /**
         * Constructs a new BookmarkListItemRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkListItemRequest);

        /** BookmarkListItemRequest labelId. */
        public labelId?: (string);

        /** BookmarkListItemRequest limit. */
        public limit?: (number);

        /** BookmarkListItemRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for BookmarkListItemRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkListItemRequestV2. */
    export interface IBookmarkListItemRequestV2 {

        /** BookmarkListItemRequestV2 labelId */
        labelId?: (string);

        /** BookmarkListItemRequestV2 limit */
        limit?: (number);

        /** BookmarkListItemRequestV2 cursor */
        cursor?: (string);
    }

    /** Represents a BookmarkListItemRequestV2. */
    class BookmarkListItemRequestV2 implements IBookmarkListItemRequestV2 {

        /**
         * Constructs a new BookmarkListItemRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkListItemRequestV2);

        /** BookmarkListItemRequestV2 labelId. */
        public labelId?: (string);

        /** BookmarkListItemRequestV2 limit. */
        public limit?: (number);

        /** BookmarkListItemRequestV2 cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for BookmarkListItemRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkListItemResponse. */
    export interface IBookmarkListItemResponse {

        /** BookmarkListItemResponse contentList */
        contentList?: (bookmark.IBookmarkItem[]);

        /** BookmarkListItemResponse cursor */
        cursor?: (string);
    }

    /** Represents a BookmarkListItemResponse. */
    class BookmarkListItemResponse implements IBookmarkListItemResponse {

        /**
         * Constructs a new BookmarkListItemResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkListItemResponse);

        /** BookmarkListItemResponse contentList. */
        public contentList: bookmark.IBookmarkItem[];

        /** BookmarkListItemResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for BookmarkListItemResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkListItemResponseV2. */
    export interface IBookmarkListItemResponseV2 {

        /** BookmarkListItemResponseV2 contentList */
        contentList?: (bookmark.IBookmarkItemV2[]);

        /** BookmarkListItemResponseV2 cursor */
        cursor?: (string);
    }

    /** Represents a BookmarkListItemResponseV2. */
    class BookmarkListItemResponseV2 implements IBookmarkListItemResponseV2 {

        /**
         * Constructs a new BookmarkListItemResponseV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkListItemResponseV2);

        /** BookmarkListItemResponseV2 contentList. */
        public contentList: bookmark.IBookmarkItemV2[];

        /** BookmarkListItemResponseV2 cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for BookmarkListItemResponseV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkListLabelRequest. */
    export interface IBookmarkListLabelRequest {

        /** BookmarkListLabelRequest userId */
        userId?: (string);

        /** BookmarkListLabelRequest limit */
        limit?: (number);

        /** BookmarkListLabelRequest cursor */
        cursor?: (string);
    }

    /** Represents a BookmarkListLabelRequest. */
    class BookmarkListLabelRequest implements IBookmarkListLabelRequest {

        /**
         * Constructs a new BookmarkListLabelRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkListLabelRequest);

        /** BookmarkListLabelRequest userId. */
        public userId?: (string);

        /** BookmarkListLabelRequest limit. */
        public limit?: (number);

        /** BookmarkListLabelRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for BookmarkListLabelRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkListLabelResponse. */
    export interface IBookmarkListLabelResponse {

        /** BookmarkListLabelResponse labelList */
        labelList?: (bookmark.IBookmarkLabel[]);

        /** BookmarkListLabelResponse cursor */
        cursor?: (string);
    }

    /** Represents a BookmarkListLabelResponse. */
    class BookmarkListLabelResponse implements IBookmarkListLabelResponse {

        /**
         * Constructs a new BookmarkListLabelResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: bookmark.IBookmarkListLabelResponse);

        /** BookmarkListLabelResponse labelList. */
        public labelList: bookmark.IBookmarkLabel[];

        /** BookmarkListLabelResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for BookmarkListLabelResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace series. */
export namespace series {

    /** Properties of a SeriesIdRequest. */
    export interface ISeriesIdRequest {

        /** SeriesIdRequest id */
        id?: (string);
    }

    /** Represents a SeriesIdRequest. */
    class SeriesIdRequest implements ISeriesIdRequest {

        /**
         * Constructs a new SeriesIdRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: series.ISeriesIdRequest);

        /** SeriesIdRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for SeriesIdRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a Series. */
    export interface ISeries {

        /** Series id */
        id?: (string);

        /** Series userId */
        userId?: (string);

        /** Series tags */
        tags?: (string[]);

        /** Series title */
        title?: (string);

        /** Series description */
        description?: (string);

        /** Series thumbnail */
        thumbnail?: (resource.IImage);

        /** Series isOfficial */
        isOfficial?: (boolean);

        /** Series isCompleted */
        isCompleted?: (boolean);

        /** Series nextPublishAt */
        nextPublishAt?: (string);

        /** Series createdAt */
        createdAt?: (string);

        /** Series updatedAt */
        updatedAt?: (string);

        /** Series sharedWithStatus */
        sharedWithStatus?: (types.SharedWithStatus);

        /** Series hasVipOnlyStory */
        hasVipOnlyStory?: (boolean);

        /** Series isPawRequired */
        isPawRequired?: (boolean);
    }

    /** Represents a Series. */
    class Series implements ISeries {

        /**
         * Constructs a new Series.
         * @param [properties] Properties to set
         */
        constructor(properties?: series.ISeries);

        /** Series id. */
        public id?: (string);

        /** Series userId. */
        public userId?: (string);

        /** Series tags. */
        public tags: string[];

        /** Series title. */
        public title?: (string);

        /** Series description. */
        public description?: (string);

        /** Series thumbnail. */
        public thumbnail?: (resource.IImage);

        /** Series isOfficial. */
        public isOfficial?: (boolean);

        /** Series isCompleted. */
        public isCompleted?: (boolean);

        /** Series nextPublishAt. */
        public nextPublishAt?: (string);

        /** Series createdAt. */
        public createdAt?: (string);

        /** Series updatedAt. */
        public updatedAt?: (string);

        /** Series sharedWithStatus. */
        public sharedWithStatus: types.SharedWithStatus;

        /** Series hasVipOnlyStory. */
        public hasVipOnlyStory?: (boolean);

        /** Series isPawRequired. */
        public isPawRequired?: (boolean);

        /**
         * Gets the default type url for Series
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesV1. */
    export interface ISeriesV1 {

        /** SeriesV1 seriesId */
        seriesId?: (string);

        /** SeriesV1 userId */
        userId?: (string);

        /** SeriesV1 tags */
        tags?: (string[]);

        /** SeriesV1 title */
        title?: (string);

        /** SeriesV1 description */
        description?: (string);

        /** SeriesV1 thumbnailUrl */
        thumbnailUrl?: (string);

        /** SeriesV1 isOfficial */
        isOfficial?: (boolean);

        /** SeriesV1 isCompleted */
        isCompleted?: (boolean);

        /** SeriesV1 nextPublishAt */
        nextPublishAt?: (string);

        /** SeriesV1 createdAt */
        createdAt?: (string);

        /** SeriesV1 updatedAt */
        updatedAt?: (string);

        /** SeriesV1 sharedWithStatus */
        sharedWithStatus?: (types.SharedWithStatus);
    }

    /** Represents a SeriesV1. */
    class SeriesV1 implements ISeriesV1 {

        /**
         * Constructs a new SeriesV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: series.ISeriesV1);

        /** SeriesV1 seriesId. */
        public seriesId?: (string);

        /** SeriesV1 userId. */
        public userId?: (string);

        /** SeriesV1 tags. */
        public tags: string[];

        /** SeriesV1 title. */
        public title?: (string);

        /** SeriesV1 description. */
        public description?: (string);

        /** SeriesV1 thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** SeriesV1 isOfficial. */
        public isOfficial?: (boolean);

        /** SeriesV1 isCompleted. */
        public isCompleted?: (boolean);

        /** SeriesV1 nextPublishAt. */
        public nextPublishAt?: (string);

        /** SeriesV1 createdAt. */
        public createdAt?: (string);

        /** SeriesV1 updatedAt. */
        public updatedAt?: (string);

        /** SeriesV1 sharedWithStatus. */
        public sharedWithStatus: types.SharedWithStatus;

        /**
         * Gets the default type url for SeriesV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesIdRequestV1. */
    export interface ISeriesIdRequestV1 {

        /** SeriesIdRequestV1 seriesId */
        seriesId?: (string);
    }

    /** Represents a SeriesIdRequestV1. */
    class SeriesIdRequestV1 implements ISeriesIdRequestV1 {

        /**
         * Constructs a new SeriesIdRequestV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: series.ISeriesIdRequestV1);

        /** SeriesIdRequestV1 seriesId. */
        public seriesId?: (string);

        /**
         * Gets the default type url for SeriesIdRequestV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesResponseV1. */
    export interface ISeriesResponseV1 {

        /** SeriesResponseV1 series */
        series?: (series.ISeriesV1);
    }

    /** Represents a SeriesResponseV1. */
    class SeriesResponseV1 implements ISeriesResponseV1 {

        /**
         * Constructs a new SeriesResponseV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: series.ISeriesResponseV1);

        /** SeriesResponseV1 series. */
        public series?: (series.ISeriesV1);

        /**
         * Gets the default type url for SeriesResponseV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace resource. */
export namespace resource {

    /** ImageFormat enum. */
    export enum ImageFormat {
        IMAGE_FORMAT_UNSPECIFIED = "IMAGE_FORMAT_UNSPECIFIED",
        IMAGE_FORMAT_PNG = "IMAGE_FORMAT_PNG",
        IMAGE_FORMAT_JPEG = "IMAGE_FORMAT_JPEG",
        IMAGE_FORMAT_GIF = "IMAGE_FORMAT_GIF",
        IMAGE_FORMAT_WEBP = "IMAGE_FORMAT_WEBP"
    }

    /** Properties of an Image. */
    export interface IImage {

        /** Image id */
        id?: (string);

        /** Image userId */
        userId?: (string);

        /** Image servingUrl */
        servingUrl?: (string);

        /** Image format */
        format?: (resource.ImageFormat);

        /** Image width */
        width?: (number);

        /** Image height */
        height?: (number);

        /** Image category */
        category?: (resource.ImageCategory);

        /** Image createdAt */
        createdAt?: (string);

        /** Image isAvailable */
        isAvailable?: (boolean);

        /** Image updatedAt */
        updatedAt?: (string);
    }

    /** Represents an Image. */
    class Image implements IImage {

        /**
         * Constructs a new Image.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IImage);

        /** Image id. */
        public id?: (string);

        /** Image userId. */
        public userId?: (string);

        /** Image servingUrl. */
        public servingUrl?: (string);

        /** Image format. */
        public format: resource.ImageFormat;

        /** Image width. */
        public width?: (number);

        /** Image height. */
        public height?: (number);

        /** Image category. */
        public category: resource.ImageCategory;

        /** Image createdAt. */
        public createdAt?: (string);

        /** Image isAvailable. */
        public isAvailable?: (boolean);

        /** Image updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for Image
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** ImageCategory enum. */
    export enum ImageCategory {
        IMAGE_CATEGORY_UNSPECIFIED = "IMAGE_CATEGORY_UNSPECIFIED",
        IMAGE_CATEGORY_USER_PROFILE = "IMAGE_CATEGORY_USER_PROFILE",
        IMAGE_CATEGORY_CHARACTER_ICON = "IMAGE_CATEGORY_CHARACTER_ICON",
        IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON = "IMAGE_CATEGORY_PUSH_NOTIFICATION_ICON",
        IMAGE_CATEGORY_CHAT_BACKGROUND = "IMAGE_CATEGORY_CHAT_BACKGROUND",
        IMAGE_CATEGORY_TALK_BACKGROUND = "IMAGE_CATEGORY_TALK_BACKGROUND",
        IMAGE_CATEGORY_CONTENT = "IMAGE_CATEGORY_CONTENT",
        IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL = "IMAGE_CATEGORY_STORY_SERIES_THUMBNAIL",
        IMAGE_CATEGORY_STAMP = "IMAGE_CATEGORY_STAMP"
    }

    /** Properties of a PresetImage. */
    export interface IPresetImage {

        /** PresetImage name */
        name?: (string);

        /** PresetImage thumbnail */
        thumbnail?: (resource.IImage);

        /** PresetImage image */
        image?: (resource.IImage);

        /** PresetImage isAvailable */
        isAvailable?: (boolean);
    }

    /** Represents a PresetImage. */
    class PresetImage implements IPresetImage {

        /**
         * Constructs a new PresetImage.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IPresetImage);

        /** PresetImage name. */
        public name?: (string);

        /** PresetImage thumbnail. */
        public thumbnail?: (resource.IImage);

        /** PresetImage image. */
        public image?: (resource.IImage);

        /** PresetImage isAvailable. */
        public isAvailable?: (boolean);

        /**
         * Gets the default type url for PresetImage
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PresetImageList. */
    export interface IPresetImageList {

        /** PresetImageList presetImageList */
        presetImageList?: (resource.IPresetImage[]);
    }

    /** Represents a PresetImageList. */
    class PresetImageList implements IPresetImageList {

        /**
         * Constructs a new PresetImageList.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IPresetImageList);

        /** PresetImageList presetImageList. */
        public presetImageList: resource.IPresetImage[];

        /**
         * Gets the default type url for PresetImageList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PresetImageSubcategory. */
    export interface IPresetImageSubcategory {

        /** PresetImageSubcategory name */
        name?: (string);

        /** PresetImageSubcategory thumbnail */
        thumbnail?: (resource.IImage);

        /** PresetImageSubcategory presetImageList */
        presetImageList?: (resource.IPresetImage[]);
    }

    /** Represents a PresetImageSubcategory. */
    class PresetImageSubcategory implements IPresetImageSubcategory {

        /**
         * Constructs a new PresetImageSubcategory.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IPresetImageSubcategory);

        /** PresetImageSubcategory name. */
        public name?: (string);

        /** PresetImageSubcategory thumbnail. */
        public thumbnail?: (resource.IImage);

        /** PresetImageSubcategory presetImageList. */
        public presetImageList: resource.IPresetImage[];

        /**
         * Gets the default type url for PresetImageSubcategory
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PresetImageSubcategoryList. */
    export interface IPresetImageSubcategoryList {

        /** PresetImageSubcategoryList subcategoryList */
        subcategoryList?: (resource.IPresetImageSubcategory[]);
    }

    /** Represents a PresetImageSubcategoryList. */
    class PresetImageSubcategoryList implements IPresetImageSubcategoryList {

        /**
         * Constructs a new PresetImageSubcategoryList.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IPresetImageSubcategoryList);

        /** PresetImageSubcategoryList subcategoryList. */
        public subcategoryList: resource.IPresetImageSubcategory[];

        /**
         * Gets the default type url for PresetImageSubcategoryList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceImageList. */
    export interface IResourceImageList {

        /** ResourceImageList imageList */
        imageList?: (resource.IImage[]);
    }

    /** Represents a ResourceImageList. */
    class ResourceImageList implements IResourceImageList {

        /**
         * Constructs a new ResourceImageList.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceImageList);

        /** ResourceImageList imageList. */
        public imageList: resource.IImage[];

        /**
         * Gets the default type url for ResourceImageList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** AudioCodec enum. */
    export enum AudioCodec {
        AUDIO_CODEC_UNSPECIFIED = "AUDIO_CODEC_UNSPECIFIED",
        AUDIO_CODEC_AAC = "AUDIO_CODEC_AAC",
        AUDIO_CODEC_MP3 = 2,
        AUDIO_CODEC_VORBIS = "AUDIO_CODEC_VORBIS"
    }

    /** Properties of an Audio. */
    export interface IAudio {

        /** Audio id */
        id?: (string);

        /** Audio userId */
        userId?: (string);

        /** Audio servingUrl */
        servingUrl?: (string);

        /** Audio duration */
        duration?: (string);

        /** Audio codec */
        codec?: (resource.AudioCodec);

        /** Audio createdAt */
        createdAt?: (string);
    }

    /** Represents an Audio. */
    class Audio implements IAudio {

        /**
         * Constructs a new Audio.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IAudio);

        /** Audio id. */
        public id?: (string);

        /** Audio userId. */
        public userId?: (string);

        /** Audio servingUrl. */
        public servingUrl?: (string);

        /** Audio duration. */
        public duration?: (string);

        /** Audio codec. */
        public codec: resource.AudioCodec;

        /** Audio createdAt. */
        public createdAt?: (string);

        /**
         * Gets the default type url for Audio
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceAudioList. */
    export interface IResourceAudioList {

        /** ResourceAudioList audioList */
        audioList?: (resource.IAudio[]);
    }

    /** Represents a ResourceAudioList. */
    class ResourceAudioList implements IResourceAudioList {

        /**
         * Constructs a new ResourceAudioList.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceAudioList);

        /** ResourceAudioList audioList. */
        public audioList: resource.IAudio[];

        /**
         * Gets the default type url for ResourceAudioList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a Resources. */
    export interface IResources {

        /** Resources imageList */
        imageList?: (resource.IImage[]);

        /** Resources audioList */
        audioList?: (resource.IAudio[]);
    }

    /** Represents a Resources. */
    class Resources implements IResources {

        /**
         * Constructs a new Resources.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResources);

        /** Resources imageList. */
        public imageList: resource.IImage[];

        /** Resources audioList. */
        public audioList: resource.IAudio[];

        /**
         * Gets the default type url for Resources
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceCreateImageRequest. */
    export interface IResourceCreateImageRequest {

        /** ResourceCreateImageRequest gsPath */
        gsPath?: (string);

        /** ResourceCreateImageRequest format */
        format?: (resource.ImageFormat);

        /** ResourceCreateImageRequest width */
        width?: (number);

        /** ResourceCreateImageRequest height */
        height?: (number);

        /** ResourceCreateImageRequest category */
        category?: (resource.ImageCategory);
    }

    /** Represents a ResourceCreateImageRequest. */
    class ResourceCreateImageRequest implements IResourceCreateImageRequest {

        /**
         * Constructs a new ResourceCreateImageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceCreateImageRequest);

        /** ResourceCreateImageRequest gsPath. */
        public gsPath?: (string);

        /** ResourceCreateImageRequest format. */
        public format: resource.ImageFormat;

        /** ResourceCreateImageRequest width. */
        public width?: (number);

        /** ResourceCreateImageRequest height. */
        public height?: (number);

        /** ResourceCreateImageRequest category. */
        public category: resource.ImageCategory;

        /**
         * Gets the default type url for ResourceCreateImageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceDeleteImageRequest. */
    export interface IResourceDeleteImageRequest {

        /** ResourceDeleteImageRequest id */
        id?: (string);
    }

    /** Represents a ResourceDeleteImageRequest. */
    class ResourceDeleteImageRequest implements IResourceDeleteImageRequest {

        /**
         * Constructs a new ResourceDeleteImageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceDeleteImageRequest);

        /** ResourceDeleteImageRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for ResourceDeleteImageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceGetMultiImageRequest. */
    export interface IResourceGetMultiImageRequest {

        /** ResourceGetMultiImageRequest idList */
        idList?: (string[]);
    }

    /** Represents a ResourceGetMultiImageRequest. */
    class ResourceGetMultiImageRequest implements IResourceGetMultiImageRequest {

        /**
         * Constructs a new ResourceGetMultiImageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceGetMultiImageRequest);

        /** ResourceGetMultiImageRequest idList. */
        public idList: string[];

        /**
         * Gets the default type url for ResourceGetMultiImageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceGetMultiImageResponse. */
    export interface IResourceGetMultiImageResponse {

        /** ResourceGetMultiImageResponse imageList */
        imageList?: (resource.IImage[]);
    }

    /** Represents a ResourceGetMultiImageResponse. */
    class ResourceGetMultiImageResponse implements IResourceGetMultiImageResponse {

        /**
         * Constructs a new ResourceGetMultiImageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceGetMultiImageResponse);

        /** ResourceGetMultiImageResponse imageList. */
        public imageList: resource.IImage[];

        /**
         * Gets the default type url for ResourceGetMultiImageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceListPresetImageRequest. */
    export interface IResourceListPresetImageRequest {

        /** ResourceListPresetImageRequest category */
        category?: (resource.ImageCategory);
    }

    /** Represents a ResourceListPresetImageRequest. */
    class ResourceListPresetImageRequest implements IResourceListPresetImageRequest {

        /**
         * Constructs a new ResourceListPresetImageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceListPresetImageRequest);

        /** ResourceListPresetImageRequest category. */
        public category: resource.ImageCategory;

        /**
         * Gets the default type url for ResourceListPresetImageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceCreateAudioRequest. */
    export interface IResourceCreateAudioRequest {

        /** ResourceCreateAudioRequest gsPath */
        gsPath?: (string);

        /** ResourceCreateAudioRequest duration */
        duration?: (string);

        /** ResourceCreateAudioRequest codec */
        codec?: (resource.AudioCodec);
    }

    /** Represents a ResourceCreateAudioRequest. */
    class ResourceCreateAudioRequest implements IResourceCreateAudioRequest {

        /**
         * Constructs a new ResourceCreateAudioRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceCreateAudioRequest);

        /** ResourceCreateAudioRequest gsPath. */
        public gsPath?: (string);

        /** ResourceCreateAudioRequest duration. */
        public duration?: (string);

        /** ResourceCreateAudioRequest codec. */
        public codec: resource.AudioCodec;

        /**
         * Gets the default type url for ResourceCreateAudioRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceDeleteAudioRequest. */
    export interface IResourceDeleteAudioRequest {

        /** ResourceDeleteAudioRequest id */
        id?: (string);
    }

    /** Represents a ResourceDeleteAudioRequest. */
    class ResourceDeleteAudioRequest implements IResourceDeleteAudioRequest {

        /**
         * Constructs a new ResourceDeleteAudioRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceDeleteAudioRequest);

        /** ResourceDeleteAudioRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for ResourceDeleteAudioRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ResourceGetMultiAudioRequest. */
    export interface IResourceGetMultiAudioRequest {

        /** ResourceGetMultiAudioRequest idList */
        idList?: (string[]);
    }

    /** Represents a ResourceGetMultiAudioRequest. */
    class ResourceGetMultiAudioRequest implements IResourceGetMultiAudioRequest {

        /**
         * Constructs a new ResourceGetMultiAudioRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: resource.IResourceGetMultiAudioRequest);

        /** ResourceGetMultiAudioRequest idList. */
        public idList: string[];

        /**
         * Gets the default type url for ResourceGetMultiAudioRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace google. */
export namespace google {

    /** Namespace protobuf. */
    export namespace protobuf {

        /** Properties of a DoubleValue. */
        export interface IDoubleValue {

            /** DoubleValue value */
            value?: (number);
        }

        /** Represents a DoubleValue. */
        class DoubleValue implements IDoubleValue {

            /**
             * Constructs a new DoubleValue.
             * @param [properties] Properties to set
             */
            constructor(properties?: number);

            /** DoubleValue value. */
            public value: number;

            /**
             * Gets the default type url for DoubleValue
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a FloatValue. */
        export interface IFloatValue {

            /** FloatValue value */
            value?: (number);
        }

        /** Represents a FloatValue. */
        class FloatValue implements IFloatValue {

            /**
             * Constructs a new FloatValue.
             * @param [properties] Properties to set
             */
            constructor(properties?: number);

            /** FloatValue value. */
            public value: number;

            /**
             * Gets the default type url for FloatValue
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of an Int64Value. */
        export interface IInt64Value {

            /** Int64Value value */
            value?: (number);
        }

        /** Represents an Int64Value. */
        class Int64Value implements IInt64Value {

            /**
             * Constructs a new Int64Value.
             * @param [properties] Properties to set
             */
            constructor(properties?: number);

            /** Int64Value value. */
            public value: number;

            /**
             * Gets the default type url for Int64Value
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a UInt64Value. */
        export interface IUInt64Value {

            /** UInt64Value value */
            value?: (number);
        }

        /** Represents a UInt64Value. */
        class UInt64Value implements IUInt64Value {

            /**
             * Constructs a new UInt64Value.
             * @param [properties] Properties to set
             */
            constructor(properties?: number);

            /** UInt64Value value. */
            public value: number;

            /**
             * Gets the default type url for UInt64Value
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of an Int32Value. */
        export interface IInt32Value {

            /** Int32Value value */
            value?: (number);
        }

        /** Represents an Int32Value. */
        class Int32Value implements IInt32Value {

            /**
             * Constructs a new Int32Value.
             * @param [properties] Properties to set
             */
            constructor(properties?: number);

            /** Int32Value value. */
            public value: number;

            /**
             * Gets the default type url for Int32Value
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a UInt32Value. */
        export interface IUInt32Value {

            /** UInt32Value value */
            value?: (number);
        }

        /** Represents a UInt32Value. */
        class UInt32Value implements IUInt32Value {

            /**
             * Constructs a new UInt32Value.
             * @param [properties] Properties to set
             */
            constructor(properties?: number);

            /** UInt32Value value. */
            public value: number;

            /**
             * Gets the default type url for UInt32Value
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a BoolValue. */
        export interface IBoolValue {

            /** BoolValue value */
            value?: (boolean);
        }

        /** Represents a BoolValue. */
        class BoolValue implements IBoolValue {

            /**
             * Constructs a new BoolValue.
             * @param [properties] Properties to set
             */
            constructor(properties?: boolean);

            /** BoolValue value. */
            public value: boolean;

            /**
             * Gets the default type url for BoolValue
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a StringValue. */
        export interface IStringValue {

            /** StringValue value */
            value?: (string);
        }

        /** Represents a StringValue. */
        class StringValue implements IStringValue {

            /**
             * Constructs a new StringValue.
             * @param [properties] Properties to set
             */
            constructor(properties?: string);

            /** StringValue value. */
            public value: string;

            /**
             * Gets the default type url for StringValue
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a BytesValue. */
        export interface IBytesValue {

            /** BytesValue value */
            value?: (Uint8Array);
        }

        /** Represents a BytesValue. */
        class BytesValue implements IBytesValue {

            /**
             * Constructs a new BytesValue.
             * @param [properties] Properties to set
             */
            constructor(properties?: Uint8Array);

            /** BytesValue value. */
            public value: Uint8Array;

            /**
             * Gets the default type url for BytesValue
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a Timestamp. */
        export interface ITimestamp {

            /** Timestamp seconds */
            seconds?: (number);

            /** Timestamp nanos */
            nanos?: (number);
        }

        /** Represents a Timestamp. */
        class Timestamp implements ITimestamp {

            /**
             * Constructs a new Timestamp.
             * @param [properties] Properties to set
             */
            constructor(properties?: string);

            /** Timestamp seconds. */
            public seconds: number;

            /** Timestamp nanos. */
            public nanos: number;

            /**
             * Gets the default type url for Timestamp
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a Duration. */
        export interface IDuration {

            /** Duration seconds */
            seconds?: (number);

            /** Duration nanos */
            nanos?: (number);
        }

        /** Represents a Duration. */
        class Duration implements IDuration {

            /**
             * Constructs a new Duration.
             * @param [properties] Properties to set
             */
            constructor(properties?: string);

            /** Duration seconds. */
            public seconds: number;

            /** Duration nanos. */
            public nanos: number;

            /**
             * Gets the default type url for Duration
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }
}

/** Namespace story. */
export namespace story {

    /** Properties of a StoryIdRequest. */
    export interface IStoryIdRequest {

        /** StoryIdRequest id */
        id?: (string);
    }

    /** Represents a StoryIdRequest. */
    class StoryIdRequest implements IStoryIdRequest {

        /**
         * Constructs a new StoryIdRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStoryIdRequest);

        /** StoryIdRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for StoryIdRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryIdListRequest. */
    export interface IStoryIdListRequest {

        /** StoryIdListRequest storyIdList */
        storyIdList?: (string[]);
    }

    /** Represents a StoryIdListRequest. */
    class StoryIdListRequest implements IStoryIdListRequest {

        /**
         * Constructs a new StoryIdListRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStoryIdListRequest);

        /** StoryIdListRequest storyIdList. */
        public storyIdList: string[];

        /**
         * Gets the default type url for StoryIdListRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryHeader. */
    export interface IStoryHeader {

        /** StoryHeader id */
        id?: (string);

        /** StoryHeader userId */
        userId?: (string);

        /** StoryHeader tags */
        tags?: (string[]);

        /** StoryHeader seriesId */
        seriesId?: (string);

        /** StoryHeader seriesIndex */
        seriesIndex?: (number);

        /** StoryHeader title */
        title?: (string);

        /** StoryHeader description */
        description?: (string);

        /** StoryHeader opening */
        opening?: (string);

        /** StoryHeader thumbnail */
        thumbnail?: (resource.IImage);

        /** StoryHeader length */
        length?: (number);

        /** StoryHeader status */
        status?: (story.StoryStatus);

        /** StoryHeader isOfficial */
        isOfficial?: (boolean);

        /** StoryHeader createdAt */
        createdAt?: (string);

        /** StoryHeader updatedAt */
        updatedAt?: (string);

        /** StoryHeader publishedAt */
        publishedAt?: (string);

        /** StoryHeader isPawRequired */
        isPawRequired?: (boolean);

        /** StoryHeader isVipOnly */
        isVipOnly?: (boolean);

        /** StoryHeader sensitiveFlag */
        sensitiveFlag?: (query_types.SensitiveFlag);
    }

    /** Represents a StoryHeader. */
    class StoryHeader implements IStoryHeader {

        /**
         * Constructs a new StoryHeader.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStoryHeader);

        /** StoryHeader id. */
        public id?: (string);

        /** StoryHeader userId. */
        public userId?: (string);

        /** StoryHeader tags. */
        public tags: string[];

        /** StoryHeader seriesId. */
        public seriesId?: (string);

        /** StoryHeader seriesIndex. */
        public seriesIndex?: (number);

        /** StoryHeader title. */
        public title?: (string);

        /** StoryHeader description. */
        public description?: (string);

        /** StoryHeader opening. */
        public opening?: (string);

        /** StoryHeader thumbnail. */
        public thumbnail?: (resource.IImage);

        /** StoryHeader length. */
        public length?: (number);

        /** StoryHeader status. */
        public status: story.StoryStatus;

        /** StoryHeader isOfficial. */
        public isOfficial?: (boolean);

        /** StoryHeader createdAt. */
        public createdAt?: (string);

        /** StoryHeader updatedAt. */
        public updatedAt?: (string);

        /** StoryHeader publishedAt. */
        public publishedAt?: (string);

        /** StoryHeader isPawRequired. */
        public isPawRequired?: (boolean);

        /** StoryHeader isVipOnly. */
        public isVipOnly?: (boolean);

        /** StoryHeader sensitiveFlag. */
        public sensitiveFlag: query_types.SensitiveFlag;

        /**
         * Gets the default type url for StoryHeader
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesStoryHeader. */
    export interface ISeriesStoryHeader {

        /** SeriesStoryHeader header */
        header?: (story.IStoryHeader);

        /** SeriesStoryHeader series */
        series?: (series.ISeries);
    }

    /** Represents a SeriesStoryHeader. */
    class SeriesStoryHeader implements ISeriesStoryHeader {

        /**
         * Constructs a new SeriesStoryHeader.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.ISeriesStoryHeader);

        /** SeriesStoryHeader header. */
        public header?: (story.IStoryHeader);

        /** SeriesStoryHeader series. */
        public series?: (series.ISeries);

        /**
         * Gets the default type url for SeriesStoryHeader
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesStoryHeaderWithMatchScore. */
    export interface ISeriesStoryHeaderWithMatchScore {

        /** SeriesStoryHeaderWithMatchScore header */
        header?: (story.ISeriesStoryHeader);

        /** SeriesStoryHeaderWithMatchScore matchScore */
        matchScore?: (number);
    }

    /** Represents a SeriesStoryHeaderWithMatchScore. */
    class SeriesStoryHeaderWithMatchScore implements ISeriesStoryHeaderWithMatchScore {

        /**
         * Constructs a new SeriesStoryHeaderWithMatchScore.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.ISeriesStoryHeaderWithMatchScore);

        /** SeriesStoryHeaderWithMatchScore header. */
        public header?: (story.ISeriesStoryHeader);

        /** SeriesStoryHeaderWithMatchScore matchScore. */
        public matchScore?: (number);

        /**
         * Gets the default type url for SeriesStoryHeaderWithMatchScore
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a Story. */
    export interface IStory {

        /** Story header */
        header?: (story.IStoryHeader);

        /** Story script */
        script?: (tellerscript.ITellerScript);

        /** Story resources */
        resources?: (resource.IResources);
    }

    /** Represents a Story. */
    class Story implements IStory {

        /**
         * Constructs a new Story.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStory);

        /** Story header. */
        public header?: (story.IStoryHeader);

        /** Story script. */
        public script?: (tellerscript.ITellerScript);

        /** Story resources. */
        public resources?: (resource.IResources);

        /**
         * Gets the default type url for Story
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserSpecificStoryMetadata. */
    export interface IUserSpecificStoryMetadata {

        /** UserSpecificStoryMetadata readingProgress */
        readingProgress?: (number);

        /** UserSpecificStoryMetadata matchScore */
        matchScore?: (number);
    }

    /** Represents a UserSpecificStoryMetadata. */
    class UserSpecificStoryMetadata implements IUserSpecificStoryMetadata {

        /**
         * Constructs a new UserSpecificStoryMetadata.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IUserSpecificStoryMetadata);

        /** UserSpecificStoryMetadata readingProgress. */
        public readingProgress?: (number);

        /** UserSpecificStoryMetadata matchScore. */
        public matchScore?: (number);

        /**
         * Gets the default type url for UserSpecificStoryMetadata
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryIDWithMetadata. */
    export interface IStoryIDWithMetadata {

        /** StoryIDWithMetadata id */
        id?: (string);

        /** StoryIDWithMetadata metadata */
        metadata?: (story.IUserSpecificStoryMetadata);
    }

    /** Represents a StoryIDWithMetadata. */
    class StoryIDWithMetadata implements IStoryIDWithMetadata {

        /**
         * Constructs a new StoryIDWithMetadata.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStoryIDWithMetadata);

        /** StoryIDWithMetadata id. */
        public id?: (string);

        /** StoryIDWithMetadata metadata. */
        public metadata?: (story.IUserSpecificStoryMetadata);

        /**
         * Gets the default type url for StoryIDWithMetadata
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StoryStatus enum. */
    export enum StoryStatus {
        STORY_STATUS_UNKNOWN = "STORY_STATUS_UNKNOWN",
        DRAFT = "DRAFT",
        RESERVE = "RESERVE",
        PUBLISH = "PUBLISH",
        REJECT = "REJECT"
    }

    /** Properties of a StoryHeaderV1. */
    export interface IStoryHeaderV1 {

        /** StoryHeaderV1 storyId */
        storyId?: (string);

        /** StoryHeaderV1 userId */
        userId?: (string);

        /** StoryHeaderV1 tags */
        tags?: (string[]);

        /** StoryHeaderV1 seriesId */
        seriesId?: (string);

        /** StoryHeaderV1 seriesIndex */
        seriesIndex?: (number);

        /** StoryHeaderV1 title */
        title?: (string);

        /** StoryHeaderV1 description */
        description?: (string);

        /** StoryHeaderV1 opening */
        opening?: (string);

        /** StoryHeaderV1 thumbnailUrl */
        thumbnailUrl?: (string);

        /** StoryHeaderV1 length */
        length?: (number);

        /** StoryHeaderV1 status */
        status?: (story.StoryStatus);

        /** StoryHeaderV1 isOfficial */
        isOfficial?: (boolean);

        /** StoryHeaderV1 createdAt */
        createdAt?: (string);

        /** StoryHeaderV1 updatedAt */
        updatedAt?: (string);

        /** StoryHeaderV1 publishedAt */
        publishedAt?: (string);
    }

    /** Represents a StoryHeaderV1. */
    class StoryHeaderV1 implements IStoryHeaderV1 {

        /**
         * Constructs a new StoryHeaderV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStoryHeaderV1);

        /** StoryHeaderV1 storyId. */
        public storyId?: (string);

        /** StoryHeaderV1 userId. */
        public userId?: (string);

        /** StoryHeaderV1 tags. */
        public tags: string[];

        /** StoryHeaderV1 seriesId. */
        public seriesId?: (string);

        /** StoryHeaderV1 seriesIndex. */
        public seriesIndex?: (number);

        /** StoryHeaderV1 title. */
        public title?: (string);

        /** StoryHeaderV1 description. */
        public description?: (string);

        /** StoryHeaderV1 opening. */
        public opening?: (string);

        /** StoryHeaderV1 thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** StoryHeaderV1 length. */
        public length?: (number);

        /** StoryHeaderV1 status. */
        public status: story.StoryStatus;

        /** StoryHeaderV1 isOfficial. */
        public isOfficial?: (boolean);

        /** StoryHeaderV1 createdAt. */
        public createdAt?: (string);

        /** StoryHeaderV1 updatedAt. */
        public updatedAt?: (string);

        /** StoryHeaderV1 publishedAt. */
        public publishedAt?: (string);

        /**
         * Gets the default type url for StoryHeaderV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesStoryHeaderV1. */
    export interface ISeriesStoryHeaderV1 {

        /** SeriesStoryHeaderV1 header */
        header?: (story.IStoryHeaderV1);

        /** SeriesStoryHeaderV1 series */
        series?: (series.ISeriesV1);
    }

    /** Represents a SeriesStoryHeaderV1. */
    class SeriesStoryHeaderV1 implements ISeriesStoryHeaderV1 {

        /**
         * Constructs a new SeriesStoryHeaderV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.ISeriesStoryHeaderV1);

        /** SeriesStoryHeaderV1 header. */
        public header?: (story.IStoryHeaderV1);

        /** SeriesStoryHeaderV1 series. */
        public series?: (series.ISeriesV1);

        /**
         * Gets the default type url for SeriesStoryHeaderV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryV1. */
    export interface IStoryV1 {

        /** StoryV1 header */
        header?: (story.IStoryHeaderV1);

        /** StoryV1 body */
        body?: (string);
    }

    /** Represents a StoryV1. */
    class StoryV1 implements IStoryV1 {

        /**
         * Constructs a new StoryV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStoryV1);

        /** StoryV1 header. */
        public header?: (story.IStoryHeaderV1);

        /** StoryV1 body. */
        public body?: (string);

        /**
         * Gets the default type url for StoryV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StoryViolationIssue enum. */
    export enum StoryViolationIssue {
        STORY_VIOLATION_ISSUE_UNKNOWN = "STORY_VIOLATION_ISSUE_UNKNOWN",
        COPYRIGHT = "COPYRIGHT",
        SLANDER = "SLANDER",
        EXCESSIVE = "EXCESSIVE",
        PORTRAIT = "PORTRAIT"
    }

    /** Properties of a StoryReportViolationRequestV2. */
    export interface IStoryReportViolationRequestV2 {

        /** StoryReportViolationRequestV2 storyId */
        storyId?: (string);

        /** StoryReportViolationRequestV2 type */
        type?: (types.ViolationType);

        /** StoryReportViolationRequestV2 message */
        message?: (string);
    }

    /** Represents a StoryReportViolationRequestV2. */
    class StoryReportViolationRequestV2 implements IStoryReportViolationRequestV2 {

        /**
         * Constructs a new StoryReportViolationRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStoryReportViolationRequestV2);

        /** StoryReportViolationRequestV2 storyId. */
        public storyId?: (string);

        /** StoryReportViolationRequestV2 type. */
        public type: types.ViolationType;

        /** StoryReportViolationRequestV2 message. */
        public message?: (string);

        /**
         * Gets the default type url for StoryReportViolationRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryReportViolationRequest. */
    export interface IStoryReportViolationRequest {

        /** StoryReportViolationRequest storyId */
        storyId?: (string);

        /** StoryReportViolationRequest issue */
        issue?: (story.StoryViolationIssue);

        /** StoryReportViolationRequest message */
        message?: (string);
    }

    /** Represents a StoryReportViolationRequest. */
    class StoryReportViolationRequest implements IStoryReportViolationRequest {

        /**
         * Constructs a new StoryReportViolationRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story.IStoryReportViolationRequest);

        /** StoryReportViolationRequest storyId. */
        public storyId?: (string);

        /** StoryReportViolationRequest issue. */
        public issue: story.StoryViolationIssue;

        /** StoryReportViolationRequest message. */
        public message?: (string);

        /**
         * Gets the default type url for StoryReportViolationRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace tellerscript. */
export namespace tellerscript {

    /** FormatVersion enum. */
    export enum FormatVersion {
        FORMAT_VERSION_UNSPECIFIED = "FORMAT_VERSION_UNSPECIFIED",
        VERSION_1 = 1
    }

    /** Properties of a TellerScript. */
    export interface ITellerScript {

        /** TellerScript header */
        header?: (tellerscript.TellerScript.IHeader);

        /** TellerScript sceneList */
        sceneList?: (tellerscript.IScene[]);
    }

    /** Represents a TellerScript. */
    class TellerScript implements ITellerScript {

        /**
         * Constructs a new TellerScript.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.ITellerScript);

        /** TellerScript header. */
        public header?: (tellerscript.TellerScript.IHeader);

        /** TellerScript sceneList. */
        public sceneList: tellerscript.IScene[];

        /**
         * Gets the default type url for TellerScript
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace TellerScript {

        /** Properties of a Header. */
        export interface IHeader {

            /** Header version */
            version?: (tellerscript.FormatVersion);

            /** Header characterList */
            characterList?: (tellerscript.ICharacter[]);
        }

        /** Represents a Header. */
        class Header implements IHeader {

            /**
             * Constructs a new Header.
             * @param [properties] Properties to set
             */
            constructor(properties?: tellerscript.TellerScript.IHeader);

            /** Header version. */
            public version: tellerscript.FormatVersion;

            /** Header characterList. */
            public characterList: tellerscript.ICharacter[];

            /**
             * Gets the default type url for Header
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a Scene. */
    export interface IScene {

        /** Scene mainCharacterIdList */
        mainCharacterIdList?: (string[]);

        /** Scene type */
        type?: (tellerscript.Scene.Type);

        /** Scene imageId */
        imageId?: (string);

        /** Scene color */
        color?: (tellerscript.IRGBAColor);

        /** Scene ops */
        ops?: (tellerscript.IOp[]);

        /** Scene name */
        name?: (string);
    }

    /** Represents a Scene. */
    class Scene implements IScene {

        /**
         * Constructs a new Scene.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IScene);

        /** Scene mainCharacterIdList. */
        public mainCharacterIdList: string[];

        /** Scene type. */
        public type: tellerscript.Scene.Type;

        /** Scene imageId. */
        public imageId?: (string);

        /** Scene color. */
        public color?: (tellerscript.IRGBAColor);

        /** Scene ops. */
        public ops: tellerscript.IOp[];

        /** Scene name. */
        public name: string;

        /** Scene background. */
        public background?: ("imageId"|"color");

        /**
         * Gets the default type url for Scene
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace Scene {

        /** Type enum. */
        export enum Type {
            TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
            TYPE_CHAT = "TYPE_CHAT",
            TYPE_TALK = "TYPE_TALK"
        }
    }

    /** Properties of an Op. */
    export interface IOp {

        /** Op showImageOp */
        showImageOp?: (tellerscript.IShowImageOp);

        /** Op showTextOp */
        showTextOp?: (tellerscript.IShowTextOp);

        /** Op vibrateOp */
        vibrateOp?: (tellerscript.IVibrateOp);

        /** Op showNotificationOp */
        showNotificationOp?: (tellerscript.IShowNotificationOp);

        /** Op postMessageOp */
        postMessageOp?: (tellerscript.IPostMessageOp);

        /** Op postImageOp */
        postImageOp?: (tellerscript.IPostImageOp);

        /** Op receiveCallOp */
        receiveCallOp?: (tellerscript.IReceiveCallOp);

        /** Op endCallOp */
        endCallOp?: (tellerscript.IEndCallOp);

        /** Op missedCallOp */
        missedCallOp?: (tellerscript.IMissCallOp);

        /** Op noResponseCallOp */
        noResponseCallOp?: (tellerscript.INoResponseCallOp);

        /** Op typingNowOp */
        typingNowOp?: (tellerscript.ITypingNowOp);
    }

    /** Represents an Op. */
    class Op implements IOp {

        /**
         * Constructs a new Op.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IOp);

        /** Op showImageOp. */
        public showImageOp?: (tellerscript.IShowImageOp);

        /** Op showTextOp. */
        public showTextOp?: (tellerscript.IShowTextOp);

        /** Op vibrateOp. */
        public vibrateOp?: (tellerscript.IVibrateOp);

        /** Op showNotificationOp. */
        public showNotificationOp?: (tellerscript.IShowNotificationOp);

        /** Op postMessageOp. */
        public postMessageOp?: (tellerscript.IPostMessageOp);

        /** Op postImageOp. */
        public postImageOp?: (tellerscript.IPostImageOp);

        /** Op receiveCallOp. */
        public receiveCallOp?: (tellerscript.IReceiveCallOp);

        /** Op endCallOp. */
        public endCallOp?: (tellerscript.IEndCallOp);

        /** Op missedCallOp. */
        public missedCallOp?: (tellerscript.IMissCallOp);

        /** Op noResponseCallOp. */
        public noResponseCallOp?: (tellerscript.INoResponseCallOp);

        /** Op typingNowOp. */
        public typingNowOp?: (tellerscript.ITypingNowOp);

        /** Op opOneof. */
        public opOneof?: ("showImageOp"|"showTextOp"|"vibrateOp"|"showNotificationOp"|"postMessageOp"|"postImageOp"|"receiveCallOp"|"endCallOp"|"missedCallOp"|"noResponseCallOp"|"typingNowOp");

        /**
         * Gets the default type url for Op
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a Character. */
    export interface ICharacter {

        /** Character id */
        id?: (string);

        /** Character name */
        name?: (string);

        /** Character imageId */
        imageId?: (string);
    }

    /** Represents a Character. */
    class Character implements ICharacter {

        /**
         * Constructs a new Character.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.ICharacter);

        /** Character id. */
        public id: string;

        /** Character name. */
        public name: string;

        /** Character imageId. */
        public imageId: string;

        /**
         * Gets the default type url for Character
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** TextAlign enum. */
    export enum TextAlign {
        TEXT_ALIGN_UNSPECIFIED = "TEXT_ALIGN_UNSPECIFIED",
        TEXT_ALIGN_LEFT = "TEXT_ALIGN_LEFT",
        TEXT_ALIGN_RIGHT = "TEXT_ALIGN_RIGHT",
        TEXT_ALIGN_CENTER = "TEXT_ALIGN_CENTER"
    }

    /** Properties of a RGBAColor. */
    export interface IRGBAColor {

        /** RGBAColor red */
        red?: (number);

        /** RGBAColor green */
        green?: (number);

        /** RGBAColor blue */
        blue?: (number);

        /** RGBAColor alpha */
        alpha?: (number);
    }

    /** Represents a RGBAColor. */
    class RGBAColor implements IRGBAColor {

        /**
         * Constructs a new RGBAColor.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IRGBAColor);

        /** RGBAColor red. */
        public red: number;

        /** RGBAColor green. */
        public green: number;

        /** RGBAColor blue. */
        public blue: number;

        /** RGBAColor alpha. */
        public alpha: number;

        /**
         * Gets the default type url for RGBAColor
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** FontSize enum. */
    export enum FontSize {
        FONT_SIZE_MEDIUM = "FONT_SIZE_MEDIUM",
        FONT_SIZE_SMALL = "FONT_SIZE_SMALL",
        FONT_SIZE_LARGE = "FONT_SIZE_LARGE",
        FONT_SIZE_X_SMALL = "FONT_SIZE_X_SMALL",
        FONT_SIZE_X_LARGE = "FONT_SIZE_X_LARGE"
    }

    /** Properties of a ShowImageOp. */
    export interface IShowImageOp {

        /** ShowImageOp imageId */
        imageId?: (string);
    }

    /** Represents a ShowImageOp. */
    class ShowImageOp implements IShowImageOp {

        /**
         * Constructs a new ShowImageOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IShowImageOp);

        /** ShowImageOp imageId. */
        public imageId: string;

        /**
         * Gets the default type url for ShowImageOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ShowTextOp. */
    export interface IShowTextOp {

        /** ShowTextOp textAlign */
        textAlign?: (tellerscript.TextAlign);

        /** ShowTextOp text */
        text?: (string);

        /** ShowTextOp fontColor */
        fontColor?: (tellerscript.IRGBAColor);

        /** ShowTextOp backgroundColor */
        backgroundColor?: (tellerscript.IRGBAColor);

        /** ShowTextOp fontSize */
        fontSize?: (tellerscript.FontSize);
    }

    /** Represents a ShowTextOp. */
    class ShowTextOp implements IShowTextOp {

        /**
         * Constructs a new ShowTextOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IShowTextOp);

        /** ShowTextOp textAlign. */
        public textAlign: tellerscript.TextAlign;

        /** ShowTextOp text. */
        public text: string;

        /** ShowTextOp fontColor. */
        public fontColor?: (tellerscript.IRGBAColor);

        /** ShowTextOp backgroundColor. */
        public backgroundColor?: (tellerscript.IRGBAColor);

        /** ShowTextOp fontSize. */
        public fontSize: tellerscript.FontSize;

        /**
         * Gets the default type url for ShowTextOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ShowNotificationOp. */
    export interface IShowNotificationOp {

        /** ShowNotificationOp imageId */
        imageId?: (string);

        /** ShowNotificationOp title */
        title?: (string);

        /** ShowNotificationOp text */
        text?: (string);
    }

    /** Represents a ShowNotificationOp. */
    class ShowNotificationOp implements IShowNotificationOp {

        /**
         * Constructs a new ShowNotificationOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IShowNotificationOp);

        /** ShowNotificationOp imageId. */
        public imageId: string;

        /** ShowNotificationOp title. */
        public title: string;

        /** ShowNotificationOp text. */
        public text: string;

        /**
         * Gets the default type url for ShowNotificationOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VibrateOp. */
    export interface IVibrateOp {

        /** VibrateOp duration */
        duration?: (string);

        /** VibrateOp strength */
        strength?: (tellerscript.VibrateOp.VibrationStrength);
    }

    /** Represents a VibrateOp. */
    class VibrateOp implements IVibrateOp {

        /**
         * Constructs a new VibrateOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IVibrateOp);

        /** VibrateOp duration. */
        public duration?: (string);

        /** VibrateOp strength. */
        public strength: tellerscript.VibrateOp.VibrationStrength;

        /**
         * Gets the default type url for VibrateOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace VibrateOp {

        /** VibrationStrength enum. */
        export enum VibrationStrength {
            VIBRATION_STRENGTH_NORMAL = "VIBRATION_STRENGTH_NORMAL",
            VIBRATION_STRENGTH_WEAK = "VIBRATION_STRENGTH_WEAK",
            VIBRATION_STRENGTH_STRONG = "VIBRATION_STRENGTH_STRONG"
        }
    }

    /** Properties of a PostMessageOp. */
    export interface IPostMessageOp {

        /** PostMessageOp characterId */
        characterId?: (string);

        /** PostMessageOp text */
        text?: (string);
    }

    /** Represents a PostMessageOp. */
    class PostMessageOp implements IPostMessageOp {

        /**
         * Constructs a new PostMessageOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IPostMessageOp);

        /** PostMessageOp characterId. */
        public characterId: string;

        /** PostMessageOp text. */
        public text: string;

        /**
         * Gets the default type url for PostMessageOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PostImageOp. */
    export interface IPostImageOp {

        /** PostImageOp characterId */
        characterId?: (string);

        /** PostImageOp imageId */
        imageId?: (string);
    }

    /** Represents a PostImageOp. */
    class PostImageOp implements IPostImageOp {

        /**
         * Constructs a new PostImageOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IPostImageOp);

        /** PostImageOp characterId. */
        public characterId: string;

        /** PostImageOp imageId. */
        public imageId: string;

        /**
         * Gets the default type url for PostImageOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ReceiveCallOp. */
    export interface IReceiveCallOp {

        /** ReceiveCallOp characterId */
        characterId?: (string);

        /** ReceiveCallOp vibrate */
        vibrate?: (boolean);

        /** ReceiveCallOp ringingDuration */
        ringingDuration?: (string);

        /** ReceiveCallOp audioId */
        audioId?: (string);

        /** ReceiveCallOp talkingDuration */
        talkingDuration?: (string);
    }

    /** Represents a ReceiveCallOp. */
    class ReceiveCallOp implements IReceiveCallOp {

        /**
         * Constructs a new ReceiveCallOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IReceiveCallOp);

        /** ReceiveCallOp characterId. */
        public characterId: string;

        /** ReceiveCallOp vibrate. */
        public vibrate: boolean;

        /** ReceiveCallOp ringingDuration. */
        public ringingDuration?: (string);

        /** ReceiveCallOp audioId. */
        public audioId: string;

        /** ReceiveCallOp talkingDuration. */
        public talkingDuration?: (string);

        /**
         * Gets the default type url for ReceiveCallOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an EndCallOp. */
    export interface IEndCallOp {

        /** EndCallOp characterId */
        characterId?: (string);

        /** EndCallOp duration */
        duration?: (string);
    }

    /** Represents an EndCallOp. */
    class EndCallOp implements IEndCallOp {

        /**
         * Constructs a new EndCallOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IEndCallOp);

        /** EndCallOp characterId. */
        public characterId: string;

        /** EndCallOp duration. */
        public duration?: (string);

        /**
         * Gets the default type url for EndCallOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MissCallOp. */
    export interface IMissCallOp {

        /** MissCallOp characterId */
        characterId?: (string);
    }

    /** Represents a MissCallOp. */
    class MissCallOp implements IMissCallOp {

        /**
         * Constructs a new MissCallOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.IMissCallOp);

        /** MissCallOp characterId. */
        public characterId: string;

        /**
         * Gets the default type url for MissCallOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NoResponseCallOp. */
    export interface INoResponseCallOp {

        /** NoResponseCallOp characterId */
        characterId?: (string);
    }

    /** Represents a NoResponseCallOp. */
    class NoResponseCallOp implements INoResponseCallOp {

        /**
         * Constructs a new NoResponseCallOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.INoResponseCallOp);

        /** NoResponseCallOp characterId. */
        public characterId: string;

        /**
         * Gets the default type url for NoResponseCallOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TypingNowOp. */
    export interface ITypingNowOp {

        /** TypingNowOp characterId */
        characterId?: (string);

        /** TypingNowOp duration */
        duration?: (string);
    }

    /** Represents a TypingNowOp. */
    class TypingNowOp implements ITypingNowOp {

        /**
         * Constructs a new TypingNowOp.
         * @param [properties] Properties to set
         */
        constructor(properties?: tellerscript.ITypingNowOp);

        /** TypingNowOp characterId. */
        public characterId: string;

        /** TypingNowOp duration. */
        public duration?: (string);

        /**
         * Gets the default type url for TypingNowOp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_types. */
export namespace query_types {

    /** Properties of a StoryFilter. */
    export interface IStoryFilter {

        /** StoryFilter excludeAlreadyRead */
        excludeAlreadyRead?: (boolean);

        /** StoryFilter includeBlocked */
        includeBlocked?: (boolean);

        /** StoryFilter excludeCompletedSeries */
        excludeCompletedSeries?: (boolean);

        /** StoryFilter excludeNotCompletedSeries */
        excludeNotCompletedSeries?: (boolean);

        /** StoryFilter excludeSeries */
        excludeSeries?: (boolean);

        /** StoryFilter excludeNotSeries */
        excludeNotSeries?: (boolean);

        /** StoryFilter includeHidden */
        includeHidden?: (boolean);
    }

    /** Represents a StoryFilter. */
    class StoryFilter implements IStoryFilter {

        /**
         * Constructs a new StoryFilter.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_types.IStoryFilter);

        /** StoryFilter excludeAlreadyRead. */
        public excludeAlreadyRead?: (boolean);

        /** StoryFilter includeBlocked. */
        public includeBlocked?: (boolean);

        /** StoryFilter excludeCompletedSeries. */
        public excludeCompletedSeries?: (boolean);

        /** StoryFilter excludeNotCompletedSeries. */
        public excludeNotCompletedSeries?: (boolean);

        /** StoryFilter excludeSeries. */
        public excludeSeries?: (boolean);

        /** StoryFilter excludeNotSeries. */
        public excludeNotSeries?: (boolean);

        /** StoryFilter includeHidden. */
        public includeHidden?: (boolean);

        /**
         * Gets the default type url for StoryFilter
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StoryScriptType enum. */
    export enum StoryScriptType {
        STORY_SCRIPT_TYPE_UNSPECIFIED = "STORY_SCRIPT_TYPE_UNSPECIFIED",
        STORY_SCRIPT_TYPE_CHAT_NOVEL = "STORY_SCRIPT_TYPE_CHAT_NOVEL",
        STORY_SCRIPT_TYPE_NOVEL = "STORY_SCRIPT_TYPE_NOVEL",
        STORY_SCRIPT_TYPE_VIDEO = "STORY_SCRIPT_TYPE_VIDEO",
        STORY_SCRIPT_TYPE_AUDIO = "STORY_SCRIPT_TYPE_AUDIO"
    }

    /** SensitiveFlag enum. */
    export enum SensitiveFlag {
        SENSITIVE_FLAG_UNSPECIFIED = "SENSITIVE_FLAG_UNSPECIFIED",
        SENSITIVE_FLAG_ENABLED_BY_USER = "SENSITIVE_FLAG_ENABLED_BY_USER",
        SENSITIVE_FLAG_ENABLED_BY_ADMIN = "SENSITIVE_FLAG_ENABLED_BY_ADMIN"
    }

    /** UserRole enum. */
    export enum UserRole {
        USER_ROLE_UNSPECIFIED = "USER_ROLE_UNSPECIFIED",
        USER_ROLE_PLAIN = "USER_ROLE_PLAIN",
        USER_ROLE_OFFICIAL_WRITER = "USER_ROLE_OFFICIAL_WRITER",
        USER_ROLE_BANNED = "USER_ROLE_BANNED",
        USER_ROLE_ADMIN = "USER_ROLE_ADMIN"
    }

    /** VipStatus enum. */
    export enum VipStatus {
        VIP_STATUS_UNSPECIFIED = "VIP_STATUS_UNSPECIFIED",
        VIP_STATUS_NONE = "VIP_STATUS_NONE",
        VIP_STATUS_IN_TRIAL = "VIP_STATUS_IN_TRIAL",
        VIP_STATUS_VIP = "VIP_STATUS_VIP"
    }

    /** VipPlan enum. */
    export enum VipPlan {
        VIP_PLAN_UNSPECIFIED = "VIP_PLAN_UNSPECIFIED",
        VIP_PLAN_LITE = "VIP_PLAN_LITE",
        VIP_PLAN_STANDARD = "VIP_PLAN_STANDARD"
    }

    /** Properties of a VipMembership. */
    export interface IVipMembership {

        /** VipMembership plan */
        plan?: (query_types.VipPlan);

        /** VipMembership inTrial */
        inTrial?: (boolean);
    }

    /** Represents a VipMembership. */
    class VipMembership implements IVipMembership {

        /**
         * Constructs a new VipMembership.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_types.IVipMembership);

        /** VipMembership plan. */
        public plan: query_types.VipPlan;

        /** VipMembership inTrial. */
        public inTrial?: (boolean);

        /**
         * Gets the default type url for VipMembership
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StoryAvailability enum. */
    export enum StoryAvailability {
        STORY_AVAILABILITY_UNSPECIFIED = "STORY_AVAILABILITY_UNSPECIFIED",
        STORY_AVAILABILITY_REQUIRE_VIP = "STORY_AVAILABILITY_REQUIRE_VIP",
        STORY_AVAILABILITY_REQUIRE_WAIT = "STORY_AVAILABILITY_REQUIRE_WAIT",
        STORY_AVAILABILITY_REQUIRE_NONE = "STORY_AVAILABILITY_REQUIRE_NONE",
        STORY_AVAILABILITY_REQUIRE_FOLLOWING = "STORY_AVAILABILITY_REQUIRE_FOLLOWING",
        STORY_AVAILABILITY_REQUIRE_FOLLOWED = "STORY_AVAILABILITY_REQUIRE_FOLLOWED",
        STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING = "STORY_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING",
        STORY_AVAILABILITY_REQUIRE_PAID = "STORY_AVAILABILITY_REQUIRE_PAID",
        STORY_AVAILABILITY_REQUIRE_PAID_PREVIOUS = "STORY_AVAILABILITY_REQUIRE_PAID_PREVIOUS"
    }

    /** PublishSchedule enum. */
    export enum PublishSchedule {
        PUBLISH_SCHEDULE_UNSPECIFIED = "PUBLISH_SCHEDULE_UNSPECIFIED",
        PUBLISH_SCHEDULE_EVARY_DAY = "PUBLISH_SCHEDULE_EVARY_DAY",
        PUBLISH_SCHEDULE_EVERY_SUNDAY = "PUBLISH_SCHEDULE_EVERY_SUNDAY",
        PUBLISH_SCHEDULE_EVERY_MONDAY = "PUBLISH_SCHEDULE_EVERY_MONDAY",
        PUBLISH_SCHEDULE_EVERY_TUESDAY = "PUBLISH_SCHEDULE_EVERY_TUESDAY",
        PUBLISH_SCHEDULE_EVERY_WEDNESDAY = "PUBLISH_SCHEDULE_EVERY_WEDNESDAY",
        PUBLISH_SCHEDULE_EVERY_THURSDAY = "PUBLISH_SCHEDULE_EVERY_THURSDAY",
        PUBLISH_SCHEDULE_EVERY_FRIDAY = "PUBLISH_SCHEDULE_EVERY_FRIDAY",
        PUBLISH_SCHEDULE_EVERY_SATURDAY = "PUBLISH_SCHEDULE_EVERY_SATURDAY"
    }

    /** SeriesAvailability enum. */
    export enum SeriesAvailability {
        SERIES_AVAILABILITY_UNSPECIFIED = "SERIES_AVAILABILITY_UNSPECIFIED",
        SERIES_AVAILABILITY_REQUIRE_FOLLOWING = "SERIES_AVAILABILITY_REQUIRE_FOLLOWING",
        SERIES_AVAILABILITY_REQUIRE_FOLLOWED = "SERIES_AVAILABILITY_REQUIRE_FOLLOWED",
        SERIES_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING = "SERIES_AVAILABILITY_REQUIRE_MUTUAL_FOLLOWING",
        SERIES_AVAILABILITY_REQUIRE_NONE = "SERIES_AVAILABILITY_REQUIRE_NONE"
    }

    /** OriginalFlag enum. */
    export enum OriginalFlag {
        ORIGINAL_FLAG_UNSPECIFIED = "ORIGINAL_FLAG_UNSPECIFIED",
        ORIGINAL_FLAG_ORIGINAL = "ORIGINAL_FLAG_ORIGINAL",
        ORIGINAL_FLAG_FANFIC = "ORIGINAL_FLAG_FANFIC",
        ORIGINAL_FLAG_OTHER = "ORIGINAL_FLAG_OTHER"
    }

    /** Genre enum. */
    export enum Genre {
        GENRE_UNSPECIFIED = "GENRE_UNSPECIFIED",
        GENRE_FANFIC = "GENRE_FANFIC",
        GENRE_OTHER = "GENRE_OTHER",
        GENRE_HORROR_THRILLER = "GENRE_HORROR_THRILLER",
        GENRE_ROMANCE_FANTASY = "GENRE_ROMANCE_FANTASY",
        GENRE_BL = "GENRE_BL",
        GENRE_DRAMA = "GENRE_DRAMA",
        GENRE_ROMANCE = "GENRE_ROMANCE",
        GENRE_FANTASY = "GENRE_FANTASY",
        GENRE_COMEDY = "GENRE_COMEDY"
    }

    /** Trend enum. */
    export enum Trend {
        TREND_UNSPECIFIED = "TREND_UNSPECIFIED",
        TREND_UP = "TREND_UP",
        TREND_DOWN = "TREND_DOWN",
        TREND_STAY = "TREND_STAY"
    }
}

/** Namespace challenge. */
export namespace challenge {

    /** Properties of a Challenge. */
    export interface IChallenge {

        /** Challenge id */
        id?: (string);

        /** Challenge name */
        name?: (string);

        /** Challenge levelList */
        levelList?: (challenge.Challenge.ILevel[]);

        /** Challenge updatedAt */
        updatedAt?: (string);
    }

    /** Represents a Challenge. */
    class Challenge implements IChallenge {

        /**
         * Constructs a new Challenge.
         * @param [properties] Properties to set
         */
        constructor(properties?: challenge.IChallenge);

        /** Challenge id. */
        public id?: (string);

        /** Challenge name. */
        public name?: (string);

        /** Challenge levelList. */
        public levelList: challenge.Challenge.ILevel[];

        /** Challenge updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for Challenge
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace Challenge {

        /** Properties of a Level. */
        export interface ILevel {

            /** Level level */
            level?: (number);

            /** Level conditionText */
            conditionText?: (string);

            /** Level icon */
            icon?: (resource.IImage);
        }

        /** Represents a Level. */
        class Level implements ILevel {

            /**
             * Constructs a new Level.
             * @param [properties] Properties to set
             */
            constructor(properties?: challenge.Challenge.ILevel);

            /** Level level. */
            public level?: (number);

            /** Level conditionText. */
            public conditionText?: (string);

            /** Level icon. */
            public icon?: (resource.IImage);

            /**
             * Gets the default type url for Level
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a ChallengeStatus. */
    export interface IChallengeStatus {

        /** ChallengeStatus challenge */
        challenge?: (challenge.IChallenge);

        /** ChallengeStatus userId */
        userId?: (string);

        /** ChallengeStatus currentLevel */
        currentLevel?: (number);

        /** ChallengeStatus achievedAt */
        achievedAt?: (string);
    }

    /** Represents a ChallengeStatus. */
    class ChallengeStatus implements IChallengeStatus {

        /**
         * Constructs a new ChallengeStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: challenge.IChallengeStatus);

        /** ChallengeStatus challenge. */
        public challenge?: (challenge.IChallenge);

        /** ChallengeStatus userId. */
        public userId?: (string);

        /** ChallengeStatus currentLevel. */
        public currentLevel?: (number);

        /** ChallengeStatus achievedAt. */
        public achievedAt?: (string);

        /**
         * Gets the default type url for ChallengeStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ChallengeStamp. */
    export interface IChallengeStamp {

        /** ChallengeStamp challengeId */
        challengeId?: (string);

        /** ChallengeStamp challengeName */
        challengeName?: (string);

        /** ChallengeStamp userId */
        userId?: (string);

        /** ChallengeStamp currentLevel */
        currentLevel?: (number);

        /** ChallengeStamp maxLevel */
        maxLevel?: (number);

        /** ChallengeStamp gainedAt */
        gainedAt?: (string);

        /** ChallengeStamp iconUrl */
        iconUrl?: (string);

        /** ChallengeStamp achievementMessage */
        achievementMessage?: (string);

        /** ChallengeStamp nextLevelMessage */
        nextLevelMessage?: (string);
    }

    /** Represents a ChallengeStamp. */
    class ChallengeStamp implements IChallengeStamp {

        /**
         * Constructs a new ChallengeStamp.
         * @param [properties] Properties to set
         */
        constructor(properties?: challenge.IChallengeStamp);

        /** ChallengeStamp challengeId. */
        public challengeId?: (string);

        /** ChallengeStamp challengeName. */
        public challengeName?: (string);

        /** ChallengeStamp userId. */
        public userId?: (string);

        /** ChallengeStamp currentLevel. */
        public currentLevel?: (number);

        /** ChallengeStamp maxLevel. */
        public maxLevel?: (number);

        /** ChallengeStamp gainedAt. */
        public gainedAt?: (string);

        /** ChallengeStamp iconUrl. */
        public iconUrl?: (string);

        /** ChallengeStamp achievementMessage. */
        public achievementMessage?: (string);

        /** ChallengeStamp nextLevelMessage. */
        public nextLevelMessage?: (string);

        /**
         * Gets the default type url for ChallengeStamp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ChallengeIdRequest. */
    export interface IChallengeIdRequest {

        /** ChallengeIdRequest id */
        id?: (string);
    }

    /** Represents a ChallengeIdRequest. */
    class ChallengeIdRequest implements IChallengeIdRequest {

        /**
         * Constructs a new ChallengeIdRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: challenge.IChallengeIdRequest);

        /** ChallengeIdRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for ChallengeIdRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ChallengeListStatusResponse. */
    export interface IChallengeListStatusResponse {

        /** ChallengeListStatusResponse statusList */
        statusList?: (challenge.IChallengeStatus[]);
    }

    /** Represents a ChallengeListStatusResponse. */
    class ChallengeListStatusResponse implements IChallengeListStatusResponse {

        /**
         * Constructs a new ChallengeListStatusResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: challenge.IChallengeListStatusResponse);

        /** ChallengeListStatusResponse statusList. */
        public statusList: challenge.IChallengeStatus[];

        /**
         * Gets the default type url for ChallengeListStatusResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ChallengeStampListResponse. */
    export interface IChallengeStampListResponse {

        /** ChallengeStampListResponse challengeStampList */
        challengeStampList?: (challenge.IChallengeStamp[]);
    }

    /** Represents a ChallengeStampListResponse. */
    class ChallengeStampListResponse implements IChallengeStampListResponse {

        /**
         * Constructs a new ChallengeStampListResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: challenge.IChallengeStampListResponse);

        /** ChallengeStampListResponse challengeStampList. */
        public challengeStampList: challenge.IChallengeStamp[];

        /**
         * Gets the default type url for ChallengeStampListResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace comment. */
export namespace comment {

    /** Properties of a CommentPermissionByStory. */
    export interface ICommentPermissionByStory {

        /** CommentPermissionByStory canAdd */
        canAdd?: (boolean);

        /** CommentPermissionByStory canSetPin */
        canSetPin?: (boolean);
    }

    /** Represents a CommentPermissionByStory. */
    class CommentPermissionByStory implements ICommentPermissionByStory {

        /**
         * Constructs a new CommentPermissionByStory.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentPermissionByStory);

        /** CommentPermissionByStory canAdd. */
        public canAdd?: (boolean);

        /** CommentPermissionByStory canSetPin. */
        public canSetPin?: (boolean);

        /**
         * Gets the default type url for CommentPermissionByStory
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentListWithCursor. */
    export interface ICommentListWithCursor {

        /** CommentListWithCursor commentList */
        commentList?: (comment.IComment[]);

        /** CommentListWithCursor cursor */
        cursor?: (string);
    }

    /** Represents a CommentListWithCursor. */
    class CommentListWithCursor implements ICommentListWithCursor {

        /**
         * Constructs a new CommentListWithCursor.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentListWithCursor);

        /** CommentListWithCursor commentList. */
        public commentList: comment.IComment[];

        /** CommentListWithCursor cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for CommentListWithCursor
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentStats. */
    export interface ICommentStats {

        /** CommentStats storyId */
        storyId?: (string);

        /** CommentStats commentCount */
        commentCount?: (number);
    }

    /** Represents a CommentStats. */
    class CommentStats implements ICommentStats {

        /**
         * Constructs a new CommentStats.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentStats);

        /** CommentStats storyId. */
        public storyId?: (string);

        /** CommentStats commentCount. */
        public commentCount?: (number);

        /**
         * Gets the default type url for CommentStats
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentStatsList. */
    export interface ICommentStatsList {

        /** CommentStatsList statsList */
        statsList?: (comment.ICommentStats[]);
    }

    /** Represents a CommentStatsList. */
    class CommentStatsList implements ICommentStatsList {

        /**
         * Constructs a new CommentStatsList.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentStatsList);

        /** CommentStatsList statsList. */
        public statsList: comment.ICommentStats[];

        /**
         * Gets the default type url for CommentStatsList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a Comment. */
    export interface IComment {

        /** Comment id */
        id?: (string);

        /** Comment storyId */
        storyId?: (string);

        /** Comment userId */
        userId?: (string);

        /** Comment body */
        body?: (string);

        /** Comment likeCount */
        likeCount?: (number);

        /** Comment liked */
        liked?: (boolean);

        /** Comment createdAt */
        createdAt?: (string);

        /** Comment deletedAt */
        deletedAt?: (string);

        /** Comment deletedBy */
        deletedBy?: (comment.CommentDeletedBy);

        /** Comment likedByAuthor */
        likedByAuthor?: (boolean);

        /** Comment replyCount */
        replyCount?: (number);

        /** Comment stampId */
        stampId?: (string);
    }

    /** Represents a Comment. */
    class Comment implements IComment {

        /**
         * Constructs a new Comment.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.IComment);

        /** Comment id. */
        public id?: (string);

        /** Comment storyId. */
        public storyId?: (string);

        /** Comment userId. */
        public userId?: (string);

        /** Comment body. */
        public body?: (string);

        /** Comment likeCount. */
        public likeCount?: (number);

        /** Comment liked. */
        public liked?: (boolean);

        /** Comment createdAt. */
        public createdAt?: (string);

        /** Comment deletedAt. */
        public deletedAt?: (string);

        /** Comment deletedBy. */
        public deletedBy: comment.CommentDeletedBy;

        /** Comment likedByAuthor. */
        public likedByAuthor?: (boolean);

        /** Comment replyCount. */
        public replyCount?: (number);

        /** Comment stampId. */
        public stampId?: (string);

        /**
         * Gets the default type url for Comment
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** CommentDeletedBy enum. */
    export enum CommentDeletedBy {
        COMMENT_DELETED_BY_UNSPECIFIED = "COMMENT_DELETED_BY_UNSPECIFIED",
        COMMENT_DELETED_BY_COMMENT_OWNER = "COMMENT_DELETED_BY_COMMENT_OWNER",
        COMMENT_DELETED_BY_STORY_OWNER = "COMMENT_DELETED_BY_STORY_OWNER",
        COMMENT_DELETED_BY_ADMIN = "COMMENT_DELETED_BY_ADMIN"
    }

    /** CommentViolationType enum. */
    export enum CommentViolationType {
        COMMENT_VIOLATION_TYPE_UNSPECIFIED = "COMMENT_VIOLATION_TYPE_UNSPECIFIED",
        COMMENT_VIOLATION_TYPE_VIOLENCE = "COMMENT_VIOLATION_TYPE_VIOLENCE",
        COMMENT_VIOLATION_TYPE_HARASSMENT = "COMMENT_VIOLATION_TYPE_HARASSMENT",
        COMMENT_VIOLATION_TYPE_PRIVACY = "COMMENT_VIOLATION_TYPE_PRIVACY",
        COMMENT_VIOLATION_TYPE_SPAM = "COMMENT_VIOLATION_TYPE_SPAM",
        COMMENT_VIOLATION_TYPE_ADULT = "COMMENT_VIOLATION_TYPE_ADULT",
        COMMENT_VIOLATION_TYPE_OTHER = "COMMENT_VIOLATION_TYPE_OTHER"
    }

    /** Properties of a CommentAddRequest. */
    export interface ICommentAddRequest {

        /** CommentAddRequest storyId */
        storyId?: (string);

        /** CommentAddRequest body */
        body?: (string);

        /** CommentAddRequest stampId */
        stampId?: (string);
    }

    /** Represents a CommentAddRequest. */
    class CommentAddRequest implements ICommentAddRequest {

        /**
         * Constructs a new CommentAddRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentAddRequest);

        /** CommentAddRequest storyId. */
        public storyId?: (string);

        /** CommentAddRequest body. */
        public body?: (string);

        /** CommentAddRequest stampId. */
        public stampId?: (string);

        /**
         * Gets the default type url for CommentAddRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentReplyRequest. */
    export interface ICommentReplyRequest {

        /** CommentReplyRequest parentCommentId */
        parentCommentId?: (string);

        /** CommentReplyRequest addRequest */
        addRequest?: (comment.ICommentAddRequest);
    }

    /** Represents a CommentReplyRequest. */
    class CommentReplyRequest implements ICommentReplyRequest {

        /**
         * Constructs a new CommentReplyRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentReplyRequest);

        /** CommentReplyRequest parentCommentId. */
        public parentCommentId?: (string);

        /** CommentReplyRequest addRequest. */
        public addRequest?: (comment.ICommentAddRequest);

        /**
         * Gets the default type url for CommentReplyRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** CommentSortedBy enum. */
    export enum CommentSortedBy {
        COMMENT_SORTED_BY_CREATED_AT_DESC = "COMMENT_SORTED_BY_CREATED_AT_DESC",
        COMMENT_SORTED_BY_LIKE_COUNT_DESC = "COMMENT_SORTED_BY_LIKE_COUNT_DESC"
    }

    /** Properties of a CanAddResponse. */
    export interface ICanAddResponse {

        /** CanAddResponse canAdd */
        canAdd?: (boolean);
    }

    /** Represents a CanAddResponse. */
    class CanAddResponse implements ICanAddResponse {

        /**
         * Constructs a new CanAddResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICanAddResponse);

        /** CanAddResponse canAdd. */
        public canAdd?: (boolean);

        /**
         * Gets the default type url for CanAddResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentIdRequest. */
    export interface ICommentIdRequest {

        /** CommentIdRequest id */
        id?: (string);
    }

    /** Represents a CommentIdRequest. */
    class CommentIdRequest implements ICommentIdRequest {

        /**
         * Constructs a new CommentIdRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentIdRequest);

        /** CommentIdRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for CommentIdRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentGetSectionsRequest. */
    export interface ICommentGetSectionsRequest {

        /** CommentGetSectionsRequest storyId */
        storyId?: (string);

        /** CommentGetSectionsRequest includeDeleted */
        includeDeleted?: (boolean);

        /** CommentGetSectionsRequest limit */
        limit?: (number);
    }

    /** Represents a CommentGetSectionsRequest. */
    class CommentGetSectionsRequest implements ICommentGetSectionsRequest {

        /**
         * Constructs a new CommentGetSectionsRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentGetSectionsRequest);

        /** CommentGetSectionsRequest storyId. */
        public storyId?: (string);

        /** CommentGetSectionsRequest includeDeleted. */
        public includeDeleted?: (boolean);

        /** CommentGetSectionsRequest limit. */
        public limit?: (number);

        /**
         * Gets the default type url for CommentGetSectionsRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentGetSectionsResponse. */
    export interface ICommentGetSectionsResponse {

        /** CommentGetSectionsResponse pinnedList */
        pinnedList?: (comment.IComment[]);

        /** CommentGetSectionsResponse popularList */
        popularList?: (comment.IComment[]);

        /** CommentGetSectionsResponse allList */
        allList?: (comment.ICommentListWithCursor);
    }

    /** Represents a CommentGetSectionsResponse. */
    class CommentGetSectionsResponse implements ICommentGetSectionsResponse {

        /**
         * Constructs a new CommentGetSectionsResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentGetSectionsResponse);

        /** CommentGetSectionsResponse pinnedList. */
        public pinnedList: comment.IComment[];

        /** CommentGetSectionsResponse popularList. */
        public popularList: comment.IComment[];

        /** CommentGetSectionsResponse allList. */
        public allList?: (comment.ICommentListWithCursor);

        /**
         * Gets the default type url for CommentGetSectionsResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentListRequest. */
    export interface ICommentListRequest {

        /** CommentListRequest storyId */
        storyId?: (string);

        /** CommentListRequest includeDeleted */
        includeDeleted?: (boolean);

        /** CommentListRequest limit */
        limit?: (number);

        /** CommentListRequest cursor */
        cursor?: (string);

        /** CommentListRequest sortedBy */
        sortedBy?: (comment.CommentSortedBy);
    }

    /** Represents a CommentListRequest. */
    class CommentListRequest implements ICommentListRequest {

        /**
         * Constructs a new CommentListRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentListRequest);

        /** CommentListRequest storyId. */
        public storyId?: (string);

        /** CommentListRequest includeDeleted. */
        public includeDeleted?: (boolean);

        /** CommentListRequest limit. */
        public limit?: (number);

        /** CommentListRequest cursor. */
        public cursor?: (string);

        /** CommentListRequest sortedBy. */
        public sortedBy: comment.CommentSortedBy;

        /**
         * Gets the default type url for CommentListRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentListReplyRequest. */
    export interface ICommentListReplyRequest {

        /** CommentListReplyRequest parentCommentId */
        parentCommentId?: (string);

        /** CommentListReplyRequest limit */
        limit?: (number);

        /** CommentListReplyRequest cursor */
        cursor?: (string);
    }

    /** Represents a CommentListReplyRequest. */
    class CommentListReplyRequest implements ICommentListReplyRequest {

        /**
         * Constructs a new CommentListReplyRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentListReplyRequest);

        /** CommentListReplyRequest parentCommentId. */
        public parentCommentId?: (string);

        /** CommentListReplyRequest limit. */
        public limit?: (number);

        /** CommentListReplyRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for CommentListReplyRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentReportViolationRequestV2. */
    export interface ICommentReportViolationRequestV2 {

        /** CommentReportViolationRequestV2 commentId */
        commentId?: (string);

        /** CommentReportViolationRequestV2 type */
        type?: (types.ViolationType);
    }

    /** Represents a CommentReportViolationRequestV2. */
    class CommentReportViolationRequestV2 implements ICommentReportViolationRequestV2 {

        /**
         * Constructs a new CommentReportViolationRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentReportViolationRequestV2);

        /** CommentReportViolationRequestV2 commentId. */
        public commentId?: (string);

        /** CommentReportViolationRequestV2 type. */
        public type: types.ViolationType;

        /**
         * Gets the default type url for CommentReportViolationRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentReportViolationRequest. */
    export interface ICommentReportViolationRequest {

        /** CommentReportViolationRequest commentId */
        commentId?: (string);

        /** CommentReportViolationRequest type */
        type?: (comment.CommentViolationType);
    }

    /** Represents a CommentReportViolationRequest. */
    class CommentReportViolationRequest implements ICommentReportViolationRequest {

        /**
         * Constructs a new CommentReportViolationRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentReportViolationRequest);

        /** CommentReportViolationRequest commentId. */
        public commentId?: (string);

        /** CommentReportViolationRequest type. */
        public type: comment.CommentViolationType;

        /**
         * Gets the default type url for CommentReportViolationRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentReply. */
    export interface ICommentReply {

        /** CommentReply parentCommentId */
        parentCommentId?: (string);

        /** CommentReply comment */
        comment?: (comment.IComment);
    }

    /** Represents a CommentReply. */
    class CommentReply implements ICommentReply {

        /**
         * Constructs a new CommentReply.
         * @param [properties] Properties to set
         */
        constructor(properties?: comment.ICommentReply);

        /** CommentReply parentCommentId. */
        public parentCommentId?: (string);

        /** CommentReply comment. */
        public comment?: (comment.IComment);

        /**
         * Gets the default type url for CommentReply
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace errors. */
export namespace errors {

    /** ErrorMessage enum. */
    export enum ErrorMessage {
        ERROR_MESSAGE_UNKNOWN = "ERROR_MESSAGE_UNKNOWN",
        BAD_REQUEST_ERROR_CODE = "BAD_REQUEST_ERROR_CODE",
        REQUIRED_ERROR_CODE = "REQUIRED_ERROR_CODE",
        MIN_LENGTH_ERROR_CODE = "MIN_LENGTH_ERROR_CODE",
        MAX_LENGTH_ERROR_CODE = "MAX_LENGTH_ERROR_CODE",
        INVALID_FORMAT_ERROR_CODE = "INVALID_FORMAT_ERROR_CODE",
        UNAUTHORIZED_ERROR_CODE = "UNAUTHORIZED_ERROR_CODE",
        FORBIDDEN_ERROR_CODE = "FORBIDDEN_ERROR_CODE",
        NOT_FOUND_ERROR_CODE = "NOT_FOUND_ERROR_CODE",
        SERVER_ERROR_CODE = "SERVER_ERROR_CODE",
        MAINTENANCE_ERROR_CODE = "MAINTENANCE_ERROR_CODE",
        AUTHORIZATION_EXPIRED_ERROR_CODE = "AUTHORIZATION_EXPIRED_ERROR_CODE",
        CONFLICT_ERROR_CODE = "CONFLICT_ERROR_CODE"
    }

    /** ErrorField enum. */
    export enum ErrorField {
        ERROR_FIELD_UNKNOWN = "ERROR_FIELD_UNKNOWN",
        TITLE = "TITLE",
        THUMBNAIL_URL = "THUMBNAIL_URL",
        TAGS = "TAGS",
        SCRIPT_BODY = "SCRIPT_BODY",
        NAME = "NAME",
        PROFILE = "PROFILE",
        GS_PATH = "GS_PATH",
        WIDTH = "WIDTH",
        HEIGHT = "HEIGHT",
        CATEGORY = "CATEGORY",
        FORMAT = "FORMAT",
        DURATION = "DURATION",
        CODEC = "CODEC",
        AMOUNT = "AMOUNT",
        DESCRIPTION = "DESCRIPTION"
    }
}

/** Namespace event. */
export namespace event {

    /** Properties of an Event. */
    export interface IEvent {

        /** Event id */
        id?: (string);

        /** Event name */
        name?: (string);

        /** Event description */
        description?: (string);

        /** Event tag */
        tag?: (string);

        /** Event actionUrl */
        actionUrl?: (string);

        /** Event coverImage */
        coverImage?: (resource.IImage);

        /** Event since */
        since?: (string);

        /** Event until */
        until?: (string);

        /** Event createdAt */
        createdAt?: (string);

        /** Event updatedAt */
        updatedAt?: (string);
    }

    /** Represents an Event. */
    class Event implements IEvent {

        /**
         * Constructs a new Event.
         * @param [properties] Properties to set
         */
        constructor(properties?: event.IEvent);

        /** Event id. */
        public id?: (string);

        /** Event name. */
        public name?: (string);

        /** Event description. */
        public description?: (string);

        /** Event tag. */
        public tag?: (string);

        /** Event actionUrl. */
        public actionUrl?: (string);

        /** Event coverImage. */
        public coverImage?: (resource.IImage);

        /** Event since. */
        public since?: (string);

        /** Event until. */
        public until?: (string);

        /** Event createdAt. */
        public createdAt?: (string);

        /** Event updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for Event
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an EventList. */
    export interface IEventList {

        /** EventList eventList */
        eventList?: (event.IEvent[]);
    }

    /** Represents an EventList. */
    class EventList implements IEventList {

        /**
         * Constructs a new EventList.
         * @param [properties] Properties to set
         */
        constructor(properties?: event.IEventList);

        /** EventList eventList. */
        public eventList: event.IEvent[];

        /**
         * Gets the default type url for EventList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** EventLabel enum. */
    export enum EventLabel {
        EVENT_LABEL_UNSPECIFIED = "EVENT_LABEL_UNSPECIFIED",
        EVENT_LABEL_THEME = "EVENT_LABEL_THEME",
        EVENT_LABEL_CONTEST = "EVENT_LABEL_CONTEST"
    }
}

/** Namespace following. */
export namespace following {

    /** Properties of a FollowingStats. */
    export interface IFollowingStats {

        /** FollowingStats userId */
        userId?: (string);

        /** FollowingStats followerCount */
        followerCount?: (number);

        /** FollowingStats followeeCount */
        followeeCount?: (number);
    }

    /** Represents a FollowingStats. */
    class FollowingStats implements IFollowingStats {

        /**
         * Constructs a new FollowingStats.
         * @param [properties] Properties to set
         */
        constructor(properties?: following.IFollowingStats);

        /** FollowingStats userId. */
        public userId?: (string);

        /** FollowingStats followerCount. */
        public followerCount?: (number);

        /** FollowingStats followeeCount. */
        public followeeCount?: (number);

        /**
         * Gets the default type url for FollowingStats
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace user. */
export namespace user {

    /** Properties of a UserIdRequest. */
    export interface IUserIdRequest {

        /** UserIdRequest id */
        id?: (string);
    }

    /** Represents a UserIdRequest. */
    class UserIdRequest implements IUserIdRequest {

        /**
         * Constructs a new UserIdRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUserIdRequest);

        /** UserIdRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for UserIdRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserGetMultiRequest. */
    export interface IUserGetMultiRequest {

        /** UserGetMultiRequest idList */
        idList?: (string[]);
    }

    /** Represents a UserGetMultiRequest. */
    class UserGetMultiRequest implements IUserGetMultiRequest {

        /**
         * Constructs a new UserGetMultiRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUserGetMultiRequest);

        /** UserGetMultiRequest idList. */
        public idList: string[];

        /**
         * Gets the default type url for UserGetMultiRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserIdWithCursorRequest. */
    export interface IUserIdWithCursorRequest {

        /** UserIdWithCursorRequest id */
        id?: (string);

        /** UserIdWithCursorRequest limit */
        limit?: (number);

        /** UserIdWithCursorRequest cursor */
        cursor?: (string);
    }

    /** Represents a UserIdWithCursorRequest. */
    class UserIdWithCursorRequest implements IUserIdWithCursorRequest {

        /**
         * Constructs a new UserIdWithCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUserIdWithCursorRequest);

        /** UserIdWithCursorRequest id. */
        public id?: (string);

        /** UserIdWithCursorRequest limit. */
        public limit?: (number);

        /** UserIdWithCursorRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for UserIdWithCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a User. */
    export interface IUser {

        /** User id */
        id?: (string);

        /** User name */
        name?: (string);

        /** User profile */
        profile?: (string);

        /** User thumbnail */
        thumbnail?: (resource.IImage);

        /** User coverImage */
        coverImage?: (resource.IImage);

        /** User createdAt */
        createdAt?: (string);

        /** User updatedAt */
        updatedAt?: (string);

        /** User isVip */
        isVip?: (boolean);

        /** User isOfficialWriter */
        isOfficialWriter?: (boolean);

        /** User isInTrial */
        isInTrial?: (boolean);
    }

    /** Represents a User. */
    class User implements IUser {

        /**
         * Constructs a new User.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUser);

        /** User id. */
        public id?: (string);

        /** User name. */
        public name?: (string);

        /** User profile. */
        public profile?: (string);

        /** User thumbnail. */
        public thumbnail?: (resource.IImage);

        /** User coverImage. */
        public coverImage?: (resource.IImage);

        /** User createdAt. */
        public createdAt?: (string);

        /** User updatedAt. */
        public updatedAt?: (string);

        /** User isVip. */
        public isVip?: (boolean);

        /** User isOfficialWriter. */
        public isOfficialWriter?: (boolean);

        /** User isInTrial. */
        public isInTrial?: (boolean);

        /**
         * Gets the default type url for User
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserList. */
    export interface IUserList {

        /** UserList userList */
        userList?: (user.IUser[]);
    }

    /** Represents a UserList. */
    class UserList implements IUserList {

        /**
         * Constructs a new UserList.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUserList);

        /** UserList userList. */
        public userList: user.IUser[];

        /**
         * Gets the default type url for UserList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserListWithCursor. */
    export interface IUserListWithCursor {

        /** UserListWithCursor userList */
        userList?: (user.IUser[]);

        /** UserListWithCursor cursor */
        cursor?: (string);
    }

    /** Represents a UserListWithCursor. */
    class UserListWithCursor implements IUserListWithCursor {

        /**
         * Constructs a new UserListWithCursor.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUserListWithCursor);

        /** UserListWithCursor userList. */
        public userList: user.IUser[];

        /** UserListWithCursor cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for UserListWithCursor
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserV1. */
    export interface IUserV1 {

        /** UserV1 id */
        id?: (string);

        /** UserV1 name */
        name?: (string);

        /** UserV1 profile */
        profile?: (string);

        /** UserV1 thumbnailUrl */
        thumbnailUrl?: (string);

        /** UserV1 coverImageUrl */
        coverImageUrl?: (string);
    }

    /** Represents a UserV1. */
    class UserV1 implements IUserV1 {

        /**
         * Constructs a new UserV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUserV1);

        /** UserV1 id. */
        public id?: (string);

        /** UserV1 name. */
        public name?: (string);

        /** UserV1 profile. */
        public profile?: (string);

        /** UserV1 thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** UserV1 coverImageUrl. */
        public coverImageUrl?: (string);

        /**
         * Gets the default type url for UserV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserGetResponseV1. */
    export interface IUserGetResponseV1 {

        /** UserGetResponseV1 user */
        user?: (user.IUserV1);
    }

    /** Represents a UserGetResponseV1. */
    class UserGetResponseV1 implements IUserGetResponseV1 {

        /**
         * Constructs a new UserGetResponseV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUserGetResponseV1);

        /** UserGetResponseV1 user. */
        public user?: (user.IUserV1);

        /**
         * Gets the default type url for UserGetResponseV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserGetMultiResponseV1. */
    export interface IUserGetMultiResponseV1 {

        /** UserGetMultiResponseV1 userList */
        userList?: (user.IUserV1[]);
    }

    /** Represents a UserGetMultiResponseV1. */
    class UserGetMultiResponseV1 implements IUserGetMultiResponseV1 {

        /**
         * Constructs a new UserGetMultiResponseV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: user.IUserGetMultiResponseV1);

        /** UserGetMultiResponseV1 userList. */
        public userList: user.IUserV1[];

        /**
         * Gets the default type url for UserGetMultiResponseV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace user_selection. */
export namespace user_selection {

    /** Properties of a UserSelectionV3. */
    export interface IUserSelectionV3 {

        /** UserSelectionV3 id */
        id?: (string);

        /** UserSelectionV3 title */
        title?: (string);

        /** UserSelectionV3 description */
        description?: (string);

        /** UserSelectionV3 thumbnail */
        thumbnail?: (resource.IImage);

        /** UserSelectionV3 userIdList */
        userIdList?: (string[]);

        /** UserSelectionV3 createdAt */
        createdAt?: (string);

        /** UserSelectionV3 updatedAt */
        updatedAt?: (string);
    }

    /** Represents a UserSelectionV3. */
    class UserSelectionV3 implements IUserSelectionV3 {

        /**
         * Constructs a new UserSelectionV3.
         * @param [properties] Properties to set
         */
        constructor(properties?: user_selection.IUserSelectionV3);

        /** UserSelectionV3 id. */
        public id?: (string);

        /** UserSelectionV3 title. */
        public title?: (string);

        /** UserSelectionV3 description. */
        public description?: (string);

        /** UserSelectionV3 thumbnail. */
        public thumbnail?: (resource.IImage);

        /** UserSelectionV3 userIdList. */
        public userIdList: string[];

        /** UserSelectionV3 createdAt. */
        public createdAt?: (string);

        /** UserSelectionV3 updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for UserSelectionV3
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserSelection. */
    export interface IUserSelection {

        /** UserSelection id */
        id?: (string);

        /** UserSelection title */
        title?: (string);

        /** UserSelection description */
        description?: (string);

        /** UserSelection thumbnail */
        thumbnail?: (resource.IImage);

        /** UserSelection userList */
        userList?: (user.IUser[]);

        /** UserSelection createdAt */
        createdAt?: (string);

        /** UserSelection updatedAt */
        updatedAt?: (string);
    }

    /** Represents a UserSelection. */
    class UserSelection implements IUserSelection {

        /**
         * Constructs a new UserSelection.
         * @param [properties] Properties to set
         */
        constructor(properties?: user_selection.IUserSelection);

        /** UserSelection id. */
        public id?: (string);

        /** UserSelection title. */
        public title?: (string);

        /** UserSelection description. */
        public description?: (string);

        /** UserSelection thumbnail. */
        public thumbnail?: (resource.IImage);

        /** UserSelection userList. */
        public userList: user.IUser[];

        /** UserSelection createdAt. */
        public createdAt?: (string);

        /** UserSelection updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for UserSelection
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserSelectionWithCursor. */
    export interface IUserSelectionWithCursor {

        /** UserSelectionWithCursor userSelection */
        userSelection?: (user_selection.IUserSelection);

        /** UserSelectionWithCursor cursor */
        cursor?: (string);
    }

    /** Represents a UserSelectionWithCursor. */
    class UserSelectionWithCursor implements IUserSelectionWithCursor {

        /**
         * Constructs a new UserSelectionWithCursor.
         * @param [properties] Properties to set
         */
        constructor(properties?: user_selection.IUserSelectionWithCursor);

        /** UserSelectionWithCursor userSelection. */
        public userSelection?: (user_selection.IUserSelection);

        /** UserSelectionWithCursor cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for UserSelectionWithCursor
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserSelectionGetResponse. */
    export interface IUserSelectionGetResponse {

        /** UserSelectionGetResponse userSelection */
        userSelection?: (user_selection.IUserSelectionV1);

        /** UserSelectionGetResponse cursor */
        cursor?: (string);
    }

    /** Represents a UserSelectionGetResponse. */
    class UserSelectionGetResponse implements IUserSelectionGetResponse {

        /**
         * Constructs a new UserSelectionGetResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: user_selection.IUserSelectionGetResponse);

        /** UserSelectionGetResponse userSelection. */
        public userSelection?: (user_selection.IUserSelectionV1);

        /** UserSelectionGetResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for UserSelectionGetResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserSelectionV1. */
    export interface IUserSelectionV1 {

        /** UserSelectionV1 id */
        id?: (string);

        /** UserSelectionV1 title */
        title?: (string);

        /** UserSelectionV1 description */
        description?: (string);

        /** UserSelectionV1 thumbnailUrl */
        thumbnailUrl?: (string);

        /** UserSelectionV1 userList */
        userList?: (user.IUserV1[]);

        /** UserSelectionV1 createdAt */
        createdAt?: (string);

        /** UserSelectionV1 updatedAt */
        updatedAt?: (string);
    }

    /** Represents a UserSelectionV1. */
    class UserSelectionV1 implements IUserSelectionV1 {

        /**
         * Constructs a new UserSelectionV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: user_selection.IUserSelectionV1);

        /** UserSelectionV1 id. */
        public id?: (string);

        /** UserSelectionV1 title. */
        public title?: (string);

        /** UserSelectionV1 description. */
        public description?: (string);

        /** UserSelectionV1 thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** UserSelectionV1 userList. */
        public userList: user.IUserV1[];

        /** UserSelectionV1 createdAt. */
        public createdAt?: (string);

        /** UserSelectionV1 updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for UserSelectionV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace home. */
export namespace home {

    /** Properties of a HomeGetResponse. */
    export interface IHomeGetResponse {

        /** HomeGetResponse readingProgressList */
        readingProgressList?: (reading_progress.IReadingProgress[]);

        /** HomeGetResponse favoriteStorySelection */
        favoriteStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse recommendStorySelection */
        recommendStorySelection?: (story_selection.IStorySelectionWithCursorV3);

        /** HomeGetResponse risingStorySelection */
        risingStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse freeStorySelection */
        freeStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse top10StorySelection */
        top10StorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse advertisementStorySelection */
        advertisementStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse horrorStorySelection */
        horrorStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse mysteryStorySelection */
        mysteryStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse dramaStorySelection */
        dramaStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse youthRomanceStorySelection */
        youthRomanceStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse adultRomanceStorySelection */
        adultRomanceStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse fantasyStorySelection */
        fantasyStorySelection?: (story_selection.IStorySelectionV3);
    }

    /** Represents a HomeGetResponse. */
    class HomeGetResponse implements IHomeGetResponse {

        /**
         * Constructs a new HomeGetResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: home.IHomeGetResponse);

        /** HomeGetResponse readingProgressList. */
        public readingProgressList: reading_progress.IReadingProgress[];

        /** HomeGetResponse favoriteStorySelection. */
        public favoriteStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse recommendStorySelection. */
        public recommendStorySelection?: (story_selection.IStorySelectionWithCursorV3);

        /** HomeGetResponse risingStorySelection. */
        public risingStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse freeStorySelection. */
        public freeStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse top10StorySelection. */
        public top10StorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse advertisementStorySelection. */
        public advertisementStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse horrorStorySelection. */
        public horrorStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse mysteryStorySelection. */
        public mysteryStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse dramaStorySelection. */
        public dramaStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse youthRomanceStorySelection. */
        public youthRomanceStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse adultRomanceStorySelection. */
        public adultRomanceStorySelection?: (story_selection.IStorySelectionV3);

        /** HomeGetResponse fantasyStorySelection. */
        public fantasyStorySelection?: (story_selection.IStorySelectionV3);

        /**
         * Gets the default type url for HomeGetResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace reading_progress. */
export namespace reading_progress {

    /** Properties of a ReadingProgress. */
    export interface IReadingProgress {

        /** ReadingProgress storyId */
        storyId?: (string);

        /** ReadingProgress index */
        index?: (number);

        /** ReadingProgress isFinished */
        isFinished?: (boolean);

        /** ReadingProgress createdAt */
        createdAt?: (string);

        /** ReadingProgress updatedAt */
        updatedAt?: (string);
    }

    /** Represents a ReadingProgress. */
    class ReadingProgress implements IReadingProgress {

        /**
         * Constructs a new ReadingProgress.
         * @param [properties] Properties to set
         */
        constructor(properties?: reading_progress.IReadingProgress);

        /** ReadingProgress storyId. */
        public storyId?: (string);

        /** ReadingProgress index. */
        public index?: (number);

        /** ReadingProgress isFinished. */
        public isFinished?: (boolean);

        /** ReadingProgress createdAt. */
        public createdAt?: (string);

        /** ReadingProgress updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for ReadingProgress
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace story_selection. */
export namespace story_selection {

    /** Properties of a StorySelectionV3. */
    export interface IStorySelectionV3 {

        /** StorySelectionV3 id */
        id?: (string);

        /** StorySelectionV3 title */
        title?: (string);

        /** StorySelectionV3 description */
        description?: (string);

        /** StorySelectionV3 thumbnail */
        thumbnail?: (resource.IImage);

        /** StorySelectionV3 storyList */
        storyList?: (story.IStoryIDWithMetadata[]);

        /** StorySelectionV3 createdAt */
        createdAt?: (string);

        /** StorySelectionV3 updatedAt */
        updatedAt?: (string);
    }

    /** Represents a StorySelectionV3. */
    class StorySelectionV3 implements IStorySelectionV3 {

        /**
         * Constructs a new StorySelectionV3.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionV3);

        /** StorySelectionV3 id. */
        public id?: (string);

        /** StorySelectionV3 title. */
        public title?: (string);

        /** StorySelectionV3 description. */
        public description?: (string);

        /** StorySelectionV3 thumbnail. */
        public thumbnail?: (resource.IImage);

        /** StorySelectionV3 storyList. */
        public storyList: story.IStoryIDWithMetadata[];

        /** StorySelectionV3 createdAt. */
        public createdAt?: (string);

        /** StorySelectionV3 updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for StorySelectionV3
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionWithCursorV3. */
    export interface IStorySelectionWithCursorV3 {

        /** StorySelectionWithCursorV3 storySelection */
        storySelection?: (story_selection.IStorySelectionV3);

        /** StorySelectionWithCursorV3 cursor */
        cursor?: (string);
    }

    /** Represents a StorySelectionWithCursorV3. */
    class StorySelectionWithCursorV3 implements IStorySelectionWithCursorV3 {

        /**
         * Constructs a new StorySelectionWithCursorV3.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionWithCursorV3);

        /** StorySelectionWithCursorV3 storySelection. */
        public storySelection?: (story_selection.IStorySelectionV3);

        /** StorySelectionWithCursorV3 cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for StorySelectionWithCursorV3
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionListV3. */
    export interface IStorySelectionListV3 {

        /** StorySelectionListV3 storySelectionList */
        storySelectionList?: (story_selection.IStorySelectionV3[]);
    }

    /** Represents a StorySelectionListV3. */
    class StorySelectionListV3 implements IStorySelectionListV3 {

        /**
         * Constructs a new StorySelectionListV3.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionListV3);

        /** StorySelectionListV3 storySelectionList. */
        public storySelectionList: story_selection.IStorySelectionV3[];

        /**
         * Gets the default type url for StorySelectionListV3
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionGetRequest. */
    export interface IStorySelectionGetRequest {

        /** StorySelectionGetRequest id */
        id?: (string);

        /** StorySelectionGetRequest limit */
        limit?: (number);

        /** StorySelectionGetRequest cursor */
        cursor?: (string);
    }

    /** Represents a StorySelectionGetRequest. */
    class StorySelectionGetRequest implements IStorySelectionGetRequest {

        /**
         * Constructs a new StorySelectionGetRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionGetRequest);

        /** StorySelectionGetRequest id. */
        public id?: (string);

        /** StorySelectionGetRequest limit. */
        public limit?: (number);

        /** StorySelectionGetRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for StorySelectionGetRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionGetMultiRequest. */
    export interface IStorySelectionGetMultiRequest {

        /** StorySelectionGetMultiRequest idList */
        idList?: (string[]);

        /** StorySelectionGetMultiRequest limitPerSelection */
        limitPerSelection?: (number);
    }

    /** Represents a StorySelectionGetMultiRequest. */
    class StorySelectionGetMultiRequest implements IStorySelectionGetMultiRequest {

        /**
         * Constructs a new StorySelectionGetMultiRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionGetMultiRequest);

        /** StorySelectionGetMultiRequest idList. */
        public idList: string[];

        /** StorySelectionGetMultiRequest limitPerSelection. */
        public limitPerSelection?: (number);

        /**
         * Gets the default type url for StorySelectionGetMultiRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionList. */
    export interface IStorySelectionList {

        /** StorySelectionList storySelectionList */
        storySelectionList?: (story_selection.IStorySelectionWithCursor[]);
    }

    /** Represents a StorySelectionList. */
    class StorySelectionList implements IStorySelectionList {

        /**
         * Constructs a new StorySelectionList.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionList);

        /** StorySelectionList storySelectionList. */
        public storySelectionList: story_selection.IStorySelectionWithCursor[];

        /**
         * Gets the default type url for StorySelectionList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionWithCursor. */
    export interface IStorySelectionWithCursor {

        /** StorySelectionWithCursor storySelection */
        storySelection?: (story_selection.IStorySelection);

        /** StorySelectionWithCursor cursor */
        cursor?: (string);
    }

    /** Represents a StorySelectionWithCursor. */
    class StorySelectionWithCursor implements IStorySelectionWithCursor {

        /**
         * Constructs a new StorySelectionWithCursor.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionWithCursor);

        /** StorySelectionWithCursor storySelection. */
        public storySelection?: (story_selection.IStorySelection);

        /** StorySelectionWithCursor cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for StorySelectionWithCursor
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelection. */
    export interface IStorySelection {

        /** StorySelection id */
        id?: (string);

        /** StorySelection title */
        title?: (string);

        /** StorySelection description */
        description?: (string);

        /** StorySelection thumbnail */
        thumbnail?: (resource.IImage);

        /** StorySelection headerList */
        headerList?: (story.ISeriesStoryHeader[]);

        /** StorySelection createdAt */
        createdAt?: (string);

        /** StorySelection updatedAt */
        updatedAt?: (string);
    }

    /** Represents a StorySelection. */
    class StorySelection implements IStorySelection {

        /**
         * Constructs a new StorySelection.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelection);

        /** StorySelection id. */
        public id?: (string);

        /** StorySelection title. */
        public title?: (string);

        /** StorySelection description. */
        public description?: (string);

        /** StorySelection thumbnail. */
        public thumbnail?: (resource.IImage);

        /** StorySelection headerList. */
        public headerList: story.ISeriesStoryHeader[];

        /** StorySelection createdAt. */
        public createdAt?: (string);

        /** StorySelection updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for StorySelection
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionWithMatchScore. */
    export interface IStorySelectionWithMatchScore {

        /** StorySelectionWithMatchScore id */
        id?: (string);

        /** StorySelectionWithMatchScore title */
        title?: (string);

        /** StorySelectionWithMatchScore description */
        description?: (string);

        /** StorySelectionWithMatchScore thumbnail */
        thumbnail?: (resource.IImage);

        /** StorySelectionWithMatchScore headerList */
        headerList?: (story.ISeriesStoryHeaderWithMatchScore[]);

        /** StorySelectionWithMatchScore createdAt */
        createdAt?: (string);

        /** StorySelectionWithMatchScore updatedAt */
        updatedAt?: (string);
    }

    /** Represents a StorySelectionWithMatchScore. */
    class StorySelectionWithMatchScore implements IStorySelectionWithMatchScore {

        /**
         * Constructs a new StorySelectionWithMatchScore.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionWithMatchScore);

        /** StorySelectionWithMatchScore id. */
        public id?: (string);

        /** StorySelectionWithMatchScore title. */
        public title?: (string);

        /** StorySelectionWithMatchScore description. */
        public description?: (string);

        /** StorySelectionWithMatchScore thumbnail. */
        public thumbnail?: (resource.IImage);

        /** StorySelectionWithMatchScore headerList. */
        public headerList: story.ISeriesStoryHeaderWithMatchScore[];

        /** StorySelectionWithMatchScore createdAt. */
        public createdAt?: (string);

        /** StorySelectionWithMatchScore updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for StorySelectionWithMatchScore
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionGetResponse. */
    export interface IStorySelectionGetResponse {

        /** StorySelectionGetResponse storySelection */
        storySelection?: (story_selection.IStorySelectionV1);

        /** StorySelectionGetResponse cursor */
        cursor?: (string);
    }

    /** Represents a StorySelectionGetResponse. */
    class StorySelectionGetResponse implements IStorySelectionGetResponse {

        /**
         * Constructs a new StorySelectionGetResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionGetResponse);

        /** StorySelectionGetResponse storySelection. */
        public storySelection?: (story_selection.IStorySelectionV1);

        /** StorySelectionGetResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for StorySelectionGetResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionV1. */
    export interface IStorySelectionV1 {

        /** StorySelectionV1 id */
        id?: (string);

        /** StorySelectionV1 title */
        title?: (string);

        /** StorySelectionV1 description */
        description?: (string);

        /** StorySelectionV1 thumbnailUrl */
        thumbnailUrl?: (string);

        /** StorySelectionV1 headerList */
        headerList?: (story.ISeriesStoryHeaderV1[]);

        /** StorySelectionV1 createdAt */
        createdAt?: (string);

        /** StorySelectionV1 updatedAt */
        updatedAt?: (string);
    }

    /** Represents a StorySelectionV1. */
    class StorySelectionV1 implements IStorySelectionV1 {

        /**
         * Constructs a new StorySelectionV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_selection.IStorySelectionV1);

        /** StorySelectionV1 id. */
        public id?: (string);

        /** StorySelectionV1 title. */
        public title?: (string);

        /** StorySelectionV1 description. */
        public description?: (string);

        /** StorySelectionV1 thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** StorySelectionV1 headerList. */
        public headerList: story.ISeriesStoryHeaderV1[];

        /** StorySelectionV1 createdAt. */
        public createdAt?: (string);

        /** StorySelectionV1 updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for StorySelectionV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace like. */
export namespace like {

    /** Properties of a Like. */
    export interface ILike {

        /** Like storyId */
        storyId?: (string);

        /** Like count */
        count?: (number);
    }

    /** Represents a Like. */
    class Like implements ILike {

        /**
         * Constructs a new Like.
         * @param [properties] Properties to set
         */
        constructor(properties?: like.ILike);

        /** Like storyId. */
        public storyId?: (string);

        /** Like count. */
        public count?: (number);

        /**
         * Gets the default type url for Like
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a LikeGetRequest. */
    export interface ILikeGetRequest {

        /** LikeGetRequest storyId */
        storyId?: (string);
    }

    /** Represents a LikeGetRequest. */
    class LikeGetRequest implements ILikeGetRequest {

        /**
         * Constructs a new LikeGetRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: like.ILikeGetRequest);

        /** LikeGetRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for LikeGetRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a LikeGetResponse. */
    export interface ILikeGetResponse {

        /** LikeGetResponse like */
        like?: (like.ILike);
    }

    /** Represents a LikeGetResponse. */
    class LikeGetResponse implements ILikeGetResponse {

        /**
         * Constructs a new LikeGetResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: like.ILikeGetResponse);

        /** LikeGetResponse like. */
        public like?: (like.ILike);

        /**
         * Gets the default type url for LikeGetResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a LikeGetMultiRequest. */
    export interface ILikeGetMultiRequest {

        /** LikeGetMultiRequest storyIdList */
        storyIdList?: (string[]);
    }

    /** Represents a LikeGetMultiRequest. */
    class LikeGetMultiRequest implements ILikeGetMultiRequest {

        /**
         * Constructs a new LikeGetMultiRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: like.ILikeGetMultiRequest);

        /** LikeGetMultiRequest storyIdList. */
        public storyIdList: string[];

        /**
         * Gets the default type url for LikeGetMultiRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a LikeGetMultiResponse. */
    export interface ILikeGetMultiResponse {

        /** LikeGetMultiResponse likeList */
        likeList?: (like.ILike[]);
    }

    /** Represents a LikeGetMultiResponse. */
    class LikeGetMultiResponse implements ILikeGetMultiResponse {

        /**
         * Constructs a new LikeGetMultiResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: like.ILikeGetMultiResponse);

        /** LikeGetMultiResponse likeList. */
        public likeList: like.ILike[];

        /**
         * Gets the default type url for LikeGetMultiResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a LikeRequest. */
    export interface ILikeRequest {

        /** LikeRequest storyId */
        storyId?: (string);

        /** LikeRequest count */
        count?: (number);
    }

    /** Represents a LikeRequest. */
    class LikeRequest implements ILikeRequest {

        /**
         * Constructs a new LikeRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: like.ILikeRequest);

        /** LikeRequest storyId. */
        public storyId?: (string);

        /** LikeRequest count. */
        public count?: (number);

        /**
         * Gets the default type url for LikeRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a LikeResponse. */
    export interface ILikeResponse {
    }

    /** Represents a LikeResponse. */
    class LikeResponse implements ILikeResponse {

        /**
         * Constructs a new LikeResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: like.ILikeResponse);

        /**
         * Gets the default type url for LikeResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace me. */
export namespace me {

    /** Properties of a MeGetResponse. */
    export interface IMeGetResponse {

        /** MeGetResponse user */
        user?: (user.IUserV1);
    }

    /** Represents a MeGetResponse. */
    class MeGetResponse implements IMeGetResponse {

        /**
         * Constructs a new MeGetResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetResponse);

        /** MeGetResponse user. */
        public user?: (user.IUserV1);

        /**
         * Gets the default type url for MeGetResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUpdateRequest. */
    export interface IMeUpdateRequest {

        /** MeUpdateRequest user */
        user?: (user.IUserV1);
    }

    /** Represents a MeUpdateRequest. */
    class MeUpdateRequest implements IMeUpdateRequest {

        /**
         * Constructs a new MeUpdateRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUpdateRequest);

        /** MeUpdateRequest user. */
        public user?: (user.IUserV1);

        /**
         * Gets the default type url for MeUpdateRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUpdateResponse. */
    export interface IMeUpdateResponse {

        /** MeUpdateResponse user */
        user?: (user.IUserV1);
    }

    /** Represents a MeUpdateResponse. */
    class MeUpdateResponse implements IMeUpdateResponse {

        /**
         * Constructs a new MeUpdateResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUpdateResponse);

        /** MeUpdateResponse user. */
        public user?: (user.IUserV1);

        /**
         * Gets the default type url for MeUpdateResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUpdateNotificationTokenRequestV2. */
    export interface IMeUpdateNotificationTokenRequestV2 {

        /** MeUpdateNotificationTokenRequestV2 firebaseInstanceId */
        firebaseInstanceId?: (string);

        /** MeUpdateNotificationTokenRequestV2 os */
        os?: (types.OS);
    }

    /** Represents a MeUpdateNotificationTokenRequestV2. */
    class MeUpdateNotificationTokenRequestV2 implements IMeUpdateNotificationTokenRequestV2 {

        /**
         * Constructs a new MeUpdateNotificationTokenRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUpdateNotificationTokenRequestV2);

        /** MeUpdateNotificationTokenRequestV2 firebaseInstanceId. */
        public firebaseInstanceId?: (string);

        /** MeUpdateNotificationTokenRequestV2 os. */
        public os: types.OS;

        /**
         * Gets the default type url for MeUpdateNotificationTokenRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeMigrationToken. */
    export interface IMeMigrationToken {

        /** MeMigrationToken userId */
        userId?: (string);

        /** MeMigrationToken token */
        token?: (string);

        /** MeMigrationToken expiresAt */
        expiresAt?: (string);
    }

    /** Represents a MeMigrationToken. */
    class MeMigrationToken implements IMeMigrationToken {

        /**
         * Constructs a new MeMigrationToken.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeMigrationToken);

        /** MeMigrationToken userId. */
        public userId?: (string);

        /** MeMigrationToken token. */
        public token?: (string);

        /** MeMigrationToken expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for MeMigrationToken
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeMigrateRequest. */
    export interface IMeMigrateRequest {

        /** MeMigrateRequest token */
        token?: (string);
    }

    /** Represents a MeMigrateRequest. */
    class MeMigrateRequest implements IMeMigrateRequest {

        /**
         * Constructs a new MeMigrateRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeMigrateRequest);

        /** MeMigrateRequest token. */
        public token?: (string);

        /**
         * Gets the default type url for MeMigrateRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetReadingProgressRequest. */
    export interface IMeGetReadingProgressRequest {

        /** MeGetReadingProgressRequest storyId */
        storyId?: (string);
    }

    /** Represents a MeGetReadingProgressRequest. */
    class MeGetReadingProgressRequest implements IMeGetReadingProgressRequest {

        /**
         * Constructs a new MeGetReadingProgressRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetReadingProgressRequest);

        /** MeGetReadingProgressRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for MeGetReadingProgressRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetReadingProgressResponse. */
    export interface IMeGetReadingProgressResponse {

        /** MeGetReadingProgressResponse readingProgress */
        readingProgress?: (reading_progress.IReadingProgress);
    }

    /** Represents a MeGetReadingProgressResponse. */
    class MeGetReadingProgressResponse implements IMeGetReadingProgressResponse {

        /**
         * Constructs a new MeGetReadingProgressResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetReadingProgressResponse);

        /** MeGetReadingProgressResponse readingProgress. */
        public readingProgress?: (reading_progress.IReadingProgress);

        /**
         * Gets the default type url for MeGetReadingProgressResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetMultiReadingProgressRequest. */
    export interface IMeGetMultiReadingProgressRequest {

        /** MeGetMultiReadingProgressRequest storyIdList */
        storyIdList?: (string[]);
    }

    /** Represents a MeGetMultiReadingProgressRequest. */
    class MeGetMultiReadingProgressRequest implements IMeGetMultiReadingProgressRequest {

        /**
         * Constructs a new MeGetMultiReadingProgressRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetMultiReadingProgressRequest);

        /** MeGetMultiReadingProgressRequest storyIdList. */
        public storyIdList: string[];

        /**
         * Gets the default type url for MeGetMultiReadingProgressRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetMultiReadingProgressResponse. */
    export interface IMeGetMultiReadingProgressResponse {

        /** MeGetMultiReadingProgressResponse readingProgressList */
        readingProgressList?: (reading_progress.IReadingProgress[]);
    }

    /** Represents a MeGetMultiReadingProgressResponse. */
    class MeGetMultiReadingProgressResponse implements IMeGetMultiReadingProgressResponse {

        /**
         * Constructs a new MeGetMultiReadingProgressResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetMultiReadingProgressResponse);

        /** MeGetMultiReadingProgressResponse readingProgressList. */
        public readingProgressList: reading_progress.IReadingProgress[];

        /**
         * Gets the default type url for MeGetMultiReadingProgressResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUpdateReadingProgressRequest. */
    export interface IMeUpdateReadingProgressRequest {

        /** MeUpdateReadingProgressRequest storyId */
        storyId?: (string);

        /** MeUpdateReadingProgressRequest index */
        index?: (number);
    }

    /** Represents a MeUpdateReadingProgressRequest. */
    class MeUpdateReadingProgressRequest implements IMeUpdateReadingProgressRequest {

        /**
         * Constructs a new MeUpdateReadingProgressRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUpdateReadingProgressRequest);

        /** MeUpdateReadingProgressRequest storyId. */
        public storyId?: (string);

        /** MeUpdateReadingProgressRequest index. */
        public index?: (number);

        /**
         * Gets the default type url for MeUpdateReadingProgressRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUpdateReadingProgressResponse. */
    export interface IMeUpdateReadingProgressResponse {

        /** MeUpdateReadingProgressResponse readingProgress */
        readingProgress?: (reading_progress.IReadingProgress);
    }

    /** Represents a MeUpdateReadingProgressResponse. */
    class MeUpdateReadingProgressResponse implements IMeUpdateReadingProgressResponse {

        /**
         * Constructs a new MeUpdateReadingProgressResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUpdateReadingProgressResponse);

        /** MeUpdateReadingProgressResponse readingProgress. */
        public readingProgress?: (reading_progress.IReadingProgress);

        /**
         * Gets the default type url for MeUpdateReadingProgressResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListReadingProgressRequest. */
    export interface IMeListReadingProgressRequest {

        /** MeListReadingProgressRequest cursor */
        cursor?: (string);

        /** MeListReadingProgressRequest limit */
        limit?: (number);
    }

    /** Represents a MeListReadingProgressRequest. */
    class MeListReadingProgressRequest implements IMeListReadingProgressRequest {

        /**
         * Constructs a new MeListReadingProgressRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListReadingProgressRequest);

        /** MeListReadingProgressRequest cursor. */
        public cursor?: (string);

        /** MeListReadingProgressRequest limit. */
        public limit?: (number);

        /**
         * Gets the default type url for MeListReadingProgressRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListReadingProgressResponse. */
    export interface IMeListReadingProgressResponse {

        /** MeListReadingProgressResponse readingProgressList */
        readingProgressList?: (reading_progress.IReadingProgress[]);

        /** MeListReadingProgressResponse cursor */
        cursor?: (string);
    }

    /** Represents a MeListReadingProgressResponse. */
    class MeListReadingProgressResponse implements IMeListReadingProgressResponse {

        /**
         * Constructs a new MeListReadingProgressResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListReadingProgressResponse);

        /** MeListReadingProgressResponse readingProgressList. */
        public readingProgressList: reading_progress.IReadingProgress[];

        /** MeListReadingProgressResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for MeListReadingProgressResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeBlockRequest. */
    export interface IMeBlockRequest {

        /** MeBlockRequest userId */
        userId?: (string);
    }

    /** Represents a MeBlockRequest. */
    class MeBlockRequest implements IMeBlockRequest {

        /**
         * Constructs a new MeBlockRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeBlockRequest);

        /** MeBlockRequest userId. */
        public userId?: (string);

        /**
         * Gets the default type url for MeBlockRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUnblockRequest. */
    export interface IMeUnblockRequest {

        /** MeUnblockRequest userId */
        userId?: (string);
    }

    /** Represents a MeUnblockRequest. */
    class MeUnblockRequest implements IMeUnblockRequest {

        /**
         * Constructs a new MeUnblockRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUnblockRequest);

        /** MeUnblockRequest userId. */
        public userId?: (string);

        /**
         * Gets the default type url for MeUnblockRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListBlockingRequest. */
    export interface IMeListBlockingRequest {

        /** MeListBlockingRequest cursor */
        cursor?: (string);
    }

    /** Represents a MeListBlockingRequest. */
    class MeListBlockingRequest implements IMeListBlockingRequest {

        /**
         * Constructs a new MeListBlockingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListBlockingRequest);

        /** MeListBlockingRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for MeListBlockingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListBlockingResponse. */
    export interface IMeListBlockingResponse {

        /** MeListBlockingResponse userList */
        userList?: (user.IUserV1[]);

        /** MeListBlockingResponse cursor */
        cursor?: (string);
    }

    /** Represents a MeListBlockingResponse. */
    class MeListBlockingResponse implements IMeListBlockingResponse {

        /**
         * Constructs a new MeListBlockingResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListBlockingResponse);

        /** MeListBlockingResponse userList. */
        public userList: user.IUserV1[];

        /** MeListBlockingResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for MeListBlockingResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeIsBlockedByRequest. */
    export interface IMeIsBlockedByRequest {

        /** MeIsBlockedByRequest userId */
        userId?: (string);
    }

    /** Represents a MeIsBlockedByRequest. */
    class MeIsBlockedByRequest implements IMeIsBlockedByRequest {

        /**
         * Constructs a new MeIsBlockedByRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeIsBlockedByRequest);

        /** MeIsBlockedByRequest userId. */
        public userId?: (string);

        /**
         * Gets the default type url for MeIsBlockedByRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeIsBlockedByResponse. */
    export interface IMeIsBlockedByResponse {

        /** MeIsBlockedByResponse isBlocked */
        isBlocked?: (boolean);
    }

    /** Represents a MeIsBlockedByResponse. */
    class MeIsBlockedByResponse implements IMeIsBlockedByResponse {

        /**
         * Constructs a new MeIsBlockedByResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeIsBlockedByResponse);

        /** MeIsBlockedByResponse isBlocked. */
        public isBlocked?: (boolean);

        /**
         * Gets the default type url for MeIsBlockedByResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetNotificationCountSinceRequestV2. */
    export interface IMeGetNotificationCountSinceRequestV2 {

        /** MeGetNotificationCountSinceRequestV2 since */
        since?: (string);

        /** MeGetNotificationCountSinceRequestV2 os */
        os?: (types.OS);

        /** MeGetNotificationCountSinceRequestV2 category */
        category?: (notification.NotificationCategory);

        /** MeGetNotificationCountSinceRequestV2 categoryList */
        categoryList?: (notification.NotificationCategory[]);
    }

    /** Represents a MeGetNotificationCountSinceRequestV2. */
    class MeGetNotificationCountSinceRequestV2 implements IMeGetNotificationCountSinceRequestV2 {

        /**
         * Constructs a new MeGetNotificationCountSinceRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetNotificationCountSinceRequestV2);

        /** MeGetNotificationCountSinceRequestV2 since. */
        public since?: (string);

        /** MeGetNotificationCountSinceRequestV2 os. */
        public os: types.OS;

        /** MeGetNotificationCountSinceRequestV2 category. */
        public category: notification.NotificationCategory;

        /** MeGetNotificationCountSinceRequestV2 categoryList. */
        public categoryList: notification.NotificationCategory[];

        /**
         * Gets the default type url for MeGetNotificationCountSinceRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetNotificationCountSinceResponse. */
    export interface IMeGetNotificationCountSinceResponse {

        /** MeGetNotificationCountSinceResponse count */
        count?: (number);
    }

    /** Represents a MeGetNotificationCountSinceResponse. */
    class MeGetNotificationCountSinceResponse implements IMeGetNotificationCountSinceResponse {

        /**
         * Constructs a new MeGetNotificationCountSinceResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetNotificationCountSinceResponse);

        /** MeGetNotificationCountSinceResponse count. */
        public count?: (number);

        /**
         * Gets the default type url for MeGetNotificationCountSinceResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListNotificationRequestV2. */
    export interface IMeListNotificationRequestV2 {

        /** MeListNotificationRequestV2 cursor */
        cursor?: (string);

        /** MeListNotificationRequestV2 os */
        os?: (types.OS);

        /** MeListNotificationRequestV2 category */
        category?: (notification.NotificationCategory);

        /** MeListNotificationRequestV2 categoryList */
        categoryList?: (notification.NotificationCategory[]);
    }

    /** Represents a MeListNotificationRequestV2. */
    class MeListNotificationRequestV2 implements IMeListNotificationRequestV2 {

        /**
         * Constructs a new MeListNotificationRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListNotificationRequestV2);

        /** MeListNotificationRequestV2 cursor. */
        public cursor?: (string);

        /** MeListNotificationRequestV2 os. */
        public os: types.OS;

        /** MeListNotificationRequestV2 category. */
        public category: notification.NotificationCategory;

        /** MeListNotificationRequestV2 categoryList. */
        public categoryList: notification.NotificationCategory[];

        /**
         * Gets the default type url for MeListNotificationRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListNotificationResponseV2. */
    export interface IMeListNotificationResponseV2 {

        /** MeListNotificationResponseV2 notificationList */
        notificationList?: (notification.INotificationV2[]);

        /** MeListNotificationResponseV2 cursor */
        cursor?: (string);
    }

    /** Represents a MeListNotificationResponseV2. */
    class MeListNotificationResponseV2 implements IMeListNotificationResponseV2 {

        /**
         * Constructs a new MeListNotificationResponseV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListNotificationResponseV2);

        /** MeListNotificationResponseV2 notificationList. */
        public notificationList: notification.INotificationV2[];

        /** MeListNotificationResponseV2 cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for MeListNotificationResponseV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUpdateNotificationTokenRequest. */
    export interface IMeUpdateNotificationTokenRequest {

        /** MeUpdateNotificationTokenRequest fcmToken */
        fcmToken?: (string);

        /** MeUpdateNotificationTokenRequest apnsToken */
        apnsToken?: (string);
    }

    /** Represents a MeUpdateNotificationTokenRequest. */
    class MeUpdateNotificationTokenRequest implements IMeUpdateNotificationTokenRequest {

        /**
         * Constructs a new MeUpdateNotificationTokenRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUpdateNotificationTokenRequest);

        /** MeUpdateNotificationTokenRequest fcmToken. */
        public fcmToken?: (string);

        /** MeUpdateNotificationTokenRequest apnsToken. */
        public apnsToken?: (string);

        /**
         * Gets the default type url for MeUpdateNotificationTokenRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a Notification. */
    export interface INotification {

        /** Notification title */
        title?: (string);

        /** Notification message */
        message?: (string);

        /** Notification actionUrl */
        actionUrl?: (string);

        /** Notification createdAt */
        createdAt?: (string);
    }

    /** Represents a Notification. */
    class Notification implements INotification {

        /**
         * Constructs a new Notification.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.INotification);

        /** Notification title. */
        public title?: (string);

        /** Notification message. */
        public message?: (string);

        /** Notification actionUrl. */
        public actionUrl?: (string);

        /** Notification createdAt. */
        public createdAt?: (string);

        /**
         * Gets the default type url for Notification
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListImageRequest. */
    export interface IMeListImageRequest {

        /** MeListImageRequest category */
        category?: (resource.ImageCategory);

        /** MeListImageRequest cursor */
        cursor?: (string);

        /** MeListImageRequest limit */
        limit?: (number);
    }

    /** Represents a MeListImageRequest. */
    class MeListImageRequest implements IMeListImageRequest {

        /**
         * Constructs a new MeListImageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListImageRequest);

        /** MeListImageRequest category. */
        public category: resource.ImageCategory;

        /** MeListImageRequest cursor. */
        public cursor?: (string);

        /** MeListImageRequest limit. */
        public limit?: (number);

        /**
         * Gets the default type url for MeListImageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListImageResponse. */
    export interface IMeListImageResponse {

        /** MeListImageResponse imageList */
        imageList?: (resource.IImage[]);

        /** MeListImageResponse cursor */
        cursor?: (string);
    }

    /** Represents a MeListImageResponse. */
    class MeListImageResponse implements IMeListImageResponse {

        /**
         * Constructs a new MeListImageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListImageResponse);

        /** MeListImageResponse imageList. */
        public imageList: resource.IImage[];

        /** MeListImageResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for MeListImageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListAudioRequest. */
    export interface IMeListAudioRequest {

        /** MeListAudioRequest cursor */
        cursor?: (string);

        /** MeListAudioRequest limit */
        limit?: (number);
    }

    /** Represents a MeListAudioRequest. */
    class MeListAudioRequest implements IMeListAudioRequest {

        /**
         * Constructs a new MeListAudioRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListAudioRequest);

        /** MeListAudioRequest cursor. */
        public cursor?: (string);

        /** MeListAudioRequest limit. */
        public limit?: (number);

        /**
         * Gets the default type url for MeListAudioRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeListAudioResponse. */
    export interface IMeListAudioResponse {

        /** MeListAudioResponse audioList */
        audioList?: (resource.IAudio[]);

        /** MeListAudioResponse cursor */
        cursor?: (string);
    }

    /** Represents a MeListAudioResponse. */
    class MeListAudioResponse implements IMeListAudioResponse {

        /**
         * Constructs a new MeListAudioResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeListAudioResponse);

        /** MeListAudioResponse audioList. */
        public audioList: resource.IAudio[];

        /** MeListAudioResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for MeListAudioResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUsePawRequest. */
    export interface IMeUsePawRequest {

        /** MeUsePawRequest amount */
        amount?: (number);
    }

    /** Represents a MeUsePawRequest. */
    class MeUsePawRequest implements IMeUsePawRequest {

        /**
         * Constructs a new MeUsePawRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUsePawRequest);

        /** MeUsePawRequest amount. */
        public amount?: (number);

        /**
         * Gets the default type url for MeUsePawRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetNotificationCountSinceRequest. */
    export interface IMeGetNotificationCountSinceRequest {

        /** MeGetNotificationCountSinceRequest since */
        since?: (string);
    }

    /** Represents a MeGetNotificationCountSinceRequest. */
    class MeGetNotificationCountSinceRequest implements IMeGetNotificationCountSinceRequest {

        /**
         * Constructs a new MeGetNotificationCountSinceRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetNotificationCountSinceRequest);

        /** MeGetNotificationCountSinceRequest since. */
        public since?: (string);

        /**
         * Gets the default type url for MeGetNotificationCountSinceRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a GetHalfReadingStorySelectionRequest. */
    export interface IGetHalfReadingStorySelectionRequest {

        /** GetHalfReadingStorySelectionRequest limit */
        limit?: (number);

        /** GetHalfReadingStorySelectionRequest cursor */
        cursor?: (string);
    }

    /** Represents a GetHalfReadingStorySelectionRequest. */
    class GetHalfReadingStorySelectionRequest implements IGetHalfReadingStorySelectionRequest {

        /**
         * Constructs a new GetHalfReadingStorySelectionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IGetHalfReadingStorySelectionRequest);

        /** GetHalfReadingStorySelectionRequest limit. */
        public limit?: (number);

        /** GetHalfReadingStorySelectionRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for GetHalfReadingStorySelectionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AuthCustomToken. */
    export interface IAuthCustomToken {

        /** AuthCustomToken token */
        token?: (string);

        /** AuthCustomToken expiresAt */
        expiresAt?: (string);
    }

    /** Represents an AuthCustomToken. */
    class AuthCustomToken implements IAuthCustomToken {

        /**
         * Constructs a new AuthCustomToken.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IAuthCustomToken);

        /** AuthCustomToken token. */
        public token?: (string);

        /** AuthCustomToken expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for AuthCustomToken
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeUpdateCommentRestrictionPreferenceRequest. */
    export interface IMeUpdateCommentRestrictionPreferenceRequest {

        /** MeUpdateCommentRestrictionPreferenceRequest commentRestrictionType */
        commentRestrictionType?: (types.CommentRestrictionType);
    }

    /** Represents a MeUpdateCommentRestrictionPreferenceRequest. */
    class MeUpdateCommentRestrictionPreferenceRequest implements IMeUpdateCommentRestrictionPreferenceRequest {

        /**
         * Constructs a new MeUpdateCommentRestrictionPreferenceRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeUpdateCommentRestrictionPreferenceRequest);

        /** MeUpdateCommentRestrictionPreferenceRequest commentRestrictionType. */
        public commentRestrictionType: types.CommentRestrictionType;

        /**
         * Gets the default type url for MeUpdateCommentRestrictionPreferenceRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetCommentRestrictionPreferenceResponse. */
    export interface IMeGetCommentRestrictionPreferenceResponse {

        /** MeGetCommentRestrictionPreferenceResponse commentRestrictionType */
        commentRestrictionType?: (types.CommentRestrictionType);

        /** MeGetCommentRestrictionPreferenceResponse updatedAt */
        updatedAt?: (string);
    }

    /** Represents a MeGetCommentRestrictionPreferenceResponse. */
    class MeGetCommentRestrictionPreferenceResponse implements IMeGetCommentRestrictionPreferenceResponse {

        /**
         * Constructs a new MeGetCommentRestrictionPreferenceResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetCommentRestrictionPreferenceResponse);

        /** MeGetCommentRestrictionPreferenceResponse commentRestrictionType. */
        public commentRestrictionType: types.CommentRestrictionType;

        /** MeGetCommentRestrictionPreferenceResponse updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for MeGetCommentRestrictionPreferenceResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a FollowingStatus. */
    export interface IFollowingStatus {

        /** FollowingStatus userId */
        userId?: (string);

        /** FollowingStatus isFollowing */
        isFollowing?: (boolean);
    }

    /** Represents a FollowingStatus. */
    class FollowingStatus implements IFollowingStatus {

        /**
         * Constructs a new FollowingStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IFollowingStatus);

        /** FollowingStatus userId. */
        public userId?: (string);

        /** FollowingStatus isFollowing. */
        public isFollowing?: (boolean);

        /**
         * Gets the default type url for FollowingStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeIsFollowingRequest. */
    export interface IMeIsFollowingRequest {

        /** MeIsFollowingRequest userId */
        userId?: (string);
    }

    /** Represents a MeIsFollowingRequest. */
    class MeIsFollowingRequest implements IMeIsFollowingRequest {

        /**
         * Constructs a new MeIsFollowingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeIsFollowingRequest);

        /** MeIsFollowingRequest userId. */
        public userId?: (string);

        /**
         * Gets the default type url for MeIsFollowingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeIsFollowingMultiRequest. */
    export interface IMeIsFollowingMultiRequest {

        /** MeIsFollowingMultiRequest userIdList */
        userIdList?: (string[]);
    }

    /** Represents a MeIsFollowingMultiRequest. */
    class MeIsFollowingMultiRequest implements IMeIsFollowingMultiRequest {

        /**
         * Constructs a new MeIsFollowingMultiRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeIsFollowingMultiRequest);

        /** MeIsFollowingMultiRequest userIdList. */
        public userIdList: string[];

        /**
         * Gets the default type url for MeIsFollowingMultiRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeIsFollowingMultiResponse. */
    export interface IMeIsFollowingMultiResponse {

        /** MeIsFollowingMultiResponse followingStatusList */
        followingStatusList?: (me.IFollowingStatus[]);
    }

    /** Represents a MeIsFollowingMultiResponse. */
    class MeIsFollowingMultiResponse implements IMeIsFollowingMultiResponse {

        /**
         * Constructs a new MeIsFollowingMultiResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeIsFollowingMultiResponse);

        /** MeIsFollowingMultiResponse followingStatusList. */
        public followingStatusList: me.IFollowingStatus[];

        /**
         * Gets the default type url for MeIsFollowingMultiResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BlockingStatus. */
    export interface IBlockingStatus {

        /** BlockingStatus userId */
        userId?: (string);

        /** BlockingStatus isBlocking */
        isBlocking?: (boolean);
    }

    /** Represents a BlockingStatus. */
    class BlockingStatus implements IBlockingStatus {

        /**
         * Constructs a new BlockingStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IBlockingStatus);

        /** BlockingStatus userId. */
        public userId?: (string);

        /** BlockingStatus isBlocking. */
        public isBlocking?: (boolean);

        /**
         * Gets the default type url for BlockingStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeIsBlockingRequest. */
    export interface IMeIsBlockingRequest {

        /** MeIsBlockingRequest userId */
        userId?: (string);
    }

    /** Represents a MeIsBlockingRequest. */
    class MeIsBlockingRequest implements IMeIsBlockingRequest {

        /**
         * Constructs a new MeIsBlockingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeIsBlockingRequest);

        /** MeIsBlockingRequest userId. */
        public userId?: (string);

        /**
         * Gets the default type url for MeIsBlockingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeIsBlockingMultiRequest. */
    export interface IMeIsBlockingMultiRequest {

        /** MeIsBlockingMultiRequest userIdList */
        userIdList?: (string[]);
    }

    /** Represents a MeIsBlockingMultiRequest. */
    class MeIsBlockingMultiRequest implements IMeIsBlockingMultiRequest {

        /**
         * Constructs a new MeIsBlockingMultiRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeIsBlockingMultiRequest);

        /** MeIsBlockingMultiRequest userIdList. */
        public userIdList: string[];

        /**
         * Gets the default type url for MeIsBlockingMultiRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeIsBlockingMultiResponse. */
    export interface IMeIsBlockingMultiResponse {

        /** MeIsBlockingMultiResponse blockingStatusList */
        blockingStatusList?: (me.IBlockingStatus[]);
    }

    /** Represents a MeIsBlockingMultiResponse. */
    class MeIsBlockingMultiResponse implements IMeIsBlockingMultiResponse {

        /**
         * Constructs a new MeIsBlockingMultiResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeIsBlockingMultiResponse);

        /** MeIsBlockingMultiResponse blockingStatusList. */
        public blockingStatusList: me.IBlockingStatus[];

        /**
         * Gets the default type url for MeIsBlockingMultiResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeGetNextStoryByRecentReadingProgressResponse. */
    export interface IMeGetNextStoryByRecentReadingProgressResponse {

        /** MeGetNextStoryByRecentReadingProgressResponse storyIdWithMetadata */
        storyIdWithMetadata?: (story.IStoryIDWithMetadata);

        /** MeGetNextStoryByRecentReadingProgressResponse type */
        type?: (me.MeGetNextStoryByRecentReadingProgressResponse.RecentReadingProgressType);
    }

    /** Represents a MeGetNextStoryByRecentReadingProgressResponse. */
    class MeGetNextStoryByRecentReadingProgressResponse implements IMeGetNextStoryByRecentReadingProgressResponse {

        /**
         * Constructs a new MeGetNextStoryByRecentReadingProgressResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: me.IMeGetNextStoryByRecentReadingProgressResponse);

        /** MeGetNextStoryByRecentReadingProgressResponse storyIdWithMetadata. */
        public storyIdWithMetadata?: (story.IStoryIDWithMetadata);

        /** MeGetNextStoryByRecentReadingProgressResponse type. */
        public type: me.MeGetNextStoryByRecentReadingProgressResponse.RecentReadingProgressType;

        /**
         * Gets the default type url for MeGetNextStoryByRecentReadingProgressResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace MeGetNextStoryByRecentReadingProgressResponse {

        /** RecentReadingProgressType enum. */
        export enum RecentReadingProgressType {
            UNSPECIFIED = "UNSPECIFIED",
            HALF_READING_STORY = "HALF_READING_STORY",
            NEXT_SERIES_STORY = "NEXT_SERIES_STORY"
        }
    }
}

/** Namespace permission. */
export namespace permission {

    /** Properties of a Permission. */
    export interface IPermission {

        /** Permission canReadPublicUserContent */
        canReadPublicUserContent?: (boolean);

        /** Permission canReadEveryUserContent */
        canReadEveryUserContent?: (boolean);

        /** Permission canWriteOwnContent */
        canWriteOwnContent?: (boolean);

        /** Permission canWriteEveryUserContent */
        canWriteEveryUserContent?: (boolean);

        /** Permission canWriteOfficialContent */
        canWriteOfficialContent?: (boolean);

        /** Permission canPublishOwnContent */
        canPublishOwnContent?: (boolean);

        /** Permission listUploadableImageCategory */
        listUploadableImageCategory?: (resource.ImageCategory[]);

        /** Permission canImportSimpleFormatScript */
        canImportSimpleFormatScript?: (boolean);

        /** Permission canWriteSeriesGroup */
        canWriteSeriesGroup?: (boolean);
    }

    /** Represents a Permission. */
    class Permission implements IPermission {

        /**
         * Constructs a new Permission.
         * @param [properties] Properties to set
         */
        constructor(properties?: permission.IPermission);

        /** Permission canReadPublicUserContent. */
        public canReadPublicUserContent: boolean;

        /** Permission canReadEveryUserContent. */
        public canReadEveryUserContent: boolean;

        /** Permission canWriteOwnContent. */
        public canWriteOwnContent: boolean;

        /** Permission canWriteEveryUserContent. */
        public canWriteEveryUserContent: boolean;

        /** Permission canWriteOfficialContent. */
        public canWriteOfficialContent: boolean;

        /** Permission canPublishOwnContent. */
        public canPublishOwnContent: boolean;

        /** Permission listUploadableImageCategory. */
        public listUploadableImageCategory: resource.ImageCategory[];

        /** Permission canImportSimpleFormatScript. */
        public canImportSimpleFormatScript: boolean;

        /** Permission canWriteSeriesGroup. */
        public canWriteSeriesGroup: boolean;

        /**
         * Gets the default type url for Permission
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace notification. */
export namespace notification {

    /** Properties of a NotificationV2. */
    export interface INotificationV2 {

        /** NotificationV2 message */
        message?: (string);

        /** NotificationV2 content */
        content?: (string);

        /** NotificationV2 actionUrl */
        actionUrl?: (string);

        /** NotificationV2 imageUrl */
        imageUrl?: (string);

        /** NotificationV2 notificationType */
        notificationType?: (notification.NotificationType);

        /** NotificationV2 iconUrls */
        iconUrls?: (string[]);

        /** NotificationV2 createdAt */
        createdAt?: (string);

        /** NotificationV2 id */
        id?: (string);

        /** NotificationV2 title */
        title?: (string);

        /** NotificationV2 metadata */
        metadata?: (notification.INotificationMetadata);
    }

    /** Represents a NotificationV2. */
    class NotificationV2 implements INotificationV2 {

        /**
         * Constructs a new NotificationV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationV2);

        /** NotificationV2 message. */
        public message?: (string);

        /** NotificationV2 content. */
        public content?: (string);

        /** NotificationV2 actionUrl. */
        public actionUrl?: (string);

        /** NotificationV2 imageUrl. */
        public imageUrl?: (string);

        /** NotificationV2 notificationType. */
        public notificationType: notification.NotificationType;

        /** NotificationV2 iconUrls. */
        public iconUrls: string[];

        /** NotificationV2 createdAt. */
        public createdAt?: (string);

        /** NotificationV2 id. */
        public id?: (string);

        /** NotificationV2 title. */
        public title?: (string);

        /** NotificationV2 metadata. */
        public metadata?: (notification.INotificationMetadata);

        /**
         * Gets the default type url for NotificationV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationMetadata. */
    export interface INotificationMetadata {

        /** NotificationMetadata storyId */
        storyId?: (string);

        /** NotificationMetadata seriesId */
        seriesId?: (string);
    }

    /** Represents a NotificationMetadata. */
    class NotificationMetadata implements INotificationMetadata {

        /**
         * Constructs a new NotificationMetadata.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationMetadata);

        /** NotificationMetadata storyId. */
        public storyId?: (string);

        /** NotificationMetadata seriesId. */
        public seriesId?: (string);

        /** NotificationMetadata content. */
        public content?: ("storyId"|"seriesId");

        /**
         * Gets the default type url for NotificationMetadata
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationPreference. */
    export interface INotificationPreference {

        /** NotificationPreference refusedPushNotificationTypeList */
        refusedPushNotificationTypeList?: (notification.NotificationType[]);

        /** NotificationPreference acceptedPushNotificationTypeList */
        acceptedPushNotificationTypeList?: (notification.NotificationType[]);

        /** NotificationPreference updatedAt */
        updatedAt?: (string);
    }

    /** Represents a NotificationPreference. */
    class NotificationPreference implements INotificationPreference {

        /**
         * Constructs a new NotificationPreference.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationPreference);

        /** NotificationPreference refusedPushNotificationTypeList. */
        public refusedPushNotificationTypeList: notification.NotificationType[];

        /** NotificationPreference acceptedPushNotificationTypeList. */
        public acceptedPushNotificationTypeList: notification.NotificationType[];

        /** NotificationPreference updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for NotificationPreference
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an ImageWithLink. */
    export interface IImageWithLink {

        /** ImageWithLink imageUrlList */
        imageUrlList?: (string[]);

        /** ImageWithLink actionUrl */
        actionUrl?: (string);
    }

    /** Represents an ImageWithLink. */
    class ImageWithLink implements IImageWithLink {

        /**
         * Constructs a new ImageWithLink.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.IImageWithLink);

        /** ImageWithLink imageUrlList. */
        public imageUrlList: string[];

        /** ImageWithLink actionUrl. */
        public actionUrl?: (string);

        /**
         * Gets the default type url for ImageWithLink
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** NotificationType enum. */
    export enum NotificationType {
        NOTIFICATION_TYPE_UNSPECIFIED = "NOTIFICATION_TYPE_UNSPECIFIED",
        NOTIFICATION_TYPE_OPERATION = "NOTIFICATION_TYPE_OPERATION",
        NOTIFICATION_TYPE_STORY_REJECTED = "NOTIFICATION_TYPE_STORY_REJECTED",
        NOTIFICATION_TYPE_USER_LIKE_BREAK = "NOTIFICATION_TYPE_USER_LIKE_BREAK",
        NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK = "NOTIFICATION_TYPE_USER_STORY_LIKE_BREAK",
        NOTIFICATION_TYPE_USER_BANNED = "NOTIFICATION_TYPE_USER_BANNED",
        NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS = "NOTIFICATION_TYPE_USER_PROFILE_DAILY_UNIQUE_VIEWS",
        NOTIFICATION_TYPE_NEW_FOLLOWER = "NOTIFICATION_TYPE_NEW_FOLLOWER",
        NOTIFICATION_TYPE_NEW_STORY_COMMENT = "NOTIFICATION_TYPE_NEW_STORY_COMMENT",
        NOTIFICATION_TYPE_NEW_COMMENT_LIKE = "NOTIFICATION_TYPE_NEW_COMMENT_LIKE",
        NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE = "NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE",
        NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY = "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY",
        NOTIFICATION_TYPE_NEW_MEDAL = "NOTIFICATION_TYPE_NEW_MEDAL",
        NOTIFICATION_TYPE_PROMOTION_OFFER = "NOTIFICATION_TYPE_PROMOTION_OFFER",
        NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY = "NOTIFICATION_TYPE_BOOKMARK_NEW_SERIES_STORY",
        NOTIFICATION_TYPE_STORY_REMINDER = "NOTIFICATION_TYPE_STORY_REMINDER",
        NOTIFICATION_TYPE_PAW_INTRODUCTION = "NOTIFICATION_TYPE_PAW_INTRODUCTION",
        NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE = "NOTIFICATION_TYPE_SUBSCRIPTION_PAYMENT_FAILURE",
        NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED = "NOTIFICATION_TYPE_SERIES_CHARGE_RECOVERED",
        NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER = "NOTIFICATION_TYPE_SUBSCRIPTION_TRIAL_ENDING_REMINDER",
        NOTIFICATION_TYPE_NEW_SEASON = "NOTIFICATION_TYPE_NEW_SEASON",
        NOTIFICATION_TYPE_NEXT_SEASON_REMINDER = "NOTIFICATION_TYPE_NEXT_SEASON_REMINDER",
        NOTIFICATION_TYPE_STORY_VIDEO_CREATED = "NOTIFICATION_TYPE_STORY_VIDEO_CREATED",
        NOTIFICATION_TYPE_USER_STORY_NEW_LIKES = "NOTIFICATION_TYPE_USER_STORY_NEW_LIKES",
        NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST = "NOTIFICATION_TYPE_MUTUAL_FOLLOW_READ_REQUEST",
        NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES = "NOTIFICATION_TYPE_DAILY_RECOMMENDED_STORIES",
        NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS = "NOTIFICATION_TYPE_USER_STORY_HOURLY_UNIQUE_VIEWS",
        NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES = "NOTIFICATION_TYPE_USER_STORY_HOURLY_NEW_LIKES",
        NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS = "NOTIFICATION_TYPE_FOLLOWING_USER_HOURLY_NEW_FOLLOWS",
        NOTIFICATION_TYPE_STORY_IN_TAG_RANKING = "NOTIFICATION_TYPE_STORY_IN_TAG_RANKING",
        NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK = "NOTIFICATION_TYPE_USER_STORY_VIEW_BREAK",
        NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES = "NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY_LIKES",
        NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK = "NOTIFICATION_TYPE_USER_UNIQUE_VIEWER_BREAK",
        NOTIFICATION_TYPE_PAW_RECOVERED = "NOTIFICATION_TYPE_PAW_RECOVERED",
        NOTIFICATION_TYPE_NEW_BOOKMARK = "NOTIFICATION_TYPE_NEW_BOOKMARK",
        NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK = "NOTIFICATION_TYPE_FOLLOWING_USER_BOOKMARK",
        NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER = "NOTIFICATION_TYPE_NEW_SERIES_STORY_TO_PREVIOUS_STORY_FINISHED_USER"
    }

    /** NotificationCategory enum. */
    export enum NotificationCategory {
        NOTIFICATION_CATEGORY_UNSPECIFIED = "NOTIFICATION_CATEGORY_UNSPECIFIED",
        NOTIFICATION_CATEGORY_OPERATION = "NOTIFICATION_CATEGORY_OPERATION",
        NOTIFICATION_CATEGORY_USER_RELATED = "NOTIFICATION_CATEGORY_USER_RELATED",
        NOTIFICATION_CATEGORY_FOLLOWING_RELATED = "NOTIFICATION_CATEGORY_FOLLOWING_RELATED",
        NOTIFICATION_CATEGORY_WAKEUP_DIALOG = "NOTIFICATION_CATEGORY_WAKEUP_DIALOG"
    }

    /** Properties of a NotificationContents. */
    export interface INotificationContents {

        /** NotificationContents operation */
        operation?: (notification.INotificationContentsOperation);

        /** NotificationContents storyRejected */
        storyRejected?: (notification.INotificationContentsStoryRejected);

        /** NotificationContents userBanned */
        userBanned?: (notification.INotificationContentsUserBanned);

        /** NotificationContents paymentFailure */
        paymentFailure?: (notification.INotificationContentsPaymentFailure);

        /** NotificationContents vipGracePeriodExpired */
        vipGracePeriodExpired?: (notification.INotificationContentsVipGracePeriodExpired);

        /** NotificationContents newFollower */
        newFollower?: (notification.INotificationContentsNewFollower);

        /** NotificationContents newStoryComment */
        newStoryComment?: (notification.INotificationContentsNewStoryComment);

        /** NotificationContents newCommentLike */
        newCommentLike?: (notification.INotificationContentsNewCommentLike);

        /** NotificationContents newCommentResponse */
        newCommentResponse?: (notification.INotificationContentsNewCommentResponse);

        /** NotificationContents followingUserNewStory */
        followingUserNewStory?: (notification.INotificationContentsFollowingUserNewStory);

        /** NotificationContents userLikeBreak */
        userLikeBreak?: (notification.INotificationContentsUserLikeBreak);

        /** NotificationContents bookmarkNewSeriesStory */
        bookmarkNewSeriesStory?: (notification.INotificationContentsBookmarkNewSeriesStory);

        /** NotificationContents userStoryLikeBreak */
        userStoryLikeBreak?: (notification.INotificationContentsUserStoryLikeBreak);

        /** NotificationContents newMedal */
        newMedal?: (notification.INotificationContentsNewMedal);

        /** NotificationContents userStoryNewLikes */
        userStoryNewLikes?: (notification.INotificationContentsUserStoryNewLikes);

        /** NotificationContents storyVideoCreated */
        storyVideoCreated?: (notification.INotificationContentsStoryVideoCreated);

        /** NotificationContents mutualFollowReadRequest */
        mutualFollowReadRequest?: (notification.INotificationContentsMutualFollowReadRequest);
    }

    /** Represents a NotificationContents. */
    class NotificationContents implements INotificationContents {

        /**
         * Constructs a new NotificationContents.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContents);

        /** NotificationContents operation. */
        public operation?: (notification.INotificationContentsOperation);

        /** NotificationContents storyRejected. */
        public storyRejected?: (notification.INotificationContentsStoryRejected);

        /** NotificationContents userBanned. */
        public userBanned?: (notification.INotificationContentsUserBanned);

        /** NotificationContents paymentFailure. */
        public paymentFailure?: (notification.INotificationContentsPaymentFailure);

        /** NotificationContents vipGracePeriodExpired. */
        public vipGracePeriodExpired?: (notification.INotificationContentsVipGracePeriodExpired);

        /** NotificationContents newFollower. */
        public newFollower?: (notification.INotificationContentsNewFollower);

        /** NotificationContents newStoryComment. */
        public newStoryComment?: (notification.INotificationContentsNewStoryComment);

        /** NotificationContents newCommentLike. */
        public newCommentLike?: (notification.INotificationContentsNewCommentLike);

        /** NotificationContents newCommentResponse. */
        public newCommentResponse?: (notification.INotificationContentsNewCommentResponse);

        /** NotificationContents followingUserNewStory. */
        public followingUserNewStory?: (notification.INotificationContentsFollowingUserNewStory);

        /** NotificationContents userLikeBreak. */
        public userLikeBreak?: (notification.INotificationContentsUserLikeBreak);

        /** NotificationContents bookmarkNewSeriesStory. */
        public bookmarkNewSeriesStory?: (notification.INotificationContentsBookmarkNewSeriesStory);

        /** NotificationContents userStoryLikeBreak. */
        public userStoryLikeBreak?: (notification.INotificationContentsUserStoryLikeBreak);

        /** NotificationContents newMedal. */
        public newMedal?: (notification.INotificationContentsNewMedal);

        /** NotificationContents userStoryNewLikes. */
        public userStoryNewLikes?: (notification.INotificationContentsUserStoryNewLikes);

        /** NotificationContents storyVideoCreated. */
        public storyVideoCreated?: (notification.INotificationContentsStoryVideoCreated);

        /** NotificationContents mutualFollowReadRequest. */
        public mutualFollowReadRequest?: (notification.INotificationContentsMutualFollowReadRequest);

        /** NotificationContents contents. */
        public contents?: ("operation"|"storyRejected"|"userBanned"|"paymentFailure"|"vipGracePeriodExpired"|"newFollower"|"newStoryComment"|"newCommentLike"|"newCommentResponse"|"followingUserNewStory"|"userLikeBreak"|"bookmarkNewSeriesStory"|"userStoryLikeBreak"|"newMedal"|"userStoryNewLikes"|"storyVideoCreated"|"mutualFollowReadRequest");

        /**
         * Gets the default type url for NotificationContents
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsOperation. */
    export interface INotificationContentsOperation {

        /** NotificationContentsOperation title */
        title?: (string);

        /** NotificationContentsOperation message */
        message?: (string);

        /** NotificationContentsOperation actionUrl */
        actionUrl?: (string);

        /** NotificationContentsOperation thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsOperation metadata */
        metadata?: (notification.INotificationMetadata);

        /** NotificationContentsOperation iconUrl */
        iconUrl?: (string);
    }

    /** Represents a NotificationContentsOperation. */
    class NotificationContentsOperation implements INotificationContentsOperation {

        /**
         * Constructs a new NotificationContentsOperation.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsOperation);

        /** NotificationContentsOperation title. */
        public title?: (string);

        /** NotificationContentsOperation message. */
        public message?: (string);

        /** NotificationContentsOperation actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsOperation thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsOperation metadata. */
        public metadata?: (notification.INotificationMetadata);

        /** NotificationContentsOperation iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for NotificationContentsOperation
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsStoryRejected. */
    export interface INotificationContentsStoryRejected {

        /** NotificationContentsStoryRejected message */
        message?: (string);

        /** NotificationContentsStoryRejected actionUrl */
        actionUrl?: (string);

        /** NotificationContentsStoryRejected thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsStoryRejected storyTitle */
        storyTitle?: (string);
    }

    /** Represents a NotificationContentsStoryRejected. */
    class NotificationContentsStoryRejected implements INotificationContentsStoryRejected {

        /**
         * Constructs a new NotificationContentsStoryRejected.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsStoryRejected);

        /** NotificationContentsStoryRejected message. */
        public message?: (string);

        /** NotificationContentsStoryRejected actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsStoryRejected thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsStoryRejected storyTitle. */
        public storyTitle?: (string);

        /**
         * Gets the default type url for NotificationContentsStoryRejected
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsUserBanned. */
    export interface INotificationContentsUserBanned {

        /** NotificationContentsUserBanned message */
        message?: (string);
    }

    /** Represents a NotificationContentsUserBanned. */
    class NotificationContentsUserBanned implements INotificationContentsUserBanned {

        /**
         * Constructs a new NotificationContentsUserBanned.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsUserBanned);

        /** NotificationContentsUserBanned message. */
        public message?: (string);

        /**
         * Gets the default type url for NotificationContentsUserBanned
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsPaymentFailure. */
    export interface INotificationContentsPaymentFailure {

        /** NotificationContentsPaymentFailure title */
        title?: (string);

        /** NotificationContentsPaymentFailure message */
        message?: (string);

        /** NotificationContentsPaymentFailure iconUrl */
        iconUrl?: (string);
    }

    /** Represents a NotificationContentsPaymentFailure. */
    class NotificationContentsPaymentFailure implements INotificationContentsPaymentFailure {

        /**
         * Constructs a new NotificationContentsPaymentFailure.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsPaymentFailure);

        /** NotificationContentsPaymentFailure title. */
        public title?: (string);

        /** NotificationContentsPaymentFailure message. */
        public message?: (string);

        /** NotificationContentsPaymentFailure iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for NotificationContentsPaymentFailure
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsVipGracePeriodExpired. */
    export interface INotificationContentsVipGracePeriodExpired {

        /** NotificationContentsVipGracePeriodExpired message */
        message?: (string);

        /** NotificationContentsVipGracePeriodExpired actionUrl */
        actionUrl?: (string);

        /** NotificationContentsVipGracePeriodExpired iconUrl */
        iconUrl?: (string);
    }

    /** Represents a NotificationContentsVipGracePeriodExpired. */
    class NotificationContentsVipGracePeriodExpired implements INotificationContentsVipGracePeriodExpired {

        /**
         * Constructs a new NotificationContentsVipGracePeriodExpired.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsVipGracePeriodExpired);

        /** NotificationContentsVipGracePeriodExpired message. */
        public message?: (string);

        /** NotificationContentsVipGracePeriodExpired actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsVipGracePeriodExpired iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for NotificationContentsVipGracePeriodExpired
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsNewFollower. */
    export interface INotificationContentsNewFollower {

        /** NotificationContentsNewFollower message */
        message?: (string);

        /** NotificationContentsNewFollower actionUrl */
        actionUrl?: (string);

        /** NotificationContentsNewFollower userIcon */
        userIcon?: (notification.IImageWithLink);
    }

    /** Represents a NotificationContentsNewFollower. */
    class NotificationContentsNewFollower implements INotificationContentsNewFollower {

        /**
         * Constructs a new NotificationContentsNewFollower.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsNewFollower);

        /** NotificationContentsNewFollower message. */
        public message?: (string);

        /** NotificationContentsNewFollower actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsNewFollower userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /**
         * Gets the default type url for NotificationContentsNewFollower
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsNewStoryComment. */
    export interface INotificationContentsNewStoryComment {

        /** NotificationContentsNewStoryComment message */
        message?: (string);

        /** NotificationContentsNewStoryComment storyTitle */
        storyTitle?: (string);

        /** NotificationContentsNewStoryComment actionUrl */
        actionUrl?: (string);

        /** NotificationContentsNewStoryComment thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsNewStoryComment userIcon */
        userIcon?: (notification.IImageWithLink);
    }

    /** Represents a NotificationContentsNewStoryComment. */
    class NotificationContentsNewStoryComment implements INotificationContentsNewStoryComment {

        /**
         * Constructs a new NotificationContentsNewStoryComment.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsNewStoryComment);

        /** NotificationContentsNewStoryComment message. */
        public message?: (string);

        /** NotificationContentsNewStoryComment storyTitle. */
        public storyTitle?: (string);

        /** NotificationContentsNewStoryComment actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsNewStoryComment thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsNewStoryComment userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /**
         * Gets the default type url for NotificationContentsNewStoryComment
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsNewCommentLike. */
    export interface INotificationContentsNewCommentLike {

        /** NotificationContentsNewCommentLike message */
        message?: (string);

        /** NotificationContentsNewCommentLike storyTitle */
        storyTitle?: (string);

        /** NotificationContentsNewCommentLike actionUrl */
        actionUrl?: (string);

        /** NotificationContentsNewCommentLike thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsNewCommentLike userIcon */
        userIcon?: (notification.IImageWithLink);
    }

    /** Represents a NotificationContentsNewCommentLike. */
    class NotificationContentsNewCommentLike implements INotificationContentsNewCommentLike {

        /**
         * Constructs a new NotificationContentsNewCommentLike.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsNewCommentLike);

        /** NotificationContentsNewCommentLike message. */
        public message?: (string);

        /** NotificationContentsNewCommentLike storyTitle. */
        public storyTitle?: (string);

        /** NotificationContentsNewCommentLike actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsNewCommentLike thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsNewCommentLike userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /**
         * Gets the default type url for NotificationContentsNewCommentLike
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsNewCommentResponse. */
    export interface INotificationContentsNewCommentResponse {

        /** NotificationContentsNewCommentResponse message */
        message?: (string);

        /** NotificationContentsNewCommentResponse storyTitle */
        storyTitle?: (string);

        /** NotificationContentsNewCommentResponse actionUrl */
        actionUrl?: (string);

        /** NotificationContentsNewCommentResponse thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsNewCommentResponse userIcon */
        userIcon?: (notification.IImageWithLink);
    }

    /** Represents a NotificationContentsNewCommentResponse. */
    class NotificationContentsNewCommentResponse implements INotificationContentsNewCommentResponse {

        /**
         * Constructs a new NotificationContentsNewCommentResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsNewCommentResponse);

        /** NotificationContentsNewCommentResponse message. */
        public message?: (string);

        /** NotificationContentsNewCommentResponse storyTitle. */
        public storyTitle?: (string);

        /** NotificationContentsNewCommentResponse actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsNewCommentResponse thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsNewCommentResponse userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /**
         * Gets the default type url for NotificationContentsNewCommentResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsFollowingUserNewStory. */
    export interface INotificationContentsFollowingUserNewStory {

        /** NotificationContentsFollowingUserNewStory message */
        message?: (string);

        /** NotificationContentsFollowingUserNewStory actionUrl */
        actionUrl?: (string);

        /** NotificationContentsFollowingUserNewStory thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsFollowingUserNewStory userIcon */
        userIcon?: (notification.IImageWithLink);
    }

    /** Represents a NotificationContentsFollowingUserNewStory. */
    class NotificationContentsFollowingUserNewStory implements INotificationContentsFollowingUserNewStory {

        /**
         * Constructs a new NotificationContentsFollowingUserNewStory.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsFollowingUserNewStory);

        /** NotificationContentsFollowingUserNewStory message. */
        public message?: (string);

        /** NotificationContentsFollowingUserNewStory actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsFollowingUserNewStory thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsFollowingUserNewStory userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /**
         * Gets the default type url for NotificationContentsFollowingUserNewStory
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsUserLikeBreak. */
    export interface INotificationContentsUserLikeBreak {

        /** NotificationContentsUserLikeBreak message */
        message?: (string);

        /** NotificationContentsUserLikeBreak actionUrl */
        actionUrl?: (string);

        /** NotificationContentsUserLikeBreak iconUrl */
        iconUrl?: (string);
    }

    /** Represents a NotificationContentsUserLikeBreak. */
    class NotificationContentsUserLikeBreak implements INotificationContentsUserLikeBreak {

        /**
         * Constructs a new NotificationContentsUserLikeBreak.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsUserLikeBreak);

        /** NotificationContentsUserLikeBreak message. */
        public message?: (string);

        /** NotificationContentsUserLikeBreak actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsUserLikeBreak iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for NotificationContentsUserLikeBreak
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsBookmarkNewSeriesStory. */
    export interface INotificationContentsBookmarkNewSeriesStory {

        /** NotificationContentsBookmarkNewSeriesStory message */
        message?: (string);

        /** NotificationContentsBookmarkNewSeriesStory actionUrl */
        actionUrl?: (string);

        /** NotificationContentsBookmarkNewSeriesStory thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsBookmarkNewSeriesStory userIcon */
        userIcon?: (notification.IImageWithLink);

        /** NotificationContentsBookmarkNewSeriesStory iconUrl */
        iconUrl?: (string);
    }

    /** Represents a NotificationContentsBookmarkNewSeriesStory. */
    class NotificationContentsBookmarkNewSeriesStory implements INotificationContentsBookmarkNewSeriesStory {

        /**
         * Constructs a new NotificationContentsBookmarkNewSeriesStory.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsBookmarkNewSeriesStory);

        /** NotificationContentsBookmarkNewSeriesStory message. */
        public message?: (string);

        /** NotificationContentsBookmarkNewSeriesStory actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsBookmarkNewSeriesStory thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsBookmarkNewSeriesStory userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /** NotificationContentsBookmarkNewSeriesStory iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for NotificationContentsBookmarkNewSeriesStory
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsUserStoryLikeBreak. */
    export interface INotificationContentsUserStoryLikeBreak {

        /** NotificationContentsUserStoryLikeBreak message */
        message?: (string);

        /** NotificationContentsUserStoryLikeBreak actionUrl */
        actionUrl?: (string);

        /** NotificationContentsUserStoryLikeBreak thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsUserStoryLikeBreak iconUrl */
        iconUrl?: (string);
    }

    /** Represents a NotificationContentsUserStoryLikeBreak. */
    class NotificationContentsUserStoryLikeBreak implements INotificationContentsUserStoryLikeBreak {

        /**
         * Constructs a new NotificationContentsUserStoryLikeBreak.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsUserStoryLikeBreak);

        /** NotificationContentsUserStoryLikeBreak message. */
        public message?: (string);

        /** NotificationContentsUserStoryLikeBreak actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsUserStoryLikeBreak thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsUserStoryLikeBreak iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for NotificationContentsUserStoryLikeBreak
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsNewMedal. */
    export interface INotificationContentsNewMedal {

        /** NotificationContentsNewMedal message */
        message?: (string);

        /** NotificationContentsNewMedal storyTitle */
        storyTitle?: (string);

        /** NotificationContentsNewMedal actionUrl */
        actionUrl?: (string);

        /** NotificationContentsNewMedal thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsNewMedal iconUrl */
        iconUrl?: (string);
    }

    /** Represents a NotificationContentsNewMedal. */
    class NotificationContentsNewMedal implements INotificationContentsNewMedal {

        /**
         * Constructs a new NotificationContentsNewMedal.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsNewMedal);

        /** NotificationContentsNewMedal message. */
        public message?: (string);

        /** NotificationContentsNewMedal storyTitle. */
        public storyTitle?: (string);

        /** NotificationContentsNewMedal actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsNewMedal thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsNewMedal iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for NotificationContentsNewMedal
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsUserStoryNewLikes. */
    export interface INotificationContentsUserStoryNewLikes {

        /** NotificationContentsUserStoryNewLikes message */
        message?: (string);

        /** NotificationContentsUserStoryNewLikes storyTitle */
        storyTitle?: (string);

        /** NotificationContentsUserStoryNewLikes actionUrl */
        actionUrl?: (string);

        /** NotificationContentsUserStoryNewLikes thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsUserStoryNewLikes userIcon */
        userIcon?: (notification.IImageWithLink);
    }

    /** Represents a NotificationContentsUserStoryNewLikes. */
    class NotificationContentsUserStoryNewLikes implements INotificationContentsUserStoryNewLikes {

        /**
         * Constructs a new NotificationContentsUserStoryNewLikes.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsUserStoryNewLikes);

        /** NotificationContentsUserStoryNewLikes message. */
        public message?: (string);

        /** NotificationContentsUserStoryNewLikes storyTitle. */
        public storyTitle?: (string);

        /** NotificationContentsUserStoryNewLikes actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsUserStoryNewLikes thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsUserStoryNewLikes userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /**
         * Gets the default type url for NotificationContentsUserStoryNewLikes
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsStoryVideoCreated. */
    export interface INotificationContentsStoryVideoCreated {

        /** NotificationContentsStoryVideoCreated message */
        message?: (string);

        /** NotificationContentsStoryVideoCreated storyTitle */
        storyTitle?: (string);

        /** NotificationContentsStoryVideoCreated actionUrl */
        actionUrl?: (string);

        /** NotificationContentsStoryVideoCreated thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsStoryVideoCreated iconUrl */
        iconUrl?: (string);
    }

    /** Represents a NotificationContentsStoryVideoCreated. */
    class NotificationContentsStoryVideoCreated implements INotificationContentsStoryVideoCreated {

        /**
         * Constructs a new NotificationContentsStoryVideoCreated.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsStoryVideoCreated);

        /** NotificationContentsStoryVideoCreated message. */
        public message?: (string);

        /** NotificationContentsStoryVideoCreated storyTitle. */
        public storyTitle?: (string);

        /** NotificationContentsStoryVideoCreated actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsStoryVideoCreated thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsStoryVideoCreated iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for NotificationContentsStoryVideoCreated
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsMutualFollowReadRequest. */
    export interface INotificationContentsMutualFollowReadRequest {

        /** NotificationContentsMutualFollowReadRequest message */
        message?: (string);

        /** NotificationContentsMutualFollowReadRequest actionUrl */
        actionUrl?: (string);

        /** NotificationContentsMutualFollowReadRequest userIcon */
        userIcon?: (notification.IImageWithLink);

        /** NotificationContentsMutualFollowReadRequest seriesTitle */
        seriesTitle?: (string);
    }

    /** Represents a NotificationContentsMutualFollowReadRequest. */
    class NotificationContentsMutualFollowReadRequest implements INotificationContentsMutualFollowReadRequest {

        /**
         * Constructs a new NotificationContentsMutualFollowReadRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: notification.INotificationContentsMutualFollowReadRequest);

        /** NotificationContentsMutualFollowReadRequest message. */
        public message?: (string);

        /** NotificationContentsMutualFollowReadRequest actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsMutualFollowReadRequest userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /** NotificationContentsMutualFollowReadRequest seriesTitle. */
        public seriesTitle?: (string);

        /**
         * Gets the default type url for NotificationContentsMutualFollowReadRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace medal. */
export namespace medal {

    /** MedalGrade enum. */
    export enum MedalGrade {
        MEDAL_GRADE_UNSPECIFIED = "MEDAL_GRADE_UNSPECIFIED",
        MEDAL_GRADE_BRONZE = "MEDAL_GRADE_BRONZE",
        MEDAL_GRADE_SILVER = "MEDAL_GRADE_SILVER",
        MEDAL_GRADE_GOLD = "MEDAL_GRADE_GOLD"
    }

    /** MedalType enum. */
    export enum MedalType {
        MEDAL_TYPE_UNSPECIFIED = "MEDAL_TYPE_UNSPECIFIED",
        MEDAL_TYPE_RANKING = "MEDAL_TYPE_RANKING"
    }

    /** Properties of a Medal. */
    export interface IMedal {

        /** Medal grade */
        grade?: (medal.MedalGrade);

        /** Medal type */
        type?: (medal.MedalType);

        /** Medal name */
        name?: (string);

        /** Medal gainedAt */
        gainedAt?: (string);

        /** Medal story */
        story?: (story.IStoryHeader);

        /** Medal tag */
        tag?: (string);

        /** Medal iconUrl */
        iconUrl?: (string);
    }

    /** Represents a Medal. */
    class Medal implements IMedal {

        /**
         * Constructs a new Medal.
         * @param [properties] Properties to set
         */
        constructor(properties?: medal.IMedal);

        /** Medal grade. */
        public grade: medal.MedalGrade;

        /** Medal type. */
        public type: medal.MedalType;

        /** Medal name. */
        public name?: (string);

        /** Medal gainedAt. */
        public gainedAt?: (string);

        /** Medal story. */
        public story?: (story.IStoryHeader);

        /** Medal tag. */
        public tag?: (string);

        /** Medal iconUrl. */
        public iconUrl?: (string);

        /**
         * Gets the default type url for Medal
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MedalListRequest. */
    export interface IMedalListRequest {

        /** MedalListRequest userId */
        userId?: (string);

        /** MedalListRequest limit */
        limit?: (number);

        /** MedalListRequest cursor */
        cursor?: (string);
    }

    /** Represents a MedalListRequest. */
    class MedalListRequest implements IMedalListRequest {

        /**
         * Constructs a new MedalListRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: medal.IMedalListRequest);

        /** MedalListRequest userId. */
        public userId?: (string);

        /** MedalListRequest limit. */
        public limit?: (number);

        /** MedalListRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for MedalListRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MedalListResponse. */
    export interface IMedalListResponse {

        /** MedalListResponse medalList */
        medalList?: (medal.IMedal[]);

        /** MedalListResponse cursor */
        cursor?: (string);
    }

    /** Represents a MedalListResponse. */
    class MedalListResponse implements IMedalListResponse {

        /**
         * Constructs a new MedalListResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: medal.IMedalListResponse);

        /** MedalListResponse medalList. */
        public medalList: medal.IMedal[];

        /** MedalListResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for MedalListResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MedalGetStatsRequest. */
    export interface IMedalGetStatsRequest {

        /** MedalGetStatsRequest userId */
        userId?: (string);
    }

    /** Represents a MedalGetStatsRequest. */
    class MedalGetStatsRequest implements IMedalGetStatsRequest {

        /**
         * Constructs a new MedalGetStatsRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: medal.IMedalGetStatsRequest);

        /** MedalGetStatsRequest userId. */
        public userId?: (string);

        /**
         * Gets the default type url for MedalGetStatsRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MedalGetStatsResponse. */
    export interface IMedalGetStatsResponse {

        /** MedalGetStatsResponse userId */
        userId?: (string);

        /** MedalGetStatsResponse totalCount */
        totalCount?: (number);
    }

    /** Represents a MedalGetStatsResponse. */
    class MedalGetStatsResponse implements IMedalGetStatsResponse {

        /**
         * Constructs a new MedalGetStatsResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: medal.IMedalGetStatsResponse);

        /** MedalGetStatsResponse userId. */
        public userId?: (string);

        /** MedalGetStatsResponse totalCount. */
        public totalCount?: (number);

        /**
         * Gets the default type url for MedalGetStatsResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace media. */
export namespace media {

    /** Properties of a MediaUploadRequest. */
    export interface IMediaUploadRequest {

        /** MediaUploadRequest blob */
        blob?: (Uint8Array);
    }

    /** Represents a MediaUploadRequest. */
    class MediaUploadRequest implements IMediaUploadRequest {

        /**
         * Constructs a new MediaUploadRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: media.IMediaUploadRequest);

        /** MediaUploadRequest blob. */
        public blob?: (Uint8Array);

        /**
         * Gets the default type url for MediaUploadRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MediaUploadResponse. */
    export interface IMediaUploadResponse {

        /** MediaUploadResponse url */
        url?: (string);
    }

    /** Represents a MediaUploadResponse. */
    class MediaUploadResponse implements IMediaUploadResponse {

        /**
         * Constructs a new MediaUploadResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: media.IMediaUploadResponse);

        /** MediaUploadResponse url. */
        public url?: (string);

        /**
         * Gets the default type url for MediaUploadResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_apple_auth. */
export namespace mutation_apple_auth {

    /** Properties of an UpdateAppleAuthAuthorizationCodeRequest. */
    export interface IUpdateAppleAuthAuthorizationCodeRequest {

        /** UpdateAppleAuthAuthorizationCodeRequest authorizationCode */
        authorizationCode?: (string);
    }

    /** Represents an UpdateAppleAuthAuthorizationCodeRequest. */
    class UpdateAppleAuthAuthorizationCodeRequest implements IUpdateAppleAuthAuthorizationCodeRequest {

        /**
         * Constructs a new UpdateAppleAuthAuthorizationCodeRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_apple_auth.IUpdateAppleAuthAuthorizationCodeRequest);

        /** UpdateAppleAuthAuthorizationCodeRequest authorizationCode. */
        public authorizationCode?: (string);

        /**
         * Gets the default type url for UpdateAppleAuthAuthorizationCodeRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_authorize_device. */
export namespace mutation_authorize_device {

    /** Properties of an AuthorizeDeviceRequest. */
    export interface IAuthorizeDeviceRequest {

        /** AuthorizeDeviceRequest deviceId */
        deviceId?: (string);

        /** AuthorizeDeviceRequest platform */
        platform?: (types.Platform);

        /** AuthorizeDeviceRequest property */
        property?: (string);

        /** AuthorizeDeviceRequest app */
        app?: (types.App);
    }

    /** Represents an AuthorizeDeviceRequest. */
    class AuthorizeDeviceRequest implements IAuthorizeDeviceRequest {

        /**
         * Constructs a new AuthorizeDeviceRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_authorize_device.IAuthorizeDeviceRequest);

        /** AuthorizeDeviceRequest deviceId. */
        public deviceId?: (string);

        /** AuthorizeDeviceRequest platform. */
        public platform: types.Platform;

        /** AuthorizeDeviceRequest property. */
        public property?: (string);

        /** AuthorizeDeviceRequest app. */
        public app: types.App;

        /**
         * Gets the default type url for AuthorizeDeviceRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AuthorizeDeviceResponse. */
    export interface IAuthorizeDeviceResponse {

        /** AuthorizeDeviceResponse succeeded */
        succeeded?: (boolean);
    }

    /** Represents an AuthorizeDeviceResponse. */
    class AuthorizeDeviceResponse implements IAuthorizeDeviceResponse {

        /**
         * Constructs a new AuthorizeDeviceResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_authorize_device.IAuthorizeDeviceResponse);

        /** AuthorizeDeviceResponse succeeded. */
        public succeeded?: (boolean);

        /**
         * Gets the default type url for AuthorizeDeviceResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_bonus. */
export namespace mutation_bonus {

    /** BonusResultType enum. */
    export enum BonusResultType {
        BONUS_RESULT_TYPE_UNSPECIFIED = "BONUS_RESULT_TYPE_UNSPECIFIED",
        BONUS_RESULT_TYPE_REWARDED = "BONUS_RESULT_TYPE_REWARDED",
        BONUS_RESULT_TYPE_NOT_AVAILABLE = "BONUS_RESULT_TYPE_NOT_AVAILABLE",
        BONUS_RESULT_TYPE_ALREADY_REWARDED = "BONUS_RESULT_TYPE_ALREADY_REWARDED"
    }

    /** Properties of a RewardWatchAdBonusCoinRequest. */
    export interface IRewardWatchAdBonusCoinRequest {
    }

    /** Represents a RewardWatchAdBonusCoinRequest. */
    class RewardWatchAdBonusCoinRequest implements IRewardWatchAdBonusCoinRequest {

        /**
         * Constructs a new RewardWatchAdBonusCoinRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWatchAdBonusCoinRequest);

        /**
         * Gets the default type url for RewardWatchAdBonusCoinRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWatchAdBonusCoinResponse. */
    export interface IRewardWatchAdBonusCoinResponse {

        /** RewardWatchAdBonusCoinResponse response */
        response?: (mutation_bonus.BonusResultType);

        /** RewardWatchAdBonusCoinResponse rewardedAmount */
        rewardedAmount?: (number);
    }

    /** Represents a RewardWatchAdBonusCoinResponse. */
    class RewardWatchAdBonusCoinResponse implements IRewardWatchAdBonusCoinResponse {

        /**
         * Constructs a new RewardWatchAdBonusCoinResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWatchAdBonusCoinResponse);

        /** RewardWatchAdBonusCoinResponse response. */
        public response: mutation_bonus.BonusResultType;

        /** RewardWatchAdBonusCoinResponse rewardedAmount. */
        public rewardedAmount?: (number);

        /**
         * Gets the default type url for RewardWatchAdBonusCoinResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardUserRegistrationBonusCoinRequest. */
    export interface IRewardUserRegistrationBonusCoinRequest {
    }

    /** Represents a RewardUserRegistrationBonusCoinRequest. */
    class RewardUserRegistrationBonusCoinRequest implements IRewardUserRegistrationBonusCoinRequest {

        /**
         * Constructs a new RewardUserRegistrationBonusCoinRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardUserRegistrationBonusCoinRequest);

        /**
         * Gets the default type url for RewardUserRegistrationBonusCoinRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardUserRegistrationBonusCoinResponse. */
    export interface IRewardUserRegistrationBonusCoinResponse {

        /** RewardUserRegistrationBonusCoinResponse response */
        response?: (mutation_bonus.BonusResultType);

        /** RewardUserRegistrationBonusCoinResponse rewardedAmount */
        rewardedAmount?: (number);
    }

    /** Represents a RewardUserRegistrationBonusCoinResponse. */
    class RewardUserRegistrationBonusCoinResponse implements IRewardUserRegistrationBonusCoinResponse {

        /**
         * Constructs a new RewardUserRegistrationBonusCoinResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardUserRegistrationBonusCoinResponse);

        /** RewardUserRegistrationBonusCoinResponse response. */
        public response: mutation_bonus.BonusResultType;

        /** RewardUserRegistrationBonusCoinResponse rewardedAmount. */
        public rewardedAmount?: (number);

        /**
         * Gets the default type url for RewardUserRegistrationBonusCoinResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWatchVideoBonusCoinRequest. */
    export interface IRewardWatchVideoBonusCoinRequest {

        /** RewardWatchVideoBonusCoinRequest step */
        step?: (number);
    }

    /** Represents a RewardWatchVideoBonusCoinRequest. */
    class RewardWatchVideoBonusCoinRequest implements IRewardWatchVideoBonusCoinRequest {

        /**
         * Constructs a new RewardWatchVideoBonusCoinRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWatchVideoBonusCoinRequest);

        /** RewardWatchVideoBonusCoinRequest step. */
        public step?: (number);

        /**
         * Gets the default type url for RewardWatchVideoBonusCoinRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWatchVideoBonusCoinResponse. */
    export interface IRewardWatchVideoBonusCoinResponse {

        /** RewardWatchVideoBonusCoinResponse response */
        response?: (mutation_bonus.BonusResultType);

        /** RewardWatchVideoBonusCoinResponse rewardedAmount */
        rewardedAmount?: (number);
    }

    /** Represents a RewardWatchVideoBonusCoinResponse. */
    class RewardWatchVideoBonusCoinResponse implements IRewardWatchVideoBonusCoinResponse {

        /**
         * Constructs a new RewardWatchVideoBonusCoinResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWatchVideoBonusCoinResponse);

        /** RewardWatchVideoBonusCoinResponse response. */
        public response: mutation_bonus.BonusResultType;

        /** RewardWatchVideoBonusCoinResponse rewardedAmount. */
        public rewardedAmount?: (number);

        /**
         * Gets the default type url for RewardWatchVideoBonusCoinResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardCheckInBonusCoinRequest. */
    export interface IRewardCheckInBonusCoinRequest {
    }

    /** Represents a RewardCheckInBonusCoinRequest. */
    class RewardCheckInBonusCoinRequest implements IRewardCheckInBonusCoinRequest {

        /**
         * Constructs a new RewardCheckInBonusCoinRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardCheckInBonusCoinRequest);

        /**
         * Gets the default type url for RewardCheckInBonusCoinRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardCheckInBonusCoinResponse. */
    export interface IRewardCheckInBonusCoinResponse {

        /** RewardCheckInBonusCoinResponse response */
        response?: (mutation_bonus.BonusResultType);

        /** RewardCheckInBonusCoinResponse rewardedAmount */
        rewardedAmount?: (number);
    }

    /** Represents a RewardCheckInBonusCoinResponse. */
    class RewardCheckInBonusCoinResponse implements IRewardCheckInBonusCoinResponse {

        /**
         * Constructs a new RewardCheckInBonusCoinResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardCheckInBonusCoinResponse);

        /** RewardCheckInBonusCoinResponse response. */
        public response: mutation_bonus.BonusResultType;

        /** RewardCheckInBonusCoinResponse rewardedAmount. */
        public rewardedAmount?: (number);

        /**
         * Gets the default type url for RewardCheckInBonusCoinResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWatchAdBonusStoryRequest. */
    export interface IRewardWatchAdBonusStoryRequest {

        /** RewardWatchAdBonusStoryRequest storyId */
        storyId?: (string);
    }

    /** Represents a RewardWatchAdBonusStoryRequest. */
    class RewardWatchAdBonusStoryRequest implements IRewardWatchAdBonusStoryRequest {

        /**
         * Constructs a new RewardWatchAdBonusStoryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWatchAdBonusStoryRequest);

        /** RewardWatchAdBonusStoryRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for RewardWatchAdBonusStoryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWatchAdBonusStoryResponse. */
    export interface IRewardWatchAdBonusStoryResponse {

        /** RewardWatchAdBonusStoryResponse response */
        response?: (mutation_bonus.BonusResultType);
    }

    /** Represents a RewardWatchAdBonusStoryResponse. */
    class RewardWatchAdBonusStoryResponse implements IRewardWatchAdBonusStoryResponse {

        /**
         * Constructs a new RewardWatchAdBonusStoryResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWatchAdBonusStoryResponse);

        /** RewardWatchAdBonusStoryResponse response. */
        public response: mutation_bonus.BonusResultType;

        /**
         * Gets the default type url for RewardWatchAdBonusStoryResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWebLoginBonusCoinRequest. */
    export interface IRewardWebLoginBonusCoinRequest {
    }

    /** Represents a RewardWebLoginBonusCoinRequest. */
    class RewardWebLoginBonusCoinRequest implements IRewardWebLoginBonusCoinRequest {

        /**
         * Constructs a new RewardWebLoginBonusCoinRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWebLoginBonusCoinRequest);

        /**
         * Gets the default type url for RewardWebLoginBonusCoinRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWebLoginBonusCoinResponse. */
    export interface IRewardWebLoginBonusCoinResponse {

        /** RewardWebLoginBonusCoinResponse response */
        response?: (mutation_bonus.BonusResultType);

        /** RewardWebLoginBonusCoinResponse rewardedAmount */
        rewardedAmount?: (number);
    }

    /** Represents a RewardWebLoginBonusCoinResponse. */
    class RewardWebLoginBonusCoinResponse implements IRewardWebLoginBonusCoinResponse {

        /**
         * Constructs a new RewardWebLoginBonusCoinResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWebLoginBonusCoinResponse);

        /** RewardWebLoginBonusCoinResponse response. */
        public response: mutation_bonus.BonusResultType;

        /** RewardWebLoginBonusCoinResponse rewardedAmount. */
        public rewardedAmount?: (number);

        /**
         * Gets the default type url for RewardWebLoginBonusCoinResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWebCoinPurchaseBonusCoinRequest. */
    export interface IRewardWebCoinPurchaseBonusCoinRequest {
    }

    /** Represents a RewardWebCoinPurchaseBonusCoinRequest. */
    class RewardWebCoinPurchaseBonusCoinRequest implements IRewardWebCoinPurchaseBonusCoinRequest {

        /**
         * Constructs a new RewardWebCoinPurchaseBonusCoinRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWebCoinPurchaseBonusCoinRequest);

        /**
         * Gets the default type url for RewardWebCoinPurchaseBonusCoinRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RewardWebCoinPurchaseBonusCoinResponse. */
    export interface IRewardWebCoinPurchaseBonusCoinResponse {

        /** RewardWebCoinPurchaseBonusCoinResponse response */
        response?: (mutation_bonus.BonusResultType);

        /** RewardWebCoinPurchaseBonusCoinResponse rewardedAmount */
        rewardedAmount?: (number);
    }

    /** Represents a RewardWebCoinPurchaseBonusCoinResponse. */
    class RewardWebCoinPurchaseBonusCoinResponse implements IRewardWebCoinPurchaseBonusCoinResponse {

        /**
         * Constructs a new RewardWebCoinPurchaseBonusCoinResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus.IRewardWebCoinPurchaseBonusCoinResponse);

        /** RewardWebCoinPurchaseBonusCoinResponse response. */
        public response: mutation_bonus.BonusResultType;

        /** RewardWebCoinPurchaseBonusCoinResponse rewardedAmount. */
        public rewardedAmount?: (number);

        /**
         * Gets the default type url for RewardWebCoinPurchaseBonusCoinResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_bonus_ticket. */
export namespace mutation_bonus_ticket {

    /** Properties of a GainBonusTicketRequest. */
    export interface IGainBonusTicketRequest {

        /** GainBonusTicketRequest bonusTicketType */
        bonusTicketType?: (mutation_bonus_ticket.GainBonusTicketRequest.BonusTicketType);
    }

    /** Represents a GainBonusTicketRequest. */
    class GainBonusTicketRequest implements IGainBonusTicketRequest {

        /**
         * Constructs a new GainBonusTicketRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus_ticket.IGainBonusTicketRequest);

        /** GainBonusTicketRequest bonusTicketType. */
        public bonusTicketType: mutation_bonus_ticket.GainBonusTicketRequest.BonusTicketType;

        /**
         * Gets the default type url for GainBonusTicketRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace GainBonusTicketRequest {

        /** BonusTicketType enum. */
        export enum BonusTicketType {
            BONUS_TICKET_TYPE_UNSPECIFIED = "BONUS_TICKET_TYPE_UNSPECIFIED",
            BONUS_TICKET_SHARING = "BONUS_TICKET_SHARING",
            BONUS_TICKET_ADVERTISEMENT = "BONUS_TICKET_ADVERTISEMENT",
            BONUS_TICKET_LOGIN = "BONUS_TICKET_LOGIN",
            BONUS_TICKET_REVIEW = "BONUS_TICKET_REVIEW",
            BONUS_TICKET_TIME_LIMITED = "BONUS_TICKET_TIME_LIMITED"
        }
    }

    /** Properties of a GainBonusTicketResponse. */
    export interface IGainBonusTicketResponse {

        /** GainBonusTicketResponse gainedAmount */
        gainedAmount?: (number);
    }

    /** Represents a GainBonusTicketResponse. */
    class GainBonusTicketResponse implements IGainBonusTicketResponse {

        /**
         * Constructs a new GainBonusTicketResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_bonus_ticket.IGainBonusTicketResponse);

        /** GainBonusTicketResponse gainedAmount. */
        public gainedAmount?: (number);

        /**
         * Gets the default type url for GainBonusTicketResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_comment. */
export namespace mutation_comment {

    /** Properties of a ValidateCommentRequest. */
    export interface IValidateCommentRequest {

        /** ValidateCommentRequest body */
        body?: (string);
    }

    /** Represents a ValidateCommentRequest. */
    class ValidateCommentRequest implements IValidateCommentRequest {

        /**
         * Constructs a new ValidateCommentRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_comment.IValidateCommentRequest);

        /** ValidateCommentRequest body. */
        public body?: (string);

        /**
         * Gets the default type url for ValidateCommentRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ValidateCommentResponse. */
    export interface IValidateCommentResponse {

        /** ValidateCommentResponse succeeded */
        succeeded?: (boolean);
    }

    /** Represents a ValidateCommentResponse. */
    class ValidateCommentResponse implements IValidateCommentResponse {

        /**
         * Constructs a new ValidateCommentResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_comment.IValidateCommentResponse);

        /** ValidateCommentResponse succeeded. */
        public succeeded?: (boolean);

        /**
         * Gets the default type url for ValidateCommentResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_follow_user. */
export namespace mutation_follow_user {

    /** Properties of a FollowUserRequest. */
    export interface IFollowUserRequest {

        /** FollowUserRequest userId */
        userId?: (string);

        /** FollowUserRequest mutualFollowSeriesId */
        mutualFollowSeriesId?: (string);
    }

    /** Represents a FollowUserRequest. */
    class FollowUserRequest implements IFollowUserRequest {

        /**
         * Constructs a new FollowUserRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_follow_user.IFollowUserRequest);

        /** FollowUserRequest userId. */
        public userId?: (string);

        /** FollowUserRequest mutualFollowSeriesId. */
        public mutualFollowSeriesId?: (string);

        /** FollowUserRequest reason. */
        public reason?: "mutualFollowSeriesId";

        /**
         * Gets the default type url for FollowUserRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an UnfollowUserRequest. */
    export interface IUnfollowUserRequest {

        /** UnfollowUserRequest userId */
        userId?: (string);
    }

    /** Represents an UnfollowUserRequest. */
    class UnfollowUserRequest implements IUnfollowUserRequest {

        /**
         * Constructs a new UnfollowUserRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_follow_user.IUnfollowUserRequest);

        /** UnfollowUserRequest userId. */
        public userId?: (string);

        /**
         * Gets the default type url for UnfollowUserRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_following_tag. */
export namespace mutation_following_tag {

    /** Properties of an UpdateNotOfficialFollowingTagRequest. */
    export interface IUpdateNotOfficialFollowingTagRequest {

        /** UpdateNotOfficialFollowingTagRequest tagList */
        tagList?: (string[]);
    }

    /** Represents an UpdateNotOfficialFollowingTagRequest. */
    class UpdateNotOfficialFollowingTagRequest implements IUpdateNotOfficialFollowingTagRequest {

        /**
         * Constructs a new UpdateNotOfficialFollowingTagRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_following_tag.IUpdateNotOfficialFollowingTagRequest);

        /** UpdateNotOfficialFollowingTagRequest tagList. */
        public tagList: string[];

        /**
         * Gets the default type url for UpdateNotOfficialFollowingTagRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_inspection. */
export namespace mutation_inspection {

    /** Properties of a ReportStoryRightViolationRequest. */
    export interface IReportStoryRightViolationRequest {

        /** ReportStoryRightViolationRequest storyId */
        storyId?: (string);

        /** ReportStoryRightViolationRequest contentType */
        contentType?: (mutation_inspection.ReportContentType);

        /** ReportStoryRightViolationRequest rightType */
        rightType?: (mutation_inspection.ReportRightType);

        /** ReportStoryRightViolationRequest isReporterRightViolated */
        isReporterRightViolated?: (boolean);

        /** ReportStoryRightViolationRequest evidenceText */
        evidenceText?: (string);

        /** ReportStoryRightViolationRequest evidenceFileList */
        evidenceFileList?: (string[]);

        /** ReportStoryRightViolationRequest name */
        name?: (string);

        /** ReportStoryRightViolationRequest allowNameDisclosure */
        allowNameDisclosure?: (boolean);

        /** ReportStoryRightViolationRequest emailAddress */
        emailAddress?: (string);

        /** ReportStoryRightViolationRequest phoneNumber */
        phoneNumber?: (string);

        /** ReportStoryRightViolationRequest rightCertificationFileList */
        rightCertificationFileList?: (string[]);

        /** ReportStoryRightViolationRequest evidenceTextByThirdParty */
        evidenceTextByThirdParty?: (string);

        /** ReportStoryRightViolationRequest evidenceFileListByThirdParty */
        evidenceFileListByThirdParty?: (string[]);
    }

    /** Represents a ReportStoryRightViolationRequest. */
    class ReportStoryRightViolationRequest implements IReportStoryRightViolationRequest {

        /**
         * Constructs a new ReportStoryRightViolationRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_inspection.IReportStoryRightViolationRequest);

        /** ReportStoryRightViolationRequest storyId. */
        public storyId?: (string);

        /** ReportStoryRightViolationRequest contentType. */
        public contentType: mutation_inspection.ReportContentType;

        /** ReportStoryRightViolationRequest rightType. */
        public rightType: mutation_inspection.ReportRightType;

        /** ReportStoryRightViolationRequest isReporterRightViolated. */
        public isReporterRightViolated?: (boolean);

        /** ReportStoryRightViolationRequest evidenceText. */
        public evidenceText?: (string);

        /** ReportStoryRightViolationRequest evidenceFileList. */
        public evidenceFileList: string[];

        /** ReportStoryRightViolationRequest name. */
        public name?: (string);

        /** ReportStoryRightViolationRequest allowNameDisclosure. */
        public allowNameDisclosure?: (boolean);

        /** ReportStoryRightViolationRequest emailAddress. */
        public emailAddress?: (string);

        /** ReportStoryRightViolationRequest phoneNumber. */
        public phoneNumber?: (string);

        /** ReportStoryRightViolationRequest rightCertificationFileList. */
        public rightCertificationFileList: string[];

        /** ReportStoryRightViolationRequest evidenceTextByThirdParty. */
        public evidenceTextByThirdParty?: (string);

        /** ReportStoryRightViolationRequest evidenceFileListByThirdParty. */
        public evidenceFileListByThirdParty: string[];

        /**
         * Gets the default type url for ReportStoryRightViolationRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ReportStoryViolationRequest. */
    export interface IReportStoryViolationRequest {

        /** ReportStoryViolationRequest storyId */
        storyId?: (string);

        /** ReportStoryViolationRequest type */
        type?: (types.ViolationType);

        /** ReportStoryViolationRequest message */
        message?: (string);
    }

    /** Represents a ReportStoryViolationRequest. */
    class ReportStoryViolationRequest implements IReportStoryViolationRequest {

        /**
         * Constructs a new ReportStoryViolationRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_inspection.IReportStoryViolationRequest);

        /** ReportStoryViolationRequest storyId. */
        public storyId?: (string);

        /** ReportStoryViolationRequest type. */
        public type: types.ViolationType;

        /** ReportStoryViolationRequest message. */
        public message?: (string);

        /**
         * Gets the default type url for ReportStoryViolationRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ReportCommentViolationRequest. */
    export interface IReportCommentViolationRequest {

        /** ReportCommentViolationRequest commentId */
        commentId?: (string);

        /** ReportCommentViolationRequest type */
        type?: (types.ViolationType);

        /** ReportCommentViolationRequest message */
        message?: (string);
    }

    /** Represents a ReportCommentViolationRequest. */
    class ReportCommentViolationRequest implements IReportCommentViolationRequest {

        /**
         * Constructs a new ReportCommentViolationRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_inspection.IReportCommentViolationRequest);

        /** ReportCommentViolationRequest commentId. */
        public commentId?: (string);

        /** ReportCommentViolationRequest type. */
        public type: types.ViolationType;

        /** ReportCommentViolationRequest message. */
        public message?: (string);

        /**
         * Gets the default type url for ReportCommentViolationRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ReportUserViolationRequest. */
    export interface IReportUserViolationRequest {

        /** ReportUserViolationRequest userId */
        userId?: (string);

        /** ReportUserViolationRequest type */
        type?: (types.ViolationType);

        /** ReportUserViolationRequest message */
        message?: (string);
    }

    /** Represents a ReportUserViolationRequest. */
    class ReportUserViolationRequest implements IReportUserViolationRequest {

        /**
         * Constructs a new ReportUserViolationRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_inspection.IReportUserViolationRequest);

        /** ReportUserViolationRequest userId. */
        public userId?: (string);

        /** ReportUserViolationRequest type. */
        public type: types.ViolationType;

        /** ReportUserViolationRequest message. */
        public message?: (string);

        /**
         * Gets the default type url for ReportUserViolationRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ReportSeriesRightViolationRequest. */
    export interface IReportSeriesRightViolationRequest {

        /** ReportSeriesRightViolationRequest seriesId */
        seriesId?: (string);

        /** ReportSeriesRightViolationRequest contentType */
        contentType?: (mutation_inspection.ReportContentType);

        /** ReportSeriesRightViolationRequest rightType */
        rightType?: (mutation_inspection.ReportRightType);

        /** ReportSeriesRightViolationRequest isReporterRightViolated */
        isReporterRightViolated?: (boolean);

        /** ReportSeriesRightViolationRequest evidenceText */
        evidenceText?: (string);

        /** ReportSeriesRightViolationRequest evidenceFileList */
        evidenceFileList?: (string[]);

        /** ReportSeriesRightViolationRequest name */
        name?: (string);

        /** ReportSeriesRightViolationRequest allowNameDisclosure */
        allowNameDisclosure?: (boolean);

        /** ReportSeriesRightViolationRequest emailAddress */
        emailAddress?: (string);

        /** ReportSeriesRightViolationRequest phoneNumber */
        phoneNumber?: (string);

        /** ReportSeriesRightViolationRequest rightCertificationFileList */
        rightCertificationFileList?: (string[]);

        /** ReportSeriesRightViolationRequest evidenceTextByThirdParty */
        evidenceTextByThirdParty?: (string);

        /** ReportSeriesRightViolationRequest evidenceFileListByThirdParty */
        evidenceFileListByThirdParty?: (string[]);
    }

    /** Represents a ReportSeriesRightViolationRequest. */
    class ReportSeriesRightViolationRequest implements IReportSeriesRightViolationRequest {

        /**
         * Constructs a new ReportSeriesRightViolationRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_inspection.IReportSeriesRightViolationRequest);

        /** ReportSeriesRightViolationRequest seriesId. */
        public seriesId?: (string);

        /** ReportSeriesRightViolationRequest contentType. */
        public contentType: mutation_inspection.ReportContentType;

        /** ReportSeriesRightViolationRequest rightType. */
        public rightType: mutation_inspection.ReportRightType;

        /** ReportSeriesRightViolationRequest isReporterRightViolated. */
        public isReporterRightViolated?: (boolean);

        /** ReportSeriesRightViolationRequest evidenceText. */
        public evidenceText?: (string);

        /** ReportSeriesRightViolationRequest evidenceFileList. */
        public evidenceFileList: string[];

        /** ReportSeriesRightViolationRequest name. */
        public name?: (string);

        /** ReportSeriesRightViolationRequest allowNameDisclosure. */
        public allowNameDisclosure?: (boolean);

        /** ReportSeriesRightViolationRequest emailAddress. */
        public emailAddress?: (string);

        /** ReportSeriesRightViolationRequest phoneNumber. */
        public phoneNumber?: (string);

        /** ReportSeriesRightViolationRequest rightCertificationFileList. */
        public rightCertificationFileList: string[];

        /** ReportSeriesRightViolationRequest evidenceTextByThirdParty. */
        public evidenceTextByThirdParty?: (string);

        /** ReportSeriesRightViolationRequest evidenceFileListByThirdParty. */
        public evidenceFileListByThirdParty: string[];

        /**
         * Gets the default type url for ReportSeriesRightViolationRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AcceptGuidelineRequest. */
    export interface IAcceptGuidelineRequest {

        /** AcceptGuidelineRequest seriesId */
        seriesId?: (string);
    }

    /** Represents an AcceptGuidelineRequest. */
    class AcceptGuidelineRequest implements IAcceptGuidelineRequest {

        /**
         * Constructs a new AcceptGuidelineRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_inspection.IAcceptGuidelineRequest);

        /** AcceptGuidelineRequest seriesId. */
        public seriesId?: (string);

        /**
         * Gets the default type url for AcceptGuidelineRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** ReportContentType enum. */
    export enum ReportContentType {
        CONTENT_TYPE_UNSPECIFIED = "CONTENT_TYPE_UNSPECIFIED",
        IMAGE = "IMAGE",
        TEXT = "TEXT"
    }

    /** ReportRightType enum. */
    export enum ReportRightType {
        RIGHT_TYPE_UNSPECIFIED = "RIGHT_TYPE_UNSPECIFIED",
        COPYRIGHT = "COPYRIGHT",
        PUBLICITY = "PUBLICITY",
        OTHER = "OTHER"
    }
}

/** Namespace mutation_payment. */
export namespace mutation_payment {

    /** Properties of a BuyOneTimeProductAppStoreRequest. */
    export interface IBuyOneTimeProductAppStoreRequest {

        /** BuyOneTimeProductAppStoreRequest productId */
        productId?: (string);

        /** BuyOneTimeProductAppStoreRequest transactionId */
        transactionId?: (string);
    }

    /** Represents a BuyOneTimeProductAppStoreRequest. */
    class BuyOneTimeProductAppStoreRequest implements IBuyOneTimeProductAppStoreRequest {

        /**
         * Constructs a new BuyOneTimeProductAppStoreRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_payment.IBuyOneTimeProductAppStoreRequest);

        /** BuyOneTimeProductAppStoreRequest productId. */
        public productId?: (string);

        /** BuyOneTimeProductAppStoreRequest transactionId. */
        public transactionId?: (string);

        /**
         * Gets the default type url for BuyOneTimeProductAppStoreRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BuyOneTimeProductGooglePlayRequest. */
    export interface IBuyOneTimeProductGooglePlayRequest {

        /** BuyOneTimeProductGooglePlayRequest productId */
        productId?: (string);

        /** BuyOneTimeProductGooglePlayRequest purchaseToken */
        purchaseToken?: (string);
    }

    /** Represents a BuyOneTimeProductGooglePlayRequest. */
    class BuyOneTimeProductGooglePlayRequest implements IBuyOneTimeProductGooglePlayRequest {

        /**
         * Constructs a new BuyOneTimeProductGooglePlayRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_payment.IBuyOneTimeProductGooglePlayRequest);

        /** BuyOneTimeProductGooglePlayRequest productId. */
        public productId?: (string);

        /** BuyOneTimeProductGooglePlayRequest purchaseToken. */
        public purchaseToken?: (string);

        /**
         * Gets the default type url for BuyOneTimeProductGooglePlayRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_profiling. */
export namespace mutation_profiling {

    /** Properties of an UpdateProfilingRequest. */
    export interface IUpdateProfilingRequest {

        /** UpdateProfilingRequest gender */
        gender?: (query_profiling.Gender);

        /** UpdateProfilingRequest birthday */
        birthday?: (string);

        /** UpdateProfilingRequest story */
        story?: (mutation_profiling.UpdateProfilingRequest.IStoryPreference);

        /** UpdateProfilingRequest tag */
        tag?: (mutation_profiling.UpdateProfilingRequest.ITagPreference);
    }

    /** Represents an UpdateProfilingRequest. */
    class UpdateProfilingRequest implements IUpdateProfilingRequest {

        /**
         * Constructs a new UpdateProfilingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_profiling.IUpdateProfilingRequest);

        /** UpdateProfilingRequest gender. */
        public gender: query_profiling.Gender;

        /** UpdateProfilingRequest birthday. */
        public birthday?: (string);

        /** UpdateProfilingRequest story. */
        public story?: (mutation_profiling.UpdateProfilingRequest.IStoryPreference);

        /** UpdateProfilingRequest tag. */
        public tag?: (mutation_profiling.UpdateProfilingRequest.ITagPreference);

        /**
         * Gets the default type url for UpdateProfilingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace UpdateProfilingRequest {

        /** Properties of a StoryPreference. */
        export interface IStoryPreference {

            /** StoryPreference questionList */
            questionList?: (string[]);

            /** StoryPreference answerPreferredList */
            answerPreferredList?: (string[]);
        }

        /** Represents a StoryPreference. */
        class StoryPreference implements IStoryPreference {

            /**
             * Constructs a new StoryPreference.
             * @param [properties] Properties to set
             */
            constructor(properties?: mutation_profiling.UpdateProfilingRequest.IStoryPreference);

            /** StoryPreference questionList. */
            public questionList: string[];

            /** StoryPreference answerPreferredList. */
            public answerPreferredList: string[];

            /**
             * Gets the default type url for StoryPreference
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a TagPreference. */
        export interface ITagPreference {

            /** TagPreference questionList */
            questionList?: (query_profiling.IProfilingTagPair[]);

            /** TagPreference answerPreferredList */
            answerPreferredList?: (query_profiling.IProfilingTagPair[]);
        }

        /** Represents a TagPreference. */
        class TagPreference implements ITagPreference {

            /**
             * Constructs a new TagPreference.
             * @param [properties] Properties to set
             */
            constructor(properties?: mutation_profiling.UpdateProfilingRequest.ITagPreference);

            /** TagPreference questionList. */
            public questionList: query_profiling.IProfilingTagPair[];

            /** TagPreference answerPreferredList. */
            public answerPreferredList: query_profiling.IProfilingTagPair[];

            /**
             * Gets the default type url for TagPreference
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of an UpdateUserPreferenceSensitiveRequest. */
    export interface IUpdateUserPreferenceSensitiveRequest {

        /** UpdateUserPreferenceSensitiveRequest showSensitive */
        showSensitive?: (user_preference_sensitive.ShowSensitive);
    }

    /** Represents an UpdateUserPreferenceSensitiveRequest. */
    class UpdateUserPreferenceSensitiveRequest implements IUpdateUserPreferenceSensitiveRequest {

        /**
         * Constructs a new UpdateUserPreferenceSensitiveRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_profiling.IUpdateUserPreferenceSensitiveRequest);

        /** UpdateUserPreferenceSensitiveRequest showSensitive. */
        public showSensitive: user_preference_sensitive.ShowSensitive;

        /**
         * Gets the default type url for UpdateUserPreferenceSensitiveRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_profiling. */
export namespace query_profiling {

    /** Properties of a ProfilingTagPair. */
    export interface IProfilingTagPair {

        /** ProfilingTagPair parent */
        parent?: (string);

        /** ProfilingTagPair child */
        child?: (string);
    }

    /** Represents a ProfilingTagPair. */
    class ProfilingTagPair implements IProfilingTagPair {

        /**
         * Constructs a new ProfilingTagPair.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_profiling.IProfilingTagPair);

        /** ProfilingTagPair parent. */
        public parent?: (string);

        /** ProfilingTagPair child. */
        public child?: (string);

        /**
         * Gets the default type url for ProfilingTagPair
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Gender enum. */
    export enum Gender {
        GENDER_UNSPECIFIED = "GENDER_UNSPECIFIED",
        GENDER_MALE = "GENDER_MALE",
        GENDER_FEMALE = "GENDER_FEMALE",
        GENDER_OTHER = "GENDER_OTHER"
    }
}

/** Namespace user_preference_sensitive. */
export namespace user_preference_sensitive {

    /** ShowSensitive enum. */
    export enum ShowSensitive {
        SHOW_SENSITIVE_UNSPECIFIED = "SHOW_SENSITIVE_UNSPECIFIED",
        SHOW_SENSITIVE_NOT_BLOCK_AND_LIST = "SHOW_SENSITIVE_NOT_BLOCK_AND_LIST",
        SHOW_SENSITIVE_BLOCK_AND_NOT_LIST = "SHOW_SENSITIVE_BLOCK_AND_NOT_LIST",
        SHOW_SENSITIVE_BLOCK_AND_LIST = "SHOW_SENSITIVE_BLOCK_AND_LIST"
    }
}

/** Namespace mutation_reading_progress. */
export namespace mutation_reading_progress {

    /** Properties of a DeleteStoryReadingProgressRequest. */
    export interface IDeleteStoryReadingProgressRequest {

        /** DeleteStoryReadingProgressRequest id */
        id?: (string);
    }

    /** Represents a DeleteStoryReadingProgressRequest. */
    class DeleteStoryReadingProgressRequest implements IDeleteStoryReadingProgressRequest {

        /**
         * Constructs a new DeleteStoryReadingProgressRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_reading_progress.IDeleteStoryReadingProgressRequest);

        /** DeleteStoryReadingProgressRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for DeleteStoryReadingProgressRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a DeleteSeriesReadingProgressRequest. */
    export interface IDeleteSeriesReadingProgressRequest {

        /** DeleteSeriesReadingProgressRequest id */
        id?: (string);
    }

    /** Represents a DeleteSeriesReadingProgressRequest. */
    class DeleteSeriesReadingProgressRequest implements IDeleteSeriesReadingProgressRequest {

        /**
         * Constructs a new DeleteSeriesReadingProgressRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_reading_progress.IDeleteSeriesReadingProgressRequest);

        /** DeleteSeriesReadingProgressRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for DeleteSeriesReadingProgressRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_resource. */
export namespace mutation_resource {

    /** Properties of a CreateImageRequest. */
    export interface ICreateImageRequest {

        /** CreateImageRequest gsPath */
        gsPath?: (string);

        /** CreateImageRequest format */
        format?: (resource.ImageFormat);

        /** CreateImageRequest width */
        width?: (number);

        /** CreateImageRequest height */
        height?: (number);

        /** CreateImageRequest category */
        category?: (resource.ImageCategory);
    }

    /** Represents a CreateImageRequest. */
    class CreateImageRequest implements ICreateImageRequest {

        /**
         * Constructs a new CreateImageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_resource.ICreateImageRequest);

        /** CreateImageRequest gsPath. */
        public gsPath?: (string);

        /** CreateImageRequest format. */
        public format: resource.ImageFormat;

        /** CreateImageRequest width. */
        public width?: (number);

        /** CreateImageRequest height. */
        public height?: (number);

        /** CreateImageRequest category. */
        public category: resource.ImageCategory;

        /**
         * Gets the default type url for CreateImageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreateImageResponse. */
    export interface ICreateImageResponse {

        /** CreateImageResponse image */
        image?: (resource.IImage);
    }

    /** Represents a CreateImageResponse. */
    class CreateImageResponse implements ICreateImageResponse {

        /**
         * Constructs a new CreateImageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_resource.ICreateImageResponse);

        /** CreateImageResponse image. */
        public image?: (resource.IImage);

        /**
         * Gets the default type url for CreateImageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a DeleteImageRequest. */
    export interface IDeleteImageRequest {

        /** DeleteImageRequest id */
        id?: (string);
    }

    /** Represents a DeleteImageRequest. */
    class DeleteImageRequest implements IDeleteImageRequest {

        /**
         * Constructs a new DeleteImageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_resource.IDeleteImageRequest);

        /** DeleteImageRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for DeleteImageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a HideImageRequest. */
    export interface IHideImageRequest {

        /** HideImageRequest id */
        id?: (string);
    }

    /** Represents a HideImageRequest. */
    class HideImageRequest implements IHideImageRequest {

        /**
         * Constructs a new HideImageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_resource.IHideImageRequest);

        /** HideImageRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for HideImageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_series. */
export namespace mutation_series {

    /** Properties of a CreateSeriesRequest. */
    export interface ICreateSeriesRequest {

        /** CreateSeriesRequest userId */
        userId?: (string);

        /** CreateSeriesRequest tags */
        tags?: (string[]);

        /** CreateSeriesRequest title */
        title?: (string);

        /** CreateSeriesRequest description */
        description?: (string);

        /** CreateSeriesRequest thumbnailId */
        thumbnailId?: (string);

        /** CreateSeriesRequest isCompleted */
        isCompleted?: (boolean);

        /** CreateSeriesRequest nextPublishAt */
        nextPublishAt?: (string);

        /** CreateSeriesRequest sharedWithStatus */
        sharedWithStatus?: (types.SharedWithStatus);

        /** CreateSeriesRequest isOneshot */
        isOneshot?: (boolean);

        /** CreateSeriesRequest genre */
        genre?: (query_types.Genre);
    }

    /** Represents a CreateSeriesRequest. */
    class CreateSeriesRequest implements ICreateSeriesRequest {

        /**
         * Constructs a new CreateSeriesRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_series.ICreateSeriesRequest);

        /** CreateSeriesRequest userId. */
        public userId?: (string);

        /** CreateSeriesRequest tags. */
        public tags: string[];

        /** CreateSeriesRequest title. */
        public title?: (string);

        /** CreateSeriesRequest description. */
        public description?: (string);

        /** CreateSeriesRequest thumbnailId. */
        public thumbnailId?: (string);

        /** CreateSeriesRequest isCompleted. */
        public isCompleted?: (boolean);

        /** CreateSeriesRequest nextPublishAt. */
        public nextPublishAt?: (string);

        /** CreateSeriesRequest sharedWithStatus. */
        public sharedWithStatus: types.SharedWithStatus;

        /** CreateSeriesRequest isOneshot. */
        public isOneshot?: (boolean);

        /** CreateSeriesRequest genre. */
        public genre: query_types.Genre;

        /**
         * Gets the default type url for CreateSeriesRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreateSeriesResponse. */
    export interface ICreateSeriesResponse {

        /** CreateSeriesResponse id */
        id?: (string);
    }

    /** Represents a CreateSeriesResponse. */
    class CreateSeriesResponse implements ICreateSeriesResponse {

        /**
         * Constructs a new CreateSeriesResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_series.ICreateSeriesResponse);

        /** CreateSeriesResponse id. */
        public id?: (string);

        /**
         * Gets the default type url for CreateSeriesResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an UpdateSeriesRequest. */
    export interface IUpdateSeriesRequest {

        /** UpdateSeriesRequest id */
        id?: (string);

        /** UpdateSeriesRequest userId */
        userId?: (string);

        /** UpdateSeriesRequest tags */
        tags?: (string[]);

        /** UpdateSeriesRequest title */
        title?: (string);

        /** UpdateSeriesRequest description */
        description?: (string);

        /** UpdateSeriesRequest thumbnailId */
        thumbnailId?: (string);

        /** UpdateSeriesRequest isCompleted */
        isCompleted?: (boolean);

        /** UpdateSeriesRequest nextPublishAt */
        nextPublishAt?: (string);

        /** UpdateSeriesRequest sharedWithStatus */
        sharedWithStatus?: (types.SharedWithStatus);

        /** UpdateSeriesRequest isOneshot */
        isOneshot?: (boolean);

        /** UpdateSeriesRequest genre */
        genre?: (query_types.Genre);
    }

    /** Represents an UpdateSeriesRequest. */
    class UpdateSeriesRequest implements IUpdateSeriesRequest {

        /**
         * Constructs a new UpdateSeriesRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_series.IUpdateSeriesRequest);

        /** UpdateSeriesRequest id. */
        public id?: (string);

        /** UpdateSeriesRequest userId. */
        public userId?: (string);

        /** UpdateSeriesRequest tags. */
        public tags: string[];

        /** UpdateSeriesRequest title. */
        public title?: (string);

        /** UpdateSeriesRequest description. */
        public description?: (string);

        /** UpdateSeriesRequest thumbnailId. */
        public thumbnailId?: (string);

        /** UpdateSeriesRequest isCompleted. */
        public isCompleted?: (boolean);

        /** UpdateSeriesRequest nextPublishAt. */
        public nextPublishAt?: (string);

        /** UpdateSeriesRequest sharedWithStatus. */
        public sharedWithStatus: types.SharedWithStatus;

        /** UpdateSeriesRequest isOneshot. */
        public isOneshot?: (boolean);

        /** UpdateSeriesRequest genre. */
        public genre: query_types.Genre;

        /**
         * Gets the default type url for UpdateSeriesRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an UpdateSeriesResponse. */
    export interface IUpdateSeriesResponse {

        /** UpdateSeriesResponse id */
        id?: (string);
    }

    /** Represents an UpdateSeriesResponse. */
    class UpdateSeriesResponse implements IUpdateSeriesResponse {

        /**
         * Constructs a new UpdateSeriesResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_series.IUpdateSeriesResponse);

        /** UpdateSeriesResponse id. */
        public id?: (string);

        /**
         * Gets the default type url for UpdateSeriesResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a DeleteSeriesRequest. */
    export interface IDeleteSeriesRequest {

        /** DeleteSeriesRequest id */
        id?: (string);

        /** DeleteSeriesRequest deleteStory */
        deleteStory?: (boolean);
    }

    /** Represents a DeleteSeriesRequest. */
    class DeleteSeriesRequest implements IDeleteSeriesRequest {

        /**
         * Constructs a new DeleteSeriesRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_series.IDeleteSeriesRequest);

        /** DeleteSeriesRequest id. */
        public id?: (string);

        /** DeleteSeriesRequest deleteStory. */
        public deleteStory?: (boolean);

        /**
         * Gets the default type url for DeleteSeriesRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an UpdateSeriesStoryOrderRequest. */
    export interface IUpdateSeriesStoryOrderRequest {

        /** UpdateSeriesStoryOrderRequest id */
        id?: (string);

        /** UpdateSeriesStoryOrderRequest orderedStoryIdList */
        orderedStoryIdList?: (string[]);
    }

    /** Represents an UpdateSeriesStoryOrderRequest. */
    class UpdateSeriesStoryOrderRequest implements IUpdateSeriesStoryOrderRequest {

        /**
         * Constructs a new UpdateSeriesStoryOrderRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_series.IUpdateSeriesStoryOrderRequest);

        /** UpdateSeriesStoryOrderRequest id. */
        public id?: (string);

        /** UpdateSeriesStoryOrderRequest orderedStoryIdList. */
        public orderedStoryIdList: string[];

        /**
         * Gets the default type url for UpdateSeriesStoryOrderRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_series_charge. */
export namespace mutation_series_charge {

    /** Properties of a UseSeriesChargeRequest. */
    export interface IUseSeriesChargeRequest {

        /** UseSeriesChargeRequest storyId */
        storyId?: (string);

        /** UseSeriesChargeRequest requireSeriesCharge */
        requireSeriesCharge?: (query_series_charge.ISeriesChargeRequireField);
    }

    /** Represents a UseSeriesChargeRequest. */
    class UseSeriesChargeRequest implements IUseSeriesChargeRequest {

        /**
         * Constructs a new UseSeriesChargeRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_series_charge.IUseSeriesChargeRequest);

        /** UseSeriesChargeRequest storyId. */
        public storyId?: (string);

        /** UseSeriesChargeRequest requireSeriesCharge. */
        public requireSeriesCharge?: (query_series_charge.ISeriesChargeRequireField);

        /**
         * Gets the default type url for UseSeriesChargeRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UseSeriesChargeResponse. */
    export interface IUseSeriesChargeResponse {

        /** UseSeriesChargeResponse seriesCharge */
        seriesCharge?: (query_series_charge.ISeriesChargeResponse);
    }

    /** Represents a UseSeriesChargeResponse. */
    class UseSeriesChargeResponse implements IUseSeriesChargeResponse {

        /**
         * Constructs a new UseSeriesChargeResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_series_charge.IUseSeriesChargeResponse);

        /** UseSeriesChargeResponse seriesCharge. */
        public seriesCharge?: (query_series_charge.ISeriesChargeResponse);

        /**
         * Gets the default type url for UseSeriesChargeResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_series_charge. */
export namespace query_series_charge {

    /** Properties of a SeriesChargeRequireField. */
    export interface ISeriesChargeRequireField {

        /** SeriesChargeRequireField requireIsAvailable */
        requireIsAvailable?: (boolean);

        /** SeriesChargeRequireField requireNextAvailableAt */
        requireNextAvailableAt?: (boolean);
    }

    /** Represents a SeriesChargeRequireField. */
    class SeriesChargeRequireField implements ISeriesChargeRequireField {

        /**
         * Constructs a new SeriesChargeRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_series_charge.ISeriesChargeRequireField);

        /** SeriesChargeRequireField requireIsAvailable. */
        public requireIsAvailable?: (boolean);

        /** SeriesChargeRequireField requireNextAvailableAt. */
        public requireNextAvailableAt?: (boolean);

        /**
         * Gets the default type url for SeriesChargeRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesChargeResponse. */
    export interface ISeriesChargeResponse {

        /** SeriesChargeResponse isAvailable */
        isAvailable?: (boolean);

        /** SeriesChargeResponse nextAvailableAt */
        nextAvailableAt?: (string);
    }

    /** Represents a SeriesChargeResponse. */
    class SeriesChargeResponse implements ISeriesChargeResponse {

        /**
         * Constructs a new SeriesChargeResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_series_charge.ISeriesChargeResponse);

        /** SeriesChargeResponse isAvailable. */
        public isAvailable?: (boolean);

        /** SeriesChargeResponse nextAvailableAt. */
        public nextAvailableAt?: (string);

        /**
         * Gets the default type url for SeriesChargeResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_ticket. */
export namespace mutation_ticket {

    /** Properties of a UseTicketRequest. */
    export interface IUseTicketRequest {

        /** UseTicketRequest storyId */
        storyId?: (string);

        /** UseTicketRequest ticketId */
        ticketId?: (string);

        /** UseTicketRequest requireTicketCount */
        requireTicketCount?: (boolean);

        /** UseTicketRequest requireSeriesCharge */
        requireSeriesCharge?: (query_series_charge.ISeriesChargeRequireField);
    }

    /** Represents a UseTicketRequest. */
    class UseTicketRequest implements IUseTicketRequest {

        /**
         * Constructs a new UseTicketRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_ticket.IUseTicketRequest);

        /** UseTicketRequest storyId. */
        public storyId?: (string);

        /** UseTicketRequest ticketId. */
        public ticketId?: (string);

        /** UseTicketRequest requireTicketCount. */
        public requireTicketCount?: (boolean);

        /** UseTicketRequest requireSeriesCharge. */
        public requireSeriesCharge?: (query_series_charge.ISeriesChargeRequireField);

        /**
         * Gets the default type url for UseTicketRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UseTicketResponse. */
    export interface IUseTicketResponse {

        /** UseTicketResponse ticketCount */
        ticketCount?: (number);

        /** UseTicketResponse seriesCharge */
        seriesCharge?: (query_series_charge.ISeriesChargeResponse);
    }

    /** Represents a UseTicketResponse. */
    class UseTicketResponse implements IUseTicketResponse {

        /**
         * Constructs a new UseTicketResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_ticket.IUseTicketResponse);

        /** UseTicketResponse ticketCount. */
        public ticketCount?: (number);

        /** UseTicketResponse seriesCharge. */
        public seriesCharge?: (query_series_charge.ISeriesChargeResponse);

        /**
         * Gets the default type url for UseTicketResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_story. */
export namespace mutation_story {

    /** Properties of a CreateStoryRequest. */
    export interface ICreateStoryRequest {

        /** CreateStoryRequest userId */
        userId?: (string);

        /** CreateStoryRequest tags */
        tags?: (string[]);

        /** CreateStoryRequest seriesId */
        seriesId?: (string);

        /** CreateStoryRequest seriesIndex */
        seriesIndex?: (number);

        /** CreateStoryRequest title */
        title?: (string);

        /** CreateStoryRequest description */
        description?: (string);

        /** CreateStoryRequest thumbnailId */
        thumbnailId?: (string);

        /** CreateStoryRequest status */
        status?: (story.StoryStatus);

        /** CreateStoryRequest scriptType */
        scriptType?: (query_types.StoryScriptType);

        /** CreateStoryRequest chatNovelScript */
        chatNovelScript?: (tellerscript.ITellerScript);

        /** CreateStoryRequest novelScript */
        novelScript?: (string);

        /** CreateStoryRequest sensitiveFlag */
        sensitiveFlag?: (query_types.SensitiveFlag);
    }

    /** Represents a CreateStoryRequest. */
    class CreateStoryRequest implements ICreateStoryRequest {

        /**
         * Constructs a new CreateStoryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story.ICreateStoryRequest);

        /** CreateStoryRequest userId. */
        public userId?: (string);

        /** CreateStoryRequest tags. */
        public tags: string[];

        /** CreateStoryRequest seriesId. */
        public seriesId?: (string);

        /** CreateStoryRequest seriesIndex. */
        public seriesIndex?: (number);

        /** CreateStoryRequest title. */
        public title?: (string);

        /** CreateStoryRequest description. */
        public description?: (string);

        /** CreateStoryRequest thumbnailId. */
        public thumbnailId?: (string);

        /** CreateStoryRequest status. */
        public status: story.StoryStatus;

        /** CreateStoryRequest scriptType. */
        public scriptType: query_types.StoryScriptType;

        /** CreateStoryRequest chatNovelScript. */
        public chatNovelScript?: (tellerscript.ITellerScript);

        /** CreateStoryRequest novelScript. */
        public novelScript?: (string);

        /** CreateStoryRequest sensitiveFlag. */
        public sensitiveFlag: query_types.SensitiveFlag;

        /** CreateStoryRequest script. */
        public script?: ("chatNovelScript"|"novelScript");

        /**
         * Gets the default type url for CreateStoryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreateStoryResponse. */
    export interface ICreateStoryResponse {

        /** CreateStoryResponse id */
        id?: (string);
    }

    /** Represents a CreateStoryResponse. */
    class CreateStoryResponse implements ICreateStoryResponse {

        /**
         * Constructs a new CreateStoryResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story.ICreateStoryResponse);

        /** CreateStoryResponse id. */
        public id?: (string);

        /**
         * Gets the default type url for CreateStoryResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an UpdateStoryRequest. */
    export interface IUpdateStoryRequest {

        /** UpdateStoryRequest id */
        id?: (string);

        /** UpdateStoryRequest userId */
        userId?: (string);

        /** UpdateStoryRequest tags */
        tags?: (string[]);

        /** UpdateStoryRequest seriesId */
        seriesId?: (string);

        /** UpdateStoryRequest seriesIndex */
        seriesIndex?: (number);

        /** UpdateStoryRequest title */
        title?: (string);

        /** UpdateStoryRequest description */
        description?: (string);

        /** UpdateStoryRequest thumbnailId */
        thumbnailId?: (string);

        /** UpdateStoryRequest status */
        status?: (story.StoryStatus);

        /** UpdateStoryRequest scriptType */
        scriptType?: (query_types.StoryScriptType);

        /** UpdateStoryRequest chatNovelScript */
        chatNovelScript?: (tellerscript.ITellerScript);

        /** UpdateStoryRequest novelScript */
        novelScript?: (string);

        /** UpdateStoryRequest sensitiveFlag */
        sensitiveFlag?: (query_types.SensitiveFlag);
    }

    /** Represents an UpdateStoryRequest. */
    class UpdateStoryRequest implements IUpdateStoryRequest {

        /**
         * Constructs a new UpdateStoryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story.IUpdateStoryRequest);

        /** UpdateStoryRequest id. */
        public id?: (string);

        /** UpdateStoryRequest userId. */
        public userId?: (string);

        /** UpdateStoryRequest tags. */
        public tags: string[];

        /** UpdateStoryRequest seriesId. */
        public seriesId?: (string);

        /** UpdateStoryRequest seriesIndex. */
        public seriesIndex?: (number);

        /** UpdateStoryRequest title. */
        public title?: (string);

        /** UpdateStoryRequest description. */
        public description?: (string);

        /** UpdateStoryRequest thumbnailId. */
        public thumbnailId?: (string);

        /** UpdateStoryRequest status. */
        public status: story.StoryStatus;

        /** UpdateStoryRequest scriptType. */
        public scriptType: query_types.StoryScriptType;

        /** UpdateStoryRequest chatNovelScript. */
        public chatNovelScript?: (tellerscript.ITellerScript);

        /** UpdateStoryRequest novelScript. */
        public novelScript?: (string);

        /** UpdateStoryRequest sensitiveFlag. */
        public sensitiveFlag: query_types.SensitiveFlag;

        /** UpdateStoryRequest script. */
        public script?: ("chatNovelScript"|"novelScript");

        /**
         * Gets the default type url for UpdateStoryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an UpdateStoryResponse. */
    export interface IUpdateStoryResponse {

        /** UpdateStoryResponse id */
        id?: (string);
    }

    /** Represents an UpdateStoryResponse. */
    class UpdateStoryResponse implements IUpdateStoryResponse {

        /**
         * Constructs a new UpdateStoryResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story.IUpdateStoryResponse);

        /** UpdateStoryResponse id. */
        public id?: (string);

        /**
         * Gets the default type url for UpdateStoryResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a DeleteStoryRequest. */
    export interface IDeleteStoryRequest {

        /** DeleteStoryRequest id */
        id?: (string);
    }

    /** Represents a DeleteStoryRequest. */
    class DeleteStoryRequest implements IDeleteStoryRequest {

        /**
         * Constructs a new DeleteStoryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story.IDeleteStoryRequest);

        /** DeleteStoryRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for DeleteStoryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_story_video. */
export namespace mutation_story_video {

    /** Properties of a CreateStoryVideoAsyncRequest. */
    export interface ICreateStoryVideoAsyncRequest {

        /** CreateStoryVideoAsyncRequest storyId */
        storyId?: (string);
    }

    /** Represents a CreateStoryVideoAsyncRequest. */
    class CreateStoryVideoAsyncRequest implements ICreateStoryVideoAsyncRequest {

        /**
         * Constructs a new CreateStoryVideoAsyncRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story_video.ICreateStoryVideoAsyncRequest);

        /** CreateStoryVideoAsyncRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for CreateStoryVideoAsyncRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreateStoryVideoAsyncResponse. */
    export interface ICreateStoryVideoAsyncResponse {
    }

    /** Represents a CreateStoryVideoAsyncResponse. */
    class CreateStoryVideoAsyncResponse implements ICreateStoryVideoAsyncResponse {

        /**
         * Constructs a new CreateStoryVideoAsyncResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story_video.ICreateStoryVideoAsyncResponse);

        /**
         * Gets the default type url for CreateStoryVideoAsyncResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace mutation_story_license. */
export namespace mutation_story_license {

    /** Properties of an AcquireFullStoryRequest. */
    export interface IAcquireFullStoryRequest {

        /** AcquireFullStoryRequest storyId */
        storyId?: (string);
    }

    /** Represents an AcquireFullStoryRequest. */
    class AcquireFullStoryRequest implements IAcquireFullStoryRequest {

        /**
         * Constructs a new AcquireFullStoryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story_license.IAcquireFullStoryRequest);

        /** AcquireFullStoryRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for AcquireFullStoryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AcquireFullStoryResponse. */
    export interface IAcquireFullStoryResponse {

        /** AcquireFullStoryResponse availability */
        availability?: (query_types.StoryAvailability);

        /** AcquireFullStoryResponse result */
        result?: (mutation_story_license.AcquireFullStoryResponse.Result);
    }

    /** Represents an AcquireFullStoryResponse. */
    class AcquireFullStoryResponse implements IAcquireFullStoryResponse {

        /**
         * Constructs a new AcquireFullStoryResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story_license.IAcquireFullStoryResponse);

        /** AcquireFullStoryResponse availability. */
        public availability: query_types.StoryAvailability;

        /** AcquireFullStoryResponse result. */
        public result: mutation_story_license.AcquireFullStoryResponse.Result;

        /**
         * Gets the default type url for AcquireFullStoryResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace AcquireFullStoryResponse {

        /** Result enum. */
        export enum Result {
            RESULT_UNSPECIFIED = "RESULT_UNSPECIFIED",
            RESULT_REQUIRE_NONE = "RESULT_REQUIRE_NONE",
            RESULT_REQUIRE_VIP = "RESULT_REQUIRE_VIP",
            RESULT_SHORTAGE = "RESULT_SHORTAGE",
            RESULT_SERIES_CHARGE_USED = "RESULT_SERIES_CHARGE_USED",
            RESULT_TICKET_USED = "RESULT_TICKET_USED",
            RESULT_REQUIRE_FOLLOWING = "RESULT_REQUIRE_FOLLOWING",
            RESULT_REQUIRE_MUTUAL_FOLLOWING = "RESULT_REQUIRE_MUTUAL_FOLLOWING"
        }
    }

    /** Properties of an AcquireRentalStoryLicenseBySeriesChargeRequest. */
    export interface IAcquireRentalStoryLicenseBySeriesChargeRequest {

        /** AcquireRentalStoryLicenseBySeriesChargeRequest storyId */
        storyId?: (string);
    }

    /** Represents an AcquireRentalStoryLicenseBySeriesChargeRequest. */
    class AcquireRentalStoryLicenseBySeriesChargeRequest implements IAcquireRentalStoryLicenseBySeriesChargeRequest {

        /**
         * Constructs a new AcquireRentalStoryLicenseBySeriesChargeRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story_license.IAcquireRentalStoryLicenseBySeriesChargeRequest);

        /** AcquireRentalStoryLicenseBySeriesChargeRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for AcquireRentalStoryLicenseBySeriesChargeRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AcquireRentalStoryLicenseByTicketRequest. */
    export interface IAcquireRentalStoryLicenseByTicketRequest {

        /** AcquireRentalStoryLicenseByTicketRequest storyId */
        storyId?: (string);
    }

    /** Represents an AcquireRentalStoryLicenseByTicketRequest. */
    class AcquireRentalStoryLicenseByTicketRequest implements IAcquireRentalStoryLicenseByTicketRequest {

        /**
         * Constructs a new AcquireRentalStoryLicenseByTicketRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story_license.IAcquireRentalStoryLicenseByTicketRequest);

        /** AcquireRentalStoryLicenseByTicketRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for AcquireRentalStoryLicenseByTicketRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AcquireStoryLicenseByCoinRequest. */
    export interface IAcquireStoryLicenseByCoinRequest {

        /** AcquireStoryLicenseByCoinRequest storyId */
        storyId?: (string);
    }

    /** Represents an AcquireStoryLicenseByCoinRequest. */
    class AcquireStoryLicenseByCoinRequest implements IAcquireStoryLicenseByCoinRequest {

        /**
         * Constructs a new AcquireStoryLicenseByCoinRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story_license.IAcquireStoryLicenseByCoinRequest);

        /** AcquireStoryLicenseByCoinRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for AcquireStoryLicenseByCoinRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AcquireStoryLicenseResponse. */
    export interface IAcquireStoryLicenseResponse {

        /** AcquireStoryLicenseResponse result */
        result?: (mutation_story_license.AcquireStoryLicenseResponse.Result);
    }

    /** Represents an AcquireStoryLicenseResponse. */
    class AcquireStoryLicenseResponse implements IAcquireStoryLicenseResponse {

        /**
         * Constructs a new AcquireStoryLicenseResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_story_license.IAcquireStoryLicenseResponse);

        /** AcquireStoryLicenseResponse result. */
        public result: mutation_story_license.AcquireStoryLicenseResponse.Result;

        /**
         * Gets the default type url for AcquireStoryLicenseResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace AcquireStoryLicenseResponse {

        /** Result enum. */
        export enum Result {
            RESULT_UNSPECIFIED = "RESULT_UNSPECIFIED",
            RESULT_SUCCEEDED = "RESULT_SUCCEEDED",
            RESULT_ERROR_SERIES_CHARGE_UNAVAILABLE = "RESULT_ERROR_SERIES_CHARGE_UNAVAILABLE",
            RESULT_ERROR_IN_APP_CURRENCY_SHORTAGE = "RESULT_ERROR_IN_APP_CURRENCY_SHORTAGE",
            RESULT_ERROR_TICKET_SHORTAGE = "RESULT_ERROR_TICKET_SHORTAGE"
        }
    }
}

/** Namespace mutation_user. */
export namespace mutation_user {

    /** Properties of a DeleteMeRequest. */
    export interface IDeleteMeRequest {
    }

    /** Represents a DeleteMeRequest. */
    class DeleteMeRequest implements IDeleteMeRequest {

        /**
         * Constructs a new DeleteMeRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: mutation_user.IDeleteMeRequest);

        /**
         * Gets the default type url for DeleteMeRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace payment. */
export namespace payment {

    /** Properties of a PaymentSubscription. */
    export interface IPaymentSubscription {

        /** PaymentSubscription id */
        id?: (string);

        /** PaymentSubscription userId */
        userId?: (string);

        /** PaymentSubscription paymentPlatform */
        paymentPlatform?: (payment.PaymentPlatform);

        /** PaymentSubscription product */
        product?: (payment.IPaymentProduct);

        /** PaymentSubscription startedAt */
        startedAt?: (string);

        /** PaymentSubscription expiresAt */
        expiresAt?: (string);

        /** PaymentSubscription resumeAt */
        resumeAt?: (string);

        /** PaymentSubscription isAutoRenewable */
        isAutoRenewable?: (boolean);

        /** PaymentSubscription isTrial */
        isTrial?: (boolean);

        /** PaymentSubscription isPending */
        isPending?: (boolean);

        /** PaymentSubscription chargeFailedAt */
        chargeFailedAt?: (string);

        /** PaymentSubscription nextChargeAt */
        nextChargeAt?: (string);

        /** PaymentSubscription promotionOffer */
        promotionOffer?: (payment.PaymentPromotionOffer);

        /** PaymentSubscription updatedAt */
        updatedAt?: (string);
    }

    /** Represents a PaymentSubscription. */
    class PaymentSubscription implements IPaymentSubscription {

        /**
         * Constructs a new PaymentSubscription.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentSubscription);

        /** PaymentSubscription id. */
        public id?: (string);

        /** PaymentSubscription userId. */
        public userId?: (string);

        /** PaymentSubscription paymentPlatform. */
        public paymentPlatform: payment.PaymentPlatform;

        /** PaymentSubscription product. */
        public product?: (payment.IPaymentProduct);

        /** PaymentSubscription startedAt. */
        public startedAt?: (string);

        /** PaymentSubscription expiresAt. */
        public expiresAt?: (string);

        /** PaymentSubscription resumeAt. */
        public resumeAt?: (string);

        /** PaymentSubscription isAutoRenewable. */
        public isAutoRenewable?: (boolean);

        /** PaymentSubscription isTrial. */
        public isTrial?: (boolean);

        /** PaymentSubscription isPending. */
        public isPending?: (boolean);

        /** PaymentSubscription chargeFailedAt. */
        public chargeFailedAt?: (string);

        /** PaymentSubscription nextChargeAt. */
        public nextChargeAt?: (string);

        /** PaymentSubscription promotionOffer. */
        public promotionOffer: payment.PaymentPromotionOffer;

        /** PaymentSubscription updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for PaymentSubscription
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** PaymentPromotionOffer enum. */
    export enum PaymentPromotionOffer {
        PAYMENT_PROMOTION_OFFER_UNSPECIFIED = "PAYMENT_PROMOTION_OFFER_UNSPECIFIED",
        PAYMENT_PROMOTION_OFFER_NONE = "PAYMENT_PROMOTION_OFFER_NONE",
        PAYMENT_PROMOTION_OFFER_6_MONTH_DISCOUNT = "_MONTH_DISCOUNT",
        PAYMENT_PROMOTION_OFFER_2_MONTH_FREE = "_MONTH_FREE",
        PAYMENT_PROMOTION_OFFER_NEW_YEAR_CAMPAIGN = "PAYMENT_PROMOTION_OFFER_NEW_YEAR_CAMPAIGN",
        PAYMENT_PROMOTION_OFFER_FREE_THREE_DAY_FOR_A_YEAR = "PAYMENT_PROMOTION_OFFER_FREE_THREE_DAY_FOR_A_YEAR"
    }

    /** PaymentPlatform enum. */
    export enum PaymentPlatform {
        PAYMENT_PLATFORM_UNSPECIFIED = "PAYMENT_PLATFORM_UNSPECIFIED",
        PAYMENT_PLATFORM_TELLER = "PAYMENT_PLATFORM_TELLER",
        PAYMENT_PLATFORM_IOS = "PAYMENT_PLATFORM_IOS",
        PAYMENT_PLATFORM_ANDROID = "PAYMENT_PLATFORM_ANDROID",
        PAYMENT_PLATFORM_CREDIT_CARD = "PAYMENT_PLATFORM_CREDIT_CARD",
        PAYMENT_PLATFORM_DOCOMO = "PAYMENT_PLATFORM_DOCOMO",
        PAYMENT_PLATFORM_AU = "PAYMENT_PLATFORM_AU",
        PAYMENT_PLATFORM_SOFTBANK = "PAYMENT_PLATFORM_SOFTBANK",
        PAYMENT_PLATFORM_STRIPE = "PAYMENT_PLATFORM_STRIPE"
    }

    /** Properties of a PaymentProduct. */
    export interface IPaymentProduct {

        /** PaymentProduct id */
        id?: (string);

        /** PaymentProduct name */
        name?: (string);

        /** PaymentProduct platform */
        platform?: (payment.PaymentPlatform);

        /** PaymentProduct trialDays */
        trialDays?: (number);

        /** PaymentProduct price */
        price?: (number);

        /** PaymentProduct termByMonth */
        termByMonth?: (number);
    }

    /** Represents a PaymentProduct. */
    class PaymentProduct implements IPaymentProduct {

        /**
         * Constructs a new PaymentProduct.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentProduct);

        /** PaymentProduct id. */
        public id?: (string);

        /** PaymentProduct name. */
        public name?: (string);

        /** PaymentProduct platform. */
        public platform: payment.PaymentPlatform;

        /** PaymentProduct trialDays. */
        public trialDays?: (number);

        /** PaymentProduct price. */
        public price?: (number);

        /** PaymentProduct termByMonth. */
        public termByMonth?: (number);

        /**
         * Gets the default type url for PaymentProduct
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreditCard. */
    export interface ICreditCard {

        /** CreditCard id */
        id?: (string);

        /** CreditCard number */
        number?: (string);

        /** CreditCard expiration */
        expiration?: (payment.ICreditCardExpiration);
    }

    /** Represents a CreditCard. */
    class CreditCard implements ICreditCard {

        /**
         * Constructs a new CreditCard.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.ICreditCard);

        /** CreditCard id. */
        public id?: (string);

        /** CreditCard number. */
        public number?: (string);

        /** CreditCard expiration. */
        public expiration?: (payment.ICreditCardExpiration);

        /**
         * Gets the default type url for CreditCard
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreditCardExpiration. */
    export interface ICreditCardExpiration {

        /** CreditCardExpiration month */
        month?: (string);

        /** CreditCardExpiration year */
        year?: (string);
    }

    /** Represents a CreditCardExpiration. */
    class CreditCardExpiration implements ICreditCardExpiration {

        /**
         * Constructs a new CreditCardExpiration.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.ICreditCardExpiration);

        /** CreditCardExpiration month. */
        public month?: (string);

        /** CreditCardExpiration year. */
        public year?: (string);

        /**
         * Gets the default type url for CreditCardExpiration
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** PauseTerm enum. */
    export enum PauseTerm {
        CREDIT_CARD_PAYMENT_PAUSE_ONE_MONTH = "CREDIT_CARD_PAYMENT_PAUSE_ONE_MONTH",
        CREDIT_CARD_PAYMENT_PAUSE_TWO_MONTH = "CREDIT_CARD_PAYMENT_PAUSE_TWO_MONTH",
        CREDIT_CARD_PAYMENT_PAUSE_THREE_MONTH = "CREDIT_CARD_PAYMENT_PAUSE_THREE_MONTH",
        CREDIT_CARD_PAYMENT_PAUSE_FOUR_MONTH = "CREDIT_CARD_PAYMENT_PAUSE_FOUR_MONTH",
        CREDIT_CARD_PAYMENT_PAUSE_FIVE_MONTH = "CREDIT_CARD_PAYMENT_PAUSE_FIVE_MONTH",
        CREDIT_CARD_PAYMENT_PAUSE_SIX_MONTH = "CREDIT_CARD_PAYMENT_PAUSE_SIX_MONTH"
    }

    /** Properties of a CreditCardPaymentActivateSubscriptionRequest. */
    export interface ICreditCardPaymentActivateSubscriptionRequest {

        /** CreditCardPaymentActivateSubscriptionRequest productId */
        productId?: (string);

        /** CreditCardPaymentActivateSubscriptionRequest cardToken */
        cardToken?: (string);
    }

    /** Represents a CreditCardPaymentActivateSubscriptionRequest. */
    class CreditCardPaymentActivateSubscriptionRequest implements ICreditCardPaymentActivateSubscriptionRequest {

        /**
         * Constructs a new CreditCardPaymentActivateSubscriptionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.ICreditCardPaymentActivateSubscriptionRequest);

        /** CreditCardPaymentActivateSubscriptionRequest productId. */
        public productId?: (string);

        /** CreditCardPaymentActivateSubscriptionRequest cardToken. */
        public cardToken?: (string);

        /**
         * Gets the default type url for CreditCardPaymentActivateSubscriptionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreditCardPaymentCancelSubscriptionRequest. */
    export interface ICreditCardPaymentCancelSubscriptionRequest {

        /** CreditCardPaymentCancelSubscriptionRequest productId */
        productId?: (string);

        /** CreditCardPaymentCancelSubscriptionRequest deleteCreditCard */
        deleteCreditCard?: (boolean);
    }

    /** Represents a CreditCardPaymentCancelSubscriptionRequest. */
    class CreditCardPaymentCancelSubscriptionRequest implements ICreditCardPaymentCancelSubscriptionRequest {

        /**
         * Constructs a new CreditCardPaymentCancelSubscriptionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.ICreditCardPaymentCancelSubscriptionRequest);

        /** CreditCardPaymentCancelSubscriptionRequest productId. */
        public productId?: (string);

        /** CreditCardPaymentCancelSubscriptionRequest deleteCreditCard. */
        public deleteCreditCard?: (boolean);

        /**
         * Gets the default type url for CreditCardPaymentCancelSubscriptionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreditCardPaymentPauseSubscriptionRequest. */
    export interface ICreditCardPaymentPauseSubscriptionRequest {

        /** CreditCardPaymentPauseSubscriptionRequest productId */
        productId?: (string);

        /** CreditCardPaymentPauseSubscriptionRequest pauseTerm */
        pauseTerm?: (payment.PauseTerm);
    }

    /** Represents a CreditCardPaymentPauseSubscriptionRequest. */
    class CreditCardPaymentPauseSubscriptionRequest implements ICreditCardPaymentPauseSubscriptionRequest {

        /**
         * Constructs a new CreditCardPaymentPauseSubscriptionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.ICreditCardPaymentPauseSubscriptionRequest);

        /** CreditCardPaymentPauseSubscriptionRequest productId. */
        public productId?: (string);

        /** CreditCardPaymentPauseSubscriptionRequest pauseTerm. */
        public pauseTerm: payment.PauseTerm;

        /**
         * Gets the default type url for CreditCardPaymentPauseSubscriptionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CreditCardPaymentUpdateCreditCardRequest. */
    export interface ICreditCardPaymentUpdateCreditCardRequest {

        /** CreditCardPaymentUpdateCreditCardRequest cardToken */
        cardToken?: (string);
    }

    /** Represents a CreditCardPaymentUpdateCreditCardRequest. */
    class CreditCardPaymentUpdateCreditCardRequest implements ICreditCardPaymentUpdateCreditCardRequest {

        /**
         * Constructs a new CreditCardPaymentUpdateCreditCardRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.ICreditCardPaymentUpdateCreditCardRequest);

        /** CreditCardPaymentUpdateCreditCardRequest cardToken. */
        public cardToken?: (string);

        /**
         * Gets the default type url for CreditCardPaymentUpdateCreditCardRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PaymentGetLatestSubscriptionRequest. */
    export interface IPaymentGetLatestSubscriptionRequest {

        /** PaymentGetLatestSubscriptionRequest platform */
        platform?: (payment.PaymentPlatform);
    }

    /** Represents a PaymentGetLatestSubscriptionRequest. */
    class PaymentGetLatestSubscriptionRequest implements IPaymentGetLatestSubscriptionRequest {

        /**
         * Constructs a new PaymentGetLatestSubscriptionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentGetLatestSubscriptionRequest);

        /** PaymentGetLatestSubscriptionRequest platform. */
        public platform: payment.PaymentPlatform;

        /**
         * Gets the default type url for PaymentGetLatestSubscriptionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PaymentListActiveSubscriptionRequest. */
    export interface IPaymentListActiveSubscriptionRequest {

        /** PaymentListActiveSubscriptionRequest platform */
        platform?: (payment.PaymentPlatform);
    }

    /** Represents a PaymentListActiveSubscriptionRequest. */
    class PaymentListActiveSubscriptionRequest implements IPaymentListActiveSubscriptionRequest {

        /**
         * Constructs a new PaymentListActiveSubscriptionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentListActiveSubscriptionRequest);

        /** PaymentListActiveSubscriptionRequest platform. */
        public platform: payment.PaymentPlatform;

        /**
         * Gets the default type url for PaymentListActiveSubscriptionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PaymentListActiveSubscriptionResponse. */
    export interface IPaymentListActiveSubscriptionResponse {

        /** PaymentListActiveSubscriptionResponse subscriptionList */
        subscriptionList?: (payment.IPaymentSubscription[]);
    }

    /** Represents a PaymentListActiveSubscriptionResponse. */
    class PaymentListActiveSubscriptionResponse implements IPaymentListActiveSubscriptionResponse {

        /**
         * Constructs a new PaymentListActiveSubscriptionResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentListActiveSubscriptionResponse);

        /** PaymentListActiveSubscriptionResponse subscriptionList. */
        public subscriptionList: payment.IPaymentSubscription[];

        /**
         * Gets the default type url for PaymentListActiveSubscriptionResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PaymentSubscriptionCancelRequest. */
    export interface IPaymentSubscriptionCancelRequest {

        /** PaymentSubscriptionCancelRequest emailAddress */
        emailAddress?: (string);
    }

    /** Represents a PaymentSubscriptionCancelRequest. */
    class PaymentSubscriptionCancelRequest implements IPaymentSubscriptionCancelRequest {

        /**
         * Constructs a new PaymentSubscriptionCancelRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentSubscriptionCancelRequest);

        /** PaymentSubscriptionCancelRequest emailAddress. */
        public emailAddress?: (string);

        /**
         * Gets the default type url for PaymentSubscriptionCancelRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PaymentListProductRequest. */
    export interface IPaymentListProductRequest {

        /** PaymentListProductRequest platform */
        platform?: (payment.PaymentPlatform);
    }

    /** Represents a PaymentListProductRequest. */
    class PaymentListProductRequest implements IPaymentListProductRequest {

        /**
         * Constructs a new PaymentListProductRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentListProductRequest);

        /** PaymentListProductRequest platform. */
        public platform: payment.PaymentPlatform;

        /**
         * Gets the default type url for PaymentListProductRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PaymentListProductResponse. */
    export interface IPaymentListProductResponse {

        /** PaymentListProductResponse paymentProductList */
        paymentProductList?: (payment.IPaymentProduct[]);
    }

    /** Represents a PaymentListProductResponse. */
    class PaymentListProductResponse implements IPaymentListProductResponse {

        /**
         * Constructs a new PaymentListProductResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentListProductResponse);

        /** PaymentListProductResponse paymentProductList. */
        public paymentProductList: payment.IPaymentProduct[];

        /**
         * Gets the default type url for PaymentListProductResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PaymentListCoinOneTimeProductRequest. */
    export interface IPaymentListCoinOneTimeProductRequest {

        /** PaymentListCoinOneTimeProductRequest platform */
        platform?: (payment.PaymentPlatform);
    }

    /** Represents a PaymentListCoinOneTimeProductRequest. */
    class PaymentListCoinOneTimeProductRequest implements IPaymentListCoinOneTimeProductRequest {

        /**
         * Constructs a new PaymentListCoinOneTimeProductRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IPaymentListCoinOneTimeProductRequest);

        /** PaymentListCoinOneTimeProductRequest platform. */
        public platform: payment.PaymentPlatform;

        /**
         * Gets the default type url for PaymentListCoinOneTimeProductRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ListCoinOneTimeProductResponse. */
    export interface IListCoinOneTimeProductResponse {

        /** ListCoinOneTimeProductResponse coinProductList */
        coinProductList?: (query_coin.ICoinOneTimeProduct[]);
    }

    /** Represents a ListCoinOneTimeProductResponse. */
    class ListCoinOneTimeProductResponse implements IListCoinOneTimeProductResponse {

        /**
         * Constructs a new ListCoinOneTimeProductResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IListCoinOneTimeProductResponse);

        /** ListCoinOneTimeProductResponse coinProductList. */
        public coinProductList: query_coin.ICoinOneTimeProduct[];

        /**
         * Gets the default type url for ListCoinOneTimeProductResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentSetDefaultPaymentMethod. */
    export interface IStripePaymentSetDefaultPaymentMethod {

        /** StripePaymentSetDefaultPaymentMethod paymentMethodId */
        paymentMethodId?: (string);
    }

    /** Represents a StripePaymentSetDefaultPaymentMethod. */
    class StripePaymentSetDefaultPaymentMethod implements IStripePaymentSetDefaultPaymentMethod {

        /**
         * Constructs a new StripePaymentSetDefaultPaymentMethod.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentSetDefaultPaymentMethod);

        /** StripePaymentSetDefaultPaymentMethod paymentMethodId. */
        public paymentMethodId?: (string);

        /**
         * Gets the default type url for StripePaymentSetDefaultPaymentMethod
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentSetDefaultPaymentMethodResponse. */
    export interface IStripePaymentSetDefaultPaymentMethodResponse {

        /** StripePaymentSetDefaultPaymentMethodResponse result */
        result?: (payment.StripePaymentSetDefaultPaymentMethodResponse.Result);
    }

    /** Represents a StripePaymentSetDefaultPaymentMethodResponse. */
    class StripePaymentSetDefaultPaymentMethodResponse implements IStripePaymentSetDefaultPaymentMethodResponse {

        /**
         * Constructs a new StripePaymentSetDefaultPaymentMethodResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentSetDefaultPaymentMethodResponse);

        /** StripePaymentSetDefaultPaymentMethodResponse result. */
        public result: payment.StripePaymentSetDefaultPaymentMethodResponse.Result;

        /**
         * Gets the default type url for StripePaymentSetDefaultPaymentMethodResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace StripePaymentSetDefaultPaymentMethodResponse {

        /** Result enum. */
        export enum Result {
            SUCCEEDED = "SUCCEEDED",
            ERROR_PAYMENT_METHOD_NOT_FOUND = "ERROR_PAYMENT_METHOD_NOT_FOUND"
        }
    }

    /** Properties of a StripePaymentMethod. */
    export interface IStripePaymentMethod {

        /** StripePaymentMethod paymentMethod */
        paymentMethod?: (payment.StripePaymentMethodType);

        /** StripePaymentMethod card */
        card?: (payment.IStripePaymentMethodCard);
    }

    /** Represents a StripePaymentMethod. */
    class StripePaymentMethod implements IStripePaymentMethod {

        /**
         * Constructs a new StripePaymentMethod.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentMethod);

        /** StripePaymentMethod paymentMethod. */
        public paymentMethod: payment.StripePaymentMethodType;

        /** StripePaymentMethod card. */
        public card?: (payment.IStripePaymentMethodCard);

        /**
         * Gets the default type url for StripePaymentMethod
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentGetTrialAvailabilityResponse. */
    export interface IStripePaymentGetTrialAvailabilityResponse {

        /** StripePaymentGetTrialAvailabilityResponse isTrialAvailable */
        isTrialAvailable?: (boolean);
    }

    /** Represents a StripePaymentGetTrialAvailabilityResponse. */
    class StripePaymentGetTrialAvailabilityResponse implements IStripePaymentGetTrialAvailabilityResponse {

        /**
         * Constructs a new StripePaymentGetTrialAvailabilityResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentGetTrialAvailabilityResponse);

        /** StripePaymentGetTrialAvailabilityResponse isTrialAvailable. */
        public isTrialAvailable?: (boolean);

        /**
         * Gets the default type url for StripePaymentGetTrialAvailabilityResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StripePaymentMethodType enum. */
    export enum StripePaymentMethodType {
        STRIPE_PAYMENT_METHOD_TYPE_UNSPECIFIED = "STRIPE_PAYMENT_METHOD_TYPE_UNSPECIFIED",
        STRIPE_PAYMENT_METHOD_TYPE_CARD = "STRIPE_PAYMENT_METHOD_TYPE_CARD"
    }

    /** Properties of a StripePaymentMethodCard. */
    export interface IStripePaymentMethodCard {

        /** StripePaymentMethodCard brand */
        brand?: (string);

        /** StripePaymentMethodCard last_4 */
        last_4?: (string);

        /** StripePaymentMethodCard wallet */
        wallet?: (payment.StripePaymentMethodCardWallet);
    }

    /** Represents a StripePaymentMethodCard. */
    class StripePaymentMethodCard implements IStripePaymentMethodCard {

        /**
         * Constructs a new StripePaymentMethodCard.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentMethodCard);

        /** StripePaymentMethodCard brand. */
        public brand?: (string);

        /** StripePaymentMethodCard last_4. */
        public last_4?: (string);

        /** StripePaymentMethodCard wallet. */
        public wallet: payment.StripePaymentMethodCardWallet;

        /**
         * Gets the default type url for StripePaymentMethodCard
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StripePaymentMethodCardWallet enum. */
    export enum StripePaymentMethodCardWallet {
        STRIPE_PAYMENT_METHOD_CARD_WALLET_UNSPECIFIED = "STRIPE_PAYMENT_METHOD_CARD_WALLET_UNSPECIFIED",
        STRIPE_PAYMENT_METHOD_CARD_WALLET_APPLE_PAY = "STRIPE_PAYMENT_METHOD_CARD_WALLET_APPLE_PAY",
        STRIPE_PAYMENT_METHOD_CARD_WALLET_GOOGLE_PAY = "STRIPE_PAYMENT_METHOD_CARD_WALLET_GOOGLE_PAY"
    }

    /** StripePaymentClientAction enum. */
    export enum StripePaymentClientAction {
        STRIPE_PAYMENT_CLIENT_ACTION_UNSPECIFIED = "STRIPE_PAYMENT_CLIENT_ACTION_UNSPECIFIED",
        STRIPE_PAYMENT_CLIENT_ACTION_ALREADY_SUBSCRIBED = "STRIPE_PAYMENT_CLIENT_ACTION_ALREADY_SUBSCRIBED",
        STRIPE_PAYMENT_CLIENT_ACTION_NONE = "STRIPE_PAYMENT_CLIENT_ACTION_NONE",
        STRIPE_PAYMENT_CLIENT_ACTION_HANDLE_NEXT_ACTION = "STRIPE_PAYMENT_CLIENT_ACTION_HANDLE_NEXT_ACTION",
        STRIPE_PAYMENT_CLIENT_ACTION_CHANGE_PAYMENT_METHOD = "STRIPE_PAYMENT_CLIENT_ACTION_CHANGE_PAYMENT_METHOD"
    }

    /** Properties of a StripePaymentStartSetupPaymentMethodResponse. */
    export interface IStripePaymentStartSetupPaymentMethodResponse {

        /** StripePaymentStartSetupPaymentMethodResponse clientSecret */
        clientSecret?: (string);
    }

    /** Represents a StripePaymentStartSetupPaymentMethodResponse. */
    class StripePaymentStartSetupPaymentMethodResponse implements IStripePaymentStartSetupPaymentMethodResponse {

        /**
         * Constructs a new StripePaymentStartSetupPaymentMethodResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentStartSetupPaymentMethodResponse);

        /** StripePaymentStartSetupPaymentMethodResponse clientSecret. */
        public clientSecret?: (string);

        /**
         * Gets the default type url for StripePaymentStartSetupPaymentMethodResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentActivateSubscriptionResponse. */
    export interface IStripePaymentActivateSubscriptionResponse {

        /** StripePaymentActivateSubscriptionResponse clientAction */
        clientAction?: (payment.StripePaymentClientAction);

        /** StripePaymentActivateSubscriptionResponse clientSecret */
        clientSecret?: (string);

        /** StripePaymentActivateSubscriptionResponse paymentMethodId */
        paymentMethodId?: (string);
    }

    /** Represents a StripePaymentActivateSubscriptionResponse. */
    class StripePaymentActivateSubscriptionResponse implements IStripePaymentActivateSubscriptionResponse {

        /**
         * Constructs a new StripePaymentActivateSubscriptionResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentActivateSubscriptionResponse);

        /** StripePaymentActivateSubscriptionResponse clientAction. */
        public clientAction: payment.StripePaymentClientAction;

        /** StripePaymentActivateSubscriptionResponse clientSecret. */
        public clientSecret?: (string);

        /** StripePaymentActivateSubscriptionResponse paymentMethodId. */
        public paymentMethodId?: (string);

        /**
         * Gets the default type url for StripePaymentActivateSubscriptionResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentActivateSubscriptionRequest. */
    export interface IStripePaymentActivateSubscriptionRequest {

        /** StripePaymentActivateSubscriptionRequest productId */
        productId?: (string);
    }

    /** Represents a StripePaymentActivateSubscriptionRequest. */
    class StripePaymentActivateSubscriptionRequest implements IStripePaymentActivateSubscriptionRequest {

        /**
         * Constructs a new StripePaymentActivateSubscriptionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentActivateSubscriptionRequest);

        /** StripePaymentActivateSubscriptionRequest productId. */
        public productId?: (string);

        /**
         * Gets the default type url for StripePaymentActivateSubscriptionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentIssueCustomerPortalLinkRequest. */
    export interface IStripePaymentIssueCustomerPortalLinkRequest {

        /** StripePaymentIssueCustomerPortalLinkRequest returnUrl */
        returnUrl?: (string);
    }

    /** Represents a StripePaymentIssueCustomerPortalLinkRequest. */
    class StripePaymentIssueCustomerPortalLinkRequest implements IStripePaymentIssueCustomerPortalLinkRequest {

        /**
         * Constructs a new StripePaymentIssueCustomerPortalLinkRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentIssueCustomerPortalLinkRequest);

        /** StripePaymentIssueCustomerPortalLinkRequest returnUrl. */
        public returnUrl?: (string);

        /**
         * Gets the default type url for StripePaymentIssueCustomerPortalLinkRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentIssueCustomerPortalLinkResponse. */
    export interface IStripePaymentIssueCustomerPortalLinkResponse {

        /** StripePaymentIssueCustomerPortalLinkResponse url */
        url?: (string);
    }

    /** Represents a StripePaymentIssueCustomerPortalLinkResponse. */
    class StripePaymentIssueCustomerPortalLinkResponse implements IStripePaymentIssueCustomerPortalLinkResponse {

        /**
         * Constructs a new StripePaymentIssueCustomerPortalLinkResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentIssueCustomerPortalLinkResponse);

        /** StripePaymentIssueCustomerPortalLinkResponse url. */
        public url?: (string);

        /**
         * Gets the default type url for StripePaymentIssueCustomerPortalLinkResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentStartOneTimeProductPaymentRequest. */
    export interface IStripePaymentStartOneTimeProductPaymentRequest {

        /** StripePaymentStartOneTimeProductPaymentRequest productId */
        productId?: (string);
    }

    /** Represents a StripePaymentStartOneTimeProductPaymentRequest. */
    class StripePaymentStartOneTimeProductPaymentRequest implements IStripePaymentStartOneTimeProductPaymentRequest {

        /**
         * Constructs a new StripePaymentStartOneTimeProductPaymentRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentStartOneTimeProductPaymentRequest);

        /** StripePaymentStartOneTimeProductPaymentRequest productId. */
        public productId?: (string);

        /**
         * Gets the default type url for StripePaymentStartOneTimeProductPaymentRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentStartOneTimeProductPaymentResponse. */
    export interface IStripePaymentStartOneTimeProductPaymentResponse {

        /** StripePaymentStartOneTimeProductPaymentResponse clientSecret */
        clientSecret?: (string);
    }

    /** Represents a StripePaymentStartOneTimeProductPaymentResponse. */
    class StripePaymentStartOneTimeProductPaymentResponse implements IStripePaymentStartOneTimeProductPaymentResponse {

        /**
         * Constructs a new StripePaymentStartOneTimeProductPaymentResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentStartOneTimeProductPaymentResponse);

        /** StripePaymentStartOneTimeProductPaymentResponse clientSecret. */
        public clientSecret?: (string);

        /**
         * Gets the default type url for StripePaymentStartOneTimeProductPaymentResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StripePaymentBuyOneTimeProductRequest. */
    export interface IStripePaymentBuyOneTimeProductRequest {

        /** StripePaymentBuyOneTimeProductRequest productId */
        productId?: (string);
    }

    /** Represents a StripePaymentBuyOneTimeProductRequest. */
    class StripePaymentBuyOneTimeProductRequest implements IStripePaymentBuyOneTimeProductRequest {

        /**
         * Constructs a new StripePaymentBuyOneTimeProductRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: payment.IStripePaymentBuyOneTimeProductRequest);

        /** StripePaymentBuyOneTimeProductRequest productId. */
        public productId?: (string);

        /**
         * Gets the default type url for StripePaymentBuyOneTimeProductRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_coin. */
export namespace query_coin {

    /** Properties of a CoinBalance. */
    export interface ICoinBalance {

        /** CoinBalance totalAmount */
        totalAmount?: (number);

        /** CoinBalance paidCoinAmount */
        paidCoinAmount?: (number);

        /** CoinBalance bonusCoinAmount */
        bonusCoinAmount?: (number);

        /** CoinBalance expirationSchedule */
        expirationSchedule?: (query_coin.CoinBalance.IExpirationSchedule);
    }

    /** Represents a CoinBalance. */
    class CoinBalance implements ICoinBalance {

        /**
         * Constructs a new CoinBalance.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_coin.ICoinBalance);

        /** CoinBalance totalAmount. */
        public totalAmount?: (number);

        /** CoinBalance paidCoinAmount. */
        public paidCoinAmount?: (number);

        /** CoinBalance bonusCoinAmount. */
        public bonusCoinAmount?: (number);

        /** CoinBalance expirationSchedule. */
        public expirationSchedule?: (query_coin.CoinBalance.IExpirationSchedule);

        /**
         * Gets the default type url for CoinBalance
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace CoinBalance {

        /** Properties of an ExpirationSchedule. */
        export interface IExpirationSchedule {

            /** ExpirationSchedule amount */
            amount?: (number);

            /** ExpirationSchedule expiresAt */
            expiresAt?: (string);
        }

        /** Represents an ExpirationSchedule. */
        class ExpirationSchedule implements IExpirationSchedule {

            /**
             * Constructs a new ExpirationSchedule.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_coin.CoinBalance.IExpirationSchedule);

            /** ExpirationSchedule amount. */
            public amount?: (number);

            /** ExpirationSchedule expiresAt. */
            public expiresAt?: (string);

            /**
             * Gets the default type url for ExpirationSchedule
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** CoinTransactionType enum. */
    export enum CoinTransactionType {
        UNSPECIFIED = "UNSPECIFIED",
        INCR_PURCHASE = "INCR_PURCHASE",
        INCR_ADMIN = "INCR_ADMIN",
        INCR_BONUS = "INCR_BONUS",
        DECR_USE = "DECR_USE",
        DECR_EXPIRE = "DECR_EXPIRE",
        DECR_ADMIN = "DECR_ADMIN"
    }

    /** Properties of a CoinTransaction. */
    export interface ICoinTransaction {

        /** CoinTransaction id */
        id?: (string);

        /** CoinTransaction type */
        type?: (query_coin.CoinTransactionType);

        /** CoinTransaction incrPaidCoin */
        incrPaidCoin?: (number);

        /** CoinTransaction incrBonusCoin */
        incrBonusCoin?: (number);

        /** CoinTransaction incrPaidCoinExpiresAt */
        incrPaidCoinExpiresAt?: (string);

        /** CoinTransaction incrBonusCoinExpiresAt */
        incrBonusCoinExpiresAt?: (string);

        /** CoinTransaction incrPaidCoinPaymentPlatform */
        incrPaidCoinPaymentPlatform?: (payment.PaymentPlatform);

        /** CoinTransaction decrPaidCoin */
        decrPaidCoin?: (number);

        /** CoinTransaction decrBonusCoin */
        decrBonusCoin?: (number);

        /** CoinTransaction createdAt */
        createdAt?: (string);
    }

    /** Represents a CoinTransaction. */
    class CoinTransaction implements ICoinTransaction {

        /**
         * Constructs a new CoinTransaction.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_coin.ICoinTransaction);

        /** CoinTransaction id. */
        public id?: (string);

        /** CoinTransaction type. */
        public type: query_coin.CoinTransactionType;

        /** CoinTransaction incrPaidCoin. */
        public incrPaidCoin?: (number);

        /** CoinTransaction incrBonusCoin. */
        public incrBonusCoin?: (number);

        /** CoinTransaction incrPaidCoinExpiresAt. */
        public incrPaidCoinExpiresAt?: (string);

        /** CoinTransaction incrBonusCoinExpiresAt. */
        public incrBonusCoinExpiresAt?: (string);

        /** CoinTransaction incrPaidCoinPaymentPlatform. */
        public incrPaidCoinPaymentPlatform: payment.PaymentPlatform;

        /** CoinTransaction decrPaidCoin. */
        public decrPaidCoin?: (number);

        /** CoinTransaction decrBonusCoin. */
        public decrBonusCoin?: (number);

        /** CoinTransaction createdAt. */
        public createdAt?: (string);

        /**
         * Gets the default type url for CoinTransaction
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CoinOneTimeProduct. */
    export interface ICoinOneTimeProduct {

        /** CoinOneTimeProduct platform */
        platform?: (payment.PaymentPlatform);

        /** CoinOneTimeProduct productId */
        productId?: (string);

        /** CoinOneTimeProduct paidCoinAmount */
        paidCoinAmount?: (number);

        /** CoinOneTimeProduct bonusCoinAmount */
        bonusCoinAmount?: (number);

        /** CoinOneTimeProduct price */
        price?: (number);

        /** CoinOneTimeProduct promotionDiscountRate */
        promotionDiscountRate?: (number);
    }

    /** Represents a CoinOneTimeProduct. */
    class CoinOneTimeProduct implements ICoinOneTimeProduct {

        /**
         * Constructs a new CoinOneTimeProduct.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_coin.ICoinOneTimeProduct);

        /** CoinOneTimeProduct platform. */
        public platform: payment.PaymentPlatform;

        /** CoinOneTimeProduct productId. */
        public productId?: (string);

        /** CoinOneTimeProduct paidCoinAmount. */
        public paidCoinAmount?: (number);

        /** CoinOneTimeProduct bonusCoinAmount. */
        public bonusCoinAmount?: (number);

        /** CoinOneTimeProduct price. */
        public price?: (number);

        /** CoinOneTimeProduct promotionDiscountRate. */
        public promotionDiscountRate?: (number);

        /**
         * Gets the default type url for CoinOneTimeProduct
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace profiling. */
export namespace profiling {

    /** Properties of a Profiling. */
    export interface IProfiling {

        /** Profiling gender */
        gender?: (profiling.IProfilingGenderQuestion);

        /** Profiling birthday */
        birthday?: (profiling.IProfilingBirthdayQuestion);

        /** Profiling storyPreferenceList */
        storyPreferenceList?: (profiling.IProfilingStoryPreferenceQuestion[]);

        /** Profiling answeredAt */
        answeredAt?: (string);
    }

    /** Represents a Profiling. */
    class Profiling implements IProfiling {

        /**
         * Constructs a new Profiling.
         * @param [properties] Properties to set
         */
        constructor(properties?: profiling.IProfiling);

        /** Profiling gender. */
        public gender?: (profiling.IProfilingGenderQuestion);

        /** Profiling birthday. */
        public birthday?: (profiling.IProfilingBirthdayQuestion);

        /** Profiling storyPreferenceList. */
        public storyPreferenceList: profiling.IProfilingStoryPreferenceQuestion[];

        /** Profiling answeredAt. */
        public answeredAt?: (string);

        /**
         * Gets the default type url for Profiling
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingStoryPreferenceQuestion. */
    export interface IProfilingStoryPreferenceQuestion {

        /** ProfilingStoryPreferenceQuestion storyId */
        storyId?: (string);

        /** ProfilingStoryPreferenceQuestion answer */
        answer?: (profiling.IProfilingStoryPreferenceAnswer);
    }

    /** Represents a ProfilingStoryPreferenceQuestion. */
    class ProfilingStoryPreferenceQuestion implements IProfilingStoryPreferenceQuestion {

        /**
         * Constructs a new ProfilingStoryPreferenceQuestion.
         * @param [properties] Properties to set
         */
        constructor(properties?: profiling.IProfilingStoryPreferenceQuestion);

        /** ProfilingStoryPreferenceQuestion storyId. */
        public storyId?: (string);

        /** ProfilingStoryPreferenceQuestion answer. */
        public answer?: (profiling.IProfilingStoryPreferenceAnswer);

        /**
         * Gets the default type url for ProfilingStoryPreferenceQuestion
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingGenderQuestion. */
    export interface IProfilingGenderQuestion {

        /** ProfilingGenderQuestion answer */
        answer?: (profiling.IProfilingGenderAnswer);
    }

    /** Represents a ProfilingGenderQuestion. */
    class ProfilingGenderQuestion implements IProfilingGenderQuestion {

        /**
         * Constructs a new ProfilingGenderQuestion.
         * @param [properties] Properties to set
         */
        constructor(properties?: profiling.IProfilingGenderQuestion);

        /** ProfilingGenderQuestion answer. */
        public answer?: (profiling.IProfilingGenderAnswer);

        /**
         * Gets the default type url for ProfilingGenderQuestion
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** ProfilingGender enum. */
    export enum ProfilingGender {
        PROFILING_GENDER_UNSPECIFIED = "PROFILING_GENDER_UNSPECIFIED",
        PROFILING_GENDER_MALE = "PROFILING_GENDER_MALE",
        PROFILING_GENDER_FEMALE = "PROFILING_GENDER_FEMALE",
        PROFILING_GENDER_OTHER = "PROFILING_GENDER_OTHER"
    }

    /** Properties of a ProfilingBirthdayQuestion. */
    export interface IProfilingBirthdayQuestion {

        /** ProfilingBirthdayQuestion answer */
        answer?: (profiling.IProfilingBirthdayAnswer);
    }

    /** Represents a ProfilingBirthdayQuestion. */
    class ProfilingBirthdayQuestion implements IProfilingBirthdayQuestion {

        /**
         * Constructs a new ProfilingBirthdayQuestion.
         * @param [properties] Properties to set
         */
        constructor(properties?: profiling.IProfilingBirthdayQuestion);

        /** ProfilingBirthdayQuestion answer. */
        public answer?: (profiling.IProfilingBirthdayAnswer);

        /**
         * Gets the default type url for ProfilingBirthdayQuestion
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingStoryPreferenceAnswer. */
    export interface IProfilingStoryPreferenceAnswer {

        /** ProfilingStoryPreferenceAnswer storyId */
        storyId?: (string);

        /** ProfilingStoryPreferenceAnswer answer */
        answer?: (boolean);

        /** ProfilingStoryPreferenceAnswer answeredAt */
        answeredAt?: (string);
    }

    /** Represents a ProfilingStoryPreferenceAnswer. */
    class ProfilingStoryPreferenceAnswer implements IProfilingStoryPreferenceAnswer {

        /**
         * Constructs a new ProfilingStoryPreferenceAnswer.
         * @param [properties] Properties to set
         */
        constructor(properties?: profiling.IProfilingStoryPreferenceAnswer);

        /** ProfilingStoryPreferenceAnswer storyId. */
        public storyId?: (string);

        /** ProfilingStoryPreferenceAnswer answer. */
        public answer?: (boolean);

        /** ProfilingStoryPreferenceAnswer answeredAt. */
        public answeredAt?: (string);

        /**
         * Gets the default type url for ProfilingStoryPreferenceAnswer
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingGenderAnswer. */
    export interface IProfilingGenderAnswer {

        /** ProfilingGenderAnswer answer */
        answer?: (profiling.ProfilingGender);

        /** ProfilingGenderAnswer answeredAt */
        answeredAt?: (string);
    }

    /** Represents a ProfilingGenderAnswer. */
    class ProfilingGenderAnswer implements IProfilingGenderAnswer {

        /**
         * Constructs a new ProfilingGenderAnswer.
         * @param [properties] Properties to set
         */
        constructor(properties?: profiling.IProfilingGenderAnswer);

        /** ProfilingGenderAnswer answer. */
        public answer: profiling.ProfilingGender;

        /** ProfilingGenderAnswer answeredAt. */
        public answeredAt?: (string);

        /**
         * Gets the default type url for ProfilingGenderAnswer
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingBirthdayAnswer. */
    export interface IProfilingBirthdayAnswer {

        /** ProfilingBirthdayAnswer answer */
        answer?: (string);

        /** ProfilingBirthdayAnswer answeredAt */
        answeredAt?: (string);
    }

    /** Represents a ProfilingBirthdayAnswer. */
    class ProfilingBirthdayAnswer implements IProfilingBirthdayAnswer {

        /**
         * Constructs a new ProfilingBirthdayAnswer.
         * @param [properties] Properties to set
         */
        constructor(properties?: profiling.IProfilingBirthdayAnswer);

        /** ProfilingBirthdayAnswer answer. */
        public answer?: (string);

        /** ProfilingBirthdayAnswer answeredAt. */
        public answeredAt?: (string);

        /**
         * Gets the default type url for ProfilingBirthdayAnswer
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingAnswerRequest. */
    export interface IProfilingAnswerRequest {

        /** ProfilingAnswerRequest genderAnswer */
        genderAnswer?: (profiling.IProfilingGenderAnswer);

        /** ProfilingAnswerRequest birthdayAnswer */
        birthdayAnswer?: (profiling.IProfilingBirthdayAnswer);

        /** ProfilingAnswerRequest storyPreferenceAnswerList */
        storyPreferenceAnswerList?: (profiling.IProfilingStoryPreferenceAnswer[]);
    }

    /** Represents a ProfilingAnswerRequest. */
    class ProfilingAnswerRequest implements IProfilingAnswerRequest {

        /**
         * Constructs a new ProfilingAnswerRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: profiling.IProfilingAnswerRequest);

        /** ProfilingAnswerRequest genderAnswer. */
        public genderAnswer?: (profiling.IProfilingGenderAnswer);

        /** ProfilingAnswerRequest birthdayAnswer. */
        public birthdayAnswer?: (profiling.IProfilingBirthdayAnswer);

        /** ProfilingAnswerRequest storyPreferenceAnswerList. */
        public storyPreferenceAnswerList: profiling.IProfilingStoryPreferenceAnswer[];

        /**
         * Gets the default type url for ProfilingAnswerRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_bonus. */
export namespace query_bonus {

    /** Properties of a WatchAdBonusCoinStatus. */
    export interface IWatchAdBonusCoinStatus {

        /** WatchAdBonusCoinStatus availableCount */
        availableCount?: (number);

        /** WatchAdBonusCoinStatus rewardedCount */
        rewardedCount?: (number);

        /** WatchAdBonusCoinStatus nextAvailableAt */
        nextAvailableAt?: (string);

        /** WatchAdBonusCoinStatus amount */
        amount?: (number);

        /** WatchAdBonusCoinStatus dailyLimit */
        dailyLimit?: (number);
    }

    /** Represents a WatchAdBonusCoinStatus. */
    class WatchAdBonusCoinStatus implements IWatchAdBonusCoinStatus {

        /**
         * Constructs a new WatchAdBonusCoinStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.IWatchAdBonusCoinStatus);

        /** WatchAdBonusCoinStatus availableCount. */
        public availableCount?: (number);

        /** WatchAdBonusCoinStatus rewardedCount. */
        public rewardedCount?: (number);

        /** WatchAdBonusCoinStatus nextAvailableAt. */
        public nextAvailableAt?: (string);

        /** WatchAdBonusCoinStatus amount. */
        public amount?: (number);

        /** WatchAdBonusCoinStatus dailyLimit. */
        public dailyLimit?: (number);

        /**
         * Gets the default type url for WatchAdBonusCoinStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserRegistrationBonusCoinStatus. */
    export interface IUserRegistrationBonusCoinStatus {

        /** UserRegistrationBonusCoinStatus isAvailable */
        isAvailable?: (boolean);

        /** UserRegistrationBonusCoinStatus isAchieved */
        isAchieved?: (boolean);

        /** UserRegistrationBonusCoinStatus amount */
        amount?: (number);
    }

    /** Represents a UserRegistrationBonusCoinStatus. */
    class UserRegistrationBonusCoinStatus implements IUserRegistrationBonusCoinStatus {

        /**
         * Constructs a new UserRegistrationBonusCoinStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.IUserRegistrationBonusCoinStatus);

        /** UserRegistrationBonusCoinStatus isAvailable. */
        public isAvailable?: (boolean);

        /** UserRegistrationBonusCoinStatus isAchieved. */
        public isAchieved?: (boolean);

        /** UserRegistrationBonusCoinStatus amount. */
        public amount?: (number);

        /**
         * Gets the default type url for UserRegistrationBonusCoinStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a WatchVideoBonusCoinStatus. */
    export interface IWatchVideoBonusCoinStatus {

        /** WatchVideoBonusCoinStatus items */
        items?: (query_bonus.WatchVideoBonusCoinStatus.IItem[]);

        /** WatchVideoBonusCoinStatus watchedCount */
        watchedCount?: (number);
    }

    /** Represents a WatchVideoBonusCoinStatus. */
    class WatchVideoBonusCoinStatus implements IWatchVideoBonusCoinStatus {

        /**
         * Constructs a new WatchVideoBonusCoinStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.IWatchVideoBonusCoinStatus);

        /** WatchVideoBonusCoinStatus items. */
        public items: query_bonus.WatchVideoBonusCoinStatus.IItem[];

        /** WatchVideoBonusCoinStatus watchedCount. */
        public watchedCount?: (number);

        /**
         * Gets the default type url for WatchVideoBonusCoinStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace WatchVideoBonusCoinStatus {

        /** Properties of an Item. */
        export interface IItem {

            /** Item isAvailable */
            isAvailable?: (boolean);

            /** Item isAchieved */
            isAchieved?: (boolean);

            /** Item amount */
            amount?: (number);

            /** Item conditionCount */
            conditionCount?: (number);

            /** Item step */
            step?: (number);
        }

        /** Represents an Item. */
        class Item implements IItem {

            /**
             * Constructs a new Item.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_bonus.WatchVideoBonusCoinStatus.IItem);

            /** Item isAvailable. */
            public isAvailable?: (boolean);

            /** Item isAchieved. */
            public isAchieved?: (boolean);

            /** Item amount. */
            public amount?: (number);

            /** Item conditionCount. */
            public conditionCount?: (number);

            /** Item step. */
            public step?: (number);

            /**
             * Gets the default type url for Item
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a CheckInBonusCoinStatus. */
    export interface ICheckInBonusCoinStatus {

        /** CheckInBonusCoinStatus isAvailable */
        isAvailable?: (boolean);

        /** CheckInBonusCoinStatus nextAvailableAt */
        nextAvailableAt?: (string);

        /** CheckInBonusCoinStatus currentStep */
        currentStep?: (number);

        /** CheckInBonusCoinStatus amountList */
        amountList?: (number[]);
    }

    /** Represents a CheckInBonusCoinStatus. */
    class CheckInBonusCoinStatus implements ICheckInBonusCoinStatus {

        /**
         * Constructs a new CheckInBonusCoinStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.ICheckInBonusCoinStatus);

        /** CheckInBonusCoinStatus isAvailable. */
        public isAvailable?: (boolean);

        /** CheckInBonusCoinStatus nextAvailableAt. */
        public nextAvailableAt?: (string);

        /** CheckInBonusCoinStatus currentStep. */
        public currentStep?: (number);

        /** CheckInBonusCoinStatus amountList. */
        public amountList: number[];

        /**
         * Gets the default type url for CheckInBonusCoinStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a WatchAdBonusStoryStatus. */
    export interface IWatchAdBonusStoryStatus {

        /** WatchAdBonusStoryStatus availableCount */
        availableCount?: (number);

        /** WatchAdBonusStoryStatus rewardedCount */
        rewardedCount?: (number);

        /** WatchAdBonusStoryStatus nextAvailableAt */
        nextAvailableAt?: (string);

        /** WatchAdBonusStoryStatus dailyLimit */
        dailyLimit?: (number);
    }

    /** Represents a WatchAdBonusStoryStatus. */
    class WatchAdBonusStoryStatus implements IWatchAdBonusStoryStatus {

        /**
         * Constructs a new WatchAdBonusStoryStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.IWatchAdBonusStoryStatus);

        /** WatchAdBonusStoryStatus availableCount. */
        public availableCount?: (number);

        /** WatchAdBonusStoryStatus rewardedCount. */
        public rewardedCount?: (number);

        /** WatchAdBonusStoryStatus nextAvailableAt. */
        public nextAvailableAt?: (string);

        /** WatchAdBonusStoryStatus dailyLimit. */
        public dailyLimit?: (number);

        /**
         * Gets the default type url for WatchAdBonusStoryStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a WebLoginBonusCoinStatus. */
    export interface IWebLoginBonusCoinStatus {

        /** WebLoginBonusCoinStatus isAvailable */
        isAvailable?: (boolean);

        /** WebLoginBonusCoinStatus isAchieved */
        isAchieved?: (boolean);

        /** WebLoginBonusCoinStatus amount */
        amount?: (number);
    }

    /** Represents a WebLoginBonusCoinStatus. */
    class WebLoginBonusCoinStatus implements IWebLoginBonusCoinStatus {

        /**
         * Constructs a new WebLoginBonusCoinStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.IWebLoginBonusCoinStatus);

        /** WebLoginBonusCoinStatus isAvailable. */
        public isAvailable?: (boolean);

        /** WebLoginBonusCoinStatus isAchieved. */
        public isAchieved?: (boolean);

        /** WebLoginBonusCoinStatus amount. */
        public amount?: (number);

        /**
         * Gets the default type url for WebLoginBonusCoinStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a WebCoinPurchaseBonusCoinStatus. */
    export interface IWebCoinPurchaseBonusCoinStatus {

        /** WebCoinPurchaseBonusCoinStatus isAvailable */
        isAvailable?: (boolean);

        /** WebCoinPurchaseBonusCoinStatus isAchieved */
        isAchieved?: (boolean);

        /** WebCoinPurchaseBonusCoinStatus amount */
        amount?: (number);
    }

    /** Represents a WebCoinPurchaseBonusCoinStatus. */
    class WebCoinPurchaseBonusCoinStatus implements IWebCoinPurchaseBonusCoinStatus {

        /**
         * Constructs a new WebCoinPurchaseBonusCoinStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.IWebCoinPurchaseBonusCoinStatus);

        /** WebCoinPurchaseBonusCoinStatus isAvailable. */
        public isAvailable?: (boolean);

        /** WebCoinPurchaseBonusCoinStatus isAchieved. */
        public isAchieved?: (boolean);

        /** WebCoinPurchaseBonusCoinStatus amount. */
        public amount?: (number);

        /**
         * Gets the default type url for WebCoinPurchaseBonusCoinStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BonusStatusRequireField. */
    export interface IBonusStatusRequireField {

        /** BonusStatusRequireField requireTotalUnrewardedAmount */
        requireTotalUnrewardedAmount?: (boolean);

        /** BonusStatusRequireField requireWatchAdBonusCoinStatus */
        requireWatchAdBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireUserRegistrationBonusCoinStatus */
        requireUserRegistrationBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireWatchVideoBonusCoinStatus */
        requireWatchVideoBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireCheckInBonusCoinStatus */
        requireCheckInBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireWatchAdBonusStoryStatus */
        requireWatchAdBonusStoryStatus?: (boolean);

        /** BonusStatusRequireField requireWebLoginBonusCoinStatus */
        requireWebLoginBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireWebCoinPurchaseBonusCoinStatus */
        requireWebCoinPurchaseBonusCoinStatus?: (boolean);
    }

    /** Represents a BonusStatusRequireField. */
    class BonusStatusRequireField implements IBonusStatusRequireField {

        /**
         * Constructs a new BonusStatusRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.IBonusStatusRequireField);

        /** BonusStatusRequireField requireTotalUnrewardedAmount. */
        public requireTotalUnrewardedAmount?: (boolean);

        /** BonusStatusRequireField requireWatchAdBonusCoinStatus. */
        public requireWatchAdBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireUserRegistrationBonusCoinStatus. */
        public requireUserRegistrationBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireWatchVideoBonusCoinStatus. */
        public requireWatchVideoBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireCheckInBonusCoinStatus. */
        public requireCheckInBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireWatchAdBonusStoryStatus. */
        public requireWatchAdBonusStoryStatus?: (boolean);

        /** BonusStatusRequireField requireWebLoginBonusCoinStatus. */
        public requireWebLoginBonusCoinStatus?: (boolean);

        /** BonusStatusRequireField requireWebCoinPurchaseBonusCoinStatus. */
        public requireWebCoinPurchaseBonusCoinStatus?: (boolean);

        /**
         * Gets the default type url for BonusStatusRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BonusStatus. */
    export interface IBonusStatus {

        /** BonusStatus totalUnrewardedAmount */
        totalUnrewardedAmount?: (number);

        /** BonusStatus watchAdBonusCoinStatus */
        watchAdBonusCoinStatus?: (query_bonus.IWatchAdBonusCoinStatus);

        /** BonusStatus userRegistrationBonusCoinStatus */
        userRegistrationBonusCoinStatus?: (query_bonus.IUserRegistrationBonusCoinStatus);

        /** BonusStatus watchVideoBonusCoinStatus */
        watchVideoBonusCoinStatus?: (query_bonus.IWatchVideoBonusCoinStatus);

        /** BonusStatus checkInBonusCoinStatus */
        checkInBonusCoinStatus?: (query_bonus.ICheckInBonusCoinStatus);

        /** BonusStatus watchAdBonusStoryStatus */
        watchAdBonusStoryStatus?: (query_bonus.IWatchAdBonusStoryStatus);

        /** BonusStatus webLoginBonusCoinStatus */
        webLoginBonusCoinStatus?: (query_bonus.IWebLoginBonusCoinStatus);

        /** BonusStatus webCoinPurchaseBonusCoinStatus */
        webCoinPurchaseBonusCoinStatus?: (query_bonus.IWebCoinPurchaseBonusCoinStatus);
    }

    /** Represents a BonusStatus. */
    class BonusStatus implements IBonusStatus {

        /**
         * Constructs a new BonusStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus.IBonusStatus);

        /** BonusStatus totalUnrewardedAmount. */
        public totalUnrewardedAmount?: (number);

        /** BonusStatus watchAdBonusCoinStatus. */
        public watchAdBonusCoinStatus?: (query_bonus.IWatchAdBonusCoinStatus);

        /** BonusStatus userRegistrationBonusCoinStatus. */
        public userRegistrationBonusCoinStatus?: (query_bonus.IUserRegistrationBonusCoinStatus);

        /** BonusStatus watchVideoBonusCoinStatus. */
        public watchVideoBonusCoinStatus?: (query_bonus.IWatchVideoBonusCoinStatus);

        /** BonusStatus checkInBonusCoinStatus. */
        public checkInBonusCoinStatus?: (query_bonus.ICheckInBonusCoinStatus);

        /** BonusStatus watchAdBonusStoryStatus. */
        public watchAdBonusStoryStatus?: (query_bonus.IWatchAdBonusStoryStatus);

        /** BonusStatus webLoginBonusCoinStatus. */
        public webLoginBonusCoinStatus?: (query_bonus.IWebLoginBonusCoinStatus);

        /** BonusStatus webCoinPurchaseBonusCoinStatus. */
        public webCoinPurchaseBonusCoinStatus?: (query_bonus.IWebCoinPurchaseBonusCoinStatus);

        /**
         * Gets the default type url for BonusStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_bonus_ticket. */
export namespace query_bonus_ticket {

    /** Properties of a FixedBonusTicketStatus. */
    export interface IFixedBonusTicketStatus {

        /** FixedBonusTicketStatus availableCount */
        availableCount?: (number);

        /** FixedBonusTicketStatus amount */
        amount?: (number);

        /** FixedBonusTicketStatus nextGainableAt */
        nextGainableAt?: (string);
    }

    /** Represents a FixedBonusTicketStatus. */
    class FixedBonusTicketStatus implements IFixedBonusTicketStatus {

        /**
         * Constructs a new FixedBonusTicketStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus_ticket.IFixedBonusTicketStatus);

        /** FixedBonusTicketStatus availableCount. */
        public availableCount?: (number);

        /** FixedBonusTicketStatus amount. */
        public amount?: (number);

        /** FixedBonusTicketStatus nextGainableAt. */
        public nextGainableAt?: (string);

        /**
         * Gets the default type url for FixedBonusTicketStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StepBonusTicketStatus. */
    export interface IStepBonusTicketStatus {

        /** StepBonusTicketStatus availableCount */
        availableCount?: (number);

        /** StepBonusTicketStatus nextGainableAt */
        nextGainableAt?: (string);

        /** StepBonusTicketStatus currentIndex */
        currentIndex?: (number);

        /** StepBonusTicketStatus stepItemList */
        stepItemList?: (query_bonus_ticket.IStepBonusTicketItem[]);
    }

    /** Represents a StepBonusTicketStatus. */
    class StepBonusTicketStatus implements IStepBonusTicketStatus {

        /**
         * Constructs a new StepBonusTicketStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus_ticket.IStepBonusTicketStatus);

        /** StepBonusTicketStatus availableCount. */
        public availableCount?: (number);

        /** StepBonusTicketStatus nextGainableAt. */
        public nextGainableAt?: (string);

        /** StepBonusTicketStatus currentIndex. */
        public currentIndex?: (number);

        /** StepBonusTicketStatus stepItemList. */
        public stepItemList: query_bonus_ticket.IStepBonusTicketItem[];

        /**
         * Gets the default type url for StepBonusTicketStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StepBonusTicketItem. */
    export interface IStepBonusTicketItem {

        /** StepBonusTicketItem amount */
        amount?: (number);

        /** StepBonusTicketItem imageUrl */
        imageUrl?: (string);
    }

    /** Represents a StepBonusTicketItem. */
    class StepBonusTicketItem implements IStepBonusTicketItem {

        /**
         * Constructs a new StepBonusTicketItem.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus_ticket.IStepBonusTicketItem);

        /** StepBonusTicketItem amount. */
        public amount?: (number);

        /** StepBonusTicketItem imageUrl. */
        public imageUrl?: (string);

        /**
         * Gets the default type url for StepBonusTicketItem
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TimeLimitedBonusTicketStatus. */
    export interface ITimeLimitedBonusTicketStatus {

        /** TimeLimitedBonusTicketStatus availableCount */
        availableCount?: (number);

        /** TimeLimitedBonusTicketStatus expiresAt */
        expiresAt?: (string);
    }

    /** Represents a TimeLimitedBonusTicketStatus. */
    class TimeLimitedBonusTicketStatus implements ITimeLimitedBonusTicketStatus {

        /**
         * Constructs a new TimeLimitedBonusTicketStatus.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus_ticket.ITimeLimitedBonusTicketStatus);

        /** TimeLimitedBonusTicketStatus availableCount. */
        public availableCount?: (number);

        /** TimeLimitedBonusTicketStatus expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for TimeLimitedBonusTicketStatus
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BonusTicketAvailabilityRequireField. */
    export interface IBonusTicketAvailabilityRequireField {

        /** BonusTicketAvailabilityRequireField requireSharing */
        requireSharing?: (boolean);

        /** BonusTicketAvailabilityRequireField requireAdvertisement */
        requireAdvertisement?: (boolean);

        /** BonusTicketAvailabilityRequireField requireLogin */
        requireLogin?: (boolean);

        /** BonusTicketAvailabilityRequireField requireReview */
        requireReview?: (boolean);

        /** BonusTicketAvailabilityRequireField requireTimeLimited */
        requireTimeLimited?: (boolean);
    }

    /** Represents a BonusTicketAvailabilityRequireField. */
    class BonusTicketAvailabilityRequireField implements IBonusTicketAvailabilityRequireField {

        /**
         * Constructs a new BonusTicketAvailabilityRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus_ticket.IBonusTicketAvailabilityRequireField);

        /** BonusTicketAvailabilityRequireField requireSharing. */
        public requireSharing?: (boolean);

        /** BonusTicketAvailabilityRequireField requireAdvertisement. */
        public requireAdvertisement?: (boolean);

        /** BonusTicketAvailabilityRequireField requireLogin. */
        public requireLogin?: (boolean);

        /** BonusTicketAvailabilityRequireField requireReview. */
        public requireReview?: (boolean);

        /** BonusTicketAvailabilityRequireField requireTimeLimited. */
        public requireTimeLimited?: (boolean);

        /**
         * Gets the default type url for BonusTicketAvailabilityRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BonusTicketAvailabilityResponse. */
    export interface IBonusTicketAvailabilityResponse {

        /** BonusTicketAvailabilityResponse sharing */
        sharing?: (query_bonus_ticket.IFixedBonusTicketStatus);

        /** BonusTicketAvailabilityResponse advertisement */
        advertisement?: (query_bonus_ticket.IFixedBonusTicketStatus);

        /** BonusTicketAvailabilityResponse login */
        login?: (query_bonus_ticket.IStepBonusTicketStatus);

        /** BonusTicketAvailabilityResponse review */
        review?: (query_bonus_ticket.IFixedBonusTicketStatus);

        /** BonusTicketAvailabilityResponse timeLimited */
        timeLimited?: (query_bonus_ticket.ITimeLimitedBonusTicketStatus);
    }

    /** Represents a BonusTicketAvailabilityResponse. */
    class BonusTicketAvailabilityResponse implements IBonusTicketAvailabilityResponse {

        /**
         * Constructs a new BonusTicketAvailabilityResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_bonus_ticket.IBonusTicketAvailabilityResponse);

        /** BonusTicketAvailabilityResponse sharing. */
        public sharing?: (query_bonus_ticket.IFixedBonusTicketStatus);

        /** BonusTicketAvailabilityResponse advertisement. */
        public advertisement?: (query_bonus_ticket.IFixedBonusTicketStatus);

        /** BonusTicketAvailabilityResponse login. */
        public login?: (query_bonus_ticket.IStepBonusTicketStatus);

        /** BonusTicketAvailabilityResponse review. */
        public review?: (query_bonus_ticket.IFixedBonusTicketStatus);

        /** BonusTicketAvailabilityResponse timeLimited. */
        public timeLimited?: (query_bonus_ticket.ITimeLimitedBonusTicketStatus);

        /**
         * Gets the default type url for BonusTicketAvailabilityResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_inspection. */
export namespace query_inspection {

    /** Properties of a UserPenaltyRequest. */
    export interface IUserPenaltyRequest {

        /** UserPenaltyRequest requireIsActivated */
        requireIsActivated?: (boolean);

        /** UserPenaltyRequest requireDuration */
        requireDuration?: (boolean);

        /** UserPenaltyRequest requireActivatedAt */
        requireActivatedAt?: (boolean);

        /** UserPenaltyRequest requireExpiresAt */
        requireExpiresAt?: (boolean);
    }

    /** Represents a UserPenaltyRequest. */
    class UserPenaltyRequest implements IUserPenaltyRequest {

        /**
         * Constructs a new UserPenaltyRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_inspection.IUserPenaltyRequest);

        /** UserPenaltyRequest requireIsActivated. */
        public requireIsActivated?: (boolean);

        /** UserPenaltyRequest requireDuration. */
        public requireDuration?: (boolean);

        /** UserPenaltyRequest requireActivatedAt. */
        public requireActivatedAt?: (boolean);

        /** UserPenaltyRequest requireExpiresAt. */
        public requireExpiresAt?: (boolean);

        /**
         * Gets the default type url for UserPenaltyRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserPenaltyResponse. */
    export interface IUserPenaltyResponse {

        /** UserPenaltyResponse isActivated */
        isActivated?: (boolean);

        /** UserPenaltyResponse duration */
        duration?: (string);

        /** UserPenaltyResponse activatedAt */
        activatedAt?: (string);

        /** UserPenaltyResponse expiresAt */
        expiresAt?: (string);
    }

    /** Represents a UserPenaltyResponse. */
    class UserPenaltyResponse implements IUserPenaltyResponse {

        /**
         * Constructs a new UserPenaltyResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_inspection.IUserPenaltyResponse);

        /** UserPenaltyResponse isActivated. */
        public isActivated?: (boolean);

        /** UserPenaltyResponse duration. */
        public duration?: (string);

        /** UserPenaltyResponse activatedAt. */
        public activatedAt?: (string);

        /** UserPenaltyResponse expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for UserPenaltyResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_pagination. */
export namespace query_pagination {

    /** Properties of a PageRequest. */
    export interface IPageRequest {

        /** PageRequest pageNumber */
        pageNumber?: (number);

        /** PageRequest limit */
        limit?: (number);

        /** PageRequest requirePageInfo */
        requirePageInfo?: (query_pagination.IPageInfoRequireField);
    }

    /** Represents a PageRequest. */
    class PageRequest implements IPageRequest {

        /**
         * Constructs a new PageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_pagination.IPageRequest);

        /** PageRequest pageNumber. */
        public pageNumber?: (number);

        /** PageRequest limit. */
        public limit?: (number);

        /** PageRequest requirePageInfo. */
        public requirePageInfo?: (query_pagination.IPageInfoRequireField);

        /**
         * Gets the default type url for PageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PageInfoRequireField. */
    export interface IPageInfoRequireField {

        /** PageInfoRequireField requireHasNextPage */
        requireHasNextPage?: (boolean);

        /** PageInfoRequireField requireTotalCount */
        requireTotalCount?: (boolean);
    }

    /** Represents a PageInfoRequireField. */
    class PageInfoRequireField implements IPageInfoRequireField {

        /**
         * Constructs a new PageInfoRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_pagination.IPageInfoRequireField);

        /** PageInfoRequireField requireHasNextPage. */
        public requireHasNextPage?: (boolean);

        /** PageInfoRequireField requireTotalCount. */
        public requireTotalCount?: (boolean);

        /**
         * Gets the default type url for PageInfoRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a PageInfo. */
    export interface IPageInfo {

        /** PageInfo hasNextPage */
        hasNextPage?: (boolean);

        /** PageInfo totalCount */
        totalCount?: (number);
    }

    /** Represents a PageInfo. */
    class PageInfo implements IPageInfo {

        /**
         * Constructs a new PageInfo.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_pagination.IPageInfo);

        /** PageInfo hasNextPage. */
        public hasNextPage?: (boolean);

        /** PageInfo totalCount. */
        public totalCount?: (number);

        /**
         * Gets the default type url for PageInfo
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CursorRequest. */
    export interface ICursorRequest {

        /** CursorRequest cursor */
        cursor?: (string);

        /** CursorRequest limit */
        limit?: (number);

        /** CursorRequest requireCursorInfo */
        requireCursorInfo?: (query_pagination.ICursorInfoRequireField);
    }

    /** Represents a CursorRequest. */
    class CursorRequest implements ICursorRequest {

        /**
         * Constructs a new CursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_pagination.ICursorRequest);

        /** CursorRequest cursor. */
        public cursor?: (string);

        /** CursorRequest limit. */
        public limit?: (number);

        /** CursorRequest requireCursorInfo. */
        public requireCursorInfo?: (query_pagination.ICursorInfoRequireField);

        /**
         * Gets the default type url for CursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CursorInfoRequireField. */
    export interface ICursorInfoRequireField {

        /** CursorInfoRequireField requireEndCursor */
        requireEndCursor?: (boolean);

        /** CursorInfoRequireField requireHasNextPage */
        requireHasNextPage?: (boolean);

        /** CursorInfoRequireField requireTotalCount */
        requireTotalCount?: (boolean);
    }

    /** Represents a CursorInfoRequireField. */
    class CursorInfoRequireField implements ICursorInfoRequireField {

        /**
         * Constructs a new CursorInfoRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_pagination.ICursorInfoRequireField);

        /** CursorInfoRequireField requireEndCursor. */
        public requireEndCursor?: (boolean);

        /** CursorInfoRequireField requireHasNextPage. */
        public requireHasNextPage?: (boolean);

        /** CursorInfoRequireField requireTotalCount. */
        public requireTotalCount?: (boolean);

        /**
         * Gets the default type url for CursorInfoRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CursorInfo. */
    export interface ICursorInfo {

        /** CursorInfo endCursor */
        endCursor?: (string);

        /** CursorInfo hasNextPage */
        hasNextPage?: (boolean);

        /** CursorInfo totalCount */
        totalCount?: (number);
    }

    /** Represents a CursorInfo. */
    class CursorInfo implements ICursorInfo {

        /**
         * Constructs a new CursorInfo.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_pagination.ICursorInfo);

        /** CursorInfo endCursor. */
        public endCursor?: (string);

        /** CursorInfo hasNextPage. */
        public hasNextPage?: (boolean);

        /** CursorInfo totalCount. */
        public totalCount?: (number);

        /**
         * Gets the default type url for CursorInfo
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_recursive_types. */
export namespace query_recursive_types {

    /** Properties of a StoryRequest. */
    export interface IStoryRequest {

        /** StoryRequest id */
        id?: (string);

        /** StoryRequest requireStory */
        requireStory?: (query_recursive_types.IStoryRequireField);
    }

    /** Represents a StoryRequest. */
    class StoryRequest implements IStoryRequest {

        /**
         * Constructs a new StoryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryRequest);

        /** StoryRequest id. */
        public id?: (string);

        /** StoryRequest requireStory. */
        public requireStory?: (query_recursive_types.IStoryRequireField);

        /**
         * Gets the default type url for StoryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryChatNovelScriptRequireField. */
    export interface IStoryChatNovelScriptRequireField {

        /** StoryChatNovelScriptRequireField requireCharLength */
        requireCharLength?: (boolean);

        /** StoryChatNovelScriptRequireField requireOpLength */
        requireOpLength?: (boolean);

        /** StoryChatNovelScriptRequireField requireTrialScript */
        requireTrialScript?: (boolean);

        /** StoryChatNovelScriptRequireField requireFullScript */
        requireFullScript?: (boolean);

        /** StoryChatNovelScriptRequireField requireScriptImageList */
        requireScriptImageList?: (query_recursive_types.IImageRequireField);

        /** StoryChatNovelScriptRequireField requireScriptAudioList */
        requireScriptAudioList?: (query_recursive_types.IAudioRequireField);
    }

    /** Represents a StoryChatNovelScriptRequireField. */
    class StoryChatNovelScriptRequireField implements IStoryChatNovelScriptRequireField {

        /**
         * Constructs a new StoryChatNovelScriptRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryChatNovelScriptRequireField);

        /** StoryChatNovelScriptRequireField requireCharLength. */
        public requireCharLength?: (boolean);

        /** StoryChatNovelScriptRequireField requireOpLength. */
        public requireOpLength?: (boolean);

        /** StoryChatNovelScriptRequireField requireTrialScript. */
        public requireTrialScript?: (boolean);

        /** StoryChatNovelScriptRequireField requireFullScript. */
        public requireFullScript?: (boolean);

        /** StoryChatNovelScriptRequireField requireScriptImageList. */
        public requireScriptImageList?: (query_recursive_types.IImageRequireField);

        /** StoryChatNovelScriptRequireField requireScriptAudioList. */
        public requireScriptAudioList?: (query_recursive_types.IAudioRequireField);

        /**
         * Gets the default type url for StoryChatNovelScriptRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryChatNovelScriptResponse. */
    export interface IStoryChatNovelScriptResponse {

        /** StoryChatNovelScriptResponse charLength */
        charLength?: (number);

        /** StoryChatNovelScriptResponse opLength */
        opLength?: (number);

        /** StoryChatNovelScriptResponse trialScript */
        trialScript?: (tellerscript.ITellerScript);

        /** StoryChatNovelScriptResponse fullScript */
        fullScript?: (tellerscript.ITellerScript);

        /** StoryChatNovelScriptResponse scriptImageList */
        scriptImageList?: (query_recursive_types.IImageResponse[]);

        /** StoryChatNovelScriptResponse scriptAudioList */
        scriptAudioList?: (query_recursive_types.IAudioResponse[]);
    }

    /** Represents a StoryChatNovelScriptResponse. */
    class StoryChatNovelScriptResponse implements IStoryChatNovelScriptResponse {

        /**
         * Constructs a new StoryChatNovelScriptResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryChatNovelScriptResponse);

        /** StoryChatNovelScriptResponse charLength. */
        public charLength?: (number);

        /** StoryChatNovelScriptResponse opLength. */
        public opLength?: (number);

        /** StoryChatNovelScriptResponse trialScript. */
        public trialScript?: (tellerscript.ITellerScript);

        /** StoryChatNovelScriptResponse fullScript. */
        public fullScript?: (tellerscript.ITellerScript);

        /** StoryChatNovelScriptResponse scriptImageList. */
        public scriptImageList: query_recursive_types.IImageResponse[];

        /** StoryChatNovelScriptResponse scriptAudioList. */
        public scriptAudioList: query_recursive_types.IAudioResponse[];

        /**
         * Gets the default type url for StoryChatNovelScriptResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryNovelScriptRequireField. */
    export interface IStoryNovelScriptRequireField {

        /** StoryNovelScriptRequireField requireCharLength */
        requireCharLength?: (boolean);

        /** StoryNovelScriptRequireField requireTrialScript */
        requireTrialScript?: (boolean);

        /** StoryNovelScriptRequireField requireFullScript */
        requireFullScript?: (boolean);

        /** StoryNovelScriptRequireField requireTrialHtmlScript */
        requireTrialHtmlScript?: (boolean);

        /** StoryNovelScriptRequireField requireFullHtmlScript */
        requireFullHtmlScript?: (boolean);

        /** StoryNovelScriptRequireField requireTrialHtmlBodyScript */
        requireTrialHtmlBodyScript?: (boolean);

        /** StoryNovelScriptRequireField requireFullHtmlBodyScript */
        requireFullHtmlBodyScript?: (boolean);
    }

    /** Represents a StoryNovelScriptRequireField. */
    class StoryNovelScriptRequireField implements IStoryNovelScriptRequireField {

        /**
         * Constructs a new StoryNovelScriptRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryNovelScriptRequireField);

        /** StoryNovelScriptRequireField requireCharLength. */
        public requireCharLength?: (boolean);

        /** StoryNovelScriptRequireField requireTrialScript. */
        public requireTrialScript?: (boolean);

        /** StoryNovelScriptRequireField requireFullScript. */
        public requireFullScript?: (boolean);

        /** StoryNovelScriptRequireField requireTrialHtmlScript. */
        public requireTrialHtmlScript?: (boolean);

        /** StoryNovelScriptRequireField requireFullHtmlScript. */
        public requireFullHtmlScript?: (boolean);

        /** StoryNovelScriptRequireField requireTrialHtmlBodyScript. */
        public requireTrialHtmlBodyScript?: (boolean);

        /** StoryNovelScriptRequireField requireFullHtmlBodyScript. */
        public requireFullHtmlBodyScript?: (boolean);

        /**
         * Gets the default type url for StoryNovelScriptRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryNovelScriptResponse. */
    export interface IStoryNovelScriptResponse {

        /** StoryNovelScriptResponse charLength */
        charLength?: (number);

        /** StoryNovelScriptResponse trialScript */
        trialScript?: (string);

        /** StoryNovelScriptResponse fullScript */
        fullScript?: (string);

        /** StoryNovelScriptResponse trialHtmlScript */
        trialHtmlScript?: (string);

        /** StoryNovelScriptResponse fullHtmlScript */
        fullHtmlScript?: (string);

        /** StoryNovelScriptResponse trialHtmlBodyScript */
        trialHtmlBodyScript?: (string);

        /** StoryNovelScriptResponse fullHtmlBodyScript */
        fullHtmlBodyScript?: (string);
    }

    /** Represents a StoryNovelScriptResponse. */
    class StoryNovelScriptResponse implements IStoryNovelScriptResponse {

        /**
         * Constructs a new StoryNovelScriptResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryNovelScriptResponse);

        /** StoryNovelScriptResponse charLength. */
        public charLength?: (number);

        /** StoryNovelScriptResponse trialScript. */
        public trialScript?: (string);

        /** StoryNovelScriptResponse fullScript. */
        public fullScript?: (string);

        /** StoryNovelScriptResponse trialHtmlScript. */
        public trialHtmlScript?: (string);

        /** StoryNovelScriptResponse fullHtmlScript. */
        public fullHtmlScript?: (string);

        /** StoryNovelScriptResponse trialHtmlBodyScript. */
        public trialHtmlBodyScript?: (string);

        /** StoryNovelScriptResponse fullHtmlBodyScript. */
        public fullHtmlBodyScript?: (string);

        /**
         * Gets the default type url for StoryNovelScriptResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryVODContentRequest. */
    export interface IStoryVODContentRequest {

        /** StoryVODContentRequest unionInfo */
        unionInfo?: (string);

        /** StoryVODContentRequest requireVodContent */
        requireVodContent?: (query_recursive_types.IStoryVODContentRequireField);
    }

    /** Represents a StoryVODContentRequest. */
    class StoryVODContentRequest implements IStoryVODContentRequest {

        /**
         * Constructs a new StoryVODContentRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryVODContentRequest);

        /** StoryVODContentRequest unionInfo. */
        public unionInfo?: (string);

        /** StoryVODContentRequest requireVodContent. */
        public requireVodContent?: (query_recursive_types.IStoryVODContentRequireField);

        /**
         * Gets the default type url for StoryVODContentRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryVODContentRequireField. */
    export interface IStoryVODContentRequireField {

        /** StoryVODContentRequireField requireVideoId */
        requireVideoId?: (boolean);

        /** StoryVODContentRequireField requirePlayAuthToken */
        requirePlayAuthToken?: (boolean);

        /** StoryVODContentRequireField requirePrivateDrmAuthToken */
        requirePrivateDrmAuthToken?: (boolean);

        /** StoryVODContentRequireField requireSha1HlsDrmAuthToken */
        requireSha1HlsDrmAuthToken?: (boolean);

        /** StoryVODContentRequireField requirePlayInfoList */
        requirePlayInfoList?: (boolean);

        /** StoryVODContentRequireField requirePosterUrl */
        requirePosterUrl?: (query_recursive_types.StoryVODContentRequireField.IPosterImageRequest);
    }

    /** Represents a StoryVODContentRequireField. */
    class StoryVODContentRequireField implements IStoryVODContentRequireField {

        /**
         * Constructs a new StoryVODContentRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryVODContentRequireField);

        /** StoryVODContentRequireField requireVideoId. */
        public requireVideoId?: (boolean);

        /** StoryVODContentRequireField requirePlayAuthToken. */
        public requirePlayAuthToken?: (boolean);

        /** StoryVODContentRequireField requirePrivateDrmAuthToken. */
        public requirePrivateDrmAuthToken?: (boolean);

        /** StoryVODContentRequireField requireSha1HlsDrmAuthToken. */
        public requireSha1HlsDrmAuthToken?: (boolean);

        /** StoryVODContentRequireField requirePlayInfoList. */
        public requirePlayInfoList?: (boolean);

        /** StoryVODContentRequireField requirePosterUrl. */
        public requirePosterUrl?: (query_recursive_types.StoryVODContentRequireField.IPosterImageRequest);

        /**
         * Gets the default type url for StoryVODContentRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace StoryVODContentRequireField {

        /** Properties of a PosterImageRequest. */
        export interface IPosterImageRequest {

            /** PosterImageRequest width */
            width?: (number);

            /** PosterImageRequest height */
            height?: (number);

            /** PosterImageRequest format */
            format?: (query_recursive_types.StoryVODContentRequireField.PosterImageRequest.PosterImageFormat);
        }

        /** Represents a PosterImageRequest. */
        class PosterImageRequest implements IPosterImageRequest {

            /**
             * Constructs a new PosterImageRequest.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_recursive_types.StoryVODContentRequireField.IPosterImageRequest);

            /** PosterImageRequest width. */
            public width?: (number);

            /** PosterImageRequest height. */
            public height?: (number);

            /** PosterImageRequest format. */
            public format: query_recursive_types.StoryVODContentRequireField.PosterImageRequest.PosterImageFormat;

            /**
             * Gets the default type url for PosterImageRequest
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        export namespace PosterImageRequest {

            /** PosterImageFormat enum. */
            export enum PosterImageFormat {
                POSTER_IMAGE_FORMAT_JPEG = "POSTER_IMAGE_FORMAT_JPEG",
                POSTER_IMAGE_FORMAT_WEBP = "POSTER_IMAGE_FORMAT_WEBP"
            }
        }
    }

    /** Properties of a StoryVODContentResponse. */
    export interface IStoryVODContentResponse {

        /** StoryVODContentResponse videoId */
        videoId?: (string);

        /** StoryVODContentResponse playAuthToken */
        playAuthToken?: (string);

        /** StoryVODContentResponse privateDrmAuthToken */
        privateDrmAuthToken?: (string);

        /** StoryVODContentResponse sha1HlsDrmAuthToken */
        sha1HlsDrmAuthToken?: (string);

        /** StoryVODContentResponse playInfoList */
        playInfoList?: (query_recursive_types.StoryVODContentResponse.IStoryVODPlayInfo[]);

        /** StoryVODContentResponse posterUrl */
        posterUrl?: (string);
    }

    /** Represents a StoryVODContentResponse. */
    class StoryVODContentResponse implements IStoryVODContentResponse {

        /**
         * Constructs a new StoryVODContentResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryVODContentResponse);

        /** StoryVODContentResponse videoId. */
        public videoId?: (string);

        /** StoryVODContentResponse playAuthToken. */
        public playAuthToken?: (string);

        /** StoryVODContentResponse privateDrmAuthToken. */
        public privateDrmAuthToken?: (string);

        /** StoryVODContentResponse sha1HlsDrmAuthToken. */
        public sha1HlsDrmAuthToken?: (string);

        /** StoryVODContentResponse playInfoList. */
        public playInfoList: query_recursive_types.StoryVODContentResponse.IStoryVODPlayInfo[];

        /** StoryVODContentResponse posterUrl. */
        public posterUrl?: (string);

        /**
         * Gets the default type url for StoryVODContentResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace StoryVODContentResponse {

        /** Properties of a StoryVODPlayInfo. */
        export interface IStoryVODPlayInfo {

            /** StoryVODPlayInfo definition */
            definition?: (string);

            /** StoryVODPlayInfo url */
            url?: (string);

            /** StoryVODPlayInfo width */
            width?: (number);

            /** StoryVODPlayInfo height */
            height?: (number);

            /** StoryVODPlayInfo auth */
            auth?: (string);
        }

        /** Represents a StoryVODPlayInfo. */
        class StoryVODPlayInfo implements IStoryVODPlayInfo {

            /**
             * Constructs a new StoryVODPlayInfo.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_recursive_types.StoryVODContentResponse.IStoryVODPlayInfo);

            /** StoryVODPlayInfo definition. */
            public definition?: (string);

            /** StoryVODPlayInfo url. */
            public url?: (string);

            /** StoryVODPlayInfo width. */
            public width?: (number);

            /** StoryVODPlayInfo height. */
            public height?: (number);

            /** StoryVODPlayInfo auth. */
            public auth?: (string);

            /**
             * Gets the default type url for StoryVODPlayInfo
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a StoryRequireField. */
    export interface IStoryRequireField {

        /** StoryRequireField requireId */
        requireId?: (boolean);

        /** StoryRequireField requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);

        /** StoryRequireField requireTags */
        requireTags?: (boolean);

        /** StoryRequireField requireSeries */
        requireSeries?: (query_recursive_types.ISeriesRequireField);

        /** StoryRequireField requireSeriesIndex */
        requireSeriesIndex?: (boolean);

        /** StoryRequireField requireTitle */
        requireTitle?: (boolean);

        /** StoryRequireField requireDescription */
        requireDescription?: (boolean);

        /** StoryRequireField requireOpening */
        requireOpening?: (boolean);

        /** StoryRequireField requireThumbnail */
        requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** StoryRequireField requireStatus */
        requireStatus?: (boolean);

        /** StoryRequireField requireIsOfficial */
        requireIsOfficial?: (boolean);

        /** StoryRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** StoryRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);

        /** StoryRequireField requirePublishedAt */
        requirePublishedAt?: (boolean);

        /** StoryRequireField requireAvailability */
        requireAvailability?: (boolean);

        /** StoryRequireField requireLikeCount */
        requireLikeCount?: (boolean);

        /** StoryRequireField requireCommentPermission */
        requireCommentPermission?: (query_recursive_types.ICommentPermissionRequireField);

        /** StoryRequireField requireCommentSection */
        requireCommentSection?: (query_recursive_types.ICommentSectionRequireField);

        /** StoryRequireField requireAdvertisementPage */
        requireAdvertisementPage?: (query_recursive_types.IStoryAdvertisementCursorRequest);

        /** StoryRequireField requireUserBookmarked */
        requireUserBookmarked?: (boolean);

        /** StoryRequireField requireUserReadingProgress */
        requireUserReadingProgress?: (query_recursive_types.IStoryReadingProgressRequireField);

        /** StoryRequireField requireUserAvailability */
        requireUserAvailability?: (boolean);

        /** StoryRequireField requireNextStory */
        requireNextStory?: (query_recursive_types.IStoryRequireField);

        /** StoryRequireField requireNextSibling */
        requireNextSibling?: (query_recursive_types.IStoryRequireField);

        /** StoryRequireField requirePreviousSibling */
        requirePreviousSibling?: (query_recursive_types.IStoryRequireField);

        /** StoryRequireField requireReviewComment */
        requireReviewComment?: (query_recursive_types.IStoryReviewCommentRequireField);

        /** StoryRequireField requireScriptType */
        requireScriptType?: (boolean);

        /** StoryRequireField requireChatNovelScript */
        requireChatNovelScript?: (query_recursive_types.IStoryChatNovelScriptRequireField);

        /** StoryRequireField requireNovelScript */
        requireNovelScript?: (query_recursive_types.IStoryNovelScriptRequireField);

        /** StoryRequireField requireVideo */
        requireVideo?: (story_video.IStoryVideoRequest);

        /** StoryRequireField requireViewCount */
        requireViewCount?: (boolean);

        /** StoryRequireField requireCanShowGoogleAd */
        requireCanShowGoogleAd?: (boolean);

        /** StoryRequireField requireOriginalFlag */
        requireOriginalFlag?: (boolean);

        /** StoryRequireField requireIsAdminSensitive */
        requireIsAdminSensitive?: (boolean);

        /** StoryRequireField requireIsUserSensitive */
        requireIsUserSensitive?: (boolean);

        /** StoryRequireField requireVideoScript */
        requireVideoScript?: (query_recursive_types.IStoryVODContentRequest);

        /** StoryRequireField requireAudioScript */
        requireAudioScript?: (query_recursive_types.IStoryVODContentRequest);

        /** StoryRequireField requirePrice */
        requirePrice?: (boolean);

        /** StoryRequireField requireUserLicenseExpiresAt */
        requireUserLicenseExpiresAt?: (boolean);

        /** StoryRequireField requireVideoList */
        requireVideoList?: (story_video.IStoryVideoRequest);

        /** StoryRequireField requireLength */
        requireLength?: (boolean);

        /** StoryRequireField requireRelatedStoryPage */
        requireRelatedStoryPage?: (query_recursive_types.IStoryCursorRequest);

        /** StoryRequireField requireTrialScript */
        requireTrialScript?: (boolean);

        /** StoryRequireField requireFullScript */
        requireFullScript?: (boolean);

        /** StoryRequireField requireScriptImageList */
        requireScriptImageList?: (query_recursive_types.IImageRequireField);

        /** StoryRequireField requireScriptAudioList */
        requireScriptAudioList?: (query_recursive_types.IAudioRequireField);

        /** StoryRequireField requireSearchRelatedStory */
        requireSearchRelatedStory?: (query_recursive_types.ISearchStoryRequest);

        /** StoryRequireField requireSensitiveFlag */
        requireSensitiveFlag?: (boolean);
    }

    /** Represents a StoryRequireField. */
    class StoryRequireField implements IStoryRequireField {

        /**
         * Constructs a new StoryRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryRequireField);

        /** StoryRequireField requireId. */
        public requireId?: (boolean);

        /** StoryRequireField requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /** StoryRequireField requireTags. */
        public requireTags?: (boolean);

        /** StoryRequireField requireSeries. */
        public requireSeries?: (query_recursive_types.ISeriesRequireField);

        /** StoryRequireField requireSeriesIndex. */
        public requireSeriesIndex?: (boolean);

        /** StoryRequireField requireTitle. */
        public requireTitle?: (boolean);

        /** StoryRequireField requireDescription. */
        public requireDescription?: (boolean);

        /** StoryRequireField requireOpening. */
        public requireOpening?: (boolean);

        /** StoryRequireField requireThumbnail. */
        public requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** StoryRequireField requireStatus. */
        public requireStatus?: (boolean);

        /** StoryRequireField requireIsOfficial. */
        public requireIsOfficial?: (boolean);

        /** StoryRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** StoryRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /** StoryRequireField requirePublishedAt. */
        public requirePublishedAt?: (boolean);

        /** StoryRequireField requireAvailability. */
        public requireAvailability?: (boolean);

        /** StoryRequireField requireLikeCount. */
        public requireLikeCount?: (boolean);

        /** StoryRequireField requireCommentPermission. */
        public requireCommentPermission?: (query_recursive_types.ICommentPermissionRequireField);

        /** StoryRequireField requireCommentSection. */
        public requireCommentSection?: (query_recursive_types.ICommentSectionRequireField);

        /** StoryRequireField requireAdvertisementPage. */
        public requireAdvertisementPage?: (query_recursive_types.IStoryAdvertisementCursorRequest);

        /** StoryRequireField requireUserBookmarked. */
        public requireUserBookmarked?: (boolean);

        /** StoryRequireField requireUserReadingProgress. */
        public requireUserReadingProgress?: (query_recursive_types.IStoryReadingProgressRequireField);

        /** StoryRequireField requireUserAvailability. */
        public requireUserAvailability?: (boolean);

        /** StoryRequireField requireNextStory. */
        public requireNextStory?: (query_recursive_types.IStoryRequireField);

        /** StoryRequireField requireNextSibling. */
        public requireNextSibling?: (query_recursive_types.IStoryRequireField);

        /** StoryRequireField requirePreviousSibling. */
        public requirePreviousSibling?: (query_recursive_types.IStoryRequireField);

        /** StoryRequireField requireReviewComment. */
        public requireReviewComment?: (query_recursive_types.IStoryReviewCommentRequireField);

        /** StoryRequireField requireScriptType. */
        public requireScriptType?: (boolean);

        /** StoryRequireField requireChatNovelScript. */
        public requireChatNovelScript?: (query_recursive_types.IStoryChatNovelScriptRequireField);

        /** StoryRequireField requireNovelScript. */
        public requireNovelScript?: (query_recursive_types.IStoryNovelScriptRequireField);

        /** StoryRequireField requireVideo. */
        public requireVideo?: (story_video.IStoryVideoRequest);

        /** StoryRequireField requireViewCount. */
        public requireViewCount?: (boolean);

        /** StoryRequireField requireCanShowGoogleAd. */
        public requireCanShowGoogleAd?: (boolean);

        /** StoryRequireField requireOriginalFlag. */
        public requireOriginalFlag?: (boolean);

        /** StoryRequireField requireIsAdminSensitive. */
        public requireIsAdminSensitive?: (boolean);

        /** StoryRequireField requireIsUserSensitive. */
        public requireIsUserSensitive?: (boolean);

        /** StoryRequireField requireVideoScript. */
        public requireVideoScript?: (query_recursive_types.IStoryVODContentRequest);

        /** StoryRequireField requireAudioScript. */
        public requireAudioScript?: (query_recursive_types.IStoryVODContentRequest);

        /** StoryRequireField requirePrice. */
        public requirePrice?: (boolean);

        /** StoryRequireField requireUserLicenseExpiresAt. */
        public requireUserLicenseExpiresAt?: (boolean);

        /** StoryRequireField requireVideoList. */
        public requireVideoList?: (story_video.IStoryVideoRequest);

        /** StoryRequireField requireLength. */
        public requireLength?: (boolean);

        /** StoryRequireField requireRelatedStoryPage. */
        public requireRelatedStoryPage?: (query_recursive_types.IStoryCursorRequest);

        /** StoryRequireField requireTrialScript. */
        public requireTrialScript?: (boolean);

        /** StoryRequireField requireFullScript. */
        public requireFullScript?: (boolean);

        /** StoryRequireField requireScriptImageList. */
        public requireScriptImageList?: (query_recursive_types.IImageRequireField);

        /** StoryRequireField requireScriptAudioList. */
        public requireScriptAudioList?: (query_recursive_types.IAudioRequireField);

        /** StoryRequireField requireSearchRelatedStory. */
        public requireSearchRelatedStory?: (query_recursive_types.ISearchStoryRequest);

        /** StoryRequireField requireSensitiveFlag. */
        public requireSensitiveFlag?: (boolean);

        /**
         * Gets the default type url for StoryRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryResponse. */
    export interface IStoryResponse {

        /** StoryResponse id */
        id?: (string);

        /** StoryResponse user */
        user?: (query_recursive_types.IUserResponse);

        /** StoryResponse tags */
        tags?: (string[]);

        /** StoryResponse series */
        series?: (query_recursive_types.ISeriesResponse);

        /** StoryResponse seriesIndex */
        seriesIndex?: (number);

        /** StoryResponse title */
        title?: (string);

        /** StoryResponse description */
        description?: (string);

        /** StoryResponse opening */
        opening?: (string);

        /** StoryResponse thumbnail */
        thumbnail?: (query_recursive_types.IImageResponse);

        /** StoryResponse status */
        status?: (story.StoryStatus);

        /** StoryResponse isOfficial */
        isOfficial?: (boolean);

        /** StoryResponse createdAt */
        createdAt?: (string);

        /** StoryResponse updatedAt */
        updatedAt?: (string);

        /** StoryResponse publishedAt */
        publishedAt?: (string);

        /** StoryResponse availability */
        availability?: (query_types.StoryAvailability);

        /** StoryResponse likeCount */
        likeCount?: (number);

        /** StoryResponse commentPermission */
        commentPermission?: (query_recursive_types.ICommentPermissionResponse);

        /** StoryResponse commentSection */
        commentSection?: (query_recursive_types.ICommentSectionResponse);

        /** StoryResponse advertisementPage */
        advertisementPage?: (query_recursive_types.IStoryAdvertisementCursorResponse);

        /** StoryResponse userBookmarked */
        userBookmarked?: (boolean);

        /** StoryResponse userReadingProgress */
        userReadingProgress?: (query_recursive_types.IStoryReadingProgressResponse);

        /** StoryResponse userAvailability */
        userAvailability?: (query_types.StoryAvailability);

        /** StoryResponse nextStory */
        nextStory?: (query_recursive_types.IStoryResponse);

        /** StoryResponse nextSibling */
        nextSibling?: (query_recursive_types.IStoryResponse);

        /** StoryResponse previousSibling */
        previousSibling?: (query_recursive_types.IStoryResponse);

        /** StoryResponse reviewComment */
        reviewComment?: (query_recursive_types.IStoryReviewCommentResponse);

        /** StoryResponse scriptType */
        scriptType?: (query_types.StoryScriptType);

        /** StoryResponse chatNovelScript */
        chatNovelScript?: (query_recursive_types.IStoryChatNovelScriptResponse);

        /** StoryResponse novelScript */
        novelScript?: (query_recursive_types.IStoryNovelScriptResponse);

        /** StoryResponse video */
        video?: (story_video.IStoryVideoResponse);

        /** StoryResponse viewCount */
        viewCount?: (number);

        /** StoryResponse canShowGoogleAd */
        canShowGoogleAd?: (boolean);

        /** StoryResponse originalFlag */
        originalFlag?: (query_types.OriginalFlag);

        /** StoryResponse isAdminSensitive */
        isAdminSensitive?: (boolean);

        /** StoryResponse isUserSensitive */
        isUserSensitive?: (boolean);

        /** StoryResponse videoScript */
        videoScript?: (query_recursive_types.IStoryVODContentResponse);

        /** StoryResponse audioScript */
        audioScript?: (query_recursive_types.IStoryVODContentResponse);

        /** StoryResponse price */
        price?: (number);

        /** StoryResponse userLicenseExpiresAt */
        userLicenseExpiresAt?: (string);

        /** StoryResponse videoList */
        videoList?: (story_video.IStoryVideo[]);

        /** StoryResponse length */
        length?: (number);

        /** StoryResponse relatedStoryPage */
        relatedStoryPage?: (query_recursive_types.IStoryCursorResponse);

        /** StoryResponse trialScript */
        trialScript?: (tellerscript.ITellerScript);

        /** StoryResponse fullScript */
        fullScript?: (tellerscript.ITellerScript);

        /** StoryResponse scriptImageList */
        scriptImageList?: (query_recursive_types.IImageResponse[]);

        /** StoryResponse scriptAudioList */
        scriptAudioList?: (query_recursive_types.IAudioResponse[]);

        /** StoryResponse searchRelatedStory */
        searchRelatedStory?: (query_recursive_types.ISearchStoryResponse);

        /** StoryResponse sensitiveFlag */
        sensitiveFlag?: (query_types.SensitiveFlag);
    }

    /** Represents a StoryResponse. */
    class StoryResponse implements IStoryResponse {

        /**
         * Constructs a new StoryResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryResponse);

        /** StoryResponse id. */
        public id?: (string);

        /** StoryResponse user. */
        public user?: (query_recursive_types.IUserResponse);

        /** StoryResponse tags. */
        public tags: string[];

        /** StoryResponse series. */
        public series?: (query_recursive_types.ISeriesResponse);

        /** StoryResponse seriesIndex. */
        public seriesIndex?: (number);

        /** StoryResponse title. */
        public title?: (string);

        /** StoryResponse description. */
        public description?: (string);

        /** StoryResponse opening. */
        public opening?: (string);

        /** StoryResponse thumbnail. */
        public thumbnail?: (query_recursive_types.IImageResponse);

        /** StoryResponse status. */
        public status: story.StoryStatus;

        /** StoryResponse isOfficial. */
        public isOfficial?: (boolean);

        /** StoryResponse createdAt. */
        public createdAt?: (string);

        /** StoryResponse updatedAt. */
        public updatedAt?: (string);

        /** StoryResponse publishedAt. */
        public publishedAt?: (string);

        /** StoryResponse availability. */
        public availability: query_types.StoryAvailability;

        /** StoryResponse likeCount. */
        public likeCount?: (number);

        /** StoryResponse commentPermission. */
        public commentPermission?: (query_recursive_types.ICommentPermissionResponse);

        /** StoryResponse commentSection. */
        public commentSection?: (query_recursive_types.ICommentSectionResponse);

        /** StoryResponse advertisementPage. */
        public advertisementPage?: (query_recursive_types.IStoryAdvertisementCursorResponse);

        /** StoryResponse userBookmarked. */
        public userBookmarked?: (boolean);

        /** StoryResponse userReadingProgress. */
        public userReadingProgress?: (query_recursive_types.IStoryReadingProgressResponse);

        /** StoryResponse userAvailability. */
        public userAvailability: query_types.StoryAvailability;

        /** StoryResponse nextStory. */
        public nextStory?: (query_recursive_types.IStoryResponse);

        /** StoryResponse nextSibling. */
        public nextSibling?: (query_recursive_types.IStoryResponse);

        /** StoryResponse previousSibling. */
        public previousSibling?: (query_recursive_types.IStoryResponse);

        /** StoryResponse reviewComment. */
        public reviewComment?: (query_recursive_types.IStoryReviewCommentResponse);

        /** StoryResponse scriptType. */
        public scriptType: query_types.StoryScriptType;

        /** StoryResponse chatNovelScript. */
        public chatNovelScript?: (query_recursive_types.IStoryChatNovelScriptResponse);

        /** StoryResponse novelScript. */
        public novelScript?: (query_recursive_types.IStoryNovelScriptResponse);

        /** StoryResponse video. */
        public video?: (story_video.IStoryVideoResponse);

        /** StoryResponse viewCount. */
        public viewCount?: (number);

        /** StoryResponse canShowGoogleAd. */
        public canShowGoogleAd?: (boolean);

        /** StoryResponse originalFlag. */
        public originalFlag: query_types.OriginalFlag;

        /** StoryResponse isAdminSensitive. */
        public isAdminSensitive?: (boolean);

        /** StoryResponse isUserSensitive. */
        public isUserSensitive?: (boolean);

        /** StoryResponse videoScript. */
        public videoScript?: (query_recursive_types.IStoryVODContentResponse);

        /** StoryResponse audioScript. */
        public audioScript?: (query_recursive_types.IStoryVODContentResponse);

        /** StoryResponse price. */
        public price?: (number);

        /** StoryResponse userLicenseExpiresAt. */
        public userLicenseExpiresAt?: (string);

        /** StoryResponse videoList. */
        public videoList: story_video.IStoryVideo[];

        /** StoryResponse length. */
        public length?: (number);

        /** StoryResponse relatedStoryPage. */
        public relatedStoryPage?: (query_recursive_types.IStoryCursorResponse);

        /** StoryResponse trialScript. */
        public trialScript?: (tellerscript.ITellerScript);

        /** StoryResponse fullScript. */
        public fullScript?: (tellerscript.ITellerScript);

        /** StoryResponse scriptImageList. */
        public scriptImageList: query_recursive_types.IImageResponse[];

        /** StoryResponse scriptAudioList. */
        public scriptAudioList: query_recursive_types.IAudioResponse[];

        /** StoryResponse searchRelatedStory. */
        public searchRelatedStory?: (query_recursive_types.ISearchStoryResponse);

        /** StoryResponse sensitiveFlag. */
        public sensitiveFlag: query_types.SensitiveFlag;

        /**
         * Gets the default type url for StoryResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CharacterRequireField. */
    export interface ICharacterRequireField {

        /** CharacterRequireField requireId */
        requireId?: (boolean);

        /** CharacterRequireField requireName */
        requireName?: (boolean);

        /** CharacterRequireField requireIcon */
        requireIcon?: (query_recursive_types.IImageRequireField);
    }

    /** Represents a CharacterRequireField. */
    class CharacterRequireField implements ICharacterRequireField {

        /**
         * Constructs a new CharacterRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICharacterRequireField);

        /** CharacterRequireField requireId. */
        public requireId?: (boolean);

        /** CharacterRequireField requireName. */
        public requireName?: (boolean);

        /** CharacterRequireField requireIcon. */
        public requireIcon?: (query_recursive_types.IImageRequireField);

        /**
         * Gets the default type url for CharacterRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CharacterResponse. */
    export interface ICharacterResponse {

        /** CharacterResponse id */
        id?: (string);

        /** CharacterResponse name */
        name?: (string);

        /** CharacterResponse icon */
        icon?: (query_recursive_types.IImageResponse);
    }

    /** Represents a CharacterResponse. */
    class CharacterResponse implements ICharacterResponse {

        /**
         * Constructs a new CharacterResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICharacterResponse);

        /** CharacterResponse id. */
        public id?: (string);

        /** CharacterResponse name. */
        public name?: (string);

        /** CharacterResponse icon. */
        public icon?: (query_recursive_types.IImageResponse);

        /**
         * Gets the default type url for CharacterResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchStoryRequest. */
    export interface ISearchStoryRequest {

        /** SearchStoryRequest params */
        params?: (query_search_types.ISearchStoryParams);

        /** SearchStoryRequest requireStoryPage */
        requireStoryPage?: (query_recursive_types.IStoryPageRequest);

        /** SearchStoryRequest requireFacetList */
        requireFacetList?: (query_search_types.ISearchStoryFacetRequest);
    }

    /** Represents a SearchStoryRequest. */
    class SearchStoryRequest implements ISearchStoryRequest {

        /**
         * Constructs a new SearchStoryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISearchStoryRequest);

        /** SearchStoryRequest params. */
        public params?: (query_search_types.ISearchStoryParams);

        /** SearchStoryRequest requireStoryPage. */
        public requireStoryPage?: (query_recursive_types.IStoryPageRequest);

        /** SearchStoryRequest requireFacetList. */
        public requireFacetList?: (query_search_types.ISearchStoryFacetRequest);

        /**
         * Gets the default type url for SearchStoryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchStoryResponse. */
    export interface ISearchStoryResponse {

        /** SearchStoryResponse storyPage */
        storyPage?: (query_recursive_types.IStoryPageResponse);

        /** SearchStoryResponse facetList */
        facetList?: (query_search_types.ISearchStoryFacetResponse[]);
    }

    /** Represents a SearchStoryResponse. */
    class SearchStoryResponse implements ISearchStoryResponse {

        /**
         * Constructs a new SearchStoryResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISearchStoryResponse);

        /** SearchStoryResponse storyPage. */
        public storyPage?: (query_recursive_types.IStoryPageResponse);

        /** SearchStoryResponse facetList. */
        public facetList: query_search_types.ISearchStoryFacetResponse[];

        /**
         * Gets the default type url for SearchStoryResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryPageRequest. */
    export interface IStoryPageRequest {

        /** StoryPageRequest requireStoryList */
        requireStoryList?: (query_recursive_types.IStoryRequireField);

        /** StoryPageRequest page */
        page?: (query_pagination.IPageRequest);
    }

    /** Represents a StoryPageRequest. */
    class StoryPageRequest implements IStoryPageRequest {

        /**
         * Constructs a new StoryPageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryPageRequest);

        /** StoryPageRequest requireStoryList. */
        public requireStoryList?: (query_recursive_types.IStoryRequireField);

        /** StoryPageRequest page. */
        public page?: (query_pagination.IPageRequest);

        /**
         * Gets the default type url for StoryPageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryPageResponse. */
    export interface IStoryPageResponse {

        /** StoryPageResponse storyList */
        storyList?: (query_recursive_types.IStoryResponse[]);

        /** StoryPageResponse page */
        page?: (query_pagination.IPageInfo);
    }

    /** Represents a StoryPageResponse. */
    class StoryPageResponse implements IStoryPageResponse {

        /**
         * Constructs a new StoryPageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryPageResponse);

        /** StoryPageResponse storyList. */
        public storyList: query_recursive_types.IStoryResponse[];

        /** StoryPageResponse page. */
        public page?: (query_pagination.IPageInfo);

        /**
         * Gets the default type url for StoryPageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** SeriesStorySortBy enum. */
    export enum SeriesStorySortBy {
        SERIES_STORY_SORT_BY_UNSPECIFIED = "SERIES_STORY_SORT_BY_UNSPECIFIED",
        SERIES_STORY_SORT_BY_SERIES_INDEX_ASC = "SERIES_STORY_SORT_BY_SERIES_INDEX_ASC",
        SERIES_STORY_SORT_BY_SERIES_INDEX_DESC = "SERIES_STORY_SORT_BY_SERIES_INDEX_DESC"
    }

    /** Properties of a SeriesStoryPageRequest. */
    export interface ISeriesStoryPageRequest {

        /** SeriesStoryPageRequest requireStoryList */
        requireStoryList?: (query_recursive_types.IStoryRequireField);

        /** SeriesStoryPageRequest page */
        page?: (query_pagination.IPageRequest);

        /** SeriesStoryPageRequest sortBy */
        sortBy?: (query_recursive_types.SeriesStorySortBy);

        /** SeriesStoryPageRequest filterStatus */
        filterStatus?: (story.StoryStatus);
    }

    /** Represents a SeriesStoryPageRequest. */
    class SeriesStoryPageRequest implements ISeriesStoryPageRequest {

        /**
         * Constructs a new SeriesStoryPageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesStoryPageRequest);

        /** SeriesStoryPageRequest requireStoryList. */
        public requireStoryList?: (query_recursive_types.IStoryRequireField);

        /** SeriesStoryPageRequest page. */
        public page?: (query_pagination.IPageRequest);

        /** SeriesStoryPageRequest sortBy. */
        public sortBy: query_recursive_types.SeriesStorySortBy;

        /** SeriesStoryPageRequest filterStatus. */
        public filterStatus: story.StoryStatus;

        /**
         * Gets the default type url for SeriesStoryPageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesStoryPageResponse. */
    export interface ISeriesStoryPageResponse {

        /** SeriesStoryPageResponse storyList */
        storyList?: (query_recursive_types.IStoryResponse[]);

        /** SeriesStoryPageResponse page */
        page?: (query_pagination.IPageInfo);
    }

    /** Represents a SeriesStoryPageResponse. */
    class SeriesStoryPageResponse implements ISeriesStoryPageResponse {

        /**
         * Constructs a new SeriesStoryPageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesStoryPageResponse);

        /** SeriesStoryPageResponse storyList. */
        public storyList: query_recursive_types.IStoryResponse[];

        /** SeriesStoryPageResponse page. */
        public page?: (query_pagination.IPageInfo);

        /**
         * Gets the default type url for SeriesStoryPageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryCursorRequest. */
    export interface IStoryCursorRequest {

        /** StoryCursorRequest requireStoryList */
        requireStoryList?: (query_recursive_types.IStoryRequireField);

        /** StoryCursorRequest filter */
        filter?: (query_types.IStoryFilter);

        /** StoryCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);
    }

    /** Represents a StoryCursorRequest. */
    class StoryCursorRequest implements IStoryCursorRequest {

        /**
         * Constructs a new StoryCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryCursorRequest);

        /** StoryCursorRequest requireStoryList. */
        public requireStoryList?: (query_recursive_types.IStoryRequireField);

        /** StoryCursorRequest filter. */
        public filter?: (query_types.IStoryFilter);

        /** StoryCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /**
         * Gets the default type url for StoryCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryCursorResponse. */
    export interface IStoryCursorResponse {

        /** StoryCursorResponse storyList */
        storyList?: (query_recursive_types.IStoryResponse[]);

        /** StoryCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a StoryCursorResponse. */
    class StoryCursorResponse implements IStoryCursorResponse {

        /**
         * Constructs a new StoryCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryCursorResponse);

        /** StoryCursorResponse storyList. */
        public storyList: query_recursive_types.IStoryResponse[];

        /** StoryCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for StoryCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryReadingProgressRequireField. */
    export interface IStoryReadingProgressRequireField {

        /** StoryReadingProgressRequireField requireStory */
        requireStory?: (query_recursive_types.IStoryRequireField);

        /** StoryReadingProgressRequireField requireIndex */
        requireIndex?: (boolean);

        /** StoryReadingProgressRequireField requireIsFinished */
        requireIsFinished?: (boolean);

        /** StoryReadingProgressRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);
    }

    /** Represents a StoryReadingProgressRequireField. */
    class StoryReadingProgressRequireField implements IStoryReadingProgressRequireField {

        /**
         * Constructs a new StoryReadingProgressRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryReadingProgressRequireField);

        /** StoryReadingProgressRequireField requireStory. */
        public requireStory?: (query_recursive_types.IStoryRequireField);

        /** StoryReadingProgressRequireField requireIndex. */
        public requireIndex?: (boolean);

        /** StoryReadingProgressRequireField requireIsFinished. */
        public requireIsFinished?: (boolean);

        /** StoryReadingProgressRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /**
         * Gets the default type url for StoryReadingProgressRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryReadingProgressResponse. */
    export interface IStoryReadingProgressResponse {

        /** StoryReadingProgressResponse story */
        story?: (query_recursive_types.IStoryResponse);

        /** StoryReadingProgressResponse index */
        index?: (number);

        /** StoryReadingProgressResponse isFinished */
        isFinished?: (boolean);

        /** StoryReadingProgressResponse updatedAt */
        updatedAt?: (string);
    }

    /** Represents a StoryReadingProgressResponse. */
    class StoryReadingProgressResponse implements IStoryReadingProgressResponse {

        /**
         * Constructs a new StoryReadingProgressResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryReadingProgressResponse);

        /** StoryReadingProgressResponse story. */
        public story?: (query_recursive_types.IStoryResponse);

        /** StoryReadingProgressResponse index. */
        public index?: (number);

        /** StoryReadingProgressResponse isFinished. */
        public isFinished?: (boolean);

        /** StoryReadingProgressResponse updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for StoryReadingProgressResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryReadingProgressCursorRequest. */
    export interface IStoryReadingProgressCursorRequest {

        /** StoryReadingProgressCursorRequest requireReadingProgressList */
        requireReadingProgressList?: (query_recursive_types.IStoryReadingProgressRequireField);

        /** StoryReadingProgressCursorRequest excludeFinished */
        excludeFinished?: (boolean);

        /** StoryReadingProgressCursorRequest excludeNotFinished */
        excludeNotFinished?: (boolean);

        /** StoryReadingProgressCursorRequest excludeSeriesStory */
        excludeSeriesStory?: (boolean);

        /** StoryReadingProgressCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);
    }

    /** Represents a StoryReadingProgressCursorRequest. */
    class StoryReadingProgressCursorRequest implements IStoryReadingProgressCursorRequest {

        /**
         * Constructs a new StoryReadingProgressCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryReadingProgressCursorRequest);

        /** StoryReadingProgressCursorRequest requireReadingProgressList. */
        public requireReadingProgressList?: (query_recursive_types.IStoryReadingProgressRequireField);

        /** StoryReadingProgressCursorRequest excludeFinished. */
        public excludeFinished?: (boolean);

        /** StoryReadingProgressCursorRequest excludeNotFinished. */
        public excludeNotFinished?: (boolean);

        /** StoryReadingProgressCursorRequest excludeSeriesStory. */
        public excludeSeriesStory?: (boolean);

        /** StoryReadingProgressCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /**
         * Gets the default type url for StoryReadingProgressCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryReadingProgressCursorResponse. */
    export interface IStoryReadingProgressCursorResponse {

        /** StoryReadingProgressCursorResponse readingProgressList */
        readingProgressList?: (query_recursive_types.IStoryReadingProgressResponse[]);

        /** StoryReadingProgressCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a StoryReadingProgressCursorResponse. */
    class StoryReadingProgressCursorResponse implements IStoryReadingProgressCursorResponse {

        /**
         * Constructs a new StoryReadingProgressCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryReadingProgressCursorResponse);

        /** StoryReadingProgressCursorResponse readingProgressList. */
        public readingProgressList: query_recursive_types.IStoryReadingProgressResponse[];

        /** StoryReadingProgressCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for StoryReadingProgressCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesReadingProgressRequireField. */
    export interface ISeriesReadingProgressRequireField {

        /** SeriesReadingProgressRequireField requireSeries */
        requireSeries?: (query_recursive_types.ISeriesRequireField);

        /** SeriesReadingProgressRequireField requireStoryReadingProgress */
        requireStoryReadingProgress?: (query_recursive_types.IStoryReadingProgressRequireField);

        /** SeriesReadingProgressRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);

        /** SeriesReadingProgressRequireField requireNextStory */
        requireNextStory?: (query_recursive_types.IStoryRequireField);
    }

    /** Represents a SeriesReadingProgressRequireField. */
    class SeriesReadingProgressRequireField implements ISeriesReadingProgressRequireField {

        /**
         * Constructs a new SeriesReadingProgressRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesReadingProgressRequireField);

        /** SeriesReadingProgressRequireField requireSeries. */
        public requireSeries?: (query_recursive_types.ISeriesRequireField);

        /** SeriesReadingProgressRequireField requireStoryReadingProgress. */
        public requireStoryReadingProgress?: (query_recursive_types.IStoryReadingProgressRequireField);

        /** SeriesReadingProgressRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /** SeriesReadingProgressRequireField requireNextStory. */
        public requireNextStory?: (query_recursive_types.IStoryRequireField);

        /**
         * Gets the default type url for SeriesReadingProgressRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesReadingProgressResponse. */
    export interface ISeriesReadingProgressResponse {

        /** SeriesReadingProgressResponse series */
        series?: (query_recursive_types.ISeriesResponse);

        /** SeriesReadingProgressResponse storyReadingProgress */
        storyReadingProgress?: (query_recursive_types.IStoryReadingProgressResponse);

        /** SeriesReadingProgressResponse updatedAt */
        updatedAt?: (string);

        /** SeriesReadingProgressResponse nextStory */
        nextStory?: (query_recursive_types.IStoryResponse);
    }

    /** Represents a SeriesReadingProgressResponse. */
    class SeriesReadingProgressResponse implements ISeriesReadingProgressResponse {

        /**
         * Constructs a new SeriesReadingProgressResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesReadingProgressResponse);

        /** SeriesReadingProgressResponse series. */
        public series?: (query_recursive_types.ISeriesResponse);

        /** SeriesReadingProgressResponse storyReadingProgress. */
        public storyReadingProgress?: (query_recursive_types.IStoryReadingProgressResponse);

        /** SeriesReadingProgressResponse updatedAt. */
        public updatedAt?: (string);

        /** SeriesReadingProgressResponse nextStory. */
        public nextStory?: (query_recursive_types.IStoryResponse);

        /**
         * Gets the default type url for SeriesReadingProgressResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesReadingProgressCursorRequest. */
    export interface ISeriesReadingProgressCursorRequest {

        /** SeriesReadingProgressCursorRequest requireReadingProgressList */
        requireReadingProgressList?: (query_recursive_types.ISeriesReadingProgressRequireField);

        /** SeriesReadingProgressCursorRequest excludeFinishedLatestStory */
        excludeFinishedLatestStory?: (boolean);

        /** SeriesReadingProgressCursorRequest excludeNotFinishedLatestStory */
        excludeNotFinishedLatestStory?: (boolean);

        /** SeriesReadingProgressCursorRequest excludeFinishedCompleteSeries */
        excludeFinishedCompleteSeries?: (boolean);

        /** SeriesReadingProgressCursorRequest excludeNotFinishedCompleteSeries */
        excludeNotFinishedCompleteSeries?: (boolean);

        /** SeriesReadingProgressCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);

        /** SeriesReadingProgressCursorRequest scriptTypeFilter */
        scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);
    }

    /** Represents a SeriesReadingProgressCursorRequest. */
    class SeriesReadingProgressCursorRequest implements ISeriesReadingProgressCursorRequest {

        /**
         * Constructs a new SeriesReadingProgressCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesReadingProgressCursorRequest);

        /** SeriesReadingProgressCursorRequest requireReadingProgressList. */
        public requireReadingProgressList?: (query_recursive_types.ISeriesReadingProgressRequireField);

        /** SeriesReadingProgressCursorRequest excludeFinishedLatestStory. */
        public excludeFinishedLatestStory?: (boolean);

        /** SeriesReadingProgressCursorRequest excludeNotFinishedLatestStory. */
        public excludeNotFinishedLatestStory?: (boolean);

        /** SeriesReadingProgressCursorRequest excludeFinishedCompleteSeries. */
        public excludeFinishedCompleteSeries?: (boolean);

        /** SeriesReadingProgressCursorRequest excludeNotFinishedCompleteSeries. */
        public excludeNotFinishedCompleteSeries?: (boolean);

        /** SeriesReadingProgressCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /** SeriesReadingProgressCursorRequest scriptTypeFilter. */
        public scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /**
         * Gets the default type url for SeriesReadingProgressCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesReadingProgressCursorResponse. */
    export interface ISeriesReadingProgressCursorResponse {

        /** SeriesReadingProgressCursorResponse readingProgressList */
        readingProgressList?: (query_recursive_types.ISeriesReadingProgressResponse[]);

        /** SeriesReadingProgressCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a SeriesReadingProgressCursorResponse. */
    class SeriesReadingProgressCursorResponse implements ISeriesReadingProgressCursorResponse {

        /**
         * Constructs a new SeriesReadingProgressCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesReadingProgressCursorResponse);

        /** SeriesReadingProgressCursorResponse readingProgressList. */
        public readingProgressList: query_recursive_types.ISeriesReadingProgressResponse[];

        /** SeriesReadingProgressCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for SeriesReadingProgressCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserRequest. */
    export interface IUserRequest {

        /** UserRequest id */
        id?: (string);

        /** UserRequest requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);
    }

    /** Represents a UserRequest. */
    class UserRequest implements IUserRequest {

        /**
         * Constructs a new UserRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUserRequest);

        /** UserRequest id. */
        public id?: (string);

        /** UserRequest requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /**
         * Gets the default type url for UserRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserRequireField. */
    export interface IUserRequireField {

        /** UserRequireField requireId */
        requireId?: (boolean);

        /** UserRequireField requireName */
        requireName?: (boolean);

        /** UserRequireField requireProfile */
        requireProfile?: (boolean);

        /** UserRequireField requireThumbnail */
        requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** UserRequireField requireCoverImage */
        requireCoverImage?: (query_recursive_types.IImageRequireField);

        /** UserRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** UserRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);

        /** UserRequireField requireUserRole */
        requireUserRole?: (boolean);

        /** UserRequireField requireTicketStatus */
        requireTicketStatus?: (query_ticket.IUserTicketStatusRequireField);

        /** UserRequireField requireBonusTicketAvailability */
        requireBonusTicketAvailability?: (query_bonus_ticket.IBonusTicketAvailabilityRequireField);

        /** UserRequireField requireFollowee */
        requireFollowee?: (query_recursive_types.IUserCursorRequest);

        /** UserRequireField requireFollower */
        requireFollower?: (query_recursive_types.IUserCursorRequest);

        /** UserRequireField requireSearchableStoryCount */
        requireSearchableStoryCount?: (boolean);

        /** UserRequireField requireSearchStory */
        requireSearchStory?: (query_recursive_types.ISearchStoryRequest);

        /** UserRequireField requireSearchSeries */
        requireSearchSeries?: (query_recursive_types.ISearchSeriesRequest);

        /** UserRequireField requireSearchSeriesGroup */
        requireSearchSeriesGroup?: (query_recursive_types.ISearchSeriesGroupRequest);

        /** UserRequireField requireStoryReadingProgressPage */
        requireStoryReadingProgressPage?: (query_recursive_types.IStoryReadingProgressCursorRequest);

        /** UserRequireField requireSeriesReadingProgressPage */
        requireSeriesReadingProgressPage?: (query_recursive_types.ISeriesReadingProgressCursorRequest);

        /** UserRequireField requireBookmarkItemPage */
        requireBookmarkItemPage?: (query_recursive_types.IBookmarkItemCursorRequest);

        /** UserRequireField requireIsFollowedByMe */
        requireIsFollowedByMe?: (boolean);

        /** UserRequireField requireIsFollowingMe */
        requireIsFollowingMe?: (boolean);

        /** UserRequireField requireProfiling */
        requireProfiling?: (query_recursive_types.IProfilingRequest);

        /** UserRequireField requireNotOfficialFollowingTagList */
        requireNotOfficialFollowingTagList?: (boolean);

        /** UserRequireField requireNotOfficialRecommendationTagList */
        requireNotOfficialRecommendationTagList?: (boolean);

        /** UserRequireField requireMedalPage */
        requireMedalPage?: (query_recursive_types.IMedalCursorRequest);

        /** UserRequireField requireImagePage */
        requireImagePage?: (query_recursive_types.IImageCursorRequest);

        /** UserRequireField requireNotificationPage */
        requireNotificationPage?: (query_recursive_types.INotificationCursorRequest);

        /** UserRequireField requirePenalty */
        requirePenalty?: (query_inspection.IUserPenaltyRequest);

        /** UserRequireField requireVipMembership */
        requireVipMembership?: (boolean);

        /** UserRequireField requireVipStatus */
        requireVipStatus?: (boolean);

        /** UserRequireField requireNotOfficialRecommendedStoryPage */
        requireNotOfficialRecommendedStoryPage?: (query_recursive_types.IStoryCursorRequest);

        /** UserRequireField requireOfficialRecommendedUserPage */
        requireOfficialRecommendedUserPage?: (query_recursive_types.IUserCursorRequest);

        /** UserRequireField requireNotOfficialRecommendedUserPage */
        requireNotOfficialRecommendedUserPage?: (query_recursive_types.IUserCursorRequest);

        /** UserRequireField requireSearchRecommendedStory */
        requireSearchRecommendedStory?: (query_recursive_types.ISearchStoryRequest);

        /** UserRequireField requireOfficialRecommendedStoryPage */
        requireOfficialRecommendedStoryPage?: (query_recursive_types.IStoryCursorRequest);
    }

    /** Represents a UserRequireField. */
    class UserRequireField implements IUserRequireField {

        /**
         * Constructs a new UserRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUserRequireField);

        /** UserRequireField requireId. */
        public requireId?: (boolean);

        /** UserRequireField requireName. */
        public requireName?: (boolean);

        /** UserRequireField requireProfile. */
        public requireProfile?: (boolean);

        /** UserRequireField requireThumbnail. */
        public requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** UserRequireField requireCoverImage. */
        public requireCoverImage?: (query_recursive_types.IImageRequireField);

        /** UserRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** UserRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /** UserRequireField requireUserRole. */
        public requireUserRole?: (boolean);

        /** UserRequireField requireTicketStatus. */
        public requireTicketStatus?: (query_ticket.IUserTicketStatusRequireField);

        /** UserRequireField requireBonusTicketAvailability. */
        public requireBonusTicketAvailability?: (query_bonus_ticket.IBonusTicketAvailabilityRequireField);

        /** UserRequireField requireFollowee. */
        public requireFollowee?: (query_recursive_types.IUserCursorRequest);

        /** UserRequireField requireFollower. */
        public requireFollower?: (query_recursive_types.IUserCursorRequest);

        /** UserRequireField requireSearchableStoryCount. */
        public requireSearchableStoryCount?: (boolean);

        /** UserRequireField requireSearchStory. */
        public requireSearchStory?: (query_recursive_types.ISearchStoryRequest);

        /** UserRequireField requireSearchSeries. */
        public requireSearchSeries?: (query_recursive_types.ISearchSeriesRequest);

        /** UserRequireField requireSearchSeriesGroup. */
        public requireSearchSeriesGroup?: (query_recursive_types.ISearchSeriesGroupRequest);

        /** UserRequireField requireStoryReadingProgressPage. */
        public requireStoryReadingProgressPage?: (query_recursive_types.IStoryReadingProgressCursorRequest);

        /** UserRequireField requireSeriesReadingProgressPage. */
        public requireSeriesReadingProgressPage?: (query_recursive_types.ISeriesReadingProgressCursorRequest);

        /** UserRequireField requireBookmarkItemPage. */
        public requireBookmarkItemPage?: (query_recursive_types.IBookmarkItemCursorRequest);

        /** UserRequireField requireIsFollowedByMe. */
        public requireIsFollowedByMe?: (boolean);

        /** UserRequireField requireIsFollowingMe. */
        public requireIsFollowingMe?: (boolean);

        /** UserRequireField requireProfiling. */
        public requireProfiling?: (query_recursive_types.IProfilingRequest);

        /** UserRequireField requireNotOfficialFollowingTagList. */
        public requireNotOfficialFollowingTagList?: (boolean);

        /** UserRequireField requireNotOfficialRecommendationTagList. */
        public requireNotOfficialRecommendationTagList?: (boolean);

        /** UserRequireField requireMedalPage. */
        public requireMedalPage?: (query_recursive_types.IMedalCursorRequest);

        /** UserRequireField requireImagePage. */
        public requireImagePage?: (query_recursive_types.IImageCursorRequest);

        /** UserRequireField requireNotificationPage. */
        public requireNotificationPage?: (query_recursive_types.INotificationCursorRequest);

        /** UserRequireField requirePenalty. */
        public requirePenalty?: (query_inspection.IUserPenaltyRequest);

        /** UserRequireField requireVipMembership. */
        public requireVipMembership?: (boolean);

        /** UserRequireField requireVipStatus. */
        public requireVipStatus?: (boolean);

        /** UserRequireField requireNotOfficialRecommendedStoryPage. */
        public requireNotOfficialRecommendedStoryPage?: (query_recursive_types.IStoryCursorRequest);

        /** UserRequireField requireOfficialRecommendedUserPage. */
        public requireOfficialRecommendedUserPage?: (query_recursive_types.IUserCursorRequest);

        /** UserRequireField requireNotOfficialRecommendedUserPage. */
        public requireNotOfficialRecommendedUserPage?: (query_recursive_types.IUserCursorRequest);

        /** UserRequireField requireSearchRecommendedStory. */
        public requireSearchRecommendedStory?: (query_recursive_types.ISearchStoryRequest);

        /** UserRequireField requireOfficialRecommendedStoryPage. */
        public requireOfficialRecommendedStoryPage?: (query_recursive_types.IStoryCursorRequest);

        /**
         * Gets the default type url for UserRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserResponse. */
    export interface IUserResponse {

        /** UserResponse id */
        id?: (string);

        /** UserResponse name */
        name?: (string);

        /** UserResponse profile */
        profile?: (string);

        /** UserResponse thumbnail */
        thumbnail?: (query_recursive_types.IImageResponse);

        /** UserResponse coverImage */
        coverImage?: (query_recursive_types.IImageResponse);

        /** UserResponse createdAt */
        createdAt?: (string);

        /** UserResponse updatedAt */
        updatedAt?: (string);

        /** UserResponse userRole */
        userRole?: (query_types.UserRole);

        /** UserResponse ticketStatus */
        ticketStatus?: (query_ticket.IUserTicketStatusResponse);

        /** UserResponse bonusTicketAvailability */
        bonusTicketAvailability?: (query_bonus_ticket.IBonusTicketAvailabilityResponse);

        /** UserResponse followeePage */
        followeePage?: (query_recursive_types.IUserCursorResponse);

        /** UserResponse followerPage */
        followerPage?: (query_recursive_types.IUserCursorResponse);

        /** UserResponse searchableStoryCount */
        searchableStoryCount?: (number);

        /** UserResponse searchStory */
        searchStory?: (query_recursive_types.ISearchStoryResponse);

        /** UserResponse searchSeries */
        searchSeries?: (query_recursive_types.ISearchSeriesResponse);

        /** UserResponse searchSeriesGroup */
        searchSeriesGroup?: (query_recursive_types.ISearchSeriesGroupResponse);

        /** UserResponse storyReadingProgressPage */
        storyReadingProgressPage?: (query_recursive_types.IStoryReadingProgressCursorResponse);

        /** UserResponse seriesReadingProgressPage */
        seriesReadingProgressPage?: (query_recursive_types.ISeriesReadingProgressCursorResponse);

        /** UserResponse bookmarkItemPage */
        bookmarkItemPage?: (query_recursive_types.IBookmarkItemCursorResponse);

        /** UserResponse searchRecommendedStory */
        searchRecommendedStory?: (query_recursive_types.ISearchStoryResponse);

        /** UserResponse officialRecommendedUserPage */
        officialRecommendedUserPage?: (query_recursive_types.IUserCursorResponse);

        /** UserResponse notOfficialRecommendedUserPage */
        notOfficialRecommendedUserPage?: (query_recursive_types.IUserCursorResponse);

        /** UserResponse isFollowedByMe */
        isFollowedByMe?: (boolean);

        /** UserResponse isFollowingMe */
        isFollowingMe?: (boolean);

        /** UserResponse profiling */
        profiling?: (query_recursive_types.IProfilingResponse);

        /** UserResponse notOfficialFollowingTagList */
        notOfficialFollowingTagList?: (string[]);

        /** UserResponse notOfficialRecommendationTagList */
        notOfficialRecommendationTagList?: (string[]);

        /** UserResponse medalPage */
        medalPage?: (query_recursive_types.IMedalCursorResponse);

        /** UserResponse imagePage */
        imagePage?: (query_recursive_types.IImageCursorResponse);

        /** UserResponse notificationPage */
        notificationPage?: (query_recursive_types.INotificationCursorResponse);

        /** UserResponse penalty */
        penalty?: (query_inspection.IUserPenaltyResponse);

        /** UserResponse vipMembership */
        vipMembership?: (query_types.IVipMembership);

        /** UserResponse vipStatus */
        vipStatus?: (query_types.VipStatus);

        /** UserResponse notOfficialRecommendedStoryPage */
        notOfficialRecommendedStoryPage?: (query_recursive_types.IStoryCursorResponse);

        /** UserResponse officialRecommendedStoryPage */
        officialRecommendedStoryPage?: (query_recursive_types.IStoryCursorResponse);
    }

    /** Represents a UserResponse. */
    class UserResponse implements IUserResponse {

        /**
         * Constructs a new UserResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUserResponse);

        /** UserResponse id. */
        public id?: (string);

        /** UserResponse name. */
        public name?: (string);

        /** UserResponse profile. */
        public profile?: (string);

        /** UserResponse thumbnail. */
        public thumbnail?: (query_recursive_types.IImageResponse);

        /** UserResponse coverImage. */
        public coverImage?: (query_recursive_types.IImageResponse);

        /** UserResponse createdAt. */
        public createdAt?: (string);

        /** UserResponse updatedAt. */
        public updatedAt?: (string);

        /** UserResponse userRole. */
        public userRole: query_types.UserRole;

        /** UserResponse ticketStatus. */
        public ticketStatus?: (query_ticket.IUserTicketStatusResponse);

        /** UserResponse bonusTicketAvailability. */
        public bonusTicketAvailability?: (query_bonus_ticket.IBonusTicketAvailabilityResponse);

        /** UserResponse followeePage. */
        public followeePage?: (query_recursive_types.IUserCursorResponse);

        /** UserResponse followerPage. */
        public followerPage?: (query_recursive_types.IUserCursorResponse);

        /** UserResponse searchableStoryCount. */
        public searchableStoryCount?: (number);

        /** UserResponse searchStory. */
        public searchStory?: (query_recursive_types.ISearchStoryResponse);

        /** UserResponse searchSeries. */
        public searchSeries?: (query_recursive_types.ISearchSeriesResponse);

        /** UserResponse searchSeriesGroup. */
        public searchSeriesGroup?: (query_recursive_types.ISearchSeriesGroupResponse);

        /** UserResponse storyReadingProgressPage. */
        public storyReadingProgressPage?: (query_recursive_types.IStoryReadingProgressCursorResponse);

        /** UserResponse seriesReadingProgressPage. */
        public seriesReadingProgressPage?: (query_recursive_types.ISeriesReadingProgressCursorResponse);

        /** UserResponse bookmarkItemPage. */
        public bookmarkItemPage?: (query_recursive_types.IBookmarkItemCursorResponse);

        /** UserResponse searchRecommendedStory. */
        public searchRecommendedStory?: (query_recursive_types.ISearchStoryResponse);

        /** UserResponse officialRecommendedUserPage. */
        public officialRecommendedUserPage?: (query_recursive_types.IUserCursorResponse);

        /** UserResponse notOfficialRecommendedUserPage. */
        public notOfficialRecommendedUserPage?: (query_recursive_types.IUserCursorResponse);

        /** UserResponse isFollowedByMe. */
        public isFollowedByMe?: (boolean);

        /** UserResponse isFollowingMe. */
        public isFollowingMe?: (boolean);

        /** UserResponse profiling. */
        public profiling?: (query_recursive_types.IProfilingResponse);

        /** UserResponse notOfficialFollowingTagList. */
        public notOfficialFollowingTagList: string[];

        /** UserResponse notOfficialRecommendationTagList. */
        public notOfficialRecommendationTagList: string[];

        /** UserResponse medalPage. */
        public medalPage?: (query_recursive_types.IMedalCursorResponse);

        /** UserResponse imagePage. */
        public imagePage?: (query_recursive_types.IImageCursorResponse);

        /** UserResponse notificationPage. */
        public notificationPage?: (query_recursive_types.INotificationCursorResponse);

        /** UserResponse penalty. */
        public penalty?: (query_inspection.IUserPenaltyResponse);

        /** UserResponse vipMembership. */
        public vipMembership?: (query_types.IVipMembership);

        /** UserResponse vipStatus. */
        public vipStatus: query_types.VipStatus;

        /** UserResponse notOfficialRecommendedStoryPage. */
        public notOfficialRecommendedStoryPage?: (query_recursive_types.IStoryCursorResponse);

        /** UserResponse officialRecommendedStoryPage. */
        public officialRecommendedStoryPage?: (query_recursive_types.IStoryCursorResponse);

        /**
         * Gets the default type url for UserResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchUserRequest. */
    export interface ISearchUserRequest {

        /** SearchUserRequest params */
        params?: (query_search_types.ISearchUserParams);

        /** SearchUserRequest requireUserPage */
        requireUserPage?: (query_recursive_types.IUserPageRequest);
    }

    /** Represents a SearchUserRequest. */
    class SearchUserRequest implements ISearchUserRequest {

        /**
         * Constructs a new SearchUserRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISearchUserRequest);

        /** SearchUserRequest params. */
        public params?: (query_search_types.ISearchUserParams);

        /** SearchUserRequest requireUserPage. */
        public requireUserPage?: (query_recursive_types.IUserPageRequest);

        /**
         * Gets the default type url for SearchUserRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchUserResponse. */
    export interface ISearchUserResponse {

        /** SearchUserResponse userPage */
        userPage?: (query_recursive_types.IUserPageResponse);
    }

    /** Represents a SearchUserResponse. */
    class SearchUserResponse implements ISearchUserResponse {

        /**
         * Constructs a new SearchUserResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISearchUserResponse);

        /** SearchUserResponse userPage. */
        public userPage?: (query_recursive_types.IUserPageResponse);

        /**
         * Gets the default type url for SearchUserResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserPageRequest. */
    export interface IUserPageRequest {

        /** UserPageRequest requireUserList */
        requireUserList?: (query_recursive_types.IUserRequireField);

        /** UserPageRequest page */
        page?: (query_pagination.IPageRequest);
    }

    /** Represents a UserPageRequest. */
    class UserPageRequest implements IUserPageRequest {

        /**
         * Constructs a new UserPageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUserPageRequest);

        /** UserPageRequest requireUserList. */
        public requireUserList?: (query_recursive_types.IUserRequireField);

        /** UserPageRequest page. */
        public page?: (query_pagination.IPageRequest);

        /**
         * Gets the default type url for UserPageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserPageResponse. */
    export interface IUserPageResponse {

        /** UserPageResponse userList */
        userList?: (query_recursive_types.IUserResponse[]);

        /** UserPageResponse page */
        page?: (query_pagination.IPageInfo);
    }

    /** Represents a UserPageResponse. */
    class UserPageResponse implements IUserPageResponse {

        /**
         * Constructs a new UserPageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUserPageResponse);

        /** UserPageResponse userList. */
        public userList: query_recursive_types.IUserResponse[];

        /** UserPageResponse page. */
        public page?: (query_pagination.IPageInfo);

        /**
         * Gets the default type url for UserPageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserCursorRequest. */
    export interface IUserCursorRequest {

        /** UserCursorRequest requireUserList */
        requireUserList?: (query_recursive_types.IUserRequireField);

        /** UserCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);
    }

    /** Represents a UserCursorRequest. */
    class UserCursorRequest implements IUserCursorRequest {

        /**
         * Constructs a new UserCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUserCursorRequest);

        /** UserCursorRequest requireUserList. */
        public requireUserList?: (query_recursive_types.IUserRequireField);

        /** UserCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /**
         * Gets the default type url for UserCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserCursorResponse. */
    export interface IUserCursorResponse {

        /** UserCursorResponse userList */
        userList?: (query_recursive_types.IUserResponse[]);

        /** UserCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a UserCursorResponse. */
    class UserCursorResponse implements IUserCursorResponse {

        /**
         * Constructs a new UserCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUserCursorResponse);

        /** UserCursorResponse userList. */
        public userList: query_recursive_types.IUserResponse[];

        /** UserCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for UserCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeRequest. */
    export interface IMeRequest {

        /** MeRequest requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);

        /** MeRequest requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.IMySeriesCursorRequest);

        /** MeRequest requireStampList */
        requireStampList?: (query_recursive_types.IStampMasterRequireField);

        /** MeRequest requireShowSensitive */
        requireShowSensitive?: (boolean);

        /** MeRequest requireUsedTagList */
        requireUsedTagList?: (query_recursive_types.IUsedTagListRequest);

        /** MeRequest requireRecommendedSeries */
        requireRecommendedSeries?: (query_recursive_types.IRecommendSeriesRequest);

        /** MeRequest requireCoinBalance */
        requireCoinBalance?: (boolean);

        /** MeRequest requireCoinStatementPage */
        requireCoinStatementPage?: (query_recursive_types.ICoinStatementCursorRequest);

        /** MeRequest requireBonusStatus */
        requireBonusStatus?: (query_bonus.IBonusStatusRequireField);

        /** MeRequest requireRecommendedSeriesPage */
        requireRecommendedSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /** MeRequest requireStoryPage */
        requireStoryPage?: (query_recursive_types.IMyStoryCursorRequest);
    }

    /** Represents a MeRequest. */
    class MeRequest implements IMeRequest {

        /**
         * Constructs a new MeRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMeRequest);

        /** MeRequest requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /** MeRequest requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.IMySeriesCursorRequest);

        /** MeRequest requireStampList. */
        public requireStampList?: (query_recursive_types.IStampMasterRequireField);

        /** MeRequest requireShowSensitive. */
        public requireShowSensitive?: (boolean);

        /** MeRequest requireUsedTagList. */
        public requireUsedTagList?: (query_recursive_types.IUsedTagListRequest);

        /** MeRequest requireRecommendedSeries. */
        public requireRecommendedSeries?: (query_recursive_types.IRecommendSeriesRequest);

        /** MeRequest requireCoinBalance. */
        public requireCoinBalance?: (boolean);

        /** MeRequest requireCoinStatementPage. */
        public requireCoinStatementPage?: (query_recursive_types.ICoinStatementCursorRequest);

        /** MeRequest requireBonusStatus. */
        public requireBonusStatus?: (query_bonus.IBonusStatusRequireField);

        /** MeRequest requireRecommendedSeriesPage. */
        public requireRecommendedSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /** MeRequest requireStoryPage. */
        public requireStoryPage?: (query_recursive_types.IMyStoryCursorRequest);

        /**
         * Gets the default type url for MeRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MeResponse. */
    export interface IMeResponse {

        /** MeResponse user */
        user?: (query_recursive_types.IUserResponse);

        /** MeResponse storyPage */
        storyPage?: (query_recursive_types.IMyStoryCursorResponse);

        /** MeResponse seriesPage */
        seriesPage?: (query_recursive_types.IMySeriesCursorResponse);

        /** MeResponse stampList */
        stampList?: (query_recursive_types.IStampMasterResponse[]);

        /** MeResponse showSensitive */
        showSensitive?: (user_preference_sensitive.ShowSensitive);

        /** MeResponse recommendedSeriesPage */
        recommendedSeriesPage?: (query_recursive_types.ISeriesPageResponse);

        /** MeResponse usedTagListResponse */
        usedTagListResponse?: (query_recursive_types.IUsedTagListResponse);

        /** MeResponse coinBalance */
        coinBalance?: (query_coin.ICoinBalance);

        /** MeResponse coinStatementPage */
        coinStatementPage?: (query_recursive_types.ICoinStatementCursorResponse);

        /** MeResponse bonusStatus */
        bonusStatus?: (query_bonus.IBonusStatus);
    }

    /** Represents a MeResponse. */
    class MeResponse implements IMeResponse {

        /**
         * Constructs a new MeResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMeResponse);

        /** MeResponse user. */
        public user?: (query_recursive_types.IUserResponse);

        /** MeResponse storyPage. */
        public storyPage?: (query_recursive_types.IMyStoryCursorResponse);

        /** MeResponse seriesPage. */
        public seriesPage?: (query_recursive_types.IMySeriesCursorResponse);

        /** MeResponse stampList. */
        public stampList: query_recursive_types.IStampMasterResponse[];

        /** MeResponse showSensitive. */
        public showSensitive: user_preference_sensitive.ShowSensitive;

        /** MeResponse recommendedSeriesPage. */
        public recommendedSeriesPage?: (query_recursive_types.ISeriesPageResponse);

        /** MeResponse usedTagListResponse. */
        public usedTagListResponse?: (query_recursive_types.IUsedTagListResponse);

        /** MeResponse coinBalance. */
        public coinBalance?: (query_coin.ICoinBalance);

        /** MeResponse coinStatementPage. */
        public coinStatementPage?: (query_recursive_types.ICoinStatementCursorResponse);

        /** MeResponse bonusStatus. */
        public bonusStatus?: (query_bonus.IBonusStatus);

        /**
         * Gets the default type url for MeResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesRequest. */
    export interface ISeriesRequest {

        /** SeriesRequest id */
        id?: (string);

        /** SeriesRequest requireSeries */
        requireSeries?: (query_recursive_types.ISeriesRequireField);
    }

    /** Represents a SeriesRequest. */
    class SeriesRequest implements ISeriesRequest {

        /**
         * Constructs a new SeriesRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesRequest);

        /** SeriesRequest id. */
        public id?: (string);

        /** SeriesRequest requireSeries. */
        public requireSeries?: (query_recursive_types.ISeriesRequireField);

        /**
         * Gets the default type url for SeriesRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagRequest. */
    export interface ITagRequest {

        /** TagRequest tag */
        tag?: (string);

        /** TagRequest requireTag */
        requireTag?: (query_recursive_types.ITagRequireField);
    }

    /** Represents a TagRequest. */
    class TagRequest implements ITagRequest {

        /**
         * Constructs a new TagRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ITagRequest);

        /** TagRequest tag. */
        public tag?: (string);

        /** TagRequest requireTag. */
        public requireTag?: (query_recursive_types.ITagRequireField);

        /**
         * Gets the default type url for TagRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesRequireField. */
    export interface ISeriesRequireField {

        /** SeriesRequireField requireId */
        requireId?: (boolean);

        /** SeriesRequireField requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);

        /** SeriesRequireField requireTags */
        requireTags?: (boolean);

        /** SeriesRequireField requireTitle */
        requireTitle?: (boolean);

        /** SeriesRequireField requireDescription */
        requireDescription?: (boolean);

        /** SeriesRequireField requireThumbnail */
        requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** SeriesRequireField requireIsOfficial */
        requireIsOfficial?: (boolean);

        /** SeriesRequireField requireIsCompleted */
        requireIsCompleted?: (boolean);

        /** SeriesRequireField requireNextPublishAt */
        requireNextPublishAt?: (boolean);

        /** SeriesRequireField requireSharedWithStatus */
        requireSharedWithStatus?: (boolean);

        /** SeriesRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** SeriesRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);

        /** SeriesRequireField requireHasVipOnlyStory */
        requireHasVipOnlyStory?: (boolean);

        /** SeriesRequireField requireHasFreeStory */
        requireHasFreeStory?: (boolean);

        /** SeriesRequireField requireSeriesCharge */
        requireSeriesCharge?: (query_series_charge.ISeriesChargeRequireField);

        /** SeriesRequireField requireSeriesGroupList */
        requireSeriesGroupList?: (query_recursive_types.ISeriesGroupRequireField);

        /** SeriesRequireField requireUserBookmarked */
        requireUserBookmarked?: (boolean);

        /** SeriesRequireField requirePublishSchedule */
        requirePublishSchedule?: (boolean);

        /** SeriesRequireField requireLatestPublishedAt */
        requireLatestPublishedAt?: (boolean);

        /** SeriesRequireField requireHasNovelScriptStory */
        requireHasNovelScriptStory?: (boolean);

        /** SeriesRequireField requireHasChatNovelScriptStory */
        requireHasChatNovelScriptStory?: (boolean);

        /** SeriesRequireField requireSearchableStoryCount */
        requireSearchableStoryCount?: (boolean);

        /** SeriesRequireField requireTotalLikeCount */
        requireTotalLikeCount?: (boolean);

        /** SeriesRequireField requireUserReadingProgress */
        requireUserReadingProgress?: (query_recursive_types.ISeriesReadingProgressRequireField);

        /** SeriesRequireField requireCharacterList */
        requireCharacterList?: (query_recursive_types.ICharacterRequireField);

        /** SeriesRequireField requireUserAvailability */
        requireUserAvailability?: (boolean);

        /** SeriesRequireField requireIsOneshot */
        requireIsOneshot?: (boolean);

        /** SeriesRequireField requireCanChangeToOneshot */
        requireCanChangeToOneshot?: (boolean);

        /** SeriesRequireField requireFirstPublishedStory */
        requireFirstPublishedStory?: (query_recursive_types.IStoryRequireField);

        /** SeriesRequireField requireRecommenderModelId */
        requireRecommenderModelId?: (boolean);

        /** SeriesRequireField requireIsUploadingImageLocked */
        requireIsUploadingImageLocked?: (boolean);

        /** SeriesRequireField requireShowGuidelineDialog */
        requireShowGuidelineDialog?: (boolean);

        /** SeriesRequireField requireCanShowGoogleAd */
        requireCanShowGoogleAd?: (boolean);

        /** SeriesRequireField requireOriginalFlag */
        requireOriginalFlag?: (boolean);

        /** SeriesRequireField requireGenre */
        requireGenre?: (boolean);

        /** SeriesRequireField requireIsSensitive */
        requireIsSensitive?: (boolean);

        /** SeriesRequireField requireRecommendedSeries */
        requireRecommendedSeries?: (query_recursive_types.IRecommendSeriesRequest);

        /** SeriesRequireField requireStoryPage */
        requireStoryPage?: (query_recursive_types.ISeriesStoryPageRequest);

        /** SeriesRequireField requireSearchStory */
        requireSearchStory?: (query_recursive_types.ISearchStoryRequest);

        /** SeriesRequireField requireSensitiveStoryCount */
        requireSensitiveStoryCount?: (boolean);

        /** SeriesRequireField requireRecommendedSeriesPage */
        requireRecommendedSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SeriesRequireField. */
    class SeriesRequireField implements ISeriesRequireField {

        /**
         * Constructs a new SeriesRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesRequireField);

        /** SeriesRequireField requireId. */
        public requireId?: (boolean);

        /** SeriesRequireField requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /** SeriesRequireField requireTags. */
        public requireTags?: (boolean);

        /** SeriesRequireField requireTitle. */
        public requireTitle?: (boolean);

        /** SeriesRequireField requireDescription. */
        public requireDescription?: (boolean);

        /** SeriesRequireField requireThumbnail. */
        public requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** SeriesRequireField requireIsOfficial. */
        public requireIsOfficial?: (boolean);

        /** SeriesRequireField requireIsCompleted. */
        public requireIsCompleted?: (boolean);

        /** SeriesRequireField requireNextPublishAt. */
        public requireNextPublishAt?: (boolean);

        /** SeriesRequireField requireSharedWithStatus. */
        public requireSharedWithStatus?: (boolean);

        /** SeriesRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** SeriesRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /** SeriesRequireField requireHasVipOnlyStory. */
        public requireHasVipOnlyStory?: (boolean);

        /** SeriesRequireField requireHasFreeStory. */
        public requireHasFreeStory?: (boolean);

        /** SeriesRequireField requireSeriesCharge. */
        public requireSeriesCharge?: (query_series_charge.ISeriesChargeRequireField);

        /** SeriesRequireField requireSeriesGroupList. */
        public requireSeriesGroupList?: (query_recursive_types.ISeriesGroupRequireField);

        /** SeriesRequireField requireUserBookmarked. */
        public requireUserBookmarked?: (boolean);

        /** SeriesRequireField requirePublishSchedule. */
        public requirePublishSchedule?: (boolean);

        /** SeriesRequireField requireLatestPublishedAt. */
        public requireLatestPublishedAt?: (boolean);

        /** SeriesRequireField requireHasNovelScriptStory. */
        public requireHasNovelScriptStory?: (boolean);

        /** SeriesRequireField requireHasChatNovelScriptStory. */
        public requireHasChatNovelScriptStory?: (boolean);

        /** SeriesRequireField requireSearchableStoryCount. */
        public requireSearchableStoryCount?: (boolean);

        /** SeriesRequireField requireTotalLikeCount. */
        public requireTotalLikeCount?: (boolean);

        /** SeriesRequireField requireUserReadingProgress. */
        public requireUserReadingProgress?: (query_recursive_types.ISeriesReadingProgressRequireField);

        /** SeriesRequireField requireCharacterList. */
        public requireCharacterList?: (query_recursive_types.ICharacterRequireField);

        /** SeriesRequireField requireUserAvailability. */
        public requireUserAvailability?: (boolean);

        /** SeriesRequireField requireIsOneshot. */
        public requireIsOneshot?: (boolean);

        /** SeriesRequireField requireCanChangeToOneshot. */
        public requireCanChangeToOneshot?: (boolean);

        /** SeriesRequireField requireFirstPublishedStory. */
        public requireFirstPublishedStory?: (query_recursive_types.IStoryRequireField);

        /** SeriesRequireField requireRecommenderModelId. */
        public requireRecommenderModelId?: (boolean);

        /** SeriesRequireField requireIsUploadingImageLocked. */
        public requireIsUploadingImageLocked?: (boolean);

        /** SeriesRequireField requireShowGuidelineDialog. */
        public requireShowGuidelineDialog?: (boolean);

        /** SeriesRequireField requireCanShowGoogleAd. */
        public requireCanShowGoogleAd?: (boolean);

        /** SeriesRequireField requireOriginalFlag. */
        public requireOriginalFlag?: (boolean);

        /** SeriesRequireField requireGenre. */
        public requireGenre?: (boolean);

        /** SeriesRequireField requireIsSensitive. */
        public requireIsSensitive?: (boolean);

        /** SeriesRequireField requireRecommendedSeries. */
        public requireRecommendedSeries?: (query_recursive_types.IRecommendSeriesRequest);

        /** SeriesRequireField requireStoryPage. */
        public requireStoryPage?: (query_recursive_types.ISeriesStoryPageRequest);

        /** SeriesRequireField requireSearchStory. */
        public requireSearchStory?: (query_recursive_types.ISearchStoryRequest);

        /** SeriesRequireField requireSensitiveStoryCount. */
        public requireSensitiveStoryCount?: (boolean);

        /** SeriesRequireField requireRecommendedSeriesPage. */
        public requireRecommendedSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SeriesRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesResponse. */
    export interface ISeriesResponse {

        /** SeriesResponse id */
        id?: (string);

        /** SeriesResponse user */
        user?: (query_recursive_types.IUserResponse);

        /** SeriesResponse tags */
        tags?: (string[]);

        /** SeriesResponse title */
        title?: (string);

        /** SeriesResponse description */
        description?: (string);

        /** SeriesResponse thumbnail */
        thumbnail?: (query_recursive_types.IImageResponse);

        /** SeriesResponse isOfficial */
        isOfficial?: (boolean);

        /** SeriesResponse isCompleted */
        isCompleted?: (boolean);

        /** SeriesResponse nextPublishAt */
        nextPublishAt?: (string);

        /** SeriesResponse sharedWithStatus */
        sharedWithStatus?: (types.SharedWithStatus);

        /** SeriesResponse createdAt */
        createdAt?: (string);

        /** SeriesResponse updatedAt */
        updatedAt?: (string);

        /** SeriesResponse hasVipOnlyStory */
        hasVipOnlyStory?: (boolean);

        /** SeriesResponse hasFreeStory */
        hasFreeStory?: (boolean);

        /** SeriesResponse seriesCharge */
        seriesCharge?: (query_series_charge.ISeriesChargeResponse);

        /** SeriesResponse seriesGroupList */
        seriesGroupList?: (query_recursive_types.ISeriesGroupResponse[]);

        /** SeriesResponse userBookmarked */
        userBookmarked?: (boolean);

        /** SeriesResponse publishSchedule */
        publishSchedule?: (query_series.IPublishScheduleResponse);

        /** SeriesResponse latestPublishedAt */
        latestPublishedAt?: (string);

        /** SeriesResponse hasNovelScriptStory */
        hasNovelScriptStory?: (boolean);

        /** SeriesResponse hasChatNovelScriptStory */
        hasChatNovelScriptStory?: (boolean);

        /** SeriesResponse searchableStoryCount */
        searchableStoryCount?: (number);

        /** SeriesResponse totalLikeCount */
        totalLikeCount?: (number);

        /** SeriesResponse userReadingProgress */
        userReadingProgress?: (query_recursive_types.ISeriesReadingProgressResponse);

        /** SeriesResponse characterList */
        characterList?: (query_recursive_types.ICharacterResponse[]);

        /** SeriesResponse userAvailability */
        userAvailability?: (query_types.SeriesAvailability);

        /** SeriesResponse isOneshot */
        isOneshot?: (boolean);

        /** SeriesResponse canChangeToOneshot */
        canChangeToOneshot?: (boolean);

        /** SeriesResponse firstPublishedStory */
        firstPublishedStory?: (query_recursive_types.IStoryResponse);

        /** SeriesResponse recommenderModelId */
        recommenderModelId?: (string);

        /** SeriesResponse recommendedSeriesPage */
        recommendedSeriesPage?: (query_recursive_types.ISeriesPageResponse);

        /** SeriesResponse isUploadingImageLocked */
        isUploadingImageLocked?: (boolean);

        /** SeriesResponse showGuidelineDialog */
        showGuidelineDialog?: (boolean);

        /** SeriesResponse canShowGoogleAd */
        canShowGoogleAd?: (boolean);

        /** SeriesResponse originalFlag */
        originalFlag?: (query_types.OriginalFlag);

        /** SeriesResponse genre */
        genre?: (query_types.Genre);

        /** SeriesResponse isSensitive */
        isSensitive?: (boolean);

        /** SeriesResponse storyPage */
        storyPage?: (query_recursive_types.ISeriesStoryPageResponse);

        /** SeriesResponse searchStory */
        searchStory?: (query_recursive_types.ISearchStoryResponse);

        /** SeriesResponse sensitiveStoryCount */
        sensitiveStoryCount?: (number);
    }

    /** Represents a SeriesResponse. */
    class SeriesResponse implements ISeriesResponse {

        /**
         * Constructs a new SeriesResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesResponse);

        /** SeriesResponse id. */
        public id?: (string);

        /** SeriesResponse user. */
        public user?: (query_recursive_types.IUserResponse);

        /** SeriesResponse tags. */
        public tags: string[];

        /** SeriesResponse title. */
        public title?: (string);

        /** SeriesResponse description. */
        public description?: (string);

        /** SeriesResponse thumbnail. */
        public thumbnail?: (query_recursive_types.IImageResponse);

        /** SeriesResponse isOfficial. */
        public isOfficial?: (boolean);

        /** SeriesResponse isCompleted. */
        public isCompleted?: (boolean);

        /** SeriesResponse nextPublishAt. */
        public nextPublishAt?: (string);

        /** SeriesResponse sharedWithStatus. */
        public sharedWithStatus: types.SharedWithStatus;

        /** SeriesResponse createdAt. */
        public createdAt?: (string);

        /** SeriesResponse updatedAt. */
        public updatedAt?: (string);

        /** SeriesResponse hasVipOnlyStory. */
        public hasVipOnlyStory?: (boolean);

        /** SeriesResponse hasFreeStory. */
        public hasFreeStory?: (boolean);

        /** SeriesResponse seriesCharge. */
        public seriesCharge?: (query_series_charge.ISeriesChargeResponse);

        /** SeriesResponse seriesGroupList. */
        public seriesGroupList: query_recursive_types.ISeriesGroupResponse[];

        /** SeriesResponse userBookmarked. */
        public userBookmarked?: (boolean);

        /** SeriesResponse publishSchedule. */
        public publishSchedule?: (query_series.IPublishScheduleResponse);

        /** SeriesResponse latestPublishedAt. */
        public latestPublishedAt?: (string);

        /** SeriesResponse hasNovelScriptStory. */
        public hasNovelScriptStory?: (boolean);

        /** SeriesResponse hasChatNovelScriptStory. */
        public hasChatNovelScriptStory?: (boolean);

        /** SeriesResponse searchableStoryCount. */
        public searchableStoryCount?: (number);

        /** SeriesResponse totalLikeCount. */
        public totalLikeCount?: (number);

        /** SeriesResponse userReadingProgress. */
        public userReadingProgress?: (query_recursive_types.ISeriesReadingProgressResponse);

        /** SeriesResponse characterList. */
        public characterList: query_recursive_types.ICharacterResponse[];

        /** SeriesResponse userAvailability. */
        public userAvailability: query_types.SeriesAvailability;

        /** SeriesResponse isOneshot. */
        public isOneshot?: (boolean);

        /** SeriesResponse canChangeToOneshot. */
        public canChangeToOneshot?: (boolean);

        /** SeriesResponse firstPublishedStory. */
        public firstPublishedStory?: (query_recursive_types.IStoryResponse);

        /** SeriesResponse recommenderModelId. */
        public recommenderModelId?: (string);

        /** SeriesResponse recommendedSeriesPage. */
        public recommendedSeriesPage?: (query_recursive_types.ISeriesPageResponse);

        /** SeriesResponse isUploadingImageLocked. */
        public isUploadingImageLocked?: (boolean);

        /** SeriesResponse showGuidelineDialog. */
        public showGuidelineDialog?: (boolean);

        /** SeriesResponse canShowGoogleAd. */
        public canShowGoogleAd?: (boolean);

        /** SeriesResponse originalFlag. */
        public originalFlag: query_types.OriginalFlag;

        /** SeriesResponse genre. */
        public genre: query_types.Genre;

        /** SeriesResponse isSensitive. */
        public isSensitive?: (boolean);

        /** SeriesResponse storyPage. */
        public storyPage?: (query_recursive_types.ISeriesStoryPageResponse);

        /** SeriesResponse searchStory. */
        public searchStory?: (query_recursive_types.ISearchStoryResponse);

        /** SeriesResponse sensitiveStoryCount. */
        public sensitiveStoryCount?: (number);

        /**
         * Gets the default type url for SeriesResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ScriptTypeFilter. */
    export interface IScriptTypeFilter {

        /** ScriptTypeFilter excludeNovel */
        excludeNovel?: (boolean);

        /** ScriptTypeFilter excludeChatNovel */
        excludeChatNovel?: (boolean);

        /** ScriptTypeFilter excludeVideo */
        excludeVideo?: (boolean);

        /** ScriptTypeFilter excludeAudio */
        excludeAudio?: (boolean);
    }

    /** Represents a ScriptTypeFilter. */
    class ScriptTypeFilter implements IScriptTypeFilter {

        /**
         * Constructs a new ScriptTypeFilter.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IScriptTypeFilter);

        /** ScriptTypeFilter excludeNovel. */
        public excludeNovel: boolean;

        /** ScriptTypeFilter excludeChatNovel. */
        public excludeChatNovel: boolean;

        /** ScriptTypeFilter excludeVideo. */
        public excludeVideo: boolean;

        /** ScriptTypeFilter excludeAudio. */
        public excludeAudio: boolean;

        /**
         * Gets the default type url for ScriptTypeFilter
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesRequest. */
    export interface ISearchSeriesRequest {

        /** SearchSeriesRequest params */
        params?: (query_search_types.ISearchSeriesParams);

        /** SearchSeriesRequest requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SearchSeriesRequest. */
    class SearchSeriesRequest implements ISearchSeriesRequest {

        /**
         * Constructs a new SearchSeriesRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISearchSeriesRequest);

        /** SearchSeriesRequest params. */
        public params?: (query_search_types.ISearchSeriesParams);

        /** SearchSeriesRequest requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SearchSeriesRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesResponse. */
    export interface ISearchSeriesResponse {

        /** SearchSeriesResponse seriesPage */
        seriesPage?: (query_recursive_types.ISeriesPageResponse);
    }

    /** Represents a SearchSeriesResponse. */
    class SearchSeriesResponse implements ISearchSeriesResponse {

        /**
         * Constructs a new SearchSeriesResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISearchSeriesResponse);

        /** SearchSeriesResponse seriesPage. */
        public seriesPage?: (query_recursive_types.ISeriesPageResponse);

        /**
         * Gets the default type url for SearchSeriesResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RecommendSeriesRequest. */
    export interface IRecommendSeriesRequest {

        /** RecommendSeriesRequest requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /** RecommendSeriesRequest scriptTypeFilter */
        scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);
    }

    /** Represents a RecommendSeriesRequest. */
    class RecommendSeriesRequest implements IRecommendSeriesRequest {

        /**
         * Constructs a new RecommendSeriesRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IRecommendSeriesRequest);

        /** RecommendSeriesRequest requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /** RecommendSeriesRequest scriptTypeFilter. */
        public scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /**
         * Gets the default type url for RecommendSeriesRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesPageRequest. */
    export interface ISeriesPageRequest {

        /** SeriesPageRequest requireSeriesList */
        requireSeriesList?: (query_recursive_types.ISeriesRequireField);

        /** SeriesPageRequest page */
        page?: (query_pagination.IPageRequest);
    }

    /** Represents a SeriesPageRequest. */
    class SeriesPageRequest implements ISeriesPageRequest {

        /**
         * Constructs a new SeriesPageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesPageRequest);

        /** SeriesPageRequest requireSeriesList. */
        public requireSeriesList?: (query_recursive_types.ISeriesRequireField);

        /** SeriesPageRequest page. */
        public page?: (query_pagination.IPageRequest);

        /**
         * Gets the default type url for SeriesPageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesPageResponse. */
    export interface ISeriesPageResponse {

        /** SeriesPageResponse seriesList */
        seriesList?: (query_recursive_types.ISeriesResponse[]);

        /** SeriesPageResponse page */
        page?: (query_pagination.IPageInfo);
    }

    /** Represents a SeriesPageResponse. */
    class SeriesPageResponse implements ISeriesPageResponse {

        /**
         * Constructs a new SeriesPageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesPageResponse);

        /** SeriesPageResponse seriesList. */
        public seriesList: query_recursive_types.ISeriesResponse[];

        /** SeriesPageResponse page. */
        public page?: (query_pagination.IPageInfo);

        /**
         * Gets the default type url for SeriesPageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesGroupRequest. */
    export interface ISeriesGroupRequest {

        /** SeriesGroupRequest id */
        id?: (string);

        /** SeriesGroupRequest requireSeriesGroup */
        requireSeriesGroup?: (query_recursive_types.ISeriesGroupRequireField);
    }

    /** Represents a SeriesGroupRequest. */
    class SeriesGroupRequest implements ISeriesGroupRequest {

        /**
         * Constructs a new SeriesGroupRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesGroupRequest);

        /** SeriesGroupRequest id. */
        public id?: (string);

        /** SeriesGroupRequest requireSeriesGroup. */
        public requireSeriesGroup?: (query_recursive_types.ISeriesGroupRequireField);

        /**
         * Gets the default type url for SeriesGroupRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesGroupRequireField. */
    export interface ISeriesGroupRequireField {

        /** SeriesGroupRequireField requireId */
        requireId?: (boolean);

        /** SeriesGroupRequireField requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);

        /** SeriesGroupRequireField requireTitle */
        requireTitle?: (boolean);

        /** SeriesGroupRequireField requireDescription */
        requireDescription?: (boolean);

        /** SeriesGroupRequireField requireSharedWithStatus */
        requireSharedWithStatus?: (boolean);

        /** SeriesGroupRequireField requireSeriesList */
        requireSeriesList?: (query_recursive_types.ISeriesRequireField);

        /** SeriesGroupRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** SeriesGroupRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);
    }

    /** Represents a SeriesGroupRequireField. */
    class SeriesGroupRequireField implements ISeriesGroupRequireField {

        /**
         * Constructs a new SeriesGroupRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesGroupRequireField);

        /** SeriesGroupRequireField requireId. */
        public requireId?: (boolean);

        /** SeriesGroupRequireField requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /** SeriesGroupRequireField requireTitle. */
        public requireTitle?: (boolean);

        /** SeriesGroupRequireField requireDescription. */
        public requireDescription?: (boolean);

        /** SeriesGroupRequireField requireSharedWithStatus. */
        public requireSharedWithStatus?: (boolean);

        /** SeriesGroupRequireField requireSeriesList. */
        public requireSeriesList?: (query_recursive_types.ISeriesRequireField);

        /** SeriesGroupRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** SeriesGroupRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /**
         * Gets the default type url for SeriesGroupRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesGroupResponse. */
    export interface ISeriesGroupResponse {

        /** SeriesGroupResponse id */
        id?: (string);

        /** SeriesGroupResponse user */
        user?: (query_recursive_types.IUserResponse);

        /** SeriesGroupResponse title */
        title?: (string);

        /** SeriesGroupResponse description */
        description?: (string);

        /** SeriesGroupResponse sharedWithStatus */
        sharedWithStatus?: (types.SharedWithStatus);

        /** SeriesGroupResponse seriesList */
        seriesList?: (query_recursive_types.ISeriesResponse[]);

        /** SeriesGroupResponse createdAt */
        createdAt?: (string);

        /** SeriesGroupResponse updatedAt */
        updatedAt?: (string);
    }

    /** Represents a SeriesGroupResponse. */
    class SeriesGroupResponse implements ISeriesGroupResponse {

        /**
         * Constructs a new SeriesGroupResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesGroupResponse);

        /** SeriesGroupResponse id. */
        public id?: (string);

        /** SeriesGroupResponse user. */
        public user?: (query_recursive_types.IUserResponse);

        /** SeriesGroupResponse title. */
        public title?: (string);

        /** SeriesGroupResponse description. */
        public description?: (string);

        /** SeriesGroupResponse sharedWithStatus. */
        public sharedWithStatus: types.SharedWithStatus;

        /** SeriesGroupResponse seriesList. */
        public seriesList: query_recursive_types.ISeriesResponse[];

        /** SeriesGroupResponse createdAt. */
        public createdAt?: (string);

        /** SeriesGroupResponse updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for SeriesGroupResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesGroupRequest. */
    export interface ISearchSeriesGroupRequest {

        /** SearchSeriesGroupRequest params */
        params?: (query_search_types.ISearchSeriesGroupParams);

        /** SearchSeriesGroupRequest requireSeriesGroupPage */
        requireSeriesGroupPage?: (query_recursive_types.ISeriesGroupPageRequest);
    }

    /** Represents a SearchSeriesGroupRequest. */
    class SearchSeriesGroupRequest implements ISearchSeriesGroupRequest {

        /**
         * Constructs a new SearchSeriesGroupRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISearchSeriesGroupRequest);

        /** SearchSeriesGroupRequest params. */
        public params?: (query_search_types.ISearchSeriesGroupParams);

        /** SearchSeriesGroupRequest requireSeriesGroupPage. */
        public requireSeriesGroupPage?: (query_recursive_types.ISeriesGroupPageRequest);

        /**
         * Gets the default type url for SearchSeriesGroupRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesGroupResponse. */
    export interface ISearchSeriesGroupResponse {

        /** SearchSeriesGroupResponse seriesGroupPage */
        seriesGroupPage?: (query_recursive_types.ISeriesGroupPageResponse);
    }

    /** Represents a SearchSeriesGroupResponse. */
    class SearchSeriesGroupResponse implements ISearchSeriesGroupResponse {

        /**
         * Constructs a new SearchSeriesGroupResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISearchSeriesGroupResponse);

        /** SearchSeriesGroupResponse seriesGroupPage. */
        public seriesGroupPage?: (query_recursive_types.ISeriesGroupPageResponse);

        /**
         * Gets the default type url for SearchSeriesGroupResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesGroupPageRequest. */
    export interface ISeriesGroupPageRequest {

        /** SeriesGroupPageRequest requireSeriesGroupList */
        requireSeriesGroupList?: (query_recursive_types.ISeriesGroupRequireField);

        /** SeriesGroupPageRequest page */
        page?: (query_pagination.IPageRequest);
    }

    /** Represents a SeriesGroupPageRequest. */
    class SeriesGroupPageRequest implements ISeriesGroupPageRequest {

        /**
         * Constructs a new SeriesGroupPageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesGroupPageRequest);

        /** SeriesGroupPageRequest requireSeriesGroupList. */
        public requireSeriesGroupList?: (query_recursive_types.ISeriesGroupRequireField);

        /** SeriesGroupPageRequest page. */
        public page?: (query_pagination.IPageRequest);

        /**
         * Gets the default type url for SeriesGroupPageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesGroupPageResponse. */
    export interface ISeriesGroupPageResponse {

        /** SeriesGroupPageResponse seriesGroupList */
        seriesGroupList?: (query_recursive_types.ISeriesGroupResponse[]);

        /** SeriesGroupPageResponse page */
        page?: (query_pagination.IPageInfo);
    }

    /** Represents a SeriesGroupPageResponse. */
    class SeriesGroupPageResponse implements ISeriesGroupPageResponse {

        /**
         * Constructs a new SeriesGroupPageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesGroupPageResponse);

        /** SeriesGroupPageResponse seriesGroupList. */
        public seriesGroupList: query_recursive_types.ISeriesGroupResponse[];

        /** SeriesGroupPageResponse page. */
        public page?: (query_pagination.IPageInfo);

        /**
         * Gets the default type url for SeriesGroupPageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an ImageRequireField. */
    export interface IImageRequireField {

        /** ImageRequireField requireId */
        requireId?: (boolean);

        /** ImageRequireField requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);

        /** ImageRequireField requireServingUrl */
        requireServingUrl?: (boolean);

        /** ImageRequireField requireFormat */
        requireFormat?: (boolean);

        /** ImageRequireField requireWidth */
        requireWidth?: (boolean);

        /** ImageRequireField requireHeight */
        requireHeight?: (boolean);

        /** ImageRequireField requireCategory */
        requireCategory?: (boolean);

        /** ImageRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** ImageRequireField requireIsAvailable */
        requireIsAvailable?: (boolean);

        /** ImageRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);
    }

    /** Represents an ImageRequireField. */
    class ImageRequireField implements IImageRequireField {

        /**
         * Constructs a new ImageRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IImageRequireField);

        /** ImageRequireField requireId. */
        public requireId?: (boolean);

        /** ImageRequireField requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /** ImageRequireField requireServingUrl. */
        public requireServingUrl?: (boolean);

        /** ImageRequireField requireFormat. */
        public requireFormat?: (boolean);

        /** ImageRequireField requireWidth. */
        public requireWidth?: (boolean);

        /** ImageRequireField requireHeight. */
        public requireHeight?: (boolean);

        /** ImageRequireField requireCategory. */
        public requireCategory?: (boolean);

        /** ImageRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** ImageRequireField requireIsAvailable. */
        public requireIsAvailable?: (boolean);

        /** ImageRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /**
         * Gets the default type url for ImageRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an ImageResponse. */
    export interface IImageResponse {

        /** ImageResponse id */
        id?: (string);

        /** ImageResponse user */
        user?: (query_recursive_types.IUserResponse);

        /** ImageResponse servingUrl */
        servingUrl?: (string);

        /** ImageResponse format */
        format?: (resource.ImageFormat);

        /** ImageResponse width */
        width?: (number);

        /** ImageResponse height */
        height?: (number);

        /** ImageResponse category */
        category?: (resource.ImageCategory);

        /** ImageResponse createdAt */
        createdAt?: (string);

        /** ImageResponse isAvailable */
        isAvailable?: (boolean);

        /** ImageResponse updatedAt */
        updatedAt?: (string);
    }

    /** Represents an ImageResponse. */
    class ImageResponse implements IImageResponse {

        /**
         * Constructs a new ImageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IImageResponse);

        /** ImageResponse id. */
        public id?: (string);

        /** ImageResponse user. */
        public user?: (query_recursive_types.IUserResponse);

        /** ImageResponse servingUrl. */
        public servingUrl?: (string);

        /** ImageResponse format. */
        public format: resource.ImageFormat;

        /** ImageResponse width. */
        public width?: (number);

        /** ImageResponse height. */
        public height?: (number);

        /** ImageResponse category. */
        public category: resource.ImageCategory;

        /** ImageResponse createdAt. */
        public createdAt?: (string);

        /** ImageResponse isAvailable. */
        public isAvailable?: (boolean);

        /** ImageResponse updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for ImageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AudioRequireField. */
    export interface IAudioRequireField {

        /** AudioRequireField requireId */
        requireId?: (boolean);

        /** AudioRequireField requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);

        /** AudioRequireField requireServingUrl */
        requireServingUrl?: (boolean);

        /** AudioRequireField requireDuration */
        requireDuration?: (boolean);

        /** AudioRequireField requireCodec */
        requireCodec?: (boolean);

        /** AudioRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);
    }

    /** Represents an AudioRequireField. */
    class AudioRequireField implements IAudioRequireField {

        /**
         * Constructs a new AudioRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IAudioRequireField);

        /** AudioRequireField requireId. */
        public requireId?: (boolean);

        /** AudioRequireField requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /** AudioRequireField requireServingUrl. */
        public requireServingUrl?: (boolean);

        /** AudioRequireField requireDuration. */
        public requireDuration?: (boolean);

        /** AudioRequireField requireCodec. */
        public requireCodec?: (boolean);

        /** AudioRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /**
         * Gets the default type url for AudioRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagRequireField. */
    export interface ITagRequireField {

        /** TagRequireField requireTag */
        requireTag?: (boolean);

        /** TagRequireField requireDescription */
        requireDescription?: (boolean);

        /** TagRequireField requireChildren */
        requireChildren?: (query_recursive_types.ITagRequireField);

        /** TagRequireField requireParent */
        requireParent?: (query_recursive_types.ITagRequireField);
    }

    /** Represents a TagRequireField. */
    class TagRequireField implements ITagRequireField {

        /**
         * Constructs a new TagRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ITagRequireField);

        /** TagRequireField requireTag. */
        public requireTag?: (boolean);

        /** TagRequireField requireDescription. */
        public requireDescription?: (boolean);

        /** TagRequireField requireChildren. */
        public requireChildren?: (query_recursive_types.ITagRequireField);

        /** TagRequireField requireParent. */
        public requireParent?: (query_recursive_types.ITagRequireField);

        /**
         * Gets the default type url for TagRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagResponse. */
    export interface ITagResponse {

        /** TagResponse tag */
        tag?: (string);

        /** TagResponse description */
        description?: (string);

        /** TagResponse children */
        children?: (query_recursive_types.ITagResponse[]);

        /** TagResponse parent */
        parent?: (query_recursive_types.ITagResponse);
    }

    /** Represents a TagResponse. */
    class TagResponse implements ITagResponse {

        /**
         * Constructs a new TagResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ITagResponse);

        /** TagResponse tag. */
        public tag?: (string);

        /** TagResponse description. */
        public description?: (string);

        /** TagResponse children. */
        public children: query_recursive_types.ITagResponse[];

        /** TagResponse parent. */
        public parent?: (query_recursive_types.ITagResponse);

        /**
         * Gets the default type url for TagResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an AudioResponse. */
    export interface IAudioResponse {

        /** AudioResponse id */
        id?: (string);

        /** AudioResponse user */
        user?: (query_recursive_types.IUserResponse);

        /** AudioResponse servingUrl */
        servingUrl?: (string);

        /** AudioResponse duration */
        duration?: (string);

        /** AudioResponse codec */
        codec?: (resource.AudioCodec);

        /** AudioResponse createdAt */
        createdAt?: (string);
    }

    /** Represents an AudioResponse. */
    class AudioResponse implements IAudioResponse {

        /**
         * Constructs a new AudioResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IAudioResponse);

        /** AudioResponse id. */
        public id?: (string);

        /** AudioResponse user. */
        public user?: (query_recursive_types.IUserResponse);

        /** AudioResponse servingUrl. */
        public servingUrl?: (string);

        /** AudioResponse duration. */
        public duration?: (string);

        /** AudioResponse codec. */
        public codec: resource.AudioCodec;

        /** AudioResponse createdAt. */
        public createdAt?: (string);

        /**
         * Gets the default type url for AudioResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementRequest. */
    export interface IStoryAdvertisementRequest {

        /** StoryAdvertisementRequest id */
        id?: (string);

        /** StoryAdvertisementRequest requireAdvertisement */
        requireAdvertisement?: (query_recursive_types.IStoryAdvertisementRequireField);
    }

    /** Represents a StoryAdvertisementRequest. */
    class StoryAdvertisementRequest implements IStoryAdvertisementRequest {

        /**
         * Constructs a new StoryAdvertisementRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryAdvertisementRequest);

        /** StoryAdvertisementRequest id. */
        public id?: (string);

        /** StoryAdvertisementRequest requireAdvertisement. */
        public requireAdvertisement?: (query_recursive_types.IStoryAdvertisementRequireField);

        /**
         * Gets the default type url for StoryAdvertisementRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementRequireField. */
    export interface IStoryAdvertisementRequireField {

        /** StoryAdvertisementRequireField requireId */
        requireId?: (boolean);

        /** StoryAdvertisementRequireField requireTitle */
        requireTitle?: (boolean);

        /** StoryAdvertisementRequireField requireDescription */
        requireDescription?: (boolean);

        /** StoryAdvertisementRequireField requireThumbnail */
        requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** StoryAdvertisementRequireField requireLinkUrl */
        requireLinkUrl?: (boolean);
    }

    /** Represents a StoryAdvertisementRequireField. */
    class StoryAdvertisementRequireField implements IStoryAdvertisementRequireField {

        /**
         * Constructs a new StoryAdvertisementRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryAdvertisementRequireField);

        /** StoryAdvertisementRequireField requireId. */
        public requireId?: (boolean);

        /** StoryAdvertisementRequireField requireTitle. */
        public requireTitle?: (boolean);

        /** StoryAdvertisementRequireField requireDescription. */
        public requireDescription?: (boolean);

        /** StoryAdvertisementRequireField requireThumbnail. */
        public requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** StoryAdvertisementRequireField requireLinkUrl. */
        public requireLinkUrl?: (boolean);

        /**
         * Gets the default type url for StoryAdvertisementRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementResponse. */
    export interface IStoryAdvertisementResponse {

        /** StoryAdvertisementResponse id */
        id?: (string);

        /** StoryAdvertisementResponse title */
        title?: (string);

        /** StoryAdvertisementResponse description */
        description?: (string);

        /** StoryAdvertisementResponse thumbnail */
        thumbnail?: (query_recursive_types.IImageResponse);

        /** StoryAdvertisementResponse linkUrl */
        linkUrl?: (string);
    }

    /** Represents a StoryAdvertisementResponse. */
    class StoryAdvertisementResponse implements IStoryAdvertisementResponse {

        /**
         * Constructs a new StoryAdvertisementResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryAdvertisementResponse);

        /** StoryAdvertisementResponse id. */
        public id?: (string);

        /** StoryAdvertisementResponse title. */
        public title?: (string);

        /** StoryAdvertisementResponse description. */
        public description?: (string);

        /** StoryAdvertisementResponse thumbnail. */
        public thumbnail?: (query_recursive_types.IImageResponse);

        /** StoryAdvertisementResponse linkUrl. */
        public linkUrl?: (string);

        /**
         * Gets the default type url for StoryAdvertisementResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementCursorRequest. */
    export interface IStoryAdvertisementCursorRequest {

        /** StoryAdvertisementCursorRequest filterStoryId */
        filterStoryId?: (string);

        /** StoryAdvertisementCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);

        /** StoryAdvertisementCursorRequest requireAdvertisementList */
        requireAdvertisementList?: (query_recursive_types.IStoryAdvertisementRequireField);

        /** StoryAdvertisementCursorRequest includeInactive */
        includeInactive?: (boolean);
    }

    /** Represents a StoryAdvertisementCursorRequest. */
    class StoryAdvertisementCursorRequest implements IStoryAdvertisementCursorRequest {

        /**
         * Constructs a new StoryAdvertisementCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryAdvertisementCursorRequest);

        /** StoryAdvertisementCursorRequest filterStoryId. */
        public filterStoryId?: (string);

        /** StoryAdvertisementCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /** StoryAdvertisementCursorRequest requireAdvertisementList. */
        public requireAdvertisementList?: (query_recursive_types.IStoryAdvertisementRequireField);

        /** StoryAdvertisementCursorRequest includeInactive. */
        public includeInactive?: (boolean);

        /**
         * Gets the default type url for StoryAdvertisementCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementCursorResponse. */
    export interface IStoryAdvertisementCursorResponse {

        /** StoryAdvertisementCursorResponse advertisementList */
        advertisementList?: (query_recursive_types.IStoryAdvertisementResponse[]);

        /** StoryAdvertisementCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a StoryAdvertisementCursorResponse. */
    class StoryAdvertisementCursorResponse implements IStoryAdvertisementCursorResponse {

        /**
         * Constructs a new StoryAdvertisementCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryAdvertisementCursorResponse);

        /** StoryAdvertisementCursorResponse advertisementList. */
        public advertisementList: query_recursive_types.IStoryAdvertisementResponse[];

        /** StoryAdvertisementCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for StoryAdvertisementCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentPermissionRequireField. */
    export interface ICommentPermissionRequireField {

        /** CommentPermissionRequireField requireCanAdd */
        requireCanAdd?: (boolean);

        /** CommentPermissionRequireField requireCanSetPin */
        requireCanSetPin?: (boolean);
    }

    /** Represents a CommentPermissionRequireField. */
    class CommentPermissionRequireField implements ICommentPermissionRequireField {

        /**
         * Constructs a new CommentPermissionRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentPermissionRequireField);

        /** CommentPermissionRequireField requireCanAdd. */
        public requireCanAdd?: (boolean);

        /** CommentPermissionRequireField requireCanSetPin. */
        public requireCanSetPin?: (boolean);

        /**
         * Gets the default type url for CommentPermissionRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentPermissionResponse. */
    export interface ICommentPermissionResponse {

        /** CommentPermissionResponse canAdd */
        canAdd?: (boolean);

        /** CommentPermissionResponse canSetPin */
        canSetPin?: (boolean);
    }

    /** Represents a CommentPermissionResponse. */
    class CommentPermissionResponse implements ICommentPermissionResponse {

        /**
         * Constructs a new CommentPermissionResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentPermissionResponse);

        /** CommentPermissionResponse canAdd. */
        public canAdd?: (boolean);

        /** CommentPermissionResponse canSetPin. */
        public canSetPin?: (boolean);

        /**
         * Gets the default type url for CommentPermissionResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentSectionRequireField. */
    export interface ICommentSectionRequireField {

        /** CommentSectionRequireField requirePinnedList */
        requirePinnedList?: (query_recursive_types.ICommentRequireField);

        /** CommentSectionRequireField requirePopularList */
        requirePopularList?: (query_recursive_types.ICommentRequireField);

        /** CommentSectionRequireField requireAllList */
        requireAllList?: (query_recursive_types.ICommentCursorRequest);
    }

    /** Represents a CommentSectionRequireField. */
    class CommentSectionRequireField implements ICommentSectionRequireField {

        /**
         * Constructs a new CommentSectionRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentSectionRequireField);

        /** CommentSectionRequireField requirePinnedList. */
        public requirePinnedList?: (query_recursive_types.ICommentRequireField);

        /** CommentSectionRequireField requirePopularList. */
        public requirePopularList?: (query_recursive_types.ICommentRequireField);

        /** CommentSectionRequireField requireAllList. */
        public requireAllList?: (query_recursive_types.ICommentCursorRequest);

        /**
         * Gets the default type url for CommentSectionRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentSectionResponse. */
    export interface ICommentSectionResponse {

        /** CommentSectionResponse pinnedList */
        pinnedList?: (query_recursive_types.ICommentResponse[]);

        /** CommentSectionResponse popularList */
        popularList?: (query_recursive_types.ICommentResponse[]);

        /** CommentSectionResponse allList */
        allList?: (query_recursive_types.ICommentCursorResponse);
    }

    /** Represents a CommentSectionResponse. */
    class CommentSectionResponse implements ICommentSectionResponse {

        /**
         * Constructs a new CommentSectionResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentSectionResponse);

        /** CommentSectionResponse pinnedList. */
        public pinnedList: query_recursive_types.ICommentResponse[];

        /** CommentSectionResponse popularList. */
        public popularList: query_recursive_types.ICommentResponse[];

        /** CommentSectionResponse allList. */
        public allList?: (query_recursive_types.ICommentCursorResponse);

        /**
         * Gets the default type url for CommentSectionResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentRequest. */
    export interface ICommentRequest {

        /** CommentRequest id */
        id?: (string);

        /** CommentRequest requireComment */
        requireComment?: (query_recursive_types.ICommentRequireField);
    }

    /** Represents a CommentRequest. */
    class CommentRequest implements ICommentRequest {

        /**
         * Constructs a new CommentRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentRequest);

        /** CommentRequest id. */
        public id?: (string);

        /** CommentRequest requireComment. */
        public requireComment?: (query_recursive_types.ICommentRequireField);

        /**
         * Gets the default type url for CommentRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentRequireField. */
    export interface ICommentRequireField {

        /** CommentRequireField requireId */
        requireId?: (boolean);

        /** CommentRequireField requireParentComment */
        requireParentComment?: (query_recursive_types.ICommentRequireField);

        /** CommentRequireField requireReplyCommentPage */
        requireReplyCommentPage?: (query_recursive_types.ICommentCursorRequest);

        /** CommentRequireField requireStory */
        requireStory?: (query_recursive_types.IStoryRequireField);

        /** CommentRequireField requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);

        /** CommentRequireField requireTextBody */
        requireTextBody?: (boolean);

        /** CommentRequireField requireStampBody */
        requireStampBody?: (query_recursive_types.IStampMasterRequireField);

        /** CommentRequireField requireLikeCount */
        requireLikeCount?: (boolean);

        /** CommentRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** CommentRequireField requireDeletedAt */
        requireDeletedAt?: (boolean);

        /** CommentRequireField requireDeletedBy */
        requireDeletedBy?: (boolean);

        /** CommentRequireField requireLikedByAuthor */
        requireLikedByAuthor?: (boolean);

        /** CommentRequireField requireLikedByMe */
        requireLikedByMe?: (boolean);
    }

    /** Represents a CommentRequireField. */
    class CommentRequireField implements ICommentRequireField {

        /**
         * Constructs a new CommentRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentRequireField);

        /** CommentRequireField requireId. */
        public requireId?: (boolean);

        /** CommentRequireField requireParentComment. */
        public requireParentComment?: (query_recursive_types.ICommentRequireField);

        /** CommentRequireField requireReplyCommentPage. */
        public requireReplyCommentPage?: (query_recursive_types.ICommentCursorRequest);

        /** CommentRequireField requireStory. */
        public requireStory?: (query_recursive_types.IStoryRequireField);

        /** CommentRequireField requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /** CommentRequireField requireTextBody. */
        public requireTextBody?: (boolean);

        /** CommentRequireField requireStampBody. */
        public requireStampBody?: (query_recursive_types.IStampMasterRequireField);

        /** CommentRequireField requireLikeCount. */
        public requireLikeCount?: (boolean);

        /** CommentRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** CommentRequireField requireDeletedAt. */
        public requireDeletedAt?: (boolean);

        /** CommentRequireField requireDeletedBy. */
        public requireDeletedBy?: (boolean);

        /** CommentRequireField requireLikedByAuthor. */
        public requireLikedByAuthor?: (boolean);

        /** CommentRequireField requireLikedByMe. */
        public requireLikedByMe?: (boolean);

        /**
         * Gets the default type url for CommentRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentResponse. */
    export interface ICommentResponse {

        /** CommentResponse id */
        id?: (string);

        /** CommentResponse parentComment */
        parentComment?: (query_recursive_types.ICommentResponse);

        /** CommentResponse replyCommentPage */
        replyCommentPage?: (query_recursive_types.ICommentCursorResponse);

        /** CommentResponse story */
        story?: (query_recursive_types.IStoryResponse);

        /** CommentResponse user */
        user?: (query_recursive_types.IUserResponse);

        /** CommentResponse textBody */
        textBody?: (string);

        /** CommentResponse stampBody */
        stampBody?: (query_recursive_types.IStampMasterResponse);

        /** CommentResponse likeCount */
        likeCount?: (number);

        /** CommentResponse createdAt */
        createdAt?: (string);

        /** CommentResponse deletedAt */
        deletedAt?: (string);

        /** CommentResponse deletedBy */
        deletedBy?: (comment.CommentDeletedBy);

        /** CommentResponse likedByAuthor */
        likedByAuthor?: (boolean);

        /** CommentResponse likedByMe */
        likedByMe?: (boolean);
    }

    /** Represents a CommentResponse. */
    class CommentResponse implements ICommentResponse {

        /**
         * Constructs a new CommentResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentResponse);

        /** CommentResponse id. */
        public id?: (string);

        /** CommentResponse parentComment. */
        public parentComment?: (query_recursive_types.ICommentResponse);

        /** CommentResponse replyCommentPage. */
        public replyCommentPage?: (query_recursive_types.ICommentCursorResponse);

        /** CommentResponse story. */
        public story?: (query_recursive_types.IStoryResponse);

        /** CommentResponse user. */
        public user?: (query_recursive_types.IUserResponse);

        /** CommentResponse textBody. */
        public textBody?: (string);

        /** CommentResponse stampBody. */
        public stampBody?: (query_recursive_types.IStampMasterResponse);

        /** CommentResponse likeCount. */
        public likeCount?: (number);

        /** CommentResponse createdAt. */
        public createdAt?: (string);

        /** CommentResponse deletedAt. */
        public deletedAt?: (string);

        /** CommentResponse deletedBy. */
        public deletedBy: comment.CommentDeletedBy;

        /** CommentResponse likedByAuthor. */
        public likedByAuthor?: (boolean);

        /** CommentResponse likedByMe. */
        public likedByMe?: (boolean);

        /**
         * Gets the default type url for CommentResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentCursorRequest. */
    export interface ICommentCursorRequest {

        /** CommentCursorRequest requireCommentList */
        requireCommentList?: (query_recursive_types.ICommentRequireField);

        /** CommentCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);

        /** CommentCursorRequest includeDeleted */
        includeDeleted?: (boolean);

        /** CommentCursorRequest includePinned */
        includePinned?: (boolean);

        /** CommentCursorRequest includeHidden */
        includeHidden?: (boolean);
    }

    /** Represents a CommentCursorRequest. */
    class CommentCursorRequest implements ICommentCursorRequest {

        /**
         * Constructs a new CommentCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentCursorRequest);

        /** CommentCursorRequest requireCommentList. */
        public requireCommentList?: (query_recursive_types.ICommentRequireField);

        /** CommentCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /** CommentCursorRequest includeDeleted. */
        public includeDeleted?: (boolean);

        /** CommentCursorRequest includePinned. */
        public includePinned?: (boolean);

        /** CommentCursorRequest includeHidden. */
        public includeHidden?: (boolean);

        /**
         * Gets the default type url for CommentCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CommentCursorResponse. */
    export interface ICommentCursorResponse {

        /** CommentCursorResponse commentList */
        commentList?: (query_recursive_types.ICommentResponse[]);

        /** CommentCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a CommentCursorResponse. */
    class CommentCursorResponse implements ICommentCursorResponse {

        /**
         * Constructs a new CommentCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICommentCursorResponse);

        /** CommentCursorResponse commentList. */
        public commentList: query_recursive_types.ICommentResponse[];

        /** CommentCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for CommentCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ChallengeRequireField. */
    export interface IChallengeRequireField {

        /** ChallengeRequireField requireId */
        requireId?: (boolean);

        /** ChallengeRequireField requireName */
        requireName?: (boolean);

        /** ChallengeRequireField requireLevelList */
        requireLevelList?: (query_recursive_types.ChallengeRequireField.ILevelRequireField);

        /** ChallengeRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);
    }

    /** Represents a ChallengeRequireField. */
    class ChallengeRequireField implements IChallengeRequireField {

        /**
         * Constructs a new ChallengeRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IChallengeRequireField);

        /** ChallengeRequireField requireId. */
        public requireId?: (boolean);

        /** ChallengeRequireField requireName. */
        public requireName?: (boolean);

        /** ChallengeRequireField requireLevelList. */
        public requireLevelList?: (query_recursive_types.ChallengeRequireField.ILevelRequireField);

        /** ChallengeRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /**
         * Gets the default type url for ChallengeRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace ChallengeRequireField {

        /** Properties of a LevelRequireField. */
        export interface ILevelRequireField {

            /** LevelRequireField requireLevel */
            requireLevel?: (boolean);

            /** LevelRequireField requireStep */
            requireStep?: (boolean);

            /** LevelRequireField requireConditionText */
            requireConditionText?: (boolean);

            /** LevelRequireField requireIcon */
            requireIcon?: (query_recursive_types.IImageRequireField);
        }

        /** Represents a LevelRequireField. */
        class LevelRequireField implements ILevelRequireField {

            /**
             * Constructs a new LevelRequireField.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_recursive_types.ChallengeRequireField.ILevelRequireField);

            /** LevelRequireField requireLevel. */
            public requireLevel?: (boolean);

            /** LevelRequireField requireStep. */
            public requireStep?: (boolean);

            /** LevelRequireField requireConditionText. */
            public requireConditionText?: (boolean);

            /** LevelRequireField requireIcon. */
            public requireIcon?: (query_recursive_types.IImageRequireField);

            /**
             * Gets the default type url for LevelRequireField
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a ChallengeResponse. */
    export interface IChallengeResponse {

        /** ChallengeResponse id */
        id?: (string);

        /** ChallengeResponse name */
        name?: (string);

        /** ChallengeResponse levelList */
        levelList?: (query_recursive_types.ChallengeResponse.ILevel[]);

        /** ChallengeResponse updatedAt */
        updatedAt?: (string);
    }

    /** Represents a ChallengeResponse. */
    class ChallengeResponse implements IChallengeResponse {

        /**
         * Constructs a new ChallengeResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IChallengeResponse);

        /** ChallengeResponse id. */
        public id?: (string);

        /** ChallengeResponse name. */
        public name?: (string);

        /** ChallengeResponse levelList. */
        public levelList: query_recursive_types.ChallengeResponse.ILevel[];

        /** ChallengeResponse updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for ChallengeResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace ChallengeResponse {

        /** Properties of a Level. */
        export interface ILevel {

            /** Level level */
            level?: (number);

            /** Level step */
            step?: (number);

            /** Level conditionText */
            conditionText?: (string);

            /** Level icon */
            icon?: (query_recursive_types.IImageResponse);
        }

        /** Represents a Level. */
        class Level implements ILevel {

            /**
             * Constructs a new Level.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_recursive_types.ChallengeResponse.ILevel);

            /** Level level. */
            public level?: (number);

            /** Level step. */
            public step?: (number);

            /** Level conditionText. */
            public conditionText?: (string);

            /** Level icon. */
            public icon?: (query_recursive_types.IImageResponse);

            /**
             * Gets the default type url for Level
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a ChallengeConditionRequireField. */
    export interface IChallengeConditionRequireField {

        /** ChallengeConditionRequireField requireChallenge */
        requireChallenge?: (query_recursive_types.IChallengeRequireField);

        /** ChallengeConditionRequireField requireLevel */
        requireLevel?: (boolean);
    }

    /** Represents a ChallengeConditionRequireField. */
    class ChallengeConditionRequireField implements IChallengeConditionRequireField {

        /**
         * Constructs a new ChallengeConditionRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IChallengeConditionRequireField);

        /** ChallengeConditionRequireField requireChallenge. */
        public requireChallenge?: (query_recursive_types.IChallengeRequireField);

        /** ChallengeConditionRequireField requireLevel. */
        public requireLevel?: (boolean);

        /**
         * Gets the default type url for ChallengeConditionRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ChallengeConditionResponse. */
    export interface IChallengeConditionResponse {

        /** ChallengeConditionResponse challenge */
        challenge?: (query_recursive_types.IChallengeResponse);

        /** ChallengeConditionResponse level */
        level?: (number);
    }

    /** Represents a ChallengeConditionResponse. */
    class ChallengeConditionResponse implements IChallengeConditionResponse {

        /**
         * Constructs a new ChallengeConditionResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IChallengeConditionResponse);

        /** ChallengeConditionResponse challenge. */
        public challenge?: (query_recursive_types.IChallengeResponse);

        /** ChallengeConditionResponse level. */
        public level?: (number);

        /**
         * Gets the default type url for ChallengeConditionResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StampMasterRequireField. */
    export interface IStampMasterRequireField {

        /** StampMasterRequireField requireId */
        requireId?: (boolean);

        /** StampMasterRequireField requireImage */
        requireImage?: (query_recursive_types.IImageRequireField);

        /** StampMasterRequireField requireAltText */
        requireAltText?: (boolean);

        /** StampMasterRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);

        /** StampMasterRequireField requireChallengeCondition */
        requireChallengeCondition?: (query_recursive_types.IChallengeConditionRequireField);
    }

    /** Represents a StampMasterRequireField. */
    class StampMasterRequireField implements IStampMasterRequireField {

        /**
         * Constructs a new StampMasterRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStampMasterRequireField);

        /** StampMasterRequireField requireId. */
        public requireId?: (boolean);

        /** StampMasterRequireField requireImage. */
        public requireImage?: (query_recursive_types.IImageRequireField);

        /** StampMasterRequireField requireAltText. */
        public requireAltText?: (boolean);

        /** StampMasterRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /** StampMasterRequireField requireChallengeCondition. */
        public requireChallengeCondition?: (query_recursive_types.IChallengeConditionRequireField);

        /**
         * Gets the default type url for StampMasterRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StampMasterResponse. */
    export interface IStampMasterResponse {

        /** StampMasterResponse id */
        id?: (string);

        /** StampMasterResponse image */
        image?: (query_recursive_types.IImageResponse);

        /** StampMasterResponse altText */
        altText?: (string);

        /** StampMasterResponse updatedAt */
        updatedAt?: (string);

        /** StampMasterResponse challengeCondition */
        challengeCondition?: (query_recursive_types.IChallengeConditionResponse);
    }

    /** Represents a StampMasterResponse. */
    class StampMasterResponse implements IStampMasterResponse {

        /**
         * Constructs a new StampMasterResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStampMasterResponse);

        /** StampMasterResponse id. */
        public id?: (string);

        /** StampMasterResponse image. */
        public image?: (query_recursive_types.IImageResponse);

        /** StampMasterResponse altText. */
        public altText?: (string);

        /** StampMasterResponse updatedAt. */
        public updatedAt?: (string);

        /** StampMasterResponse challengeCondition. */
        public challengeCondition?: (query_recursive_types.IChallengeConditionResponse);

        /**
         * Gets the default type url for StampMasterResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkItemRequireField. */
    export interface IBookmarkItemRequireField {

        /** BookmarkItemRequireField requireItemType */
        requireItemType?: (boolean);

        /** BookmarkItemRequireField requireItem */
        requireItem?: (query_recursive_types.BookmarkItemRequireField.IBookmarkItemRequireField);

        /** BookmarkItemRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** BookmarkItemRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);
    }

    /** Represents a BookmarkItemRequireField. */
    class BookmarkItemRequireField implements IBookmarkItemRequireField {

        /**
         * Constructs a new BookmarkItemRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IBookmarkItemRequireField);

        /** BookmarkItemRequireField requireItemType. */
        public requireItemType?: (boolean);

        /** BookmarkItemRequireField requireItem. */
        public requireItem?: (query_recursive_types.BookmarkItemRequireField.IBookmarkItemRequireField);

        /** BookmarkItemRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** BookmarkItemRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /**
         * Gets the default type url for BookmarkItemRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace BookmarkItemRequireField {

        /** Properties of a BookmarkItemRequireField. */
        export interface IBookmarkItemRequireField {

            /** BookmarkItemRequireField requireStory */
            requireStory?: (query_recursive_types.IStoryRequireField);

            /** BookmarkItemRequireField requireSeries */
            requireSeries?: (query_recursive_types.ISeriesRequireField);
        }

        /** Represents a BookmarkItemRequireField. */
        class BookmarkItemRequireField implements IBookmarkItemRequireField {

            /**
             * Constructs a new BookmarkItemRequireField.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_recursive_types.BookmarkItemRequireField.IBookmarkItemRequireField);

            /** BookmarkItemRequireField requireStory. */
            public requireStory?: (query_recursive_types.IStoryRequireField);

            /** BookmarkItemRequireField requireSeries. */
            public requireSeries?: (query_recursive_types.ISeriesRequireField);

            /**
             * Gets the default type url for BookmarkItemRequireField
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a BookmarkItemResponse. */
    export interface IBookmarkItemResponse {

        /** BookmarkItemResponse itemType */
        itemType?: (query_recursive_types.BookmarkItemResponse.ItemType);

        /** BookmarkItemResponse item */
        item?: (query_recursive_types.BookmarkItemResponse.IItem);

        /** BookmarkItemResponse createdAt */
        createdAt?: (string);

        /** BookmarkItemResponse updatedAt */
        updatedAt?: (string);
    }

    /** Represents a BookmarkItemResponse. */
    class BookmarkItemResponse implements IBookmarkItemResponse {

        /**
         * Constructs a new BookmarkItemResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IBookmarkItemResponse);

        /** BookmarkItemResponse itemType. */
        public itemType: query_recursive_types.BookmarkItemResponse.ItemType;

        /** BookmarkItemResponse item. */
        public item?: (query_recursive_types.BookmarkItemResponse.IItem);

        /** BookmarkItemResponse createdAt. */
        public createdAt?: (string);

        /** BookmarkItemResponse updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for BookmarkItemResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace BookmarkItemResponse {

        /** ItemType enum. */
        export enum ItemType {
            ITEM_TYPE_UNSPECIFIED = "ITEM_TYPE_UNSPECIFIED",
            ITEM_TYPE_STORY = "ITEM_TYPE_STORY",
            ITEM_TYPE_SERIES = "ITEM_TYPE_SERIES"
        }

        /** Properties of an Item. */
        export interface IItem {

            /** Item story */
            story?: (query_recursive_types.IStoryResponse);

            /** Item series */
            series?: (query_recursive_types.ISeriesResponse);
        }

        /** Represents an Item. */
        class Item implements IItem {

            /**
             * Constructs a new Item.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_recursive_types.BookmarkItemResponse.IItem);

            /** Item story. */
            public story?: (query_recursive_types.IStoryResponse);

            /** Item series. */
            public series?: (query_recursive_types.ISeriesResponse);

            /**
             * Gets the default type url for Item
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a BookmarkItemCursorRequest. */
    export interface IBookmarkItemCursorRequest {

        /** BookmarkItemCursorRequest requireBookmarkItemList */
        requireBookmarkItemList?: (query_recursive_types.IBookmarkItemRequireField);

        /** BookmarkItemCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);
    }

    /** Represents a BookmarkItemCursorRequest. */
    class BookmarkItemCursorRequest implements IBookmarkItemCursorRequest {

        /**
         * Constructs a new BookmarkItemCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IBookmarkItemCursorRequest);

        /** BookmarkItemCursorRequest requireBookmarkItemList. */
        public requireBookmarkItemList?: (query_recursive_types.IBookmarkItemRequireField);

        /** BookmarkItemCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /**
         * Gets the default type url for BookmarkItemCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a BookmarkItemCursorResponse. */
    export interface IBookmarkItemCursorResponse {

        /** BookmarkItemCursorResponse bookmarkItemList */
        bookmarkItemList?: (query_recursive_types.IBookmarkItemResponse[]);

        /** BookmarkItemCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a BookmarkItemCursorResponse. */
    class BookmarkItemCursorResponse implements IBookmarkItemCursorResponse {

        /**
         * Constructs a new BookmarkItemCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IBookmarkItemCursorResponse);

        /** BookmarkItemCursorResponse bookmarkItemList. */
        public bookmarkItemList: query_recursive_types.IBookmarkItemResponse[];

        /** BookmarkItemCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for BookmarkItemCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingRequest. */
    export interface IProfilingRequest {

        /** ProfilingRequest requireGender */
        requireGender?: (boolean);

        /** ProfilingRequest requireBirthday */
        requireBirthday?: (boolean);

        /** ProfilingRequest requireAnsweredAt */
        requireAnsweredAt?: (boolean);

        /** ProfilingRequest requireTagPreference */
        requireTagPreference?: (query_recursive_types.ProfilingRequest.ITagPreferenceRequest);

        /** ProfilingRequest requireStoryPreference */
        requireStoryPreference?: (query_recursive_types.ProfilingRequest.IStoryPreferenceRequest);
    }

    /** Represents a ProfilingRequest. */
    class ProfilingRequest implements IProfilingRequest {

        /**
         * Constructs a new ProfilingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IProfilingRequest);

        /** ProfilingRequest requireGender. */
        public requireGender?: (boolean);

        /** ProfilingRequest requireBirthday. */
        public requireBirthday?: (boolean);

        /** ProfilingRequest requireAnsweredAt. */
        public requireAnsweredAt?: (boolean);

        /** ProfilingRequest requireTagPreference. */
        public requireTagPreference?: (query_recursive_types.ProfilingRequest.ITagPreferenceRequest);

        /** ProfilingRequest requireStoryPreference. */
        public requireStoryPreference?: (query_recursive_types.ProfilingRequest.IStoryPreferenceRequest);

        /**
         * Gets the default type url for ProfilingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace ProfilingRequest {

        /** Properties of a TagPreferenceRequest. */
        export interface ITagPreferenceRequest {

            /** TagPreferenceRequest requireQuestionList */
            requireQuestionList?: (boolean);

            /** TagPreferenceRequest requireAnswerPreferredList */
            requireAnswerPreferredList?: (boolean);
        }

        /** Represents a TagPreferenceRequest. */
        class TagPreferenceRequest implements ITagPreferenceRequest {

            /**
             * Constructs a new TagPreferenceRequest.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_recursive_types.ProfilingRequest.ITagPreferenceRequest);

            /** TagPreferenceRequest requireQuestionList. */
            public requireQuestionList?: (boolean);

            /** TagPreferenceRequest requireAnswerPreferredList. */
            public requireAnswerPreferredList?: (boolean);

            /**
             * Gets the default type url for TagPreferenceRequest
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }

        /** Properties of a StoryPreferenceRequest. */
        export interface IStoryPreferenceRequest {

            /** StoryPreferenceRequest requireQuestionList */
            requireQuestionList?: (query_recursive_types.IStoryRequireField);

            /** StoryPreferenceRequest requireAnswerPreferredList */
            requireAnswerPreferredList?: (query_recursive_types.IStoryRequireField);
        }

        /** Represents a StoryPreferenceRequest. */
        class StoryPreferenceRequest implements IStoryPreferenceRequest {

            /**
             * Constructs a new StoryPreferenceRequest.
             * @param [properties] Properties to set
             */
            constructor(properties?: query_recursive_types.ProfilingRequest.IStoryPreferenceRequest);

            /** StoryPreferenceRequest requireQuestionList. */
            public requireQuestionList?: (query_recursive_types.IStoryRequireField);

            /** StoryPreferenceRequest requireAnswerPreferredList. */
            public requireAnswerPreferredList?: (query_recursive_types.IStoryRequireField);

            /**
             * Gets the default type url for StoryPreferenceRequest
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a ProfilingResponse. */
    export interface IProfilingResponse {

        /** ProfilingResponse gender */
        gender?: (query_profiling.Gender);

        /** ProfilingResponse birthday */
        birthday?: (string);

        /** ProfilingResponse answeredAt */
        answeredAt?: (string);

        /** ProfilingResponse tagPreference */
        tagPreference?: (query_recursive_types.IProfilingTagPreference);

        /** ProfilingResponse storyPreference */
        storyPreference?: (query_recursive_types.IProfilingStoryPreference);
    }

    /** Represents a ProfilingResponse. */
    class ProfilingResponse implements IProfilingResponse {

        /**
         * Constructs a new ProfilingResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IProfilingResponse);

        /** ProfilingResponse gender. */
        public gender: query_profiling.Gender;

        /** ProfilingResponse birthday. */
        public birthday?: (string);

        /** ProfilingResponse answeredAt. */
        public answeredAt?: (string);

        /** ProfilingResponse tagPreference. */
        public tagPreference?: (query_recursive_types.IProfilingTagPreference);

        /** ProfilingResponse storyPreference. */
        public storyPreference?: (query_recursive_types.IProfilingStoryPreference);

        /**
         * Gets the default type url for ProfilingResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingBirthday. */
    export interface IProfilingBirthday {

        /** ProfilingBirthday answer */
        answer?: (string);
    }

    /** Represents a ProfilingBirthday. */
    class ProfilingBirthday implements IProfilingBirthday {

        /**
         * Constructs a new ProfilingBirthday.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IProfilingBirthday);

        /** ProfilingBirthday answer. */
        public answer?: (string);

        /**
         * Gets the default type url for ProfilingBirthday
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingStoryPreference. */
    export interface IProfilingStoryPreference {

        /** ProfilingStoryPreference questionList */
        questionList?: (query_recursive_types.IStoryResponse[]);

        /** ProfilingStoryPreference answerPreferredList */
        answerPreferredList?: (query_recursive_types.IStoryResponse[]);
    }

    /** Represents a ProfilingStoryPreference. */
    class ProfilingStoryPreference implements IProfilingStoryPreference {

        /**
         * Constructs a new ProfilingStoryPreference.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IProfilingStoryPreference);

        /** ProfilingStoryPreference questionList. */
        public questionList: query_recursive_types.IStoryResponse[];

        /** ProfilingStoryPreference answerPreferredList. */
        public answerPreferredList: query_recursive_types.IStoryResponse[];

        /**
         * Gets the default type url for ProfilingStoryPreference
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a ProfilingTagPreference. */
    export interface IProfilingTagPreference {

        /** ProfilingTagPreference questionList */
        questionList?: (query_profiling.IProfilingTagPair[]);

        /** ProfilingTagPreference answerPreferredList */
        answerPreferredList?: (query_profiling.IProfilingTagPair[]);
    }

    /** Represents a ProfilingTagPreference. */
    class ProfilingTagPreference implements IProfilingTagPreference {

        /**
         * Constructs a new ProfilingTagPreference.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IProfilingTagPreference);

        /** ProfilingTagPreference questionList. */
        public questionList: query_profiling.IProfilingTagPair[];

        /** ProfilingTagPreference answerPreferredList. */
        public answerPreferredList: query_profiling.IProfilingTagPair[];

        /**
         * Gets the default type url for ProfilingTagPreference
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryReviewCommentRequireField. */
    export interface IStoryReviewCommentRequireField {

        /** StoryReviewCommentRequireField requireTextBody */
        requireTextBody?: (boolean);
    }

    /** Represents a StoryReviewCommentRequireField. */
    class StoryReviewCommentRequireField implements IStoryReviewCommentRequireField {

        /**
         * Constructs a new StoryReviewCommentRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryReviewCommentRequireField);

        /** StoryReviewCommentRequireField requireTextBody. */
        public requireTextBody?: (boolean);

        /**
         * Gets the default type url for StoryReviewCommentRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryReviewCommentResponse. */
    export interface IStoryReviewCommentResponse {

        /** StoryReviewCommentResponse textBody */
        textBody?: (string);
    }

    /** Represents a StoryReviewCommentResponse. */
    class StoryReviewCommentResponse implements IStoryReviewCommentResponse {

        /**
         * Constructs a new StoryReviewCommentResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IStoryReviewCommentResponse);

        /** StoryReviewCommentResponse textBody. */
        public textBody?: (string);

        /**
         * Gets the default type url for StoryReviewCommentResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an EventListRequest. */
    export interface IEventListRequest {

        /** EventListRequest requireEventList */
        requireEventList?: (query_recursive_types.IEventRequireField);

        /** EventListRequest includeInactive */
        includeInactive?: (boolean);

        /** EventListRequest filterAnyEventLabel */
        filterAnyEventLabel?: (event.EventLabel[]);
    }

    /** Represents an EventListRequest. */
    class EventListRequest implements IEventListRequest {

        /**
         * Constructs a new EventListRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IEventListRequest);

        /** EventListRequest requireEventList. */
        public requireEventList?: (query_recursive_types.IEventRequireField);

        /** EventListRequest includeInactive. */
        public includeInactive?: (boolean);

        /** EventListRequest filterAnyEventLabel. */
        public filterAnyEventLabel: event.EventLabel[];

        /**
         * Gets the default type url for EventListRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an EventListResponse. */
    export interface IEventListResponse {

        /** EventListResponse eventList */
        eventList?: (query_recursive_types.IEventResponse[]);
    }

    /** Represents an EventListResponse. */
    class EventListResponse implements IEventListResponse {

        /**
         * Constructs a new EventListResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IEventListResponse);

        /** EventListResponse eventList. */
        public eventList: query_recursive_types.IEventResponse[];

        /**
         * Gets the default type url for EventListResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an EventRequireField. */
    export interface IEventRequireField {

        /** EventRequireField requireId */
        requireId?: (boolean);

        /** EventRequireField requireTag */
        requireTag?: (boolean);

        /** EventRequireField requireDescription */
        requireDescription?: (boolean);

        /** EventRequireField requireActionUrl */
        requireActionUrl?: (boolean);

        /** EventRequireField requireCoverImage */
        requireCoverImage?: (query_recursive_types.IImageRequireField);

        /** EventRequireField requireSince */
        requireSince?: (boolean);

        /** EventRequireField requireUntil */
        requireUntil?: (boolean);

        /** EventRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** EventRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);

        /** EventRequireField requireLabelList */
        requireLabelList?: (boolean);
    }

    /** Represents an EventRequireField. */
    class EventRequireField implements IEventRequireField {

        /**
         * Constructs a new EventRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IEventRequireField);

        /** EventRequireField requireId. */
        public requireId?: (boolean);

        /** EventRequireField requireTag. */
        public requireTag?: (boolean);

        /** EventRequireField requireDescription. */
        public requireDescription?: (boolean);

        /** EventRequireField requireActionUrl. */
        public requireActionUrl?: (boolean);

        /** EventRequireField requireCoverImage. */
        public requireCoverImage?: (query_recursive_types.IImageRequireField);

        /** EventRequireField requireSince. */
        public requireSince?: (boolean);

        /** EventRequireField requireUntil. */
        public requireUntil?: (boolean);

        /** EventRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** EventRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /** EventRequireField requireLabelList. */
        public requireLabelList?: (boolean);

        /**
         * Gets the default type url for EventRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an EventResponse. */
    export interface IEventResponse {

        /** EventResponse id */
        id?: (string);

        /** EventResponse tag */
        tag?: (string);

        /** EventResponse description */
        description?: (string);

        /** EventResponse actionUrl */
        actionUrl?: (string);

        /** EventResponse coverImage */
        coverImage?: (query_recursive_types.IImageResponse);

        /** EventResponse since */
        since?: (string);

        /** EventResponse until */
        until?: (string);

        /** EventResponse createdAt */
        createdAt?: (string);

        /** EventResponse updatedAt */
        updatedAt?: (string);

        /** EventResponse labelList */
        labelList?: (event.EventLabel[]);
    }

    /** Represents an EventResponse. */
    class EventResponse implements IEventResponse {

        /**
         * Constructs a new EventResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IEventResponse);

        /** EventResponse id. */
        public id?: (string);

        /** EventResponse tag. */
        public tag?: (string);

        /** EventResponse description. */
        public description?: (string);

        /** EventResponse actionUrl. */
        public actionUrl?: (string);

        /** EventResponse coverImage. */
        public coverImage?: (query_recursive_types.IImageResponse);

        /** EventResponse since. */
        public since?: (string);

        /** EventResponse until. */
        public until?: (string);

        /** EventResponse createdAt. */
        public createdAt?: (string);

        /** EventResponse updatedAt. */
        public updatedAt?: (string);

        /** EventResponse labelList. */
        public labelList: event.EventLabel[];

        /**
         * Gets the default type url for EventResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagMedalCursorRequest. */
    export interface ITagMedalCursorRequest {

        /** TagMedalCursorRequest tag */
        tag?: (string);

        /** TagMedalCursorRequest requireMedalPage */
        requireMedalPage?: (query_recursive_types.IMedalCursorRequest);
    }

    /** Represents a TagMedalCursorRequest. */
    class TagMedalCursorRequest implements ITagMedalCursorRequest {

        /**
         * Constructs a new TagMedalCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ITagMedalCursorRequest);

        /** TagMedalCursorRequest tag. */
        public tag?: (string);

        /** TagMedalCursorRequest requireMedalPage. */
        public requireMedalPage?: (query_recursive_types.IMedalCursorRequest);

        /**
         * Gets the default type url for TagMedalCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagMedalCursorResponse. */
    export interface ITagMedalCursorResponse {

        /** TagMedalCursorResponse medalPage */
        medalPage?: (query_recursive_types.IMedalCursorResponse);
    }

    /** Represents a TagMedalCursorResponse. */
    class TagMedalCursorResponse implements ITagMedalCursorResponse {

        /**
         * Constructs a new TagMedalCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ITagMedalCursorResponse);

        /** TagMedalCursorResponse medalPage. */
        public medalPage?: (query_recursive_types.IMedalCursorResponse);

        /**
         * Gets the default type url for TagMedalCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MedalCursorRequest. */
    export interface IMedalCursorRequest {

        /** MedalCursorRequest requireMedalList */
        requireMedalList?: (query_recursive_types.IMedalRequireField);

        /** MedalCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);
    }

    /** Represents a MedalCursorRequest. */
    class MedalCursorRequest implements IMedalCursorRequest {

        /**
         * Constructs a new MedalCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMedalCursorRequest);

        /** MedalCursorRequest requireMedalList. */
        public requireMedalList?: (query_recursive_types.IMedalRequireField);

        /** MedalCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /**
         * Gets the default type url for MedalCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MedalCursorResponse. */
    export interface IMedalCursorResponse {

        /** MedalCursorResponse medalList */
        medalList?: (query_recursive_types.IMedalResponse[]);

        /** MedalCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a MedalCursorResponse. */
    class MedalCursorResponse implements IMedalCursorResponse {

        /**
         * Constructs a new MedalCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMedalCursorResponse);

        /** MedalCursorResponse medalList. */
        public medalList: query_recursive_types.IMedalResponse[];

        /** MedalCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for MedalCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MedalRequireField. */
    export interface IMedalRequireField {

        /** MedalRequireField requireId */
        requireId?: (boolean);

        /** MedalRequireField requireUser */
        requireUser?: (query_recursive_types.IUserRequireField);

        /** MedalRequireField requireGrade */
        requireGrade?: (boolean);

        /** MedalRequireField requireTag */
        requireTag?: (boolean);

        /** MedalRequireField requireStory */
        requireStory?: (query_recursive_types.IStoryRequireField);

        /** MedalRequireField requireIconUrl */
        requireIconUrl?: (boolean);

        /** MedalRequireField requireGainedAt */
        requireGainedAt?: (boolean);

        /** MedalRequireField requireName */
        requireName?: (boolean);
    }

    /** Represents a MedalRequireField. */
    class MedalRequireField implements IMedalRequireField {

        /**
         * Constructs a new MedalRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMedalRequireField);

        /** MedalRequireField requireId. */
        public requireId?: (boolean);

        /** MedalRequireField requireUser. */
        public requireUser?: (query_recursive_types.IUserRequireField);

        /** MedalRequireField requireGrade. */
        public requireGrade?: (boolean);

        /** MedalRequireField requireTag. */
        public requireTag?: (boolean);

        /** MedalRequireField requireStory. */
        public requireStory?: (query_recursive_types.IStoryRequireField);

        /** MedalRequireField requireIconUrl. */
        public requireIconUrl?: (boolean);

        /** MedalRequireField requireGainedAt. */
        public requireGainedAt?: (boolean);

        /** MedalRequireField requireName. */
        public requireName?: (boolean);

        /**
         * Gets the default type url for MedalRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MedalResponse. */
    export interface IMedalResponse {

        /** MedalResponse id */
        id?: (string);

        /** MedalResponse user */
        user?: (query_recursive_types.IUserResponse);

        /** MedalResponse grade */
        grade?: (query_recursive_types.MedalResponse.Grade);

        /** MedalResponse tag */
        tag?: (string);

        /** MedalResponse story */
        story?: (query_recursive_types.IStoryResponse);

        /** MedalResponse iconUrl */
        iconUrl?: (string);

        /** MedalResponse gainedAt */
        gainedAt?: (string);

        /** MedalResponse name */
        name?: (string);
    }

    /** Represents a MedalResponse. */
    class MedalResponse implements IMedalResponse {

        /**
         * Constructs a new MedalResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMedalResponse);

        /** MedalResponse id. */
        public id?: (string);

        /** MedalResponse user. */
        public user?: (query_recursive_types.IUserResponse);

        /** MedalResponse grade. */
        public grade: query_recursive_types.MedalResponse.Grade;

        /** MedalResponse tag. */
        public tag?: (string);

        /** MedalResponse story. */
        public story?: (query_recursive_types.IStoryResponse);

        /** MedalResponse iconUrl. */
        public iconUrl?: (string);

        /** MedalResponse gainedAt. */
        public gainedAt?: (string);

        /** MedalResponse name. */
        public name?: (string);

        /**
         * Gets the default type url for MedalResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace MedalResponse {

        /** Grade enum. */
        export enum Grade {
            GRADE_UNSPECIFIED = "GRADE_UNSPECIFIED",
            GRADE_BRONZE = "GRADE_BRONZE",
            GRADE_SILVER = "GRADE_SILVER",
            GRADE_GOLD = "GRADE_GOLD"
        }
    }

    /** Properties of an ImageCursorRequest. */
    export interface IImageCursorRequest {

        /** ImageCursorRequest requireImageList */
        requireImageList?: (query_recursive_types.IImageRequireField);

        /** ImageCursorRequest category */
        category?: (resource.ImageCategory);

        /** ImageCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);

        /** ImageCursorRequest includeUnavailable */
        includeUnavailable?: (boolean);
    }

    /** Represents an ImageCursorRequest. */
    class ImageCursorRequest implements IImageCursorRequest {

        /**
         * Constructs a new ImageCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IImageCursorRequest);

        /** ImageCursorRequest requireImageList. */
        public requireImageList?: (query_recursive_types.IImageRequireField);

        /** ImageCursorRequest category. */
        public category: resource.ImageCategory;

        /** ImageCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /** ImageCursorRequest includeUnavailable. */
        public includeUnavailable?: (boolean);

        /**
         * Gets the default type url for ImageCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an ImageCursorResponse. */
    export interface IImageCursorResponse {

        /** ImageCursorResponse imageList */
        imageList?: (query_recursive_types.IImageResponse[]);

        /** ImageCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents an ImageCursorResponse. */
    class ImageCursorResponse implements IImageCursorResponse {

        /**
         * Constructs a new ImageCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IImageCursorResponse);

        /** ImageCursorResponse imageList. */
        public imageList: query_recursive_types.IImageResponse[];

        /** ImageCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for ImageCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MyStoryCursorRequest. */
    export interface IMyStoryCursorRequest {

        /** MyStoryCursorRequest requireStoryList */
        requireStoryList?: (query_recursive_types.IStoryRequireField);

        /** MyStoryCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);

        /** MyStoryCursorRequest excludeContainingChatNovel */
        excludeContainingChatNovel?: (boolean);

        /** MyStoryCursorRequest excludeContainingNovel */
        excludeContainingNovel?: (boolean);

        /** MyStoryCursorRequest seriesId */
        seriesId?: (string);
    }

    /** Represents a MyStoryCursorRequest. */
    class MyStoryCursorRequest implements IMyStoryCursorRequest {

        /**
         * Constructs a new MyStoryCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMyStoryCursorRequest);

        /** MyStoryCursorRequest requireStoryList. */
        public requireStoryList?: (query_recursive_types.IStoryRequireField);

        /** MyStoryCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /** MyStoryCursorRequest excludeContainingChatNovel. */
        public excludeContainingChatNovel?: (boolean);

        /** MyStoryCursorRequest excludeContainingNovel. */
        public excludeContainingNovel?: (boolean);

        /** MyStoryCursorRequest seriesId. */
        public seriesId?: (string);

        /**
         * Gets the default type url for MyStoryCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MySeriesCursorRequest. */
    export interface IMySeriesCursorRequest {

        /** MySeriesCursorRequest requireSeriesList */
        requireSeriesList?: (query_recursive_types.ISeriesRequireField);

        /** MySeriesCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);

        /** MySeriesCursorRequest excludeContainingChatNovel */
        excludeContainingChatNovel?: (boolean);

        /** MySeriesCursorRequest excludeContainingNovel */
        excludeContainingNovel?: (boolean);
    }

    /** Represents a MySeriesCursorRequest. */
    class MySeriesCursorRequest implements IMySeriesCursorRequest {

        /**
         * Constructs a new MySeriesCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMySeriesCursorRequest);

        /** MySeriesCursorRequest requireSeriesList. */
        public requireSeriesList?: (query_recursive_types.ISeriesRequireField);

        /** MySeriesCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /** MySeriesCursorRequest excludeContainingChatNovel. */
        public excludeContainingChatNovel?: (boolean);

        /** MySeriesCursorRequest excludeContainingNovel. */
        public excludeContainingNovel?: (boolean);

        /**
         * Gets the default type url for MySeriesCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationCursorRequest. */
    export interface INotificationCursorRequest {

        /** NotificationCursorRequest requireNotificationList */
        requireNotificationList?: (query_recursive_types.INotificationRequireField);

        /** NotificationCursorRequest os */
        os?: (types.OS);

        /** NotificationCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);

        /** NotificationCursorRequest categoryList */
        categoryList?: (notification.NotificationCategory[]);

        /** NotificationCursorRequest requireRecommendedUserList */
        requireRecommendedUserList?: (query_recursive_types.IUserRequireField);

        /** NotificationCursorRequest category */
        category?: (notification.NotificationCategory);
    }

    /** Represents a NotificationCursorRequest. */
    class NotificationCursorRequest implements INotificationCursorRequest {

        /**
         * Constructs a new NotificationCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.INotificationCursorRequest);

        /** NotificationCursorRequest requireNotificationList. */
        public requireNotificationList?: (query_recursive_types.INotificationRequireField);

        /** NotificationCursorRequest os. */
        public os: types.OS;

        /** NotificationCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /** NotificationCursorRequest categoryList. */
        public categoryList: notification.NotificationCategory[];

        /** NotificationCursorRequest requireRecommendedUserList. */
        public requireRecommendedUserList?: (query_recursive_types.IUserRequireField);

        /** NotificationCursorRequest category. */
        public category: notification.NotificationCategory;

        /**
         * Gets the default type url for NotificationCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationCursorResponse. */
    export interface INotificationCursorResponse {

        /** NotificationCursorResponse notificationList */
        notificationList?: (query_recursive_types.INotificationResponse[]);

        /** NotificationCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);

        /** NotificationCursorResponse recommendedUserList */
        recommendedUserList?: (query_recursive_types.IUserResponse[]);
    }

    /** Represents a NotificationCursorResponse. */
    class NotificationCursorResponse implements INotificationCursorResponse {

        /**
         * Constructs a new NotificationCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.INotificationCursorResponse);

        /** NotificationCursorResponse notificationList. */
        public notificationList: query_recursive_types.INotificationResponse[];

        /** NotificationCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /** NotificationCursorResponse recommendedUserList. */
        public recommendedUserList: query_recursive_types.IUserResponse[];

        /**
         * Gets the default type url for NotificationCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationRequireField. */
    export interface INotificationRequireField {

        /** NotificationRequireField requireId */
        requireId?: (boolean);

        /** NotificationRequireField requireType */
        requireType?: (boolean);

        /** NotificationRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** NotificationRequireField requireContentsV2 */
        requireContentsV2?: (query_recursive_types.INotificationContentsV2RequireField);

        /** NotificationRequireField requireContents */
        requireContents?: (boolean);
    }

    /** Represents a NotificationRequireField. */
    class NotificationRequireField implements INotificationRequireField {

        /**
         * Constructs a new NotificationRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.INotificationRequireField);

        /** NotificationRequireField requireId. */
        public requireId?: (boolean);

        /** NotificationRequireField requireType. */
        public requireType?: (boolean);

        /** NotificationRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** NotificationRequireField requireContentsV2. */
        public requireContentsV2?: (query_recursive_types.INotificationContentsV2RequireField);

        /** NotificationRequireField requireContents. */
        public requireContents?: (boolean);

        /**
         * Gets the default type url for NotificationRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsV2RequireField. */
    export interface INotificationContentsV2RequireField {

        /** NotificationContentsV2RequireField requireStory */
        requireStory?: (query_recursive_types.IStoryRequireField);
    }

    /** Represents a NotificationContentsV2RequireField. */
    class NotificationContentsV2RequireField implements INotificationContentsV2RequireField {

        /**
         * Constructs a new NotificationContentsV2RequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.INotificationContentsV2RequireField);

        /** NotificationContentsV2RequireField requireStory. */
        public requireStory?: (query_recursive_types.IStoryRequireField);

        /**
         * Gets the default type url for NotificationContentsV2RequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationResponse. */
    export interface INotificationResponse {

        /** NotificationResponse id */
        id?: (string);

        /** NotificationResponse type */
        type?: (notification.NotificationType);

        /** NotificationResponse createdAt */
        createdAt?: (string);

        /** NotificationResponse contentsV2 */
        contentsV2?: (query_recursive_types.INotificationContentsV2);

        /** NotificationResponse contents */
        contents?: (notification.INotificationContents);
    }

    /** Represents a NotificationResponse. */
    class NotificationResponse implements INotificationResponse {

        /**
         * Constructs a new NotificationResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.INotificationResponse);

        /** NotificationResponse id. */
        public id?: (string);

        /** NotificationResponse type. */
        public type: notification.NotificationType;

        /** NotificationResponse createdAt. */
        public createdAt?: (string);

        /** NotificationResponse contentsV2. */
        public contentsV2?: (query_recursive_types.INotificationContentsV2);

        /** NotificationResponse contents. */
        public contents?: (notification.INotificationContents);

        /**
         * Gets the default type url for NotificationResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MyStoryCursorResponse. */
    export interface IMyStoryCursorResponse {

        /** MyStoryCursorResponse storyList */
        storyList?: (query_recursive_types.IStoryResponse[]);

        /** MyStoryCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a MyStoryCursorResponse. */
    class MyStoryCursorResponse implements IMyStoryCursorResponse {

        /**
         * Constructs a new MyStoryCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMyStoryCursorResponse);

        /** MyStoryCursorResponse storyList. */
        public storyList: query_recursive_types.IStoryResponse[];

        /** MyStoryCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for MyStoryCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a MySeriesCursorResponse. */
    export interface IMySeriesCursorResponse {

        /** MySeriesCursorResponse seriesList */
        seriesList?: (query_recursive_types.ISeriesResponse[]);

        /** MySeriesCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a MySeriesCursorResponse. */
    class MySeriesCursorResponse implements IMySeriesCursorResponse {

        /**
         * Constructs a new MySeriesCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IMySeriesCursorResponse);

        /** MySeriesCursorResponse seriesList. */
        public seriesList: query_recursive_types.ISeriesResponse[];

        /** MySeriesCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for MySeriesCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsV2. */
    export interface INotificationContentsV2 {

        /** NotificationContentsV2 operation */
        operation?: (notification.INotificationContentsOperation);

        /** NotificationContentsV2 storyRejected */
        storyRejected?: (notification.INotificationContentsStoryRejected);

        /** NotificationContentsV2 userBanned */
        userBanned?: (notification.INotificationContentsUserBanned);

        /** NotificationContentsV2 paymentFailure */
        paymentFailure?: (notification.INotificationContentsPaymentFailure);

        /** NotificationContentsV2 vipGracePeriodExpired */
        vipGracePeriodExpired?: (notification.INotificationContentsVipGracePeriodExpired);

        /** NotificationContentsV2 newFollower */
        newFollower?: (notification.INotificationContentsNewFollower);

        /** NotificationContentsV2 newStoryComment */
        newStoryComment?: (notification.INotificationContentsNewStoryComment);

        /** NotificationContentsV2 newCommentLike */
        newCommentLike?: (notification.INotificationContentsNewCommentLike);

        /** NotificationContentsV2 newCommentResponse */
        newCommentResponse?: (notification.INotificationContentsNewCommentResponse);

        /** NotificationContentsV2 followingUserNewStory */
        followingUserNewStory?: (query_recursive_types.INotificationContentsFollowingUserNewStoryV2);

        /** NotificationContentsV2 userLikeBreak */
        userLikeBreak?: (notification.INotificationContentsUserLikeBreak);

        /** NotificationContentsV2 bookmarkNewSeriesStory */
        bookmarkNewSeriesStory?: (query_recursive_types.INotificationContentsBookmarkNewSeriesStoryV2);

        /** NotificationContentsV2 userStoryLikeBreak */
        userStoryLikeBreak?: (notification.INotificationContentsUserStoryLikeBreak);

        /** NotificationContentsV2 newMedal */
        newMedal?: (notification.INotificationContentsNewMedal);

        /** NotificationContentsV2 userStoryNewLikes */
        userStoryNewLikes?: (notification.INotificationContentsUserStoryNewLikes);

        /** NotificationContentsV2 storyVideoCreated */
        storyVideoCreated?: (notification.INotificationContentsStoryVideoCreated);

        /** NotificationContentsV2 mutualFollowReadRequest */
        mutualFollowReadRequest?: (notification.INotificationContentsMutualFollowReadRequest);
    }

    /** Represents a NotificationContentsV2. */
    class NotificationContentsV2 implements INotificationContentsV2 {

        /**
         * Constructs a new NotificationContentsV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.INotificationContentsV2);

        /** NotificationContentsV2 operation. */
        public operation?: (notification.INotificationContentsOperation);

        /** NotificationContentsV2 storyRejected. */
        public storyRejected?: (notification.INotificationContentsStoryRejected);

        /** NotificationContentsV2 userBanned. */
        public userBanned?: (notification.INotificationContentsUserBanned);

        /** NotificationContentsV2 paymentFailure. */
        public paymentFailure?: (notification.INotificationContentsPaymentFailure);

        /** NotificationContentsV2 vipGracePeriodExpired. */
        public vipGracePeriodExpired?: (notification.INotificationContentsVipGracePeriodExpired);

        /** NotificationContentsV2 newFollower. */
        public newFollower?: (notification.INotificationContentsNewFollower);

        /** NotificationContentsV2 newStoryComment. */
        public newStoryComment?: (notification.INotificationContentsNewStoryComment);

        /** NotificationContentsV2 newCommentLike. */
        public newCommentLike?: (notification.INotificationContentsNewCommentLike);

        /** NotificationContentsV2 newCommentResponse. */
        public newCommentResponse?: (notification.INotificationContentsNewCommentResponse);

        /** NotificationContentsV2 followingUserNewStory. */
        public followingUserNewStory?: (query_recursive_types.INotificationContentsFollowingUserNewStoryV2);

        /** NotificationContentsV2 userLikeBreak. */
        public userLikeBreak?: (notification.INotificationContentsUserLikeBreak);

        /** NotificationContentsV2 bookmarkNewSeriesStory. */
        public bookmarkNewSeriesStory?: (query_recursive_types.INotificationContentsBookmarkNewSeriesStoryV2);

        /** NotificationContentsV2 userStoryLikeBreak. */
        public userStoryLikeBreak?: (notification.INotificationContentsUserStoryLikeBreak);

        /** NotificationContentsV2 newMedal. */
        public newMedal?: (notification.INotificationContentsNewMedal);

        /** NotificationContentsV2 userStoryNewLikes. */
        public userStoryNewLikes?: (notification.INotificationContentsUserStoryNewLikes);

        /** NotificationContentsV2 storyVideoCreated. */
        public storyVideoCreated?: (notification.INotificationContentsStoryVideoCreated);

        /** NotificationContentsV2 mutualFollowReadRequest. */
        public mutualFollowReadRequest?: (notification.INotificationContentsMutualFollowReadRequest);

        /** NotificationContentsV2 contents. */
        public contents?: ("operation"|"storyRejected"|"userBanned"|"paymentFailure"|"vipGracePeriodExpired"|"newFollower"|"newStoryComment"|"newCommentLike"|"newCommentResponse"|"followingUserNewStory"|"userLikeBreak"|"bookmarkNewSeriesStory"|"userStoryLikeBreak"|"newMedal"|"userStoryNewLikes"|"storyVideoCreated"|"mutualFollowReadRequest");

        /**
         * Gets the default type url for NotificationContentsV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsFollowingUserNewStoryV2. */
    export interface INotificationContentsFollowingUserNewStoryV2 {

        /** NotificationContentsFollowingUserNewStoryV2 message */
        message?: (string);

        /** NotificationContentsFollowingUserNewStoryV2 actionUrl */
        actionUrl?: (string);

        /** NotificationContentsFollowingUserNewStoryV2 thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsFollowingUserNewStoryV2 userIcon */
        userIcon?: (notification.IImageWithLink);

        /** NotificationContentsFollowingUserNewStoryV2 story */
        story?: (query_recursive_types.IStoryResponse);
    }

    /** Represents a NotificationContentsFollowingUserNewStoryV2. */
    class NotificationContentsFollowingUserNewStoryV2 implements INotificationContentsFollowingUserNewStoryV2 {

        /**
         * Constructs a new NotificationContentsFollowingUserNewStoryV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.INotificationContentsFollowingUserNewStoryV2);

        /** NotificationContentsFollowingUserNewStoryV2 message. */
        public message?: (string);

        /** NotificationContentsFollowingUserNewStoryV2 actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsFollowingUserNewStoryV2 thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsFollowingUserNewStoryV2 userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /** NotificationContentsFollowingUserNewStoryV2 story. */
        public story?: (query_recursive_types.IStoryResponse);

        /**
         * Gets the default type url for NotificationContentsFollowingUserNewStoryV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a NotificationContentsBookmarkNewSeriesStoryV2. */
    export interface INotificationContentsBookmarkNewSeriesStoryV2 {

        /** NotificationContentsBookmarkNewSeriesStoryV2 message */
        message?: (string);

        /** NotificationContentsBookmarkNewSeriesStoryV2 actionUrl */
        actionUrl?: (string);

        /** NotificationContentsBookmarkNewSeriesStoryV2 thumbnailUrl */
        thumbnailUrl?: (string);

        /** NotificationContentsBookmarkNewSeriesStoryV2 userIcon */
        userIcon?: (notification.IImageWithLink);

        /** NotificationContentsBookmarkNewSeriesStoryV2 story */
        story?: (query_recursive_types.IStoryResponse);
    }

    /** Represents a NotificationContentsBookmarkNewSeriesStoryV2. */
    class NotificationContentsBookmarkNewSeriesStoryV2 implements INotificationContentsBookmarkNewSeriesStoryV2 {

        /**
         * Constructs a new NotificationContentsBookmarkNewSeriesStoryV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.INotificationContentsBookmarkNewSeriesStoryV2);

        /** NotificationContentsBookmarkNewSeriesStoryV2 message. */
        public message?: (string);

        /** NotificationContentsBookmarkNewSeriesStoryV2 actionUrl. */
        public actionUrl?: (string);

        /** NotificationContentsBookmarkNewSeriesStoryV2 thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** NotificationContentsBookmarkNewSeriesStoryV2 userIcon. */
        public userIcon?: (notification.IImageWithLink);

        /** NotificationContentsBookmarkNewSeriesStoryV2 story. */
        public story?: (query_recursive_types.IStoryResponse);

        /**
         * Gets the default type url for NotificationContentsBookmarkNewSeriesStoryV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesSelectionRequest. */
    export interface ISeriesSelectionRequest {

        /** SeriesSelectionRequest id */
        id?: (string);

        /** SeriesSelectionRequest requireSeriesSelection */
        requireSeriesSelection?: (query_recursive_types.ISeriesSelectionRequireField);

        /** SeriesSelectionRequest applyShuffling */
        applyShuffling?: (query_series_selection.SeriesSelectionShuffling);
    }

    /** Represents a SeriesSelectionRequest. */
    class SeriesSelectionRequest implements ISeriesSelectionRequest {

        /**
         * Constructs a new SeriesSelectionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesSelectionRequest);

        /** SeriesSelectionRequest id. */
        public id?: (string);

        /** SeriesSelectionRequest requireSeriesSelection. */
        public requireSeriesSelection?: (query_recursive_types.ISeriesSelectionRequireField);

        /** SeriesSelectionRequest applyShuffling. */
        public applyShuffling: query_series_selection.SeriesSelectionShuffling;

        /**
         * Gets the default type url for SeriesSelectionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesSelectionRequireField. */
    export interface ISeriesSelectionRequireField {

        /** SeriesSelectionRequireField requireId */
        requireId?: (boolean);

        /** SeriesSelectionRequireField requireTitle */
        requireTitle?: (boolean);

        /** SeriesSelectionRequireField requireDescription */
        requireDescription?: (boolean);

        /** SeriesSelectionRequireField requireItemPage */
        requireItemPage?: (query_recursive_types.ISeriesSelectionItemPageRequireField);

        /** SeriesSelectionRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** SeriesSelectionRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);

        /** SeriesSelectionRequireField requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SeriesSelectionRequireField. */
    class SeriesSelectionRequireField implements ISeriesSelectionRequireField {

        /**
         * Constructs a new SeriesSelectionRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesSelectionRequireField);

        /** SeriesSelectionRequireField requireId. */
        public requireId?: (boolean);

        /** SeriesSelectionRequireField requireTitle. */
        public requireTitle?: (boolean);

        /** SeriesSelectionRequireField requireDescription. */
        public requireDescription?: (boolean);

        /** SeriesSelectionRequireField requireItemPage. */
        public requireItemPage?: (query_recursive_types.ISeriesSelectionItemPageRequireField);

        /** SeriesSelectionRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** SeriesSelectionRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /** SeriesSelectionRequireField requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SeriesSelectionRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesSelectionResponse. */
    export interface ISeriesSelectionResponse {

        /** SeriesSelectionResponse id */
        id?: (string);

        /** SeriesSelectionResponse title */
        title?: (string);

        /** SeriesSelectionResponse description */
        description?: (string);

        /** SeriesSelectionResponse itemPage */
        itemPage?: (query_recursive_types.ISeriesSelectionItemPageResponse);

        /** SeriesSelectionResponse createdAt */
        createdAt?: (string);

        /** SeriesSelectionResponse updatedAt */
        updatedAt?: (string);

        /** SeriesSelectionResponse seriesPage */
        seriesPage?: (query_recursive_types.ISeriesPageResponse);
    }

    /** Represents a SeriesSelectionResponse. */
    class SeriesSelectionResponse implements ISeriesSelectionResponse {

        /**
         * Constructs a new SeriesSelectionResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesSelectionResponse);

        /** SeriesSelectionResponse id. */
        public id?: (string);

        /** SeriesSelectionResponse title. */
        public title?: (string);

        /** SeriesSelectionResponse description. */
        public description?: (string);

        /** SeriesSelectionResponse itemPage. */
        public itemPage?: (query_recursive_types.ISeriesSelectionItemPageResponse);

        /** SeriesSelectionResponse createdAt. */
        public createdAt?: (string);

        /** SeriesSelectionResponse updatedAt. */
        public updatedAt?: (string);

        /** SeriesSelectionResponse seriesPage. */
        public seriesPage?: (query_recursive_types.ISeriesPageResponse);

        /**
         * Gets the default type url for SeriesSelectionResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesSelectionItemPageRequireField. */
    export interface ISeriesSelectionItemPageRequireField {

        /** SeriesSelectionItemPageRequireField requireItemList */
        requireItemList?: (query_recursive_types.ISeriesSelectionItemRequireField);

        /** SeriesSelectionItemPageRequireField page */
        page?: (query_pagination.IPageRequest);
    }

    /** Represents a SeriesSelectionItemPageRequireField. */
    class SeriesSelectionItemPageRequireField implements ISeriesSelectionItemPageRequireField {

        /**
         * Constructs a new SeriesSelectionItemPageRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesSelectionItemPageRequireField);

        /** SeriesSelectionItemPageRequireField requireItemList. */
        public requireItemList?: (query_recursive_types.ISeriesSelectionItemRequireField);

        /** SeriesSelectionItemPageRequireField page. */
        public page?: (query_pagination.IPageRequest);

        /**
         * Gets the default type url for SeriesSelectionItemPageRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesSelectionItemPageResponse. */
    export interface ISeriesSelectionItemPageResponse {

        /** SeriesSelectionItemPageResponse itemList */
        itemList?: (query_recursive_types.ISeriesSelectionItemResponse[]);

        /** SeriesSelectionItemPageResponse page */
        page?: (query_pagination.IPageInfo);
    }

    /** Represents a SeriesSelectionItemPageResponse. */
    class SeriesSelectionItemPageResponse implements ISeriesSelectionItemPageResponse {

        /**
         * Constructs a new SeriesSelectionItemPageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesSelectionItemPageResponse);

        /** SeriesSelectionItemPageResponse itemList. */
        public itemList: query_recursive_types.ISeriesSelectionItemResponse[];

        /** SeriesSelectionItemPageResponse page. */
        public page?: (query_pagination.IPageInfo);

        /**
         * Gets the default type url for SeriesSelectionItemPageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesSelectionItemRequireField. */
    export interface ISeriesSelectionItemRequireField {

        /** SeriesSelectionItemRequireField requireSeries */
        requireSeries?: (query_recursive_types.ISeriesRequireField);

        /** SeriesSelectionItemRequireField requireThumbnail */
        requireThumbnail?: (query_recursive_types.IImageRequireField);
    }

    /** Represents a SeriesSelectionItemRequireField. */
    class SeriesSelectionItemRequireField implements ISeriesSelectionItemRequireField {

        /**
         * Constructs a new SeriesSelectionItemRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesSelectionItemRequireField);

        /** SeriesSelectionItemRequireField requireSeries. */
        public requireSeries?: (query_recursive_types.ISeriesRequireField);

        /** SeriesSelectionItemRequireField requireThumbnail. */
        public requireThumbnail?: (query_recursive_types.IImageRequireField);

        /**
         * Gets the default type url for SeriesSelectionItemRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesSelectionItemResponse. */
    export interface ISeriesSelectionItemResponse {

        /** SeriesSelectionItemResponse series */
        series?: (query_recursive_types.ISeriesResponse);

        /** SeriesSelectionItemResponse thumbnail */
        thumbnail?: (query_recursive_types.IImageResponse);
    }

    /** Represents a SeriesSelectionItemResponse. */
    class SeriesSelectionItemResponse implements ISeriesSelectionItemResponse {

        /**
         * Constructs a new SeriesSelectionItemResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ISeriesSelectionItemResponse);

        /** SeriesSelectionItemResponse series. */
        public series?: (query_recursive_types.ISeriesResponse);

        /** SeriesSelectionItemResponse thumbnail. */
        public thumbnail?: (query_recursive_types.IImageResponse);

        /**
         * Gets the default type url for SeriesSelectionItemResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UsedTagListRequest. */
    export interface IUsedTagListRequest {

        /** UsedTagListRequest limit */
        limit?: (number);
    }

    /** Represents a UsedTagListRequest. */
    class UsedTagListRequest implements IUsedTagListRequest {

        /**
         * Constructs a new UsedTagListRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUsedTagListRequest);

        /** UsedTagListRequest limit. */
        public limit?: (number);

        /**
         * Gets the default type url for UsedTagListRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UsedTagListResponse. */
    export interface IUsedTagListResponse {

        /** UsedTagListResponse tagList */
        tagList?: (string[]);
    }

    /** Represents a UsedTagListResponse. */
    class UsedTagListResponse implements IUsedTagListResponse {

        /**
         * Constructs a new UsedTagListResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.IUsedTagListResponse);

        /** UsedTagListResponse tagList. */
        public tagList: string[];

        /**
         * Gets the default type url for UsedTagListResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CoinStatementCursorRequest. */
    export interface ICoinStatementCursorRequest {

        /** CoinStatementCursorRequest requireCoinStatementList */
        requireCoinStatementList?: (query_recursive_types.ICoinStatementRequireField);

        /** CoinStatementCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);

        /** CoinStatementCursorRequest includeTransactionTypeList */
        includeTransactionTypeList?: (query_coin.CoinTransactionType[]);
    }

    /** Represents a CoinStatementCursorRequest. */
    class CoinStatementCursorRequest implements ICoinStatementCursorRequest {

        /**
         * Constructs a new CoinStatementCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICoinStatementCursorRequest);

        /** CoinStatementCursorRequest requireCoinStatementList. */
        public requireCoinStatementList?: (query_recursive_types.ICoinStatementRequireField);

        /** CoinStatementCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /** CoinStatementCursorRequest includeTransactionTypeList. */
        public includeTransactionTypeList: query_coin.CoinTransactionType[];

        /**
         * Gets the default type url for CoinStatementCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CoinStatementCursorResponse. */
    export interface ICoinStatementCursorResponse {

        /** CoinStatementCursorResponse coinStatementList */
        coinStatementList?: (query_recursive_types.ICoinStatementResponse[]);

        /** CoinStatementCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a CoinStatementCursorResponse. */
    class CoinStatementCursorResponse implements ICoinStatementCursorResponse {

        /**
         * Constructs a new CoinStatementCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICoinStatementCursorResponse);

        /** CoinStatementCursorResponse coinStatementList. */
        public coinStatementList: query_recursive_types.ICoinStatementResponse[];

        /** CoinStatementCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for CoinStatementCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CoinStatementRequireField. */
    export interface ICoinStatementRequireField {

        /** CoinStatementRequireField requireTransaction */
        requireTransaction?: (boolean);

        /** CoinStatementRequireField requireStory */
        requireStory?: (query_recursive_types.IStoryRequireField);
    }

    /** Represents a CoinStatementRequireField. */
    class CoinStatementRequireField implements ICoinStatementRequireField {

        /**
         * Constructs a new CoinStatementRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICoinStatementRequireField);

        /** CoinStatementRequireField requireTransaction. */
        public requireTransaction?: (boolean);

        /** CoinStatementRequireField requireStory. */
        public requireStory?: (query_recursive_types.IStoryRequireField);

        /**
         * Gets the default type url for CoinStatementRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a CoinStatementResponse. */
    export interface ICoinStatementResponse {

        /** CoinStatementResponse transaction */
        transaction?: (query_coin.ICoinTransaction);

        /** CoinStatementResponse story */
        story?: (query_recursive_types.IStoryResponse);
    }

    /** Represents a CoinStatementResponse. */
    class CoinStatementResponse implements ICoinStatementResponse {

        /**
         * Constructs a new CoinStatementResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_recursive_types.ICoinStatementResponse);

        /** CoinStatementResponse transaction. */
        public transaction?: (query_coin.ICoinTransaction);

        /** CoinStatementResponse story. */
        public story?: (query_recursive_types.IStoryResponse);

        /**
         * Gets the default type url for CoinStatementResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_ticket. */
export namespace query_ticket {

    /** Properties of a UserTicketStatusRequireField. */
    export interface IUserTicketStatusRequireField {

        /** UserTicketStatusRequireField requireAvailableCount */
        requireAvailableCount?: (boolean);

        /** UserTicketStatusRequireField requireAvailableTicket */
        requireAvailableTicket?: (query_ticket.ITicketRequireField);
    }

    /** Represents a UserTicketStatusRequireField. */
    class UserTicketStatusRequireField implements IUserTicketStatusRequireField {

        /**
         * Constructs a new UserTicketStatusRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_ticket.IUserTicketStatusRequireField);

        /** UserTicketStatusRequireField requireAvailableCount. */
        public requireAvailableCount?: (boolean);

        /** UserTicketStatusRequireField requireAvailableTicket. */
        public requireAvailableTicket?: (query_ticket.ITicketRequireField);

        /**
         * Gets the default type url for UserTicketStatusRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a UserTicketStatusResponse. */
    export interface IUserTicketStatusResponse {

        /** UserTicketStatusResponse availableCount */
        availableCount?: (number);

        /** UserTicketStatusResponse availableTicket */
        availableTicket?: (query_ticket.ITicketResponse);
    }

    /** Represents a UserTicketStatusResponse. */
    class UserTicketStatusResponse implements IUserTicketStatusResponse {

        /**
         * Constructs a new UserTicketStatusResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_ticket.IUserTicketStatusResponse);

        /** UserTicketStatusResponse availableCount. */
        public availableCount?: (number);

        /** UserTicketStatusResponse availableTicket. */
        public availableTicket?: (query_ticket.ITicketResponse);

        /**
         * Gets the default type url for UserTicketStatusResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TicketRequireField. */
    export interface ITicketRequireField {

        /** TicketRequireField requireId */
        requireId?: (boolean);

        /** TicketRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** TicketRequireField requireExpiresAt */
        requireExpiresAt?: (boolean);
    }

    /** Represents a TicketRequireField. */
    class TicketRequireField implements ITicketRequireField {

        /**
         * Constructs a new TicketRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_ticket.ITicketRequireField);

        /** TicketRequireField requireId. */
        public requireId?: (boolean);

        /** TicketRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** TicketRequireField requireExpiresAt. */
        public requireExpiresAt?: (boolean);

        /**
         * Gets the default type url for TicketRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TicketResponse. */
    export interface ITicketResponse {

        /** TicketResponse id */
        id?: (string);

        /** TicketResponse createdAt */
        createdAt?: (string);

        /** TicketResponse expiresAt */
        expiresAt?: (string);
    }

    /** Represents a TicketResponse. */
    class TicketResponse implements ITicketResponse {

        /**
         * Constructs a new TicketResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_ticket.ITicketResponse);

        /** TicketResponse id. */
        public id?: (string);

        /** TicketResponse createdAt. */
        public createdAt?: (string);

        /** TicketResponse expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for TicketResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_search_types. */
export namespace query_search_types {

    /** Properties of a SearchStoryFilter. */
    export interface ISearchStoryFilter {

        /** SearchStoryFilter filterTag */
        filterTag?: (string[]);

        /** SearchStoryFilter filterAnyUserId */
        filterAnyUserId?: (string[]);

        /** SearchStoryFilter excludeAlreadyRead */
        excludeAlreadyRead?: (boolean);

        /** SearchStoryFilter filterSeriesId */
        filterSeriesId?: (string);

        /** SearchStoryFilter filterSeriesIndex */
        filterSeriesIndex?: (number);

        /** SearchStoryFilter includeBlocked */
        includeBlocked?: (boolean);

        /** SearchStoryFilter excludeOfficial */
        excludeOfficial?: (boolean);

        /** SearchStoryFilter excludeNotOfficial */
        excludeNotOfficial?: (boolean);

        /** SearchStoryFilter excludeCompletedSeries */
        excludeCompletedSeries?: (boolean);

        /** SearchStoryFilter excludeNotCompletedSeries */
        excludeNotCompletedSeries?: (boolean);

        /** SearchStoryFilter excludeSeries */
        excludeSeries?: (boolean);

        /** SearchStoryFilter excludeNotSeries */
        excludeNotSeries?: (boolean);

        /** SearchStoryFilter filterAnyAvailability */
        filterAnyAvailability?: (query_types.StoryAvailability[]);

        /** SearchStoryFilter filterPublishedAt */
        filterPublishedAt?: (types.ITimestampRange);

        /** SearchStoryFilter filterAnyScriptType */
        filterAnyScriptType?: (query_types.StoryScriptType[]);

        /** SearchStoryFilter excludeFirstPublished */
        excludeFirstPublished?: (boolean);

        /** SearchStoryFilter excludeNotFirstPublished */
        excludeNotFirstPublished?: (boolean);

        /** SearchStoryFilter includeSensitive */
        includeSensitive?: (boolean);

        /** SearchStoryFilter filterAnyStatus */
        filterAnyStatus?: (story.StoryStatus[]);

        /** SearchStoryFilter filterAnyUserRole */
        filterAnyUserRole?: (query_types.UserRole[]);

        /** SearchStoryFilter includeHidden */
        includeHidden?: (boolean);
    }

    /** Represents a SearchStoryFilter. */
    class SearchStoryFilter implements ISearchStoryFilter {

        /**
         * Constructs a new SearchStoryFilter.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchStoryFilter);

        /** SearchStoryFilter filterTag. */
        public filterTag: string[];

        /** SearchStoryFilter filterAnyUserId. */
        public filterAnyUserId: string[];

        /** SearchStoryFilter excludeAlreadyRead. */
        public excludeAlreadyRead?: (boolean);

        /** SearchStoryFilter filterSeriesId. */
        public filterSeriesId?: (string);

        /** SearchStoryFilter filterSeriesIndex. */
        public filterSeriesIndex?: (number);

        /** SearchStoryFilter includeBlocked. */
        public includeBlocked?: (boolean);

        /** SearchStoryFilter excludeOfficial. */
        public excludeOfficial?: (boolean);

        /** SearchStoryFilter excludeNotOfficial. */
        public excludeNotOfficial?: (boolean);

        /** SearchStoryFilter excludeCompletedSeries. */
        public excludeCompletedSeries?: (boolean);

        /** SearchStoryFilter excludeNotCompletedSeries. */
        public excludeNotCompletedSeries?: (boolean);

        /** SearchStoryFilter excludeSeries. */
        public excludeSeries?: (boolean);

        /** SearchStoryFilter excludeNotSeries. */
        public excludeNotSeries?: (boolean);

        /** SearchStoryFilter filterAnyAvailability. */
        public filterAnyAvailability: query_types.StoryAvailability[];

        /** SearchStoryFilter filterPublishedAt. */
        public filterPublishedAt?: (types.ITimestampRange);

        /** SearchStoryFilter filterAnyScriptType. */
        public filterAnyScriptType: query_types.StoryScriptType[];

        /** SearchStoryFilter excludeFirstPublished. */
        public excludeFirstPublished?: (boolean);

        /** SearchStoryFilter excludeNotFirstPublished. */
        public excludeNotFirstPublished?: (boolean);

        /** SearchStoryFilter includeSensitive. */
        public includeSensitive?: (boolean);

        /** SearchStoryFilter filterAnyStatus. */
        public filterAnyStatus: story.StoryStatus[];

        /** SearchStoryFilter filterAnyUserRole. */
        public filterAnyUserRole: query_types.UserRole[];

        /** SearchStoryFilter includeHidden. */
        public includeHidden?: (boolean);

        /**
         * Gets the default type url for SearchStoryFilter
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchStoryOptionalFilter. */
    export interface ISearchStoryOptionalFilter {

        /** SearchStoryOptionalFilter prioritizeOfficial */
        prioritizeOfficial?: (number);

        /** SearchStoryOptionalFilter prioritizeNotOfficial */
        prioritizeNotOfficial?: (number);
    }

    /** Represents a SearchStoryOptionalFilter. */
    class SearchStoryOptionalFilter implements ISearchStoryOptionalFilter {

        /**
         * Constructs a new SearchStoryOptionalFilter.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchStoryOptionalFilter);

        /** SearchStoryOptionalFilter prioritizeOfficial. */
        public prioritizeOfficial?: (number);

        /** SearchStoryOptionalFilter prioritizeNotOfficial. */
        public prioritizeNotOfficial?: (number);

        /**
         * Gets the default type url for SearchStoryOptionalFilter
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StorySearchableAttribute enum. */
    export enum StorySearchableAttribute {
        STORY_SEARCHABLE_ATTRIBUTE_UNSPECIFIED = "STORY_SEARCHABLE_ATTRIBUTE_UNSPECIFIED",
        STORY_SEARCHABLE_ATTRIBUTE_TITLE = "STORY_SEARCHABLE_ATTRIBUTE_TITLE",
        STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE = "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE",
        STORY_SEARCHABLE_ATTRIBUTE_DESCRIPTION = "STORY_SEARCHABLE_ATTRIBUTE_DESCRIPTION",
        STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION = "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION",
        STORY_SEARCHABLE_ATTRIBUTE_TAGS = "STORY_SEARCHABLE_ATTRIBUTE_TAGS",
        STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS = "STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS",
        STORY_SEARCHABLE_ATTRIBUTE_SERIES_TITLE = "STORY_SEARCHABLE_ATTRIBUTE_SERIES_TITLE",
        STORY_SEARCHABLE_ATTRIBUTE_SERIES_HIRAGANA_TITLE = "STORY_SEARCHABLE_ATTRIBUTE_SERIES_HIRAGANA_TITLE",
        STORY_SEARCHABLE_ATTRIBUTE_USER_NAME = "STORY_SEARCHABLE_ATTRIBUTE_USER_NAME",
        STORY_SEARCHABLE_ATTRIBUTE_USER_HIRAGANA_NAME = "STORY_SEARCHABLE_ATTRIBUTE_USER_HIRAGANA_NAME"
    }

    /** Properties of a SearchStoryParams. */
    export interface ISearchStoryParams {

        /** SearchStoryParams query */
        query?: (string);

        /** SearchStoryParams searchableAttributes */
        searchableAttributes?: (query_search_types.StorySearchableAttribute[]);

        /** SearchStoryParams filter */
        filter?: (query_search_types.ISearchStoryFilter);

        /** SearchStoryParams optionalFilter */
        optionalFilter?: (query_search_types.ISearchStoryOptionalFilter);

        /** SearchStoryParams sortBy */
        sortBy?: (query_search_types.SearchStorySortBy);

        /** SearchStoryParams distinct */
        distinct?: (query_search_types.SearchStoryDistinct);
    }

    /** Represents a SearchStoryParams. */
    class SearchStoryParams implements ISearchStoryParams {

        /**
         * Constructs a new SearchStoryParams.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchStoryParams);

        /** SearchStoryParams query. */
        public query?: (string);

        /** SearchStoryParams searchableAttributes. */
        public searchableAttributes: query_search_types.StorySearchableAttribute[];

        /** SearchStoryParams filter. */
        public filter?: (query_search_types.ISearchStoryFilter);

        /** SearchStoryParams optionalFilter. */
        public optionalFilter?: (query_search_types.ISearchStoryOptionalFilter);

        /** SearchStoryParams sortBy. */
        public sortBy: query_search_types.SearchStorySortBy;

        /** SearchStoryParams distinct. */
        public distinct: query_search_types.SearchStoryDistinct;

        /**
         * Gets the default type url for SearchStoryParams
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** SearchStoryDistinct enum. */
    export enum SearchStoryDistinct {
        SEARCH_STORY_DISTINCT_UNSPECIFIED = "SEARCH_STORY_DISTINCT_UNSPECIFIED",
        SEARCH_STORY_DISTINCT_SERIES_OR_STORY = "SEARCH_STORY_DISTINCT_SERIES_OR_STORY"
    }

    /** SearchStorySortBy enum. */
    export enum SearchStorySortBy {
        SEARCH_STORY_SORT_BY_UNSPECIFIED = "SEARCH_STORY_SORT_BY_UNSPECIFIED",
        SEARCH_STORY_SORT_BY_DEFAULT = "SEARCH_STORY_SORT_BY_DEFAULT",
        SEARCH_STORY_SORT_BY_PUBLISHED_AT_DESC = "SEARCH_STORY_SORT_BY_PUBLISHED_AT_DESC",
        SEARCH_STORY_SORT_BY_UPDATED_AT_DESC = "SEARCH_STORY_SORT_BY_UPDATED_AT_DESC",
        SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC = "SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC"
    }

    /** SearchStoryFacetType enum. */
    export enum SearchStoryFacetType {
        SEARCH_STORY_FACET_TYPE_UNSPECIFIED = "SEARCH_STORY_FACET_TYPE_UNSPECIFIED",
        SEARCH_STORY_FACET_TYPE_TAGS = "SEARCH_STORY_FACET_TYPE_TAGS"
    }

    /** Properties of a SearchStoryFacetRequest. */
    export interface ISearchStoryFacetRequest {

        /** SearchStoryFacetRequest facetType */
        facetType?: (query_search_types.SearchStoryFacetType[]);

        /** SearchStoryFacetRequest limitPerFacet */
        limitPerFacet?: (number);
    }

    /** Represents a SearchStoryFacetRequest. */
    class SearchStoryFacetRequest implements ISearchStoryFacetRequest {

        /**
         * Constructs a new SearchStoryFacetRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchStoryFacetRequest);

        /** SearchStoryFacetRequest facetType. */
        public facetType: query_search_types.SearchStoryFacetType[];

        /** SearchStoryFacetRequest limitPerFacet. */
        public limitPerFacet?: (number);

        /**
         * Gets the default type url for SearchStoryFacetRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchStoryFacetTagRequest. */
    export interface ISearchStoryFacetTagRequest {

        /** SearchStoryFacetTagRequest query */
        query?: (string);

        /** SearchStoryFacetTagRequest filter */
        filter?: (query_search_types.ISearchStoryFilter);

        /** SearchStoryFacetTagRequest limit */
        limit?: (number);

        /** SearchStoryFacetTagRequest distinct */
        distinct?: (query_search_types.SearchStoryDistinct);
    }

    /** Represents a SearchStoryFacetTagRequest. */
    class SearchStoryFacetTagRequest implements ISearchStoryFacetTagRequest {

        /**
         * Constructs a new SearchStoryFacetTagRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchStoryFacetTagRequest);

        /** SearchStoryFacetTagRequest query. */
        public query?: (string);

        /** SearchStoryFacetTagRequest filter. */
        public filter?: (query_search_types.ISearchStoryFilter);

        /** SearchStoryFacetTagRequest limit. */
        public limit?: (number);

        /** SearchStoryFacetTagRequest distinct. */
        public distinct: query_search_types.SearchStoryDistinct;

        /**
         * Gets the default type url for SearchStoryFacetTagRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchFacetItem. */
    export interface ISearchFacetItem {

        /** SearchFacetItem item */
        item?: (string);

        /** SearchFacetItem count */
        count?: (number);
    }

    /** Represents a SearchFacetItem. */
    class SearchFacetItem implements ISearchFacetItem {

        /**
         * Constructs a new SearchFacetItem.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchFacetItem);

        /** SearchFacetItem item. */
        public item?: (string);

        /** SearchFacetItem count. */
        public count?: (number);

        /**
         * Gets the default type url for SearchFacetItem
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchStoryFacetResponse. */
    export interface ISearchStoryFacetResponse {

        /** SearchStoryFacetResponse facetType */
        facetType?: (query_search_types.SearchStoryFacetType);

        /** SearchStoryFacetResponse itemList */
        itemList?: (query_search_types.ISearchFacetItem[]);
    }

    /** Represents a SearchStoryFacetResponse. */
    class SearchStoryFacetResponse implements ISearchStoryFacetResponse {

        /**
         * Constructs a new SearchStoryFacetResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchStoryFacetResponse);

        /** SearchStoryFacetResponse facetType. */
        public facetType: query_search_types.SearchStoryFacetType;

        /** SearchStoryFacetResponse itemList. */
        public itemList: query_search_types.ISearchFacetItem[];

        /**
         * Gets the default type url for SearchStoryFacetResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchUserParams. */
    export interface ISearchUserParams {

        /** SearchUserParams query */
        query?: (string);

        /** SearchUserParams searchableAttributes */
        searchableAttributes?: (query_search_types.UserSearchableAttribute[]);

        /** SearchUserParams filter */
        filter?: (query_search_types.ISearchUserFilter);

        /** SearchUserParams sortBy */
        sortBy?: (query_search_types.SearchUserSortBy);
    }

    /** Represents a SearchUserParams. */
    class SearchUserParams implements ISearchUserParams {

        /**
         * Constructs a new SearchUserParams.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchUserParams);

        /** SearchUserParams query. */
        public query?: (string);

        /** SearchUserParams searchableAttributes. */
        public searchableAttributes: query_search_types.UserSearchableAttribute[];

        /** SearchUserParams filter. */
        public filter?: (query_search_types.ISearchUserFilter);

        /** SearchUserParams sortBy. */
        public sortBy: query_search_types.SearchUserSortBy;

        /**
         * Gets the default type url for SearchUserParams
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** UserSearchableAttribute enum. */
    export enum UserSearchableAttribute {
        USER_SEARCHABLE_ATTRIBUTE_UNSPECIFIED = "USER_SEARCHABLE_ATTRIBUTE_UNSPECIFIED",
        USER_SEARCHABLE_ATTRIBUTE_NAME = "USER_SEARCHABLE_ATTRIBUTE_NAME",
        USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_NAME = "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_NAME",
        USER_SEARCHABLE_ATTRIBUTE_PROFILE = "USER_SEARCHABLE_ATTRIBUTE_PROFILE",
        USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PROFILE = "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PROFILE",
        USER_SEARCHABLE_ATTRIBUTE_PUBLISHED_STORY_TAGS = "USER_SEARCHABLE_ATTRIBUTE_PUBLISHED_STORY_TAGS",
        USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PUBLISHED_STORY_TAGS = "USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PUBLISHED_STORY_TAGS"
    }

    /** SearchUserSortBy enum. */
    export enum SearchUserSortBy {
        SEARCH_USER_SORT_BY_UNSPECIFIED = "SEARCH_USER_SORT_BY_UNSPECIFIED",
        SEARCH_USER_SORT_BY_DEFAULT = "SEARCH_USER_SORT_BY_DEFAULT",
        SEARCH_USER_SORT_BY_LATEST_PUBLISHED_AT_DESC = "SEARCH_USER_SORT_BY_LATEST_PUBLISHED_AT_DESC"
    }

    /** Properties of a SearchUserFilter. */
    export interface ISearchUserFilter {

        /** SearchUserFilter filterPublishedStoryTag */
        filterPublishedStoryTag?: (string[]);

        /** SearchUserFilter filterPublishedAnyStoryTag */
        filterPublishedAnyStoryTag?: (string[]);

        /** SearchUserFilter includeBlocked */
        includeBlocked?: (boolean);

        /** SearchUserFilter excludeVip */
        excludeVip?: (boolean);

        /** SearchUserFilter excludeNotVip */
        excludeNotVip?: (boolean);

        /** SearchUserFilter filterSearchableStoryCount */
        filterSearchableStoryCount?: (types.IUInt32Range);

        /** SearchUserFilter filterAnyUserRole */
        filterAnyUserRole?: (query_types.UserRole[]);
    }

    /** Represents a SearchUserFilter. */
    class SearchUserFilter implements ISearchUserFilter {

        /**
         * Constructs a new SearchUserFilter.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchUserFilter);

        /** SearchUserFilter filterPublishedStoryTag. */
        public filterPublishedStoryTag: string[];

        /** SearchUserFilter filterPublishedAnyStoryTag. */
        public filterPublishedAnyStoryTag: string[];

        /** SearchUserFilter includeBlocked. */
        public includeBlocked?: (boolean);

        /** SearchUserFilter excludeVip. */
        public excludeVip?: (boolean);

        /** SearchUserFilter excludeNotVip. */
        public excludeNotVip?: (boolean);

        /** SearchUserFilter filterSearchableStoryCount. */
        public filterSearchableStoryCount?: (types.IUInt32Range);

        /** SearchUserFilter filterAnyUserRole. */
        public filterAnyUserRole: query_types.UserRole[];

        /**
         * Gets the default type url for SearchUserFilter
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesParams. */
    export interface ISearchSeriesParams {

        /** SearchSeriesParams query */
        query?: (string);

        /** SearchSeriesParams searchableAttributes */
        searchableAttributes?: (query_search_types.SeriesSearchableAttribute[]);

        /** SearchSeriesParams filter */
        filter?: (query_search_types.ISearchSeriesFilter);

        /** SearchSeriesParams sortBy */
        sortBy?: (query_search_types.SearchSeriesSortBy);
    }

    /** Represents a SearchSeriesParams. */
    class SearchSeriesParams implements ISearchSeriesParams {

        /**
         * Constructs a new SearchSeriesParams.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchSeriesParams);

        /** SearchSeriesParams query. */
        public query?: (string);

        /** SearchSeriesParams searchableAttributes. */
        public searchableAttributes: query_search_types.SeriesSearchableAttribute[];

        /** SearchSeriesParams filter. */
        public filter?: (query_search_types.ISearchSeriesFilter);

        /** SearchSeriesParams sortBy. */
        public sortBy: query_search_types.SearchSeriesSortBy;

        /**
         * Gets the default type url for SearchSeriesParams
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** SeriesSearchableAttribute enum. */
    export enum SeriesSearchableAttribute {
        SERIES_SEARCHABLE_ATTRIBUTE_UNSPECIFIED = "SERIES_SEARCHABLE_ATTRIBUTE_UNSPECIFIED",
        SERIES_SEARCHABLE_ATTRIBUTE_TITLE = "SERIES_SEARCHABLE_ATTRIBUTE_TITLE",
        SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE = "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE",
        SERIES_SEARCHABLE_ATTRIBUTE_DESCRIPTION = "SERIES_SEARCHABLE_ATTRIBUTE_DESCRIPTION",
        SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION = "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION",
        SERIES_SEARCHABLE_ATTRIBUTE_TAGS = "SERIES_SEARCHABLE_ATTRIBUTE_TAGS",
        SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS = "SERIES_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS"
    }

    /** SearchSeriesSortBy enum. */
    export enum SearchSeriesSortBy {
        SEARCH_SERIES_SORT_BY_UNSPECIFIED = "SEARCH_SERIES_SORT_BY_UNSPECIFIED",
        SEARCH_SERIES_SORT_BY_DEFAULT = "SEARCH_SERIES_SORT_BY_DEFAULT",
        SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_DESC = "SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_DESC",
        SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_ASC = "SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_ASC"
    }

    /** Properties of a SearchSeriesFilter. */
    export interface ISearchSeriesFilter {

        /** SearchSeriesFilter filterTag */
        filterTag?: (string[]);

        /** SearchSeriesFilter filterAnyUserId */
        filterAnyUserId?: (string[]);

        /** SearchSeriesFilter filterSeriesGroupId */
        filterSeriesGroupId?: (string);

        /** SearchSeriesFilter includeBlocked */
        includeBlocked?: (boolean);

        /** SearchSeriesFilter excludeOfficial */
        excludeOfficial?: (boolean);

        /** SearchSeriesFilter excludeNotOfficial */
        excludeNotOfficial?: (boolean);

        /** SearchSeriesFilter excludeCompleted */
        excludeCompleted?: (boolean);

        /** SearchSeriesFilter excludeNotCompleted */
        excludeNotCompleted?: (boolean);

        /** SearchSeriesFilter filterSearchableStoryCount */
        filterSearchableStoryCount?: (types.IUInt32Range);

        /** SearchSeriesFilter excludeContainingChatNovel */
        excludeContainingChatNovel?: (boolean);

        /** SearchSeriesFilter excludeContainingNovel */
        excludeContainingNovel?: (boolean);

        /** SearchSeriesFilter filterAnyStatus */
        filterAnyStatus?: (types.SharedWithStatus[]);

        /** SearchSeriesFilter filterAnyUserRole */
        filterAnyUserRole?: (query_types.UserRole[]);
    }

    /** Represents a SearchSeriesFilter. */
    class SearchSeriesFilter implements ISearchSeriesFilter {

        /**
         * Constructs a new SearchSeriesFilter.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchSeriesFilter);

        /** SearchSeriesFilter filterTag. */
        public filterTag: string[];

        /** SearchSeriesFilter filterAnyUserId. */
        public filterAnyUserId: string[];

        /** SearchSeriesFilter filterSeriesGroupId. */
        public filterSeriesGroupId?: (string);

        /** SearchSeriesFilter includeBlocked. */
        public includeBlocked?: (boolean);

        /** SearchSeriesFilter excludeOfficial. */
        public excludeOfficial?: (boolean);

        /** SearchSeriesFilter excludeNotOfficial. */
        public excludeNotOfficial?: (boolean);

        /** SearchSeriesFilter excludeCompleted. */
        public excludeCompleted?: (boolean);

        /** SearchSeriesFilter excludeNotCompleted. */
        public excludeNotCompleted?: (boolean);

        /** SearchSeriesFilter filterSearchableStoryCount. */
        public filterSearchableStoryCount?: (types.IUInt32Range);

        /** SearchSeriesFilter excludeContainingChatNovel. */
        public excludeContainingChatNovel?: (boolean);

        /** SearchSeriesFilter excludeContainingNovel. */
        public excludeContainingNovel?: (boolean);

        /** SearchSeriesFilter filterAnyStatus. */
        public filterAnyStatus: types.SharedWithStatus[];

        /** SearchSeriesFilter filterAnyUserRole. */
        public filterAnyUserRole: query_types.UserRole[];

        /**
         * Gets the default type url for SearchSeriesFilter
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesGroupParams. */
    export interface ISearchSeriesGroupParams {

        /** SearchSeriesGroupParams query */
        query?: (string);

        /** SearchSeriesGroupParams searchableAttributes */
        searchableAttributes?: (query_search_types.SeriesGroupSearchableAttribute[]);

        /** SearchSeriesGroupParams filter */
        filter?: (query_search_types.ISearchSeriesGroupFilter);

        /** SearchSeriesGroupParams sortBy */
        sortBy?: (query_search_types.SearchSeriesGroupSortBy);
    }

    /** Represents a SearchSeriesGroupParams. */
    class SearchSeriesGroupParams implements ISearchSeriesGroupParams {

        /**
         * Constructs a new SearchSeriesGroupParams.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchSeriesGroupParams);

        /** SearchSeriesGroupParams query. */
        public query?: (string);

        /** SearchSeriesGroupParams searchableAttributes. */
        public searchableAttributes: query_search_types.SeriesGroupSearchableAttribute[];

        /** SearchSeriesGroupParams filter. */
        public filter?: (query_search_types.ISearchSeriesGroupFilter);

        /** SearchSeriesGroupParams sortBy. */
        public sortBy: query_search_types.SearchSeriesGroupSortBy;

        /**
         * Gets the default type url for SearchSeriesGroupParams
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** SeriesGroupSearchableAttribute enum. */
    export enum SeriesGroupSearchableAttribute {
        SERIES_GROUP_SEARCHABLE_ATTRIBUTE_UNSPECIFIED = "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_UNSPECIFIED",
        SERIES_GROUP_SEARCHABLE_ATTRIBUTE_TITLE = "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_TITLE",
        SERIES_GROUP_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE = "SERIES_GROUP_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE"
    }

    /** SearchSeriesGroupSortBy enum. */
    export enum SearchSeriesGroupSortBy {
        SEARCH_SERIES_GROUP_SORT_BY_UNSPECIFIED = "SEARCH_SERIES_GROUP_SORT_BY_UNSPECIFIED",
        SEARCH_SERIES_GROUP_SORT_BY_DEFAULT = "SEARCH_SERIES_GROUP_SORT_BY_DEFAULT"
    }

    /** Properties of a SearchSeriesGroupFilter. */
    export interface ISearchSeriesGroupFilter {

        /** SearchSeriesGroupFilter filterAnyUserId */
        filterAnyUserId?: (string[]);

        /** SearchSeriesGroupFilter includeBlocked */
        includeBlocked?: (boolean);

        /** SearchSeriesGroupFilter filterAnyStatus */
        filterAnyStatus?: (types.SharedWithStatus[]);

        /** SearchSeriesGroupFilter filterAnyUserRole */
        filterAnyUserRole?: (query_types.UserRole[]);
    }

    /** Represents a SearchSeriesGroupFilter. */
    class SearchSeriesGroupFilter implements ISearchSeriesGroupFilter {

        /**
         * Constructs a new SearchSeriesGroupFilter.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_types.ISearchSeriesGroupFilter);

        /** SearchSeriesGroupFilter filterAnyUserId. */
        public filterAnyUserId: string[];

        /** SearchSeriesGroupFilter includeBlocked. */
        public includeBlocked?: (boolean);

        /** SearchSeriesGroupFilter filterAnyStatus. */
        public filterAnyStatus: types.SharedWithStatus[];

        /** SearchSeriesGroupFilter filterAnyUserRole. */
        public filterAnyUserRole: query_types.UserRole[];

        /**
         * Gets the default type url for SearchSeriesGroupFilter
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_series. */
export namespace query_series {

    /** Properties of a PublishScheduleResponse. */
    export interface IPublishScheduleResponse {

        /** PublishScheduleResponse schedule */
        schedule?: (query_types.PublishSchedule);

        /** PublishScheduleResponse text */
        text?: (string);
    }

    /** Represents a PublishScheduleResponse. */
    class PublishScheduleResponse implements IPublishScheduleResponse {

        /**
         * Constructs a new PublishScheduleResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_series.IPublishScheduleResponse);

        /** PublishScheduleResponse schedule. */
        public schedule: query_types.PublishSchedule;

        /** PublishScheduleResponse text. */
        public text?: (string);

        /**
         * Gets the default type url for PublishScheduleResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace story_video. */
export namespace story_video {

    /** Properties of a StoryVideoRequest. */
    export interface IStoryVideoRequest {

        /** StoryVideoRequest requireForShare */
        requireForShare?: (story_video.IStoryVideoRequireField);

        /** StoryVideoRequest requireForInAppTimeline */
        requireForInAppTimeline?: (story_video.IStoryVideoRequireField);

        /** StoryVideoRequest requireForTiktok */
        requireForTiktok?: (story_video.IStoryVideoRequireField);

        /** StoryVideoRequest typeList */
        typeList?: (story_video.StoryVideoType[]);

        /** StoryVideoRequest requireStoryVideo */
        requireStoryVideo?: (story_video.IStoryVideoRequireField);
    }

    /** Represents a StoryVideoRequest. */
    class StoryVideoRequest implements IStoryVideoRequest {

        /**
         * Constructs a new StoryVideoRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideoRequest);

        /** StoryVideoRequest requireForShare. */
        public requireForShare?: (story_video.IStoryVideoRequireField);

        /** StoryVideoRequest requireForInAppTimeline. */
        public requireForInAppTimeline?: (story_video.IStoryVideoRequireField);

        /** StoryVideoRequest requireForTiktok. */
        public requireForTiktok?: (story_video.IStoryVideoRequireField);

        /** StoryVideoRequest typeList. */
        public typeList: story_video.StoryVideoType[];

        /** StoryVideoRequest requireStoryVideo. */
        public requireStoryVideo?: (story_video.IStoryVideoRequireField);

        /**
         * Gets the default type url for StoryVideoRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryVideoResponse. */
    export interface IStoryVideoResponse {

        /** StoryVideoResponse forShare */
        forShare?: (story_video.IStoryVideo);

        /** StoryVideoResponse forInAppTimeline */
        forInAppTimeline?: (story_video.IStoryVideo);

        /** StoryVideoResponse forTiktok */
        forTiktok?: (story_video.IStoryVideo);
    }

    /** Represents a StoryVideoResponse. */
    class StoryVideoResponse implements IStoryVideoResponse {

        /**
         * Constructs a new StoryVideoResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideoResponse);

        /** StoryVideoResponse forShare. */
        public forShare?: (story_video.IStoryVideo);

        /** StoryVideoResponse forInAppTimeline. */
        public forInAppTimeline?: (story_video.IStoryVideo);

        /** StoryVideoResponse forTiktok. */
        public forTiktok?: (story_video.IStoryVideo);

        /**
         * Gets the default type url for StoryVideoResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StoryVideoStatus enum. */
    export enum StoryVideoStatus {
        STORY_VIDEO_STATUS_UNSPECIFIED = "STORY_VIDEO_STATUS_UNSPECIFIED",
        STORY_VIDEO_STATUS_NO_VIDEO = "STORY_VIDEO_STATUS_NO_VIDEO",
        STORY_VIDEO_STATUS_CREATING = "STORY_VIDEO_STATUS_CREATING",
        STORY_VIDEO_STATUS_UP_TO_DATE = "STORY_VIDEO_STATUS_UP_TO_DATE",
        STORY_VIDEO_STATUS_STALE = "STORY_VIDEO_STATUS_STALE"
    }

    /** Properties of a StoryVideoRequireField. */
    export interface IStoryVideoRequireField {

        /** StoryVideoRequireField requireId */
        requireId?: (boolean);

        /** StoryVideoRequireField requireType */
        requireType?: (boolean);

        /** StoryVideoRequireField requireDownloadUrl */
        requireDownloadUrl?: (boolean);

        /** StoryVideoRequireField requireSize */
        requireSize?: (boolean);

        /** StoryVideoRequireField requireGenerateOption */
        requireGenerateOption?: (story_video.IStoryVideoOptionRequireField);

        /** StoryVideoRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** StoryVideoRequireField requireStatus */
        requireStatus?: (boolean);
    }

    /** Represents a StoryVideoRequireField. */
    class StoryVideoRequireField implements IStoryVideoRequireField {

        /**
         * Constructs a new StoryVideoRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideoRequireField);

        /** StoryVideoRequireField requireId. */
        public requireId?: (boolean);

        /** StoryVideoRequireField requireType. */
        public requireType?: (boolean);

        /** StoryVideoRequireField requireDownloadUrl. */
        public requireDownloadUrl?: (boolean);

        /** StoryVideoRequireField requireSize. */
        public requireSize?: (boolean);

        /** StoryVideoRequireField requireGenerateOption. */
        public requireGenerateOption?: (story_video.IStoryVideoOptionRequireField);

        /** StoryVideoRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** StoryVideoRequireField requireStatus. */
        public requireStatus?: (boolean);

        /**
         * Gets the default type url for StoryVideoRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryVideoOptionRequireField. */
    export interface IStoryVideoOptionRequireField {

        /** StoryVideoOptionRequireField requireWidth */
        requireWidth?: (boolean);

        /** StoryVideoOptionRequireField requireHeight */
        requireHeight?: (boolean);

        /** StoryVideoOptionRequireField requireScale */
        requireScale?: (boolean);

        /** StoryVideoOptionRequireField requireWait */
        requireWait?: (boolean);

        /** StoryVideoOptionRequireField requireMaxTapCount */
        requireMaxTapCount?: (boolean);

        /** StoryVideoOptionRequireField requireShowHeader */
        requireShowHeader?: (boolean);

        /** StoryVideoOptionRequireField requireShowAppPromotion */
        requireShowAppPromotion?: (boolean);

        /** StoryVideoOptionRequireField requireBitrate */
        requireBitrate?: (boolean);

        /** StoryVideoOptionRequireField requireCrf */
        requireCrf?: (boolean);

        /** StoryVideoOptionRequireField requireShowWatermark */
        requireShowWatermark?: (boolean);
    }

    /** Represents a StoryVideoOptionRequireField. */
    class StoryVideoOptionRequireField implements IStoryVideoOptionRequireField {

        /**
         * Constructs a new StoryVideoOptionRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideoOptionRequireField);

        /** StoryVideoOptionRequireField requireWidth. */
        public requireWidth?: (boolean);

        /** StoryVideoOptionRequireField requireHeight. */
        public requireHeight?: (boolean);

        /** StoryVideoOptionRequireField requireScale. */
        public requireScale?: (boolean);

        /** StoryVideoOptionRequireField requireWait. */
        public requireWait?: (boolean);

        /** StoryVideoOptionRequireField requireMaxTapCount. */
        public requireMaxTapCount?: (boolean);

        /** StoryVideoOptionRequireField requireShowHeader. */
        public requireShowHeader?: (boolean);

        /** StoryVideoOptionRequireField requireShowAppPromotion. */
        public requireShowAppPromotion?: (boolean);

        /** StoryVideoOptionRequireField requireBitrate. */
        public requireBitrate?: (boolean);

        /** StoryVideoOptionRequireField requireCrf. */
        public requireCrf?: (boolean);

        /** StoryVideoOptionRequireField requireShowWatermark. */
        public requireShowWatermark?: (boolean);

        /**
         * Gets the default type url for StoryVideoOptionRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryVideo. */
    export interface IStoryVideo {

        /** StoryVideo id */
        id?: (string);

        /** StoryVideo storyId */
        storyId?: (string);

        /** StoryVideo type */
        type?: (story_video.StoryVideoType);

        /** StoryVideo downloadUrl */
        downloadUrl?: (string);

        /** StoryVideo size */
        size?: (number);

        /** StoryVideo generateOption */
        generateOption?: (story_video.IStoryVideoOption);

        /** StoryVideo createdAt */
        createdAt?: (string);

        /** StoryVideo status */
        status?: (story_video.StoryVideoStatus);
    }

    /** Represents a StoryVideo. */
    class StoryVideo implements IStoryVideo {

        /**
         * Constructs a new StoryVideo.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideo);

        /** StoryVideo id. */
        public id?: (string);

        /** StoryVideo storyId. */
        public storyId?: (string);

        /** StoryVideo type. */
        public type: story_video.StoryVideoType;

        /** StoryVideo downloadUrl. */
        public downloadUrl?: (string);

        /** StoryVideo size. */
        public size?: (number);

        /** StoryVideo generateOption. */
        public generateOption?: (story_video.IStoryVideoOption);

        /** StoryVideo createdAt. */
        public createdAt?: (string);

        /** StoryVideo status. */
        public status: story_video.StoryVideoStatus;

        /**
         * Gets the default type url for StoryVideo
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryVideoList. */
    export interface IStoryVideoList {

        /** StoryVideoList storyVideoList */
        storyVideoList?: (story_video.IStoryVideo[]);
    }

    /** Represents a StoryVideoList. */
    class StoryVideoList implements IStoryVideoList {

        /**
         * Constructs a new StoryVideoList.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideoList);

        /** StoryVideoList storyVideoList. */
        public storyVideoList: story_video.IStoryVideo[];

        /**
         * Gets the default type url for StoryVideoList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryVideoOption. */
    export interface IStoryVideoOption {

        /** StoryVideoOption width */
        width?: (number);

        /** StoryVideoOption height */
        height?: (number);

        /** StoryVideoOption scale */
        scale?: (number);

        /** StoryVideoOption wait */
        wait?: (string);

        /** StoryVideoOption maxTapCount */
        maxTapCount?: (number);

        /** StoryVideoOption showHeader */
        showHeader?: (boolean);

        /** StoryVideoOption showAppPromotion */
        showAppPromotion?: (boolean);

        /** StoryVideoOption bitrate */
        bitrate?: (number);

        /** StoryVideoOption crf */
        crf?: (number);

        /** StoryVideoOption showWatermark */
        showWatermark?: (string);
    }

    /** Represents a StoryVideoOption. */
    class StoryVideoOption implements IStoryVideoOption {

        /**
         * Constructs a new StoryVideoOption.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideoOption);

        /** StoryVideoOption width. */
        public width?: (number);

        /** StoryVideoOption height. */
        public height?: (number);

        /** StoryVideoOption scale. */
        public scale?: (number);

        /** StoryVideoOption wait. */
        public wait?: (string);

        /** StoryVideoOption maxTapCount. */
        public maxTapCount?: (number);

        /** StoryVideoOption showHeader. */
        public showHeader?: (boolean);

        /** StoryVideoOption showAppPromotion. */
        public showAppPromotion?: (boolean);

        /** StoryVideoOption bitrate. */
        public bitrate?: (number);

        /** StoryVideoOption crf. */
        public crf?: (number);

        /** StoryVideoOption showWatermark. */
        public showWatermark?: (string);

        /**
         * Gets the default type url for StoryVideoOption
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** StoryVideoType enum. */
    export enum StoryVideoType {
        STORY_VIDEO_TYPE_UNSPECIFIED = "STORY_VIDEO_TYPE_UNSPECIFIED",
        STORY_VIDEO_TYPE_FOR_SHARE = "STORY_VIDEO_TYPE_FOR_SHARE",
        STORY_VIDEO_TYPE_FOR_IN_APP_TIMELINE = "STORY_VIDEO_TYPE_FOR_IN_APP_TIMELINE",
        STORY_VIDEO_TYPE_FOR_TIKTOK = "STORY_VIDEO_TYPE_FOR_TIKTOK"
    }

    /** Properties of a StoryVideoGetRequest. */
    export interface IStoryVideoGetRequest {

        /** StoryVideoGetRequest storyId */
        storyId?: (string);

        /** StoryVideoGetRequest type */
        type?: (story_video.StoryVideoType);
    }

    /** Represents a StoryVideoGetRequest. */
    class StoryVideoGetRequest implements IStoryVideoGetRequest {

        /**
         * Constructs a new StoryVideoGetRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideoGetRequest);

        /** StoryVideoGetRequest storyId. */
        public storyId?: (string);

        /** StoryVideoGetRequest type. */
        public type: story_video.StoryVideoType;

        /**
         * Gets the default type url for StoryVideoGetRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryVideoGetMultiRequest. */
    export interface IStoryVideoGetMultiRequest {

        /** StoryVideoGetMultiRequest storyIdList */
        storyIdList?: (string[]);

        /** StoryVideoGetMultiRequest type */
        type?: (story_video.StoryVideoType);
    }

    /** Represents a StoryVideoGetMultiRequest. */
    class StoryVideoGetMultiRequest implements IStoryVideoGetMultiRequest {

        /**
         * Constructs a new StoryVideoGetMultiRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_video.IStoryVideoGetMultiRequest);

        /** StoryVideoGetMultiRequest storyIdList. */
        public storyIdList: string[];

        /** StoryVideoGetMultiRequest type. */
        public type: story_video.StoryVideoType;

        /**
         * Gets the default type url for StoryVideoGetMultiRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_series_selection. */
export namespace query_series_selection {

    /** SeriesSelectionShuffling enum. */
    export enum SeriesSelectionShuffling {
        SERIES_SELECTION_SHUFFLING_UNSPECIFIED = "SERIES_SELECTION_SHUFFLING_UNSPECIFIED",
        SERIES_SELECTION_SHUFFLING_EVERYTIME = "SERIES_SELECTION_SHUFFLING_EVERYTIME",
        SERIES_SELECTION_SHUFFLING_DAILY = "SERIES_SELECTION_SHUFFLING_DAILY"
    }
}

/** Namespace query_search_query. */
export namespace query_search_query {

    /** Properties of a SearchQueryRequest. */
    export interface ISearchQueryRequest {

        /** SearchQueryRequest requirePopularSearchQuery */
        requirePopularSearchQuery?: (query_search_query.ISearchQueryPageRequest);
    }

    /** Represents a SearchQueryRequest. */
    class SearchQueryRequest implements ISearchQueryRequest {

        /**
         * Constructs a new SearchQueryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_query.ISearchQueryRequest);

        /** SearchQueryRequest requirePopularSearchQuery. */
        public requirePopularSearchQuery?: (query_search_query.ISearchQueryPageRequest);

        /**
         * Gets the default type url for SearchQueryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchQueryPageRequest. */
    export interface ISearchQueryPageRequest {

        /** SearchQueryPageRequest requireQueryList */
        requireQueryList?: (boolean);

        /** SearchQueryPageRequest page */
        page?: (query_pagination.IPageRequest);
    }

    /** Represents a SearchQueryPageRequest. */
    class SearchQueryPageRequest implements ISearchQueryPageRequest {

        /**
         * Constructs a new SearchQueryPageRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_query.ISearchQueryPageRequest);

        /** SearchQueryPageRequest requireQueryList. */
        public requireQueryList?: (boolean);

        /** SearchQueryPageRequest page. */
        public page?: (query_pagination.IPageRequest);

        /**
         * Gets the default type url for SearchQueryPageRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchQueryResponse. */
    export interface ISearchQueryResponse {

        /** SearchQueryResponse popularSearchQueryList */
        popularSearchQueryList?: (query_search_query.ISearchQueryPageResponse);
    }

    /** Represents a SearchQueryResponse. */
    class SearchQueryResponse implements ISearchQueryResponse {

        /**
         * Constructs a new SearchQueryResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_query.ISearchQueryResponse);

        /** SearchQueryResponse popularSearchQueryList. */
        public popularSearchQueryList?: (query_search_query.ISearchQueryPageResponse);

        /**
         * Gets the default type url for SearchQueryResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchQueryPageResponse. */
    export interface ISearchQueryPageResponse {

        /** SearchQueryPageResponse queryList */
        queryList?: (string[]);

        /** SearchQueryPageResponse page */
        page?: (query_pagination.IPageInfo);
    }

    /** Represents a SearchQueryPageResponse. */
    class SearchQueryPageResponse implements ISearchQueryPageResponse {

        /**
         * Constructs a new SearchQueryPageResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_query.ISearchQueryPageResponse);

        /** SearchQueryPageResponse queryList. */
        public queryList: string[];

        /** SearchQueryPageResponse page. */
        public page?: (query_pagination.IPageInfo);

        /**
         * Gets the default type url for SearchQueryPageResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_search_v2. */
export namespace query_search_v2 {

    /** Properties of a SearchSeriesRequestV2. */
    export interface ISearchSeriesRequestV2 {

        /** SearchSeriesRequestV2 q */
        q?: (string);

        /** SearchSeriesRequestV2 officialFilter */
        officialFilter?: (query_search_v2.OfficialFilter);

        /** SearchSeriesRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);

        /** SearchSeriesRequestV2 scriptTypeFilter */
        scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchSeriesRequestV2 requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SearchSeriesRequestV2. */
    class SearchSeriesRequestV2 implements ISearchSeriesRequestV2 {

        /**
         * Constructs a new SearchSeriesRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchSeriesRequestV2);

        /** SearchSeriesRequestV2 q. */
        public q?: (string);

        /** SearchSeriesRequestV2 officialFilter. */
        public officialFilter: query_search_v2.OfficialFilter;

        /** SearchSeriesRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /** SearchSeriesRequestV2 scriptTypeFilter. */
        public scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchSeriesRequestV2 requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SearchSeriesRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesByTitleRequestV2. */
    export interface ISearchSeriesByTitleRequestV2 {

        /** SearchSeriesByTitleRequestV2 q */
        q?: (string);

        /** SearchSeriesByTitleRequestV2 officialFilter */
        officialFilter?: (query_search_v2.OfficialFilter);

        /** SearchSeriesByTitleRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);

        /** SearchSeriesByTitleRequestV2 scriptTypeFilter */
        scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchSeriesByTitleRequestV2 requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SearchSeriesByTitleRequestV2. */
    class SearchSeriesByTitleRequestV2 implements ISearchSeriesByTitleRequestV2 {

        /**
         * Constructs a new SearchSeriesByTitleRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchSeriesByTitleRequestV2);

        /** SearchSeriesByTitleRequestV2 q. */
        public q?: (string);

        /** SearchSeriesByTitleRequestV2 officialFilter. */
        public officialFilter: query_search_v2.OfficialFilter;

        /** SearchSeriesByTitleRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /** SearchSeriesByTitleRequestV2 scriptTypeFilter. */
        public scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchSeriesByTitleRequestV2 requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SearchSeriesByTitleRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesByTagRequestV2. */
    export interface ISearchSeriesByTagRequestV2 {

        /** SearchSeriesByTagRequestV2 tag */
        tag?: (string);

        /** SearchSeriesByTagRequestV2 officialFilter */
        officialFilter?: (query_search_v2.OfficialFilter);

        /** SearchSeriesByTagRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);

        /** SearchSeriesByTagRequestV2 scriptTypeFilter */
        scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchSeriesByTagRequestV2 requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SearchSeriesByTagRequestV2. */
    class SearchSeriesByTagRequestV2 implements ISearchSeriesByTagRequestV2 {

        /**
         * Constructs a new SearchSeriesByTagRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchSeriesByTagRequestV2);

        /** SearchSeriesByTagRequestV2 tag. */
        public tag?: (string);

        /** SearchSeriesByTagRequestV2 officialFilter. */
        public officialFilter: query_search_v2.OfficialFilter;

        /** SearchSeriesByTagRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /** SearchSeriesByTagRequestV2 scriptTypeFilter. */
        public scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchSeriesByTagRequestV2 requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SearchSeriesByTagRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchLatestSeriesRequestV2. */
    export interface ISearchLatestSeriesRequestV2 {

        /** SearchLatestSeriesRequestV2 officialFilter */
        officialFilter?: (query_search_v2.OfficialFilter);

        /** SearchLatestSeriesRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);

        /** SearchLatestSeriesRequestV2 scriptTypeFilter */
        scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchLatestSeriesRequestV2 requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SearchLatestSeriesRequestV2. */
    class SearchLatestSeriesRequestV2 implements ISearchLatestSeriesRequestV2 {

        /**
         * Constructs a new SearchLatestSeriesRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchLatestSeriesRequestV2);

        /** SearchLatestSeriesRequestV2 officialFilter. */
        public officialFilter: query_search_v2.OfficialFilter;

        /** SearchLatestSeriesRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /** SearchLatestSeriesRequestV2 scriptTypeFilter. */
        public scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchLatestSeriesRequestV2 requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SearchLatestSeriesRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchCompletedOfficialSeriesRequestV2. */
    export interface ISearchCompletedOfficialSeriesRequestV2 {

        /** SearchCompletedOfficialSeriesRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);

        /** SearchCompletedOfficialSeriesRequestV2 scriptTypeFilter */
        scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchCompletedOfficialSeriesRequestV2 requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SearchCompletedOfficialSeriesRequestV2. */
    class SearchCompletedOfficialSeriesRequestV2 implements ISearchCompletedOfficialSeriesRequestV2 {

        /**
         * Constructs a new SearchCompletedOfficialSeriesRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchCompletedOfficialSeriesRequestV2);

        /** SearchCompletedOfficialSeriesRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /** SearchCompletedOfficialSeriesRequestV2 scriptTypeFilter. */
        public scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchCompletedOfficialSeriesRequestV2 requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SearchCompletedOfficialSeriesRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchUncompletedOfficialSeriesRequestV2. */
    export interface ISearchUncompletedOfficialSeriesRequestV2 {

        /** SearchUncompletedOfficialSeriesRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);

        /** SearchUncompletedOfficialSeriesRequestV2 scriptTypeFilter */
        scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchUncompletedOfficialSeriesRequestV2 requireSeriesPage */
        requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);
    }

    /** Represents a SearchUncompletedOfficialSeriesRequestV2. */
    class SearchUncompletedOfficialSeriesRequestV2 implements ISearchUncompletedOfficialSeriesRequestV2 {

        /**
         * Constructs a new SearchUncompletedOfficialSeriesRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchUncompletedOfficialSeriesRequestV2);

        /** SearchUncompletedOfficialSeriesRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /** SearchUncompletedOfficialSeriesRequestV2 scriptTypeFilter. */
        public scriptTypeFilter?: (query_recursive_types.IScriptTypeFilter);

        /** SearchUncompletedOfficialSeriesRequestV2 requireSeriesPage. */
        public requireSeriesPage?: (query_recursive_types.ISeriesPageRequest);

        /**
         * Gets the default type url for SearchUncompletedOfficialSeriesRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchSeriesResponseV2. */
    export interface ISearchSeriesResponseV2 {

        /** SearchSeriesResponseV2 seriesPage */
        seriesPage?: (query_recursive_types.ISeriesPageResponse);
    }

    /** Represents a SearchSeriesResponseV2. */
    class SearchSeriesResponseV2 implements ISearchSeriesResponseV2 {

        /**
         * Constructs a new SearchSeriesResponseV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchSeriesResponseV2);

        /** SearchSeriesResponseV2 seriesPage. */
        public seriesPage?: (query_recursive_types.ISeriesPageResponse);

        /**
         * Gets the default type url for SearchSeriesResponseV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchFacetRelatedTagRequestV2. */
    export interface ISearchFacetRelatedTagRequestV2 {

        /** SearchFacetRelatedTagRequestV2 anyTags */
        anyTags?: (string[]);

        /** SearchFacetRelatedTagRequestV2 limit */
        limit?: (number);

        /** SearchFacetRelatedTagRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);

        /** SearchFacetRelatedTagRequestV2 tag */
        tag?: (string);
    }

    /** Represents a SearchFacetRelatedTagRequestV2. */
    class SearchFacetRelatedTagRequestV2 implements ISearchFacetRelatedTagRequestV2 {

        /**
         * Constructs a new SearchFacetRelatedTagRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchFacetRelatedTagRequestV2);

        /** SearchFacetRelatedTagRequestV2 anyTags. */
        public anyTags: string[];

        /** SearchFacetRelatedTagRequestV2 limit. */
        public limit?: (number);

        /** SearchFacetRelatedTagRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /** SearchFacetRelatedTagRequestV2 tag. */
        public tag?: (string);

        /**
         * Gets the default type url for SearchFacetRelatedTagRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchFacetTagRequestV2. */
    export interface ISearchFacetTagRequestV2 {

        /** SearchFacetTagRequestV2 q */
        q?: (string);

        /** SearchFacetTagRequestV2 limit */
        limit?: (number);

        /** SearchFacetTagRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);

        /** SearchFacetTagRequestV2 matchType */
        matchType?: (query_search_v2.MatchType);
    }

    /** Represents a SearchFacetTagRequestV2. */
    class SearchFacetTagRequestV2 implements ISearchFacetTagRequestV2 {

        /**
         * Constructs a new SearchFacetTagRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchFacetTagRequestV2);

        /** SearchFacetTagRequestV2 q. */
        public q?: (string);

        /** SearchFacetTagRequestV2 limit. */
        public limit?: (number);

        /** SearchFacetTagRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /** SearchFacetTagRequestV2 matchType. */
        public matchType: query_search_v2.MatchType;

        /**
         * Gets the default type url for SearchFacetTagRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchLatestFacetTagRequestV2. */
    export interface ISearchLatestFacetTagRequestV2 {

        /** SearchLatestFacetTagRequestV2 limit */
        limit?: (number);

        /** SearchLatestFacetTagRequestV2 sensitiveFilter */
        sensitiveFilter?: (query_search_v2.SensitiveFilter);
    }

    /** Represents a SearchLatestFacetTagRequestV2. */
    class SearchLatestFacetTagRequestV2 implements ISearchLatestFacetTagRequestV2 {

        /**
         * Constructs a new SearchLatestFacetTagRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchLatestFacetTagRequestV2);

        /** SearchLatestFacetTagRequestV2 limit. */
        public limit?: (number);

        /** SearchLatestFacetTagRequestV2 sensitiveFilter. */
        public sensitiveFilter: query_search_v2.SensitiveFilter;

        /**
         * Gets the default type url for SearchLatestFacetTagRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchFacetTagResponseV2. */
    export interface ISearchFacetTagResponseV2 {

        /** SearchFacetTagResponseV2 facetTags */
        facetTags?: (query_search_v2.IFacetTag[]);
    }

    /** Represents a SearchFacetTagResponseV2. */
    class SearchFacetTagResponseV2 implements ISearchFacetTagResponseV2 {

        /**
         * Constructs a new SearchFacetTagResponseV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchFacetTagResponseV2);

        /** SearchFacetTagResponseV2 facetTags. */
        public facetTags: query_search_v2.IFacetTag[];

        /**
         * Gets the default type url for SearchFacetTagResponseV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a FacetTag. */
    export interface IFacetTag {

        /** FacetTag tag */
        tag?: (string);

        /** FacetTag count */
        count?: (number);
    }

    /** Represents a FacetTag. */
    class FacetTag implements IFacetTag {

        /**
         * Constructs a new FacetTag.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.IFacetTag);

        /** FacetTag tag. */
        public tag?: (string);

        /** FacetTag count. */
        public count?: (number);

        /**
         * Gets the default type url for FacetTag
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** OfficialFilter enum. */
    export enum OfficialFilter {
        OFFICIAL_FILTER_UNSPECIFIED = "OFFICIAL_FILTER_UNSPECIFIED",
        OFFICIAL_FILTER_OFFICIAL = "OFFICIAL_FILTER_OFFICIAL",
        OFFICIAL_FILTER_NOT_OFFICIAL = "OFFICIAL_FILTER_NOT_OFFICIAL"
    }

    /** SensitiveFilter enum. */
    export enum SensitiveFilter {
        SENSITIVE_FILTER_UNSPECIFIED = "SENSITIVE_FILTER_UNSPECIFIED",
        SENSITIVE_FILTER_NON_SENSITIVE = "SENSITIVE_FILTER_NON_SENSITIVE"
    }

    /** MatchType enum. */
    export enum MatchType {
        MATCH_TYPE_UNSPECIFIED = "MATCH_TYPE_UNSPECIFIED",
        MATCH_TYPE_PARTIAL = "MATCH_TYPE_PARTIAL",
        MATCH_TYPE_EXACT = "MATCH_TYPE_EXACT"
    }

    /** Properties of a SearchUserRequestV2. */
    export interface ISearchUserRequestV2 {

        /** SearchUserRequestV2 q */
        q?: (string);

        /** SearchUserRequestV2 requireUserPage */
        requireUserPage?: (query_recursive_types.IUserPageRequest);
    }

    /** Represents a SearchUserRequestV2. */
    class SearchUserRequestV2 implements ISearchUserRequestV2 {

        /**
         * Constructs a new SearchUserRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchUserRequestV2);

        /** SearchUserRequestV2 q. */
        public q?: (string);

        /** SearchUserRequestV2 requireUserPage. */
        public requireUserPage?: (query_recursive_types.IUserPageRequest);

        /**
         * Gets the default type url for SearchUserRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchUserByTagsRequestV2. */
    export interface ISearchUserByTagsRequestV2 {

        /** SearchUserByTagsRequestV2 anyTags */
        anyTags?: (string[]);

        /** SearchUserByTagsRequestV2 officialFilter */
        officialFilter?: (query_search_v2.OfficialFilter);

        /** SearchUserByTagsRequestV2 requireUserPage */
        requireUserPage?: (query_recursive_types.IUserPageRequest);
    }

    /** Represents a SearchUserByTagsRequestV2. */
    class SearchUserByTagsRequestV2 implements ISearchUserByTagsRequestV2 {

        /**
         * Constructs a new SearchUserByTagsRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchUserByTagsRequestV2);

        /** SearchUserByTagsRequestV2 anyTags. */
        public anyTags: string[];

        /** SearchUserByTagsRequestV2 officialFilter. */
        public officialFilter: query_search_v2.OfficialFilter;

        /** SearchUserByTagsRequestV2 requireUserPage. */
        public requireUserPage?: (query_recursive_types.IUserPageRequest);

        /**
         * Gets the default type url for SearchUserByTagsRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchLatestWriterRequestV2. */
    export interface ISearchLatestWriterRequestV2 {

        /** SearchLatestWriterRequestV2 requireUserPage */
        requireUserPage?: (query_recursive_types.IUserPageRequest);
    }

    /** Represents a SearchLatestWriterRequestV2. */
    class SearchLatestWriterRequestV2 implements ISearchLatestWriterRequestV2 {

        /**
         * Constructs a new SearchLatestWriterRequestV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchLatestWriterRequestV2);

        /** SearchLatestWriterRequestV2 requireUserPage. */
        public requireUserPage?: (query_recursive_types.IUserPageRequest);

        /**
         * Gets the default type url for SearchLatestWriterRequestV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchUserResponseV2. */
    export interface ISearchUserResponseV2 {

        /** SearchUserResponseV2 userPage */
        userPage?: (query_recursive_types.IUserPageResponse);
    }

    /** Represents a SearchUserResponseV2. */
    class SearchUserResponseV2 implements ISearchUserResponseV2 {

        /**
         * Constructs a new SearchUserResponseV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_search_v2.ISearchUserResponseV2);

        /** SearchUserResponseV2 userPage. */
        public userPage?: (query_recursive_types.IUserPageResponse);

        /**
         * Gets the default type url for SearchUserResponseV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_series_genre_ranking. */
export namespace query_series_genre_ranking {

    /** Properties of a SeriesGenreRankingCursorRequest. */
    export interface ISeriesGenreRankingCursorRequest {

        /** SeriesGenreRankingCursorRequest genre */
        genre?: (query_types.Genre);

        /** SeriesGenreRankingCursorRequest requireSeriesRanking */
        requireSeriesRanking?: (query_series_genre_ranking.ISeriesRankingRequest);

        /** SeriesGenreRankingCursorRequest requireTargetDate */
        requireTargetDate?: (boolean);

        /** SeriesGenreRankingCursorRequest cursor */
        cursor?: (query_pagination.ICursorRequest);
    }

    /** Represents a SeriesGenreRankingCursorRequest. */
    class SeriesGenreRankingCursorRequest implements ISeriesGenreRankingCursorRequest {

        /**
         * Constructs a new SeriesGenreRankingCursorRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_series_genre_ranking.ISeriesGenreRankingCursorRequest);

        /** SeriesGenreRankingCursorRequest genre. */
        public genre: query_types.Genre;

        /** SeriesGenreRankingCursorRequest requireSeriesRanking. */
        public requireSeriesRanking?: (query_series_genre_ranking.ISeriesRankingRequest);

        /** SeriesGenreRankingCursorRequest requireTargetDate. */
        public requireTargetDate?: (boolean);

        /** SeriesGenreRankingCursorRequest cursor. */
        public cursor?: (query_pagination.ICursorRequest);

        /**
         * Gets the default type url for SeriesGenreRankingCursorRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesGenreRankingCursorResponse. */
    export interface ISeriesGenreRankingCursorResponse {

        /** SeriesGenreRankingCursorResponse seriesList */
        seriesList?: (query_series_genre_ranking.ISeriesRankingResponse[]);

        /** SeriesGenreRankingCursorResponse targetDate */
        targetDate?: (string);

        /** SeriesGenreRankingCursorResponse cursor */
        cursor?: (query_pagination.ICursorInfo);
    }

    /** Represents a SeriesGenreRankingCursorResponse. */
    class SeriesGenreRankingCursorResponse implements ISeriesGenreRankingCursorResponse {

        /**
         * Constructs a new SeriesGenreRankingCursorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_series_genre_ranking.ISeriesGenreRankingCursorResponse);

        /** SeriesGenreRankingCursorResponse seriesList. */
        public seriesList: query_series_genre_ranking.ISeriesRankingResponse[];

        /** SeriesGenreRankingCursorResponse targetDate. */
        public targetDate?: (string);

        /** SeriesGenreRankingCursorResponse cursor. */
        public cursor?: (query_pagination.ICursorInfo);

        /**
         * Gets the default type url for SeriesGenreRankingCursorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesRankingRequest. */
    export interface ISeriesRankingRequest {

        /** SeriesRankingRequest requireSeries */
        requireSeries?: (query_recursive_types.ISeriesRequireField);

        /** SeriesRankingRequest requireTrend */
        requireTrend?: (boolean);

        /** SeriesRankingRequest requireRank */
        requireRank?: (boolean);
    }

    /** Represents a SeriesRankingRequest. */
    class SeriesRankingRequest implements ISeriesRankingRequest {

        /**
         * Constructs a new SeriesRankingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_series_genre_ranking.ISeriesRankingRequest);

        /** SeriesRankingRequest requireSeries. */
        public requireSeries?: (query_recursive_types.ISeriesRequireField);

        /** SeriesRankingRequest requireTrend. */
        public requireTrend?: (boolean);

        /** SeriesRankingRequest requireRank. */
        public requireRank?: (boolean);

        /**
         * Gets the default type url for SeriesRankingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesRankingResponse. */
    export interface ISeriesRankingResponse {

        /** SeriesRankingResponse series */
        series?: (query_recursive_types.ISeriesResponse);

        /** SeriesRankingResponse trend */
        trend?: (query_types.Trend);

        /** SeriesRankingResponse rank */
        rank?: (number);
    }

    /** Represents a SeriesRankingResponse. */
    class SeriesRankingResponse implements ISeriesRankingResponse {

        /**
         * Constructs a new SeriesRankingResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_series_genre_ranking.ISeriesRankingResponse);

        /** SeriesRankingResponse series. */
        public series?: (query_recursive_types.ISeriesResponse);

        /** SeriesRankingResponse trend. */
        public trend: query_types.Trend;

        /** SeriesRankingResponse rank. */
        public rank?: (number);

        /**
         * Gets the default type url for SeriesRankingResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query. */
export namespace query {

    /** Properties of a QueryRequest. */
    export interface IQueryRequest {

        /** QueryRequest requestList */
        requestList?: (query.IQueryRequestItem[]);
    }

    /** Represents a QueryRequest. */
    class QueryRequest implements IQueryRequest {

        /**
         * Constructs a new QueryRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query.IQueryRequest);

        /** QueryRequest requestList. */
        public requestList: query.IQueryRequestItem[];

        /**
         * Gets the default type url for QueryRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a QueryRequestItem. */
    export interface IQueryRequestItem {

        /** QueryRequestItem requestId */
        requestId?: (string);

        /** QueryRequestItem meRequest */
        meRequest?: (query_recursive_types.IMeRequest);

        /** QueryRequestItem userRequest */
        userRequest?: (query_recursive_types.IUserRequest);

        /** QueryRequestItem storyRequest */
        storyRequest?: (query_recursive_types.IStoryRequest);

        /** QueryRequestItem seriesRequest */
        seriesRequest?: (query_recursive_types.ISeriesRequest);

        /** QueryRequestItem seriesGroupRequest */
        seriesGroupRequest?: (query_recursive_types.ISeriesGroupRequest);

        /** QueryRequestItem storyAdvertisementRequest */
        storyAdvertisementRequest?: (query_recursive_types.IStoryAdvertisementRequest);

        /** QueryRequestItem commentRequest */
        commentRequest?: (query_recursive_types.ICommentRequest);

        /** QueryRequestItem eventListRequest */
        eventListRequest?: (query_recursive_types.IEventListRequest);

        /** QueryRequestItem tagRankingRequest */
        tagRankingRequest?: (query_tag_list.ITagRankingRequest);

        /** QueryRequestItem tagMedalRequest */
        tagMedalRequest?: (query_recursive_types.ITagMedalCursorRequest);

        /** QueryRequestItem searchQueryRequest */
        searchQueryRequest?: (query_search_query.ISearchQueryRequest);

        /** QueryRequestItem tagRequest */
        tagRequest?: (query_recursive_types.ITagRequest);

        /** QueryRequestItem tagHotListRequest */
        tagHotListRequest?: (query_tag_list.ITagHotListRequest);

        /** QueryRequestItem searchSeriesRequestV2 */
        searchSeriesRequestV2?: (query_search_v2.ISearchSeriesRequestV2);

        /** QueryRequestItem searchSeriesByTitleRequestV2 */
        searchSeriesByTitleRequestV2?: (query_search_v2.ISearchSeriesByTitleRequestV2);

        /** QueryRequestItem searchSeriesByTagRequestV2 */
        searchSeriesByTagRequestV2?: (query_search_v2.ISearchSeriesByTagRequestV2);

        /** QueryRequestItem searchLatestSeriesRequestV2 */
        searchLatestSeriesRequestV2?: (query_search_v2.ISearchLatestSeriesRequestV2);

        /** QueryRequestItem searchCompletedOfficialSeriesRequestV2 */
        searchCompletedOfficialSeriesRequestV2?: (query_search_v2.ISearchCompletedOfficialSeriesRequestV2);

        /** QueryRequestItem searchUncompletedOfficialSeriesRequestV2 */
        searchUncompletedOfficialSeriesRequestV2?: (query_search_v2.ISearchUncompletedOfficialSeriesRequestV2);

        /** QueryRequestItem searchFacetRelatedTagRequestV2 */
        searchFacetRelatedTagRequestV2?: (query_search_v2.ISearchFacetRelatedTagRequestV2);

        /** QueryRequestItem searchFacetTagRequestV2 */
        searchFacetTagRequestV2?: (query_search_v2.ISearchFacetTagRequestV2);

        /** QueryRequestItem searchLatestFacetTagRequestV2 */
        searchLatestFacetTagRequestV2?: (query_search_v2.ISearchLatestFacetTagRequestV2);

        /** QueryRequestItem searchUserRequestV2 */
        searchUserRequestV2?: (query_search_v2.ISearchUserRequestV2);

        /** QueryRequestItem searchUserByTagsRequestV2 */
        searchUserByTagsRequestV2?: (query_search_v2.ISearchUserByTagsRequestV2);

        /** QueryRequestItem searchLatestWriterRequestV2 */
        searchLatestWriterRequestV2?: (query_search_v2.ISearchLatestWriterRequestV2);

        /** QueryRequestItem seriesSelectionRequest */
        seriesSelectionRequest?: (query_recursive_types.ISeriesSelectionRequest);

        /** QueryRequestItem seriesGenreRankingRequest */
        seriesGenreRankingRequest?: (query_series_genre_ranking.ISeriesGenreRankingCursorRequest);

        /** QueryRequestItem storySelectionRequest */
        storySelectionRequest?: (query_story_selection.IStorySelectionRequest);

        /** QueryRequestItem searchStoryRequest */
        searchStoryRequest?: (query_recursive_types.ISearchStoryRequest);

        /** QueryRequestItem searchStoryFacetTagRequest */
        searchStoryFacetTagRequest?: (query_search_types.ISearchStoryFacetTagRequest);

        /** QueryRequestItem searchUserRequest */
        searchUserRequest?: (query_recursive_types.ISearchUserRequest);

        /** QueryRequestItem searchSeriesRequest */
        searchSeriesRequest?: (query_recursive_types.ISearchSeriesRequest);

        /** QueryRequestItem searchSeriesGroupRequest */
        searchSeriesGroupRequest?: (query_recursive_types.ISearchSeriesGroupRequest);

        /** QueryRequestItem storyRankingRequest */
        storyRankingRequest?: (query_story_ranking.IStoryRankingRequest);
    }

    /** Represents a QueryRequestItem. */
    class QueryRequestItem implements IQueryRequestItem {

        /**
         * Constructs a new QueryRequestItem.
         * @param [properties] Properties to set
         */
        constructor(properties?: query.IQueryRequestItem);

        /** QueryRequestItem requestId. */
        public requestId?: (string);

        /** QueryRequestItem meRequest. */
        public meRequest?: (query_recursive_types.IMeRequest);

        /** QueryRequestItem userRequest. */
        public userRequest?: (query_recursive_types.IUserRequest);

        /** QueryRequestItem storyRequest. */
        public storyRequest?: (query_recursive_types.IStoryRequest);

        /** QueryRequestItem seriesRequest. */
        public seriesRequest?: (query_recursive_types.ISeriesRequest);

        /** QueryRequestItem seriesGroupRequest. */
        public seriesGroupRequest?: (query_recursive_types.ISeriesGroupRequest);

        /** QueryRequestItem storyAdvertisementRequest. */
        public storyAdvertisementRequest?: (query_recursive_types.IStoryAdvertisementRequest);

        /** QueryRequestItem commentRequest. */
        public commentRequest?: (query_recursive_types.ICommentRequest);

        /** QueryRequestItem eventListRequest. */
        public eventListRequest?: (query_recursive_types.IEventListRequest);

        /** QueryRequestItem tagRankingRequest. */
        public tagRankingRequest?: (query_tag_list.ITagRankingRequest);

        /** QueryRequestItem tagMedalRequest. */
        public tagMedalRequest?: (query_recursive_types.ITagMedalCursorRequest);

        /** QueryRequestItem searchQueryRequest. */
        public searchQueryRequest?: (query_search_query.ISearchQueryRequest);

        /** QueryRequestItem tagRequest. */
        public tagRequest?: (query_recursive_types.ITagRequest);

        /** QueryRequestItem tagHotListRequest. */
        public tagHotListRequest?: (query_tag_list.ITagHotListRequest);

        /** QueryRequestItem searchSeriesRequestV2. */
        public searchSeriesRequestV2?: (query_search_v2.ISearchSeriesRequestV2);

        /** QueryRequestItem searchSeriesByTitleRequestV2. */
        public searchSeriesByTitleRequestV2?: (query_search_v2.ISearchSeriesByTitleRequestV2);

        /** QueryRequestItem searchSeriesByTagRequestV2. */
        public searchSeriesByTagRequestV2?: (query_search_v2.ISearchSeriesByTagRequestV2);

        /** QueryRequestItem searchLatestSeriesRequestV2. */
        public searchLatestSeriesRequestV2?: (query_search_v2.ISearchLatestSeriesRequestV2);

        /** QueryRequestItem searchCompletedOfficialSeriesRequestV2. */
        public searchCompletedOfficialSeriesRequestV2?: (query_search_v2.ISearchCompletedOfficialSeriesRequestV2);

        /** QueryRequestItem searchUncompletedOfficialSeriesRequestV2. */
        public searchUncompletedOfficialSeriesRequestV2?: (query_search_v2.ISearchUncompletedOfficialSeriesRequestV2);

        /** QueryRequestItem searchFacetRelatedTagRequestV2. */
        public searchFacetRelatedTagRequestV2?: (query_search_v2.ISearchFacetRelatedTagRequestV2);

        /** QueryRequestItem searchFacetTagRequestV2. */
        public searchFacetTagRequestV2?: (query_search_v2.ISearchFacetTagRequestV2);

        /** QueryRequestItem searchLatestFacetTagRequestV2. */
        public searchLatestFacetTagRequestV2?: (query_search_v2.ISearchLatestFacetTagRequestV2);

        /** QueryRequestItem searchUserRequestV2. */
        public searchUserRequestV2?: (query_search_v2.ISearchUserRequestV2);

        /** QueryRequestItem searchUserByTagsRequestV2. */
        public searchUserByTagsRequestV2?: (query_search_v2.ISearchUserByTagsRequestV2);

        /** QueryRequestItem searchLatestWriterRequestV2. */
        public searchLatestWriterRequestV2?: (query_search_v2.ISearchLatestWriterRequestV2);

        /** QueryRequestItem seriesSelectionRequest. */
        public seriesSelectionRequest?: (query_recursive_types.ISeriesSelectionRequest);

        /** QueryRequestItem seriesGenreRankingRequest. */
        public seriesGenreRankingRequest?: (query_series_genre_ranking.ISeriesGenreRankingCursorRequest);

        /** QueryRequestItem storySelectionRequest. */
        public storySelectionRequest?: (query_story_selection.IStorySelectionRequest);

        /** QueryRequestItem searchStoryRequest. */
        public searchStoryRequest?: (query_recursive_types.ISearchStoryRequest);

        /** QueryRequestItem searchStoryFacetTagRequest. */
        public searchStoryFacetTagRequest?: (query_search_types.ISearchStoryFacetTagRequest);

        /** QueryRequestItem searchUserRequest. */
        public searchUserRequest?: (query_recursive_types.ISearchUserRequest);

        /** QueryRequestItem searchSeriesRequest. */
        public searchSeriesRequest?: (query_recursive_types.ISearchSeriesRequest);

        /** QueryRequestItem searchSeriesGroupRequest. */
        public searchSeriesGroupRequest?: (query_recursive_types.ISearchSeriesGroupRequest);

        /** QueryRequestItem storyRankingRequest. */
        public storyRankingRequest?: (query_story_ranking.IStoryRankingRequest);

        /** QueryRequestItem request. */
        public request?: ("meRequest"|"userRequest"|"storyRequest"|"seriesRequest"|"seriesGroupRequest"|"storyAdvertisementRequest"|"commentRequest"|"eventListRequest"|"tagRankingRequest"|"tagMedalRequest"|"searchQueryRequest"|"tagRequest"|"tagHotListRequest"|"searchSeriesRequestV2"|"searchSeriesByTitleRequestV2"|"searchSeriesByTagRequestV2"|"searchLatestSeriesRequestV2"|"searchCompletedOfficialSeriesRequestV2"|"searchUncompletedOfficialSeriesRequestV2"|"searchFacetRelatedTagRequestV2"|"searchFacetTagRequestV2"|"searchLatestFacetTagRequestV2"|"searchUserRequestV2"|"searchUserByTagsRequestV2"|"searchLatestWriterRequestV2"|"seriesSelectionRequest"|"seriesGenreRankingRequest"|"storySelectionRequest"|"searchStoryRequest"|"searchStoryFacetTagRequest"|"searchUserRequest"|"searchSeriesRequest"|"searchSeriesGroupRequest"|"storyRankingRequest");

        /**
         * Gets the default type url for QueryRequestItem
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a QueryResponse. */
    export interface IQueryResponse {

        /** QueryResponse responseList */
        responseList?: (query.IQueryResponseItem[]);
    }

    /** Represents a QueryResponse. */
    class QueryResponse implements IQueryResponse {

        /**
         * Constructs a new QueryResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query.IQueryResponse);

        /** QueryResponse responseList. */
        public responseList: query.IQueryResponseItem[];

        /**
         * Gets the default type url for QueryResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a QueryResponseItem. */
    export interface IQueryResponseItem {

        /** QueryResponseItem requestId */
        requestId?: (string);

        /** QueryResponseItem error */
        error?: (query.IErrorResponse);

        /** QueryResponseItem me */
        me?: (query_recursive_types.IMeResponse);

        /** QueryResponseItem user */
        user?: (query_recursive_types.IUserResponse);

        /** QueryResponseItem story */
        story?: (query_recursive_types.IStoryResponse);

        /** QueryResponseItem series */
        series?: (query_recursive_types.ISeriesResponse);

        /** QueryResponseItem seriesGroup */
        seriesGroup?: (query_recursive_types.ISeriesGroupResponse);

        /** QueryResponseItem storySelection */
        storySelection?: (query_story_selection.IStorySelectionResponse);

        /** QueryResponseItem storyAdvertisement */
        storyAdvertisement?: (query_recursive_types.IStoryAdvertisementResponse);

        /** QueryResponseItem comment */
        comment?: (query_recursive_types.ICommentResponse);

        /** QueryResponseItem searchStory */
        searchStory?: (query_recursive_types.ISearchStoryResponse);

        /** QueryResponseItem searchStoryFacetTag */
        searchStoryFacetTag?: (query_search_types.ISearchStoryFacetResponse);

        /** QueryResponseItem searchUser */
        searchUser?: (query_recursive_types.ISearchUserResponse);

        /** QueryResponseItem searchSeries */
        searchSeries?: (query_recursive_types.ISearchSeriesResponse);

        /** QueryResponseItem searchSeriesGroup */
        searchSeriesGroup?: (query_recursive_types.ISearchSeriesGroupResponse);

        /** QueryResponseItem eventList */
        eventList?: (query_recursive_types.IEventListResponse);

        /** QueryResponseItem tagRanking */
        tagRanking?: (query_tag_list.ITagRankingResponse);

        /** QueryResponseItem tagMedal */
        tagMedal?: (query_recursive_types.ITagMedalCursorResponse);

        /** QueryResponseItem storyRanking */
        storyRanking?: (query_story_ranking.IStoryRankingResponse);

        /** QueryResponseItem searchQuery */
        searchQuery?: (query_search_query.ISearchQueryResponse);

        /** QueryResponseItem tag */
        tag?: (query_recursive_types.ITagResponse);

        /** QueryResponseItem tagHotList */
        tagHotList?: (query_tag_list.ITagHotListResponse);

        /** QueryResponseItem searchSeriesV2 */
        searchSeriesV2?: (query_search_v2.ISearchSeriesResponseV2);

        /** QueryResponseItem searchFacetTagV2 */
        searchFacetTagV2?: (query_search_v2.ISearchFacetTagResponseV2);

        /** QueryResponseItem searchUserV2 */
        searchUserV2?: (query_search_v2.ISearchUserResponseV2);

        /** QueryResponseItem seriesSelection */
        seriesSelection?: (query_recursive_types.ISeriesSelectionResponse);

        /** QueryResponseItem seriesGenreRanking */
        seriesGenreRanking?: (query_series_genre_ranking.ISeriesGenreRankingCursorResponse);
    }

    /** Represents a QueryResponseItem. */
    class QueryResponseItem implements IQueryResponseItem {

        /**
         * Constructs a new QueryResponseItem.
         * @param [properties] Properties to set
         */
        constructor(properties?: query.IQueryResponseItem);

        /** QueryResponseItem requestId. */
        public requestId?: (string);

        /** QueryResponseItem error. */
        public error?: (query.IErrorResponse);

        /** QueryResponseItem me. */
        public me?: (query_recursive_types.IMeResponse);

        /** QueryResponseItem user. */
        public user?: (query_recursive_types.IUserResponse);

        /** QueryResponseItem story. */
        public story?: (query_recursive_types.IStoryResponse);

        /** QueryResponseItem series. */
        public series?: (query_recursive_types.ISeriesResponse);

        /** QueryResponseItem seriesGroup. */
        public seriesGroup?: (query_recursive_types.ISeriesGroupResponse);

        /** QueryResponseItem storySelection. */
        public storySelection?: (query_story_selection.IStorySelectionResponse);

        /** QueryResponseItem storyAdvertisement. */
        public storyAdvertisement?: (query_recursive_types.IStoryAdvertisementResponse);

        /** QueryResponseItem comment. */
        public comment?: (query_recursive_types.ICommentResponse);

        /** QueryResponseItem searchStory. */
        public searchStory?: (query_recursive_types.ISearchStoryResponse);

        /** QueryResponseItem searchStoryFacetTag. */
        public searchStoryFacetTag?: (query_search_types.ISearchStoryFacetResponse);

        /** QueryResponseItem searchUser. */
        public searchUser?: (query_recursive_types.ISearchUserResponse);

        /** QueryResponseItem searchSeries. */
        public searchSeries?: (query_recursive_types.ISearchSeriesResponse);

        /** QueryResponseItem searchSeriesGroup. */
        public searchSeriesGroup?: (query_recursive_types.ISearchSeriesGroupResponse);

        /** QueryResponseItem eventList. */
        public eventList?: (query_recursive_types.IEventListResponse);

        /** QueryResponseItem tagRanking. */
        public tagRanking?: (query_tag_list.ITagRankingResponse);

        /** QueryResponseItem tagMedal. */
        public tagMedal?: (query_recursive_types.ITagMedalCursorResponse);

        /** QueryResponseItem storyRanking. */
        public storyRanking?: (query_story_ranking.IStoryRankingResponse);

        /** QueryResponseItem searchQuery. */
        public searchQuery?: (query_search_query.ISearchQueryResponse);

        /** QueryResponseItem tag. */
        public tag?: (query_recursive_types.ITagResponse);

        /** QueryResponseItem tagHotList. */
        public tagHotList?: (query_tag_list.ITagHotListResponse);

        /** QueryResponseItem searchSeriesV2. */
        public searchSeriesV2?: (query_search_v2.ISearchSeriesResponseV2);

        /** QueryResponseItem searchFacetTagV2. */
        public searchFacetTagV2?: (query_search_v2.ISearchFacetTagResponseV2);

        /** QueryResponseItem searchUserV2. */
        public searchUserV2?: (query_search_v2.ISearchUserResponseV2);

        /** QueryResponseItem seriesSelection. */
        public seriesSelection?: (query_recursive_types.ISeriesSelectionResponse);

        /** QueryResponseItem seriesGenreRanking. */
        public seriesGenreRanking?: (query_series_genre_ranking.ISeriesGenreRankingCursorResponse);

        /** QueryResponseItem response. */
        public response?: ("error"|"me"|"user"|"story"|"series"|"seriesGroup"|"storySelection"|"storyAdvertisement"|"comment"|"searchStory"|"searchStoryFacetTag"|"searchUser"|"searchSeries"|"searchSeriesGroup"|"eventList"|"tagRanking"|"tagMedal"|"storyRanking"|"searchQuery"|"tag"|"tagHotList"|"searchSeriesV2"|"searchFacetTagV2"|"searchUserV2"|"seriesSelection"|"seriesGenreRanking");

        /**
         * Gets the default type url for QueryResponseItem
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of an ErrorResponse. */
    export interface IErrorResponse {

        /** ErrorResponse status */
        status?: (number);

        /** ErrorResponse message */
        message?: (string);
    }

    /** Represents an ErrorResponse. */
    class ErrorResponse implements IErrorResponse {

        /**
         * Constructs a new ErrorResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query.IErrorResponse);

        /** ErrorResponse status. */
        public status?: (number);

        /** ErrorResponse message. */
        public message?: (string);

        /**
         * Gets the default type url for ErrorResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_story_selection. */
export namespace query_story_selection {

    /** StorySelectionShuffling enum. */
    export enum StorySelectionShuffling {
        STORY_SELECTION_SHUFFLING_UNSPECIFIED = "STORY_SELECTION_SHUFFLING_UNSPECIFIED",
        STORY_SELECTION_SHUFFLING_EVERYTIME = "STORY_SELECTION_SHUFFLING_EVERYTIME",
        STORY_SELECTION_SHUFFLING_DAILY = "STORY_SELECTION_SHUFFLING_DAILY"
    }

    /** Properties of a StorySelectionRequest. */
    export interface IStorySelectionRequest {

        /** StorySelectionRequest id */
        id?: (string);

        /** StorySelectionRequest requireStorySelection */
        requireStorySelection?: (query_story_selection.IStorySelectionRequireField);

        /** StorySelectionRequest applyShuffling */
        applyShuffling?: (query_story_selection.StorySelectionShuffling);
    }

    /** Represents a StorySelectionRequest. */
    class StorySelectionRequest implements IStorySelectionRequest {

        /**
         * Constructs a new StorySelectionRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_story_selection.IStorySelectionRequest);

        /** StorySelectionRequest id. */
        public id?: (string);

        /** StorySelectionRequest requireStorySelection. */
        public requireStorySelection?: (query_story_selection.IStorySelectionRequireField);

        /** StorySelectionRequest applyShuffling. */
        public applyShuffling: query_story_selection.StorySelectionShuffling;

        /**
         * Gets the default type url for StorySelectionRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionRequireField. */
    export interface IStorySelectionRequireField {

        /** StorySelectionRequireField requireId */
        requireId?: (boolean);

        /** StorySelectionRequireField requireTitle */
        requireTitle?: (boolean);

        /** StorySelectionRequireField requireDescription */
        requireDescription?: (boolean);

        /** StorySelectionRequireField requireThumbnail */
        requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** StorySelectionRequireField requireCreatedAt */
        requireCreatedAt?: (boolean);

        /** StorySelectionRequireField requireUpdatedAt */
        requireUpdatedAt?: (boolean);

        /** StorySelectionRequireField requireSearchStoryPage */
        requireSearchStoryPage?: (query_recursive_types.ISearchStoryRequest);

        /** StorySelectionRequireField requireStoryPage */
        requireStoryPage?: (query_recursive_types.IStoryCursorRequest);
    }

    /** Represents a StorySelectionRequireField. */
    class StorySelectionRequireField implements IStorySelectionRequireField {

        /**
         * Constructs a new StorySelectionRequireField.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_story_selection.IStorySelectionRequireField);

        /** StorySelectionRequireField requireId. */
        public requireId?: (boolean);

        /** StorySelectionRequireField requireTitle. */
        public requireTitle?: (boolean);

        /** StorySelectionRequireField requireDescription. */
        public requireDescription?: (boolean);

        /** StorySelectionRequireField requireThumbnail. */
        public requireThumbnail?: (query_recursive_types.IImageRequireField);

        /** StorySelectionRequireField requireCreatedAt. */
        public requireCreatedAt?: (boolean);

        /** StorySelectionRequireField requireUpdatedAt. */
        public requireUpdatedAt?: (boolean);

        /** StorySelectionRequireField requireSearchStoryPage. */
        public requireSearchStoryPage?: (query_recursive_types.ISearchStoryRequest);

        /** StorySelectionRequireField requireStoryPage. */
        public requireStoryPage?: (query_recursive_types.IStoryCursorRequest);

        /**
         * Gets the default type url for StorySelectionRequireField
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StorySelectionResponse. */
    export interface IStorySelectionResponse {

        /** StorySelectionResponse id */
        id?: (string);

        /** StorySelectionResponse title */
        title?: (string);

        /** StorySelectionResponse description */
        description?: (string);

        /** StorySelectionResponse thumbnail */
        thumbnail?: (query_recursive_types.IImageResponse);

        /** StorySelectionResponse createdAt */
        createdAt?: (string);

        /** StorySelectionResponse updatedAt */
        updatedAt?: (string);

        /** StorySelectionResponse searchStoryPage */
        searchStoryPage?: (query_recursive_types.ISearchStoryResponse);

        /** StorySelectionResponse storyPage */
        storyPage?: (query_recursive_types.IStoryCursorResponse);
    }

    /** Represents a StorySelectionResponse. */
    class StorySelectionResponse implements IStorySelectionResponse {

        /**
         * Constructs a new StorySelectionResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_story_selection.IStorySelectionResponse);

        /** StorySelectionResponse id. */
        public id?: (string);

        /** StorySelectionResponse title. */
        public title?: (string);

        /** StorySelectionResponse description. */
        public description?: (string);

        /** StorySelectionResponse thumbnail. */
        public thumbnail?: (query_recursive_types.IImageResponse);

        /** StorySelectionResponse createdAt. */
        public createdAt?: (string);

        /** StorySelectionResponse updatedAt. */
        public updatedAt?: (string);

        /** StorySelectionResponse searchStoryPage. */
        public searchStoryPage?: (query_recursive_types.ISearchStoryResponse);

        /** StorySelectionResponse storyPage. */
        public storyPage?: (query_recursive_types.IStoryCursorResponse);

        /**
         * Gets the default type url for StorySelectionResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_tag_list. */
export namespace query_tag_list {

    /** Properties of a TagRankingRequest. */
    export interface ITagRankingRequest {

        /** TagRankingRequest requireNotOfficialPopularList */
        requireNotOfficialPopularList?: (boolean);
    }

    /** Represents a TagRankingRequest. */
    class TagRankingRequest implements ITagRankingRequest {

        /**
         * Constructs a new TagRankingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_tag_list.ITagRankingRequest);

        /** TagRankingRequest requireNotOfficialPopularList. */
        public requireNotOfficialPopularList?: (boolean);

        /**
         * Gets the default type url for TagRankingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagRankingResponse. */
    export interface ITagRankingResponse {

        /** TagRankingResponse notOfficialPopularList */
        notOfficialPopularList?: (string[]);
    }

    /** Represents a TagRankingResponse. */
    class TagRankingResponse implements ITagRankingResponse {

        /**
         * Constructs a new TagRankingResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_tag_list.ITagRankingResponse);

        /** TagRankingResponse notOfficialPopularList. */
        public notOfficialPopularList: string[];

        /**
         * Gets the default type url for TagRankingResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagHotListRequest. */
    export interface ITagHotListRequest {

        /** TagHotListRequest includeEvent */
        includeEvent?: (boolean);

        /** TagHotListRequest requireTagHotList */
        requireTagHotList?: (boolean);
    }

    /** Represents a TagHotListRequest. */
    class TagHotListRequest implements ITagHotListRequest {

        /**
         * Constructs a new TagHotListRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_tag_list.ITagHotListRequest);

        /** TagHotListRequest includeEvent. */
        public includeEvent?: (boolean);

        /** TagHotListRequest requireTagHotList. */
        public requireTagHotList?: (boolean);

        /**
         * Gets the default type url for TagHotListRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagHotListResponse. */
    export interface ITagHotListResponse {

        /** TagHotListResponse tagHotList */
        tagHotList?: (string[]);
    }

    /** Represents a TagHotListResponse. */
    class TagHotListResponse implements ITagHotListResponse {

        /**
         * Constructs a new TagHotListResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_tag_list.ITagHotListResponse);

        /** TagHotListResponse tagHotList. */
        public tagHotList: string[];

        /**
         * Gets the default type url for TagHotListResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace query_story_ranking. */
export namespace query_story_ranking {

    /** Properties of a StoryRankingRequest. */
    export interface IStoryRankingRequest {

        /** StoryRankingRequest requireSearchStoryPage */
        requireSearchStoryPage?: (query_recursive_types.ISearchStoryRequest);
    }

    /** Represents a StoryRankingRequest. */
    class StoryRankingRequest implements IStoryRankingRequest {

        /**
         * Constructs a new StoryRankingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_story_ranking.IStoryRankingRequest);

        /** StoryRankingRequest requireSearchStoryPage. */
        public requireSearchStoryPage?: (query_recursive_types.ISearchStoryRequest);

        /**
         * Gets the default type url for StoryRankingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryRankingResponse. */
    export interface IStoryRankingResponse {

        /** StoryRankingResponse searchStoryPage */
        searchStoryPage?: (query_recursive_types.ISearchStoryResponse);
    }

    /** Represents a StoryRankingResponse. */
    class StoryRankingResponse implements IStoryRankingResponse {

        /**
         * Constructs a new StoryRankingResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: query_story_ranking.IStoryRankingResponse);

        /** StoryRankingResponse searchStoryPage. */
        public searchStoryPage?: (query_recursive_types.ISearchStoryResponse);

        /**
         * Gets the default type url for StoryRankingResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace ranking. */
export namespace ranking {

    /** RankingType enum. */
    export enum RankingType {
        RANKING_TYPE_UNKNOWN = "RANKING_TYPE_UNKNOWN",
        OFFICIAL = "OFFICIAL",
        NON_OFFICIAL = "NON_OFFICIAL",
        BOTH = "BOTH"
    }

    /** Properties of a RankingGetRequest. */
    export interface IRankingGetRequest {

        /** RankingGetRequest rankingType */
        rankingType?: (ranking.RankingType);
    }

    /** Represents a RankingGetRequest. */
    class RankingGetRequest implements IRankingGetRequest {

        /**
         * Constructs a new RankingGetRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: ranking.IRankingGetRequest);

        /** RankingGetRequest rankingType. */
        public rankingType: ranking.RankingType;

        /**
         * Gets the default type url for RankingGetRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a Ranking. */
    export interface IRanking {

        /** Ranking header */
        header?: (story.IStoryHeaderV1);

        /** Ranking rank */
        rank?: (number);
    }

    /** Represents a Ranking. */
    class Ranking implements IRanking {

        /**
         * Constructs a new Ranking.
         * @param [properties] Properties to set
         */
        constructor(properties?: ranking.IRanking);

        /** Ranking header. */
        public header?: (story.IStoryHeaderV1);

        /** Ranking rank. */
        public rank?: (number);

        /**
         * Gets the default type url for Ranking
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RankingGetResponse. */
    export interface IRankingGetResponse {

        /** RankingGetResponse rankingList */
        rankingList?: (ranking.IRanking[]);
    }

    /** Represents a RankingGetResponse. */
    class RankingGetResponse implements IRankingGetResponse {

        /**
         * Constructs a new RankingGetResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: ranking.IRankingGetResponse);

        /** RankingGetResponse rankingList. */
        public rankingList: ranking.IRanking[];

        /**
         * Gets the default type url for RankingGetResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace recommendation. */
export namespace recommendation {

    /** RecommendationStoryFilter enum. */
    export enum RecommendationStoryFilter {
        RECOMMEND_STORY_FILTER_UNKNOWN = "RECOMMEND_STORY_FILTER_UNKNOWN",
        RECOMMEND_STORY_FILTER_OFFICIAL = "RECOMMEND_STORY_FILTER_OFFICIAL",
        RECOMMEND_STORY_FILTER_OFFICIAL_SERIES = "RECOMMEND_STORY_FILTER_OFFICIAL_SERIES",
        RECOMMEND_STORY_FILTER_OFFICIAL_ONESHOT = "RECOMMEND_STORY_FILTER_OFFICIAL_ONESHOT",
        RECOMMEND_STORY_FILTER_NON_OFFICIAL = "RECOMMEND_STORY_FILTER_NON_OFFICIAL",
        RECOMMEND_STORY_FILTER_PROFILING = "RECOMMEND_STORY_FILTER_PROFILING"
    }

    /** RecommendationUserType enum. */
    export enum RecommendationUserType {
        RECOMMEND_USER_TYPE_UNSPECIFIED = "RECOMMEND_USER_TYPE_UNSPECIFIED",
        RECOMMEND_USER_TYPE_OFFICIAL = "RECOMMEND_USER_TYPE_OFFICIAL",
        RECOMMEND_USER_TYPE_NON_OFFICIAL = "RECOMMEND_USER_TYPE_NON_OFFICIAL",
        RECOMMEND_USER_TYPE_BOTH = "RECOMMEND_USER_TYPE_BOTH"
    }

    /** Properties of a RecommendationStoryIdPagingRequest. */
    export interface IRecommendationStoryIdPagingRequest {

        /** RecommendationStoryIdPagingRequest storyId */
        storyId?: (string);

        /** RecommendationStoryIdPagingRequest filter */
        filter?: (recommendation.RecommendationStoryFilter);

        /** RecommendationStoryIdPagingRequest limit */
        limit?: (number);

        /** RecommendationStoryIdPagingRequest cursor */
        cursor?: (string);
    }

    /** Represents a RecommendationStoryIdPagingRequest. */
    class RecommendationStoryIdPagingRequest implements IRecommendationStoryIdPagingRequest {

        /**
         * Constructs a new RecommendationStoryIdPagingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: recommendation.IRecommendationStoryIdPagingRequest);

        /** RecommendationStoryIdPagingRequest storyId. */
        public storyId?: (string);

        /** RecommendationStoryIdPagingRequest filter. */
        public filter: recommendation.RecommendationStoryFilter;

        /** RecommendationStoryIdPagingRequest limit. */
        public limit?: (number);

        /** RecommendationStoryIdPagingRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for RecommendationStoryIdPagingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RecommendationUserIdPagingRequest. */
    export interface IRecommendationUserIdPagingRequest {

        /** RecommendationUserIdPagingRequest userId */
        userId?: (string);

        /** RecommendationUserIdPagingRequest filter */
        filter?: (recommendation.RecommendationStoryFilter);

        /** RecommendationUserIdPagingRequest limit */
        limit?: (number);

        /** RecommendationUserIdPagingRequest cursor */
        cursor?: (string);
    }

    /** Represents a RecommendationUserIdPagingRequest. */
    class RecommendationUserIdPagingRequest implements IRecommendationUserIdPagingRequest {

        /**
         * Constructs a new RecommendationUserIdPagingRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: recommendation.IRecommendationUserIdPagingRequest);

        /** RecommendationUserIdPagingRequest userId. */
        public userId?: (string);

        /** RecommendationUserIdPagingRequest filter. */
        public filter: recommendation.RecommendationStoryFilter;

        /** RecommendationUserIdPagingRequest limit. */
        public limit?: (number);

        /** RecommendationUserIdPagingRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for RecommendationUserIdPagingRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a RecommendationUserTypeRequest. */
    export interface IRecommendationUserTypeRequest {

        /** RecommendationUserTypeRequest userType */
        userType?: (recommendation.RecommendationUserType);
    }

    /** Represents a RecommendationUserTypeRequest. */
    class RecommendationUserTypeRequest implements IRecommendationUserTypeRequest {

        /**
         * Constructs a new RecommendationUserTypeRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: recommendation.IRecommendationUserTypeRequest);

        /** RecommendationUserTypeRequest userType. */
        public userType: recommendation.RecommendationUserType;

        /**
         * Gets the default type url for RecommendationUserTypeRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace search. */
export namespace search {

    /** Properties of a SearchIssueAPIKeyRequest. */
    export interface ISearchIssueAPIKeyRequest {
    }

    /** Represents a SearchIssueAPIKeyRequest. */
    class SearchIssueAPIKeyRequest implements ISearchIssueAPIKeyRequest {

        /**
         * Constructs a new SearchIssueAPIKeyRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.ISearchIssueAPIKeyRequest);

        /**
         * Gets the default type url for SearchIssueAPIKeyRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchIssueAPIKeyResponse. */
    export interface ISearchIssueAPIKeyResponse {

        /** SearchIssueAPIKeyResponse appId */
        appId?: (string);

        /** SearchIssueAPIKeyResponse indices */
        indices?: (search.ISearchIndicesName);

        /** SearchIssueAPIKeyResponse publicApiKey */
        publicApiKey?: (string);

        /** SearchIssueAPIKeyResponse privateApiKey */
        privateApiKey?: (string);

        /** SearchIssueAPIKeyResponse expiresAt */
        expiresAt?: (string);
    }

    /** Represents a SearchIssueAPIKeyResponse. */
    class SearchIssueAPIKeyResponse implements ISearchIssueAPIKeyResponse {

        /**
         * Constructs a new SearchIssueAPIKeyResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.ISearchIssueAPIKeyResponse);

        /** SearchIssueAPIKeyResponse appId. */
        public appId?: (string);

        /** SearchIssueAPIKeyResponse indices. */
        public indices?: (search.ISearchIndicesName);

        /** SearchIssueAPIKeyResponse publicApiKey. */
        public publicApiKey?: (string);

        /** SearchIssueAPIKeyResponse privateApiKey. */
        public privateApiKey?: (string);

        /** SearchIssueAPIKeyResponse expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for SearchIssueAPIKeyResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchIndicesName. */
    export interface ISearchIndicesName {

        /** SearchIndicesName storyPublishedAtAsc */
        storyPublishedAtAsc?: (string);

        /** SearchIndicesName storyPublishedAtDesc */
        storyPublishedAtDesc?: (string);
    }

    /** Represents a SearchIndicesName. */
    class SearchIndicesName implements ISearchIndicesName {

        /**
         * Constructs a new SearchIndicesName.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.ISearchIndicesName);

        /** SearchIndicesName storyPublishedAtAsc. */
        public storyPublishedAtAsc?: (string);

        /** SearchIndicesName storyPublishedAtDesc. */
        public storyPublishedAtDesc?: (string);

        /**
         * Gets the default type url for SearchIndicesName
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchAPIKey. */
    export interface ISearchAPIKey {

        /** SearchAPIKey publicApiKey */
        publicApiKey?: (string);

        /** SearchAPIKey privateApiKey */
        privateApiKey?: (string);

        /** SearchAPIKey expiresAt */
        expiresAt?: (string);
    }

    /** Represents a SearchAPIKey. */
    class SearchAPIKey implements ISearchAPIKey {

        /**
         * Constructs a new SearchAPIKey.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.ISearchAPIKey);

        /** SearchAPIKey publicApiKey. */
        public publicApiKey?: (string);

        /** SearchAPIKey privateApiKey. */
        public privateApiKey?: (string);

        /** SearchAPIKey expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for SearchAPIKey
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAPIKey. */
    export interface IStoryAPIKey {

        /** StoryAPIKey key */
        key?: (search.ISearchAPIKey);

        /** StoryAPIKey indices */
        indices?: (search.StoryAPIKey.IStoryIndices);
    }

    /** Represents a StoryAPIKey. */
    class StoryAPIKey implements IStoryAPIKey {

        /**
         * Constructs a new StoryAPIKey.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.IStoryAPIKey);

        /** StoryAPIKey key. */
        public key?: (search.ISearchAPIKey);

        /** StoryAPIKey indices. */
        public indices?: (search.StoryAPIKey.IStoryIndices);

        /**
         * Gets the default type url for StoryAPIKey
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace StoryAPIKey {

        /** Properties of a StoryIndices. */
        export interface IStoryIndices {

            /** StoryIndices storyMaster */
            storyMaster?: (string);

            /** StoryIndices storyPublishedAtAsc */
            storyPublishedAtAsc?: (string);

            /** StoryIndices storyPublishedAtDesc */
            storyPublishedAtDesc?: (string);

            /** StoryIndices storyUpdatedAtDesc */
            storyUpdatedAtDesc?: (string);
        }

        /** Represents a StoryIndices. */
        class StoryIndices implements IStoryIndices {

            /**
             * Constructs a new StoryIndices.
             * @param [properties] Properties to set
             */
            constructor(properties?: search.StoryAPIKey.IStoryIndices);

            /** StoryIndices storyMaster. */
            public storyMaster?: (string);

            /** StoryIndices storyPublishedAtAsc. */
            public storyPublishedAtAsc?: (string);

            /** StoryIndices storyPublishedAtDesc. */
            public storyPublishedAtDesc?: (string);

            /** StoryIndices storyUpdatedAtDesc. */
            public storyUpdatedAtDesc?: (string);

            /**
             * Gets the default type url for StoryIndices
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a SeriesAPIKey. */
    export interface ISeriesAPIKey {

        /** SeriesAPIKey key */
        key?: (search.ISearchAPIKey);

        /** SeriesAPIKey indices */
        indices?: (search.SeriesAPIKey.ISeriesIndices);
    }

    /** Represents a SeriesAPIKey. */
    class SeriesAPIKey implements ISeriesAPIKey {

        /**
         * Constructs a new SeriesAPIKey.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.ISeriesAPIKey);

        /** SeriesAPIKey key. */
        public key?: (search.ISearchAPIKey);

        /** SeriesAPIKey indices. */
        public indices?: (search.SeriesAPIKey.ISeriesIndices);

        /**
         * Gets the default type url for SeriesAPIKey
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace SeriesAPIKey {

        /** Properties of a SeriesIndices. */
        export interface ISeriesIndices {

            /** SeriesIndices seriesMaster */
            seriesMaster?: (string);

            /** SeriesIndices seriesLatestPublishedAtAsc */
            seriesLatestPublishedAtAsc?: (string);

            /** SeriesIndices seriesLatestPublishedAtDesc */
            seriesLatestPublishedAtDesc?: (string);
        }

        /** Represents a SeriesIndices. */
        class SeriesIndices implements ISeriesIndices {

            /**
             * Constructs a new SeriesIndices.
             * @param [properties] Properties to set
             */
            constructor(properties?: search.SeriesAPIKey.ISeriesIndices);

            /** SeriesIndices seriesMaster. */
            public seriesMaster?: (string);

            /** SeriesIndices seriesLatestPublishedAtAsc. */
            public seriesLatestPublishedAtAsc?: (string);

            /** SeriesIndices seriesLatestPublishedAtDesc. */
            public seriesLatestPublishedAtDesc?: (string);

            /**
             * Gets the default type url for SeriesIndices
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a SeriesGroupAPIKey. */
    export interface ISeriesGroupAPIKey {

        /** SeriesGroupAPIKey key */
        key?: (search.ISearchAPIKey);

        /** SeriesGroupAPIKey indices */
        indices?: (search.SeriesGroupAPIKey.ISeriesGroupIndices);
    }

    /** Represents a SeriesGroupAPIKey. */
    class SeriesGroupAPIKey implements ISeriesGroupAPIKey {

        /**
         * Constructs a new SeriesGroupAPIKey.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.ISeriesGroupAPIKey);

        /** SeriesGroupAPIKey key. */
        public key?: (search.ISearchAPIKey);

        /** SeriesGroupAPIKey indices. */
        public indices?: (search.SeriesGroupAPIKey.ISeriesGroupIndices);

        /**
         * Gets the default type url for SeriesGroupAPIKey
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace SeriesGroupAPIKey {

        /** Properties of a SeriesGroupIndices. */
        export interface ISeriesGroupIndices {

            /** SeriesGroupIndices seriesGroupMaster */
            seriesGroupMaster?: (string);
        }

        /** Represents a SeriesGroupIndices. */
        class SeriesGroupIndices implements ISeriesGroupIndices {

            /**
             * Constructs a new SeriesGroupIndices.
             * @param [properties] Properties to set
             */
            constructor(properties?: search.SeriesGroupAPIKey.ISeriesGroupIndices);

            /** SeriesGroupIndices seriesGroupMaster. */
            public seriesGroupMaster?: (string);

            /**
             * Gets the default type url for SeriesGroupIndices
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a UserAPIKey. */
    export interface IUserAPIKey {

        /** UserAPIKey key */
        key?: (search.ISearchAPIKey);

        /** UserAPIKey indices */
        indices?: (search.UserAPIKey.IUserIndices);
    }

    /** Represents a UserAPIKey. */
    class UserAPIKey implements IUserAPIKey {

        /**
         * Constructs a new UserAPIKey.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.IUserAPIKey);

        /** UserAPIKey key. */
        public key?: (search.ISearchAPIKey);

        /** UserAPIKey indices. */
        public indices?: (search.UserAPIKey.IUserIndices);

        /**
         * Gets the default type url for UserAPIKey
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace UserAPIKey {

        /** Properties of a UserIndices. */
        export interface IUserIndices {

            /** UserIndices userMaster */
            userMaster?: (string);
        }

        /** Represents a UserIndices. */
        class UserIndices implements IUserIndices {

            /**
             * Constructs a new UserIndices.
             * @param [properties] Properties to set
             */
            constructor(properties?: search.UserAPIKey.IUserIndices);

            /** UserIndices userMaster. */
            public userMaster?: (string);

            /**
             * Gets the default type url for UserIndices
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }

    /** Properties of a SearchIssueAPIKeysResponse. */
    export interface ISearchIssueAPIKeysResponse {

        /** SearchIssueAPIKeysResponse appId */
        appId?: (string);

        /** SearchIssueAPIKeysResponse story */
        story?: (search.IStoryAPIKey);

        /** SearchIssueAPIKeysResponse series */
        series?: (search.ISeriesAPIKey);

        /** SearchIssueAPIKeysResponse user */
        user?: (search.IUserAPIKey);

        /** SearchIssueAPIKeysResponse seriesGroup */
        seriesGroup?: (search.ISeriesGroupAPIKey);
    }

    /** Represents a SearchIssueAPIKeysResponse. */
    class SearchIssueAPIKeysResponse implements ISearchIssueAPIKeysResponse {

        /**
         * Constructs a new SearchIssueAPIKeysResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: search.ISearchIssueAPIKeysResponse);

        /** SearchIssueAPIKeysResponse appId. */
        public appId?: (string);

        /** SearchIssueAPIKeysResponse story. */
        public story?: (search.IStoryAPIKey);

        /** SearchIssueAPIKeysResponse series. */
        public series?: (search.ISeriesAPIKey);

        /** SearchIssueAPIKeysResponse user. */
        public user?: (search.IUserAPIKey);

        /** SearchIssueAPIKeysResponse seriesGroup. */
        public seriesGroup?: (search.ISeriesGroupAPIKey);

        /**
         * Gets the default type url for SearchIssueAPIKeysResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace search_top. */
export namespace search_top {

    /** Properties of a SearchTopGetResponseV2. */
    export interface ISearchTopGetResponseV2 {

        /** SearchTopGetResponseV2 pickupStorySelection */
        pickupStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 seriesStorySelection */
        seriesStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 risingStorySelection */
        risingStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 freeStorySelection */
        freeStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 newStorySelection */
        newStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 favoriteStorySelection */
        favoriteStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 advertisementStorySelection */
        advertisementStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 horrorStorySelection */
        horrorStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 mysteryStorySelection */
        mysteryStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 adultRomanceStorySelection */
        adultRomanceStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 youthRomanceStorySelection */
        youthRomanceStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 dramaStorySelection */
        dramaStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 fantasyStorySelection */
        fantasyStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 completedSeriesStorySelection */
        completedSeriesStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 horrorRelatedTagSelection */
        horrorRelatedTagSelection?: (tag.ITagSelection);

        /** SearchTopGetResponseV2 romanceRelatedTagSelection */
        romanceRelatedTagSelection?: (tag.ITagSelection);

        /** SearchTopGetResponseV2 dailyRankingStorySelection */
        dailyRankingStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 newArrivalRankingStorySelection */
        newArrivalRankingStorySelection?: (story_selection.IStorySelectionV3);
    }

    /** Represents a SearchTopGetResponseV2. */
    class SearchTopGetResponseV2 implements ISearchTopGetResponseV2 {

        /**
         * Constructs a new SearchTopGetResponseV2.
         * @param [properties] Properties to set
         */
        constructor(properties?: search_top.ISearchTopGetResponseV2);

        /** SearchTopGetResponseV2 pickupStorySelection. */
        public pickupStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 seriesStorySelection. */
        public seriesStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 risingStorySelection. */
        public risingStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 freeStorySelection. */
        public freeStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 newStorySelection. */
        public newStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 favoriteStorySelection. */
        public favoriteStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 advertisementStorySelection. */
        public advertisementStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 horrorStorySelection. */
        public horrorStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 mysteryStorySelection. */
        public mysteryStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 adultRomanceStorySelection. */
        public adultRomanceStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 youthRomanceStorySelection. */
        public youthRomanceStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 dramaStorySelection. */
        public dramaStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 fantasyStorySelection. */
        public fantasyStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 completedSeriesStorySelection. */
        public completedSeriesStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 horrorRelatedTagSelection. */
        public horrorRelatedTagSelection?: (tag.ITagSelection);

        /** SearchTopGetResponseV2 romanceRelatedTagSelection. */
        public romanceRelatedTagSelection?: (tag.ITagSelection);

        /** SearchTopGetResponseV2 dailyRankingStorySelection. */
        public dailyRankingStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponseV2 newArrivalRankingStorySelection. */
        public newArrivalRankingStorySelection?: (story_selection.IStorySelectionV3);

        /**
         * Gets the default type url for SearchTopGetResponseV2
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SearchTopGetResponse. */
    export interface ISearchTopGetResponse {

        /** SearchTopGetResponse pickupStorySelection */
        pickupStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse seriesStorySelection */
        seriesStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse risingStorySelection */
        risingStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse freeStorySelection */
        freeStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse newStorySelection */
        newStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse favoriteStorySelection */
        favoriteStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse updateStorySelection */
        updateStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse advertisementStorySelection */
        advertisementStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse horrorStorySelection */
        horrorStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse romanceStorySelection */
        romanceStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse schoolStorySelection */
        schoolStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse mysteryStorySelection */
        mysteryStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse fantasyStorySelection */
        fantasyStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse completedSeriesStorySelection */
        completedSeriesStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse horrorRelatedTagSelection */
        horrorRelatedTagSelection?: (tag.ITagSelection);

        /** SearchTopGetResponse romanceRelatedTagSelection */
        romanceRelatedTagSelection?: (tag.ITagSelection);

        /** SearchTopGetResponse dailyRankingStorySelection */
        dailyRankingStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse newArrivalRankingStorySelection */
        newArrivalRankingStorySelection?: (story_selection.IStorySelectionV3);
    }

    /** Represents a SearchTopGetResponse. */
    class SearchTopGetResponse implements ISearchTopGetResponse {

        /**
         * Constructs a new SearchTopGetResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: search_top.ISearchTopGetResponse);

        /** SearchTopGetResponse pickupStorySelection. */
        public pickupStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse seriesStorySelection. */
        public seriesStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse risingStorySelection. */
        public risingStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse freeStorySelection. */
        public freeStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse newStorySelection. */
        public newStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse favoriteStorySelection. */
        public favoriteStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse updateStorySelection. */
        public updateStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse advertisementStorySelection. */
        public advertisementStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse horrorStorySelection. */
        public horrorStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse romanceStorySelection. */
        public romanceStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse schoolStorySelection. */
        public schoolStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse mysteryStorySelection. */
        public mysteryStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse fantasyStorySelection. */
        public fantasyStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse completedSeriesStorySelection. */
        public completedSeriesStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse horrorRelatedTagSelection. */
        public horrorRelatedTagSelection?: (tag.ITagSelection);

        /** SearchTopGetResponse romanceRelatedTagSelection. */
        public romanceRelatedTagSelection?: (tag.ITagSelection);

        /** SearchTopGetResponse dailyRankingStorySelection. */
        public dailyRankingStorySelection?: (story_selection.IStorySelectionV3);

        /** SearchTopGetResponse newArrivalRankingStorySelection. */
        public newArrivalRankingStorySelection?: (story_selection.IStorySelectionV3);

        /**
         * Gets the default type url for SearchTopGetResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace tag. */
export namespace tag {

    /** Properties of a TagSelection. */
    export interface ITagSelection {

        /** TagSelection tagList */
        tagList?: (string[]);
    }

    /** Represents a TagSelection. */
    class TagSelection implements ITagSelection {

        /**
         * Constructs a new TagSelection.
         * @param [properties] Properties to set
         */
        constructor(properties?: tag.ITagSelection);

        /** TagSelection tagList. */
        public tagList: string[];

        /**
         * Gets the default type url for TagSelection
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a TagListHotRequest. */
    export interface ITagListHotRequest {

        /** TagListHotRequest includeEvent */
        includeEvent?: (boolean);
    }

    /** Represents a TagListHotRequest. */
    class TagListHotRequest implements ITagListHotRequest {

        /**
         * Constructs a new TagListHotRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: tag.ITagListHotRequest);

        /** TagListHotRequest includeEvent. */
        public includeEvent?: (boolean);

        /**
         * Gets the default type url for TagListHotRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace series_group. */
export namespace series_group {

    /** Properties of a SeriesGroup. */
    export interface ISeriesGroup {

        /** SeriesGroup id */
        id?: (string);

        /** SeriesGroup userId */
        userId?: (string);

        /** SeriesGroup title */
        title?: (string);

        /** SeriesGroup description */
        description?: (string);

        /** SeriesGroup sharedWithStatus */
        sharedWithStatus?: (types.SharedWithStatus);

        /** SeriesGroup seriesList */
        seriesList?: (series.ISeries[]);

        /** SeriesGroup createdAt */
        createdAt?: (string);

        /** SeriesGroup updatedAt */
        updatedAt?: (string);
    }

    /** Represents a SeriesGroup. */
    class SeriesGroup implements ISeriesGroup {

        /**
         * Constructs a new SeriesGroup.
         * @param [properties] Properties to set
         */
        constructor(properties?: series_group.ISeriesGroup);

        /** SeriesGroup id. */
        public id?: (string);

        /** SeriesGroup userId. */
        public userId?: (string);

        /** SeriesGroup title. */
        public title?: (string);

        /** SeriesGroup description. */
        public description?: (string);

        /** SeriesGroup sharedWithStatus. */
        public sharedWithStatus: types.SharedWithStatus;

        /** SeriesGroup seriesList. */
        public seriesList: series.ISeries[];

        /** SeriesGroup createdAt. */
        public createdAt?: (string);

        /** SeriesGroup updatedAt. */
        public updatedAt?: (string);

        /**
         * Gets the default type url for SeriesGroup
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a SeriesGroupIdRequest. */
    export interface ISeriesGroupIdRequest {

        /** SeriesGroupIdRequest id */
        id?: (string);
    }

    /** Represents a SeriesGroupIdRequest. */
    class SeriesGroupIdRequest implements ISeriesGroupIdRequest {

        /**
         * Constructs a new SeriesGroupIdRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: series_group.ISeriesGroupIdRequest);

        /** SeriesGroupIdRequest id. */
        public id?: (string);

        /**
         * Gets the default type url for SeriesGroupIdRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace stamp. */
export namespace stamp {

    /** Properties of a ChallengeCondition. */
    export interface IChallengeCondition {

        /** ChallengeCondition requiredChallengeId */
        requiredChallengeId?: (string);

        /** ChallengeCondition requiredLevel */
        requiredLevel?: (number);
    }

    /** Represents a ChallengeCondition. */
    class ChallengeCondition implements IChallengeCondition {

        /**
         * Constructs a new ChallengeCondition.
         * @param [properties] Properties to set
         */
        constructor(properties?: stamp.IChallengeCondition);

        /** ChallengeCondition requiredChallengeId. */
        public requiredChallengeId?: (string);

        /** ChallengeCondition requiredLevel. */
        public requiredLevel?: (number);

        /**
         * Gets the default type url for ChallengeCondition
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StampMaster. */
    export interface IStampMaster {

        /** StampMaster id */
        id?: (string);

        /** StampMaster image */
        image?: (resource.IImage);

        /** StampMaster altText */
        altText?: (string);

        /** StampMaster updatedAt */
        updatedAt?: (string);

        /** StampMaster challengeCondition */
        challengeCondition?: (stamp.IChallengeCondition);
    }

    /** Represents a StampMaster. */
    class StampMaster implements IStampMaster {

        /**
         * Constructs a new StampMaster.
         * @param [properties] Properties to set
         */
        constructor(properties?: stamp.IStampMaster);

        /** StampMaster id. */
        public id?: (string);

        /** StampMaster image. */
        public image?: (resource.IImage);

        /** StampMaster altText. */
        public altText?: (string);

        /** StampMaster updatedAt. */
        public updatedAt?: (string);

        /** StampMaster challengeCondition. */
        public challengeCondition?: (stamp.IChallengeCondition);

        /**
         * Gets the default type url for StampMaster
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a Stamp. */
    export interface IStamp {

        /** Stamp userId */
        userId?: (string);

        /** Stamp stamp */
        stamp?: (stamp.IStampMaster);

        /** Stamp gainedAt */
        gainedAt?: (string);
    }

    /** Represents a Stamp. */
    class Stamp implements IStamp {

        /**
         * Constructs a new Stamp.
         * @param [properties] Properties to set
         */
        constructor(properties?: stamp.IStamp);

        /** Stamp userId. */
        public userId?: (string);

        /** Stamp stamp. */
        public stamp?: (stamp.IStampMaster);

        /** Stamp gainedAt. */
        public gainedAt?: (string);

        /**
         * Gets the default type url for Stamp
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StampListMasterRequest. */
    export interface IStampListMasterRequest {

        /** StampListMasterRequest limit */
        limit?: (number);

        /** StampListMasterRequest cursor */
        cursor?: (string);
    }

    /** Represents a StampListMasterRequest. */
    class StampListMasterRequest implements IStampListMasterRequest {

        /**
         * Constructs a new StampListMasterRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: stamp.IStampListMasterRequest);

        /** StampListMasterRequest limit. */
        public limit?: (number);

        /** StampListMasterRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for StampListMasterRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StampListMasterResponse. */
    export interface IStampListMasterResponse {

        /** StampListMasterResponse masterList */
        masterList?: (stamp.IStampMaster[]);

        /** StampListMasterResponse cursor */
        cursor?: (string);

        /** StampListMasterResponse lastUpdatedAt */
        lastUpdatedAt?: (string);
    }

    /** Represents a StampListMasterResponse. */
    class StampListMasterResponse implements IStampListMasterResponse {

        /**
         * Constructs a new StampListMasterResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: stamp.IStampListMasterResponse);

        /** StampListMasterResponse masterList. */
        public masterList: stamp.IStampMaster[];

        /** StampListMasterResponse cursor. */
        public cursor?: (string);

        /** StampListMasterResponse lastUpdatedAt. */
        public lastUpdatedAt?: (string);

        /**
         * Gets the default type url for StampListMasterResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StampListRequest. */
    export interface IStampListRequest {

        /** StampListRequest limit */
        limit?: (number);

        /** StampListRequest cursor */
        cursor?: (string);
    }

    /** Represents a StampListRequest. */
    class StampListRequest implements IStampListRequest {

        /**
         * Constructs a new StampListRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: stamp.IStampListRequest);

        /** StampListRequest limit. */
        public limit?: (number);

        /** StampListRequest cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for StampListRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StampListResponse. */
    export interface IStampListResponse {

        /** StampListResponse stampList */
        stampList?: (stamp.IStamp[]);

        /** StampListResponse cursor */
        cursor?: (string);
    }

    /** Represents a StampListResponse. */
    class StampListResponse implements IStampListResponse {

        /**
         * Constructs a new StampListResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: stamp.IStampListResponse);

        /** StampListResponse stampList. */
        public stampList: stamp.IStamp[];

        /** StampListResponse cursor. */
        public cursor?: (string);

        /**
         * Gets the default type url for StampListResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace story_advertisement. */
export namespace story_advertisement {

    /** Properties of a StoryAdvertisement. */
    export interface IStoryAdvertisement {

        /** StoryAdvertisement id */
        id?: (string);

        /** StoryAdvertisement title */
        title?: (string);

        /** StoryAdvertisement description */
        description?: (string);

        /** StoryAdvertisement thumbnail */
        thumbnail?: (resource.IImage);

        /** StoryAdvertisement linkUrl */
        linkUrl?: (string);
    }

    /** Represents a StoryAdvertisement. */
    class StoryAdvertisement implements IStoryAdvertisement {

        /**
         * Constructs a new StoryAdvertisement.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_advertisement.IStoryAdvertisement);

        /** StoryAdvertisement id. */
        public id?: (string);

        /** StoryAdvertisement title. */
        public title?: (string);

        /** StoryAdvertisement description. */
        public description?: (string);

        /** StoryAdvertisement thumbnail. */
        public thumbnail?: (resource.IImage);

        /** StoryAdvertisement linkUrl. */
        public linkUrl?: (string);

        /**
         * Gets the default type url for StoryAdvertisement
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementList. */
    export interface IStoryAdvertisementList {

        /** StoryAdvertisementList storyAdvertisementList */
        storyAdvertisementList?: (story_advertisement.IStoryAdvertisement[]);
    }

    /** Represents a StoryAdvertisementList. */
    class StoryAdvertisementList implements IStoryAdvertisementList {

        /**
         * Constructs a new StoryAdvertisementList.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_advertisement.IStoryAdvertisementList);

        /** StoryAdvertisementList storyAdvertisementList. */
        public storyAdvertisementList: story_advertisement.IStoryAdvertisement[];

        /**
         * Gets the default type url for StoryAdvertisementList
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementV1. */
    export interface IStoryAdvertisementV1 {

        /** StoryAdvertisementV1 id */
        id?: (string);

        /** StoryAdvertisementV1 title */
        title?: (string);

        /** StoryAdvertisementV1 description */
        description?: (string);

        /** StoryAdvertisementV1 thumbnailUrl */
        thumbnailUrl?: (string);

        /** StoryAdvertisementV1 linkUrl */
        linkUrl?: (string);
    }

    /** Represents a StoryAdvertisementV1. */
    class StoryAdvertisementV1 implements IStoryAdvertisementV1 {

        /**
         * Constructs a new StoryAdvertisementV1.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_advertisement.IStoryAdvertisementV1);

        /** StoryAdvertisementV1 id. */
        public id?: (string);

        /** StoryAdvertisementV1 title. */
        public title?: (string);

        /** StoryAdvertisementV1 description. */
        public description?: (string);

        /** StoryAdvertisementV1 thumbnailUrl. */
        public thumbnailUrl?: (string);

        /** StoryAdvertisementV1 linkUrl. */
        public linkUrl?: (string);

        /**
         * Gets the default type url for StoryAdvertisementV1
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementListRequest. */
    export interface IStoryAdvertisementListRequest {

        /** StoryAdvertisementListRequest storyId */
        storyId?: (string);
    }

    /** Represents a StoryAdvertisementListRequest. */
    class StoryAdvertisementListRequest implements IStoryAdvertisementListRequest {

        /**
         * Constructs a new StoryAdvertisementListRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_advertisement.IStoryAdvertisementListRequest);

        /** StoryAdvertisementListRequest storyId. */
        public storyId?: (string);

        /**
         * Gets the default type url for StoryAdvertisementListRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a StoryAdvertisementListResponse. */
    export interface IStoryAdvertisementListResponse {

        /** StoryAdvertisementListResponse storyAdvertisementList */
        storyAdvertisementList?: (story_advertisement.IStoryAdvertisementV1[]);
    }

    /** Represents a StoryAdvertisementListResponse. */
    class StoryAdvertisementListResponse implements IStoryAdvertisementListResponse {

        /**
         * Constructs a new StoryAdvertisementListResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: story_advertisement.IStoryAdvertisementListResponse);

        /** StoryAdvertisementListResponse storyAdvertisementList. */
        public storyAdvertisementList: story_advertisement.IStoryAdvertisementV1[];

        /**
         * Gets the default type url for StoryAdvertisementListResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }
}

/** Namespace vip. */
export namespace vip {

    /** VipOfferType enum. */
    export enum VipOfferType {
        VIP_OFFER_TYPE_UNSPECIFIED = "VIP_OFFER_TYPE_UNSPECIFIED",
        VIP_OFFER_TYPE_NORMAL = "VIP_OFFER_TYPE_NORMAL",
        VIP_OFFER_TYPE_CAMPAIGN = "VIP_OFFER_TYPE_CAMPAIGN"
    }

    /** VipPromotionOfferType enum. */
    export enum VipPromotionOfferType {
        VIP_PROMOTION_OFFER_TYPE_UNSPECIFIED = "VIP_PROMOTION_OFFER_TYPE_UNSPECIFIED",
        VIP_PROMOTION_OFFER_TYPE_NONE = "VIP_PROMOTION_OFFER_TYPE_NONE",
        VIP_PROMOTION_OFFER_TYPE_6_MONTH_DISCOUNT = "_MONTH_DISCOUNT",
        VIP_PROMOTION_OFFER_TYPE_2_MONTH_FREE = "_MONTH_FREE",
        VIP_PROMOTION_OFFER_TYPE_NEW_YEAR_CAMPAIGN = "VIP_PROMOTION_OFFER_TYPE_NEW_YEAR_CAMPAIGN",
        VIP_PROMOTION_OFFER_TYPE_FREE_THREE_DAY_FOR_A_YEAR = "VIP_PROMOTION_OFFER_TYPE_FREE_THREE_DAY_FOR_A_YEAR"
    }

    /** Properties of a VipGetOfferTypeResponse. */
    export interface IVipGetOfferTypeResponse {

        /** VipGetOfferTypeResponse offerType */
        offerType?: (vip.VipOfferType);

        /** VipGetOfferTypeResponse expiresAt */
        expiresAt?: (string);
    }

    /** Represents a VipGetOfferTypeResponse. */
    class VipGetOfferTypeResponse implements IVipGetOfferTypeResponse {

        /**
         * Constructs a new VipGetOfferTypeResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipGetOfferTypeResponse);

        /** VipGetOfferTypeResponse offerType. */
        public offerType: vip.VipOfferType;

        /** VipGetOfferTypeResponse expiresAt. */
        public expiresAt?: (string);

        /**
         * Gets the default type url for VipGetOfferTypeResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VipGetPromotionOfferResponse. */
    export interface IVipGetPromotionOfferResponse {

        /** VipGetPromotionOfferResponse promotionOfferType */
        promotionOfferType?: (vip.VipPromotionOfferType);
    }

    /** Represents a VipGetPromotionOfferResponse. */
    class VipGetPromotionOfferResponse implements IVipGetPromotionOfferResponse {

        /**
         * Constructs a new VipGetPromotionOfferResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipGetPromotionOfferResponse);

        /** VipGetPromotionOfferResponse promotionOfferType. */
        public promotionOfferType: vip.VipPromotionOfferType;

        /**
         * Gets the default type url for VipGetPromotionOfferResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VipClosePromotionOfferRequest. */
    export interface IVipClosePromotionOfferRequest {

        /** VipClosePromotionOfferRequest promotionOfferType */
        promotionOfferType?: (vip.VipPromotionOfferType);

        /** VipClosePromotionOfferRequest accepted */
        accepted?: (boolean);
    }

    /** Represents a VipClosePromotionOfferRequest. */
    class VipClosePromotionOfferRequest implements IVipClosePromotionOfferRequest {

        /**
         * Constructs a new VipClosePromotionOfferRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipClosePromotionOfferRequest);

        /** VipClosePromotionOfferRequest promotionOfferType. */
        public promotionOfferType: vip.VipPromotionOfferType;

        /** VipClosePromotionOfferRequest accepted. */
        public accepted?: (boolean);

        /**
         * Gets the default type url for VipClosePromotionOfferRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VipSubscribeAppStoreRequest. */
    export interface IVipSubscribeAppStoreRequest {

        /** VipSubscribeAppStoreRequest receiptData */
        receiptData?: (string);
    }

    /** Represents a VipSubscribeAppStoreRequest. */
    class VipSubscribeAppStoreRequest implements IVipSubscribeAppStoreRequest {

        /**
         * Constructs a new VipSubscribeAppStoreRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipSubscribeAppStoreRequest);

        /** VipSubscribeAppStoreRequest receiptData. */
        public receiptData?: (string);

        /**
         * Gets the default type url for VipSubscribeAppStoreRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VipSubscribePlayStoreRequest. */
    export interface IVipSubscribePlayStoreRequest {

        /** VipSubscribePlayStoreRequest packageName */
        packageName?: (string);

        /** VipSubscribePlayStoreRequest subscriptionId */
        subscriptionId?: (string);

        /** VipSubscribePlayStoreRequest token */
        token?: (string);
    }

    /** Represents a VipSubscribePlayStoreRequest. */
    class VipSubscribePlayStoreRequest implements IVipSubscribePlayStoreRequest {

        /**
         * Constructs a new VipSubscribePlayStoreRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipSubscribePlayStoreRequest);

        /** VipSubscribePlayStoreRequest packageName. */
        public packageName?: (string);

        /** VipSubscribePlayStoreRequest subscriptionId. */
        public subscriptionId?: (string);

        /** VipSubscribePlayStoreRequest token. */
        public token?: (string);

        /**
         * Gets the default type url for VipSubscribePlayStoreRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VipGenerateAppStorePromotionOfferSignatureRequest. */
    export interface IVipGenerateAppStorePromotionOfferSignatureRequest {

        /** VipGenerateAppStorePromotionOfferSignatureRequest appBundleId */
        appBundleId?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureRequest keyIdentifier */
        keyIdentifier?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureRequest productIdentifier */
        productIdentifier?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureRequest offerIdentifier */
        offerIdentifier?: (string);
    }

    /** Represents a VipGenerateAppStorePromotionOfferSignatureRequest. */
    class VipGenerateAppStorePromotionOfferSignatureRequest implements IVipGenerateAppStorePromotionOfferSignatureRequest {

        /**
         * Constructs a new VipGenerateAppStorePromotionOfferSignatureRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipGenerateAppStorePromotionOfferSignatureRequest);

        /** VipGenerateAppStorePromotionOfferSignatureRequest appBundleId. */
        public appBundleId?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureRequest keyIdentifier. */
        public keyIdentifier?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureRequest productIdentifier. */
        public productIdentifier?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureRequest offerIdentifier. */
        public offerIdentifier?: (string);

        /**
         * Gets the default type url for VipGenerateAppStorePromotionOfferSignatureRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VipGenerateAppStorePromotionOfferSignatureResponse. */
    export interface IVipGenerateAppStorePromotionOfferSignatureResponse {

        /** VipGenerateAppStorePromotionOfferSignatureResponse signature */
        signature?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureResponse nonce */
        nonce?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureResponse unixtimeMs */
        unixtimeMs?: (number);

        /** VipGenerateAppStorePromotionOfferSignatureResponse keyIdentifier */
        keyIdentifier?: (string);
    }

    /** Represents a VipGenerateAppStorePromotionOfferSignatureResponse. */
    class VipGenerateAppStorePromotionOfferSignatureResponse implements IVipGenerateAppStorePromotionOfferSignatureResponse {

        /**
         * Constructs a new VipGenerateAppStorePromotionOfferSignatureResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipGenerateAppStorePromotionOfferSignatureResponse);

        /** VipGenerateAppStorePromotionOfferSignatureResponse signature. */
        public signature?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureResponse nonce. */
        public nonce?: (string);

        /** VipGenerateAppStorePromotionOfferSignatureResponse unixtimeMs. */
        public unixtimeMs?: (number);

        /** VipGenerateAppStorePromotionOfferSignatureResponse keyIdentifier. */
        public keyIdentifier?: (string);

        /**
         * Gets the default type url for VipGenerateAppStorePromotionOfferSignatureResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VipGetTrialAvailabilityAppStoreRequest. */
    export interface IVipGetTrialAvailabilityAppStoreRequest {

        /** VipGetTrialAvailabilityAppStoreRequest receiptData */
        receiptData?: (string);
    }

    /** Represents a VipGetTrialAvailabilityAppStoreRequest. */
    class VipGetTrialAvailabilityAppStoreRequest implements IVipGetTrialAvailabilityAppStoreRequest {

        /**
         * Constructs a new VipGetTrialAvailabilityAppStoreRequest.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipGetTrialAvailabilityAppStoreRequest);

        /** VipGetTrialAvailabilityAppStoreRequest receiptData. */
        public receiptData?: (string);

        /**
         * Gets the default type url for VipGetTrialAvailabilityAppStoreRequest
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    /** Properties of a VipGetTrialAvailabilityAppStoreResponse. */
    export interface IVipGetTrialAvailabilityAppStoreResponse {

        /** VipGetTrialAvailabilityAppStoreResponse trialAvailabilityList */
        trialAvailabilityList?: (vip.VipGetTrialAvailabilityAppStoreResponse.ITrialAvailability[]);
    }

    /** Represents a VipGetTrialAvailabilityAppStoreResponse. */
    class VipGetTrialAvailabilityAppStoreResponse implements IVipGetTrialAvailabilityAppStoreResponse {

        /**
         * Constructs a new VipGetTrialAvailabilityAppStoreResponse.
         * @param [properties] Properties to set
         */
        constructor(properties?: vip.IVipGetTrialAvailabilityAppStoreResponse);

        /** VipGetTrialAvailabilityAppStoreResponse trialAvailabilityList. */
        public trialAvailabilityList: vip.VipGetTrialAvailabilityAppStoreResponse.ITrialAvailability[];

        /**
         * Gets the default type url for VipGetTrialAvailabilityAppStoreResponse
         * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns The default type url
         */
        public static getTypeUrl(typeUrlPrefix?: string): string;
    }

    export namespace VipGetTrialAvailabilityAppStoreResponse {

        /** Properties of a TrialAvailability. */
        export interface ITrialAvailability {

            /** TrialAvailability subscriptionGroupIdentifier */
            subscriptionGroupIdentifier?: (string);

            /** TrialAvailability isAvailable */
            isAvailable?: (boolean);
        }

        /** Represents a TrialAvailability. */
        class TrialAvailability implements ITrialAvailability {

            /**
             * Constructs a new TrialAvailability.
             * @param [properties] Properties to set
             */
            constructor(properties?: vip.VipGetTrialAvailabilityAppStoreResponse.ITrialAvailability);

            /** TrialAvailability subscriptionGroupIdentifier. */
            public subscriptionGroupIdentifier?: (string);

            /** TrialAvailability isAvailable. */
            public isAvailable?: (boolean);

            /**
             * Gets the default type url for TrialAvailability
             * @param [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns The default type url
             */
            public static getTypeUrl(typeUrlPrefix?: string): string;
        }
    }
}
